import React from 'react';
import { Pagination } from 'react-bootstrap';

require('../stylesheets/app.scss');

const default_total_pages = 1;
const default_current_page = 1;

export default class ListComponentPagination extends React.Component {
  constructor (props) {
    super(props);

    this.handlePageChange = this.handlePageChange.bind(this);
  }
  
  handlePageChange (e) {
    this.props.location.query.page = e;
    this.props.changePage();
  }

  render () {
    var meta = this.props.meta; 
    
    return(
      <div className="list-component-pagination">
        <Pagination
          first
          last
          ellipsis
          boundaryLinks
          items={(meta && meta.total_pages ? meta.total_pages : default_total_pages)}
          maxButtons={5}
          activePage={(meta && meta.current_page ? meta.current_page : default_current_page)}
          onSelect={(event)=>this.handlePageChange(event)} />
      </div>
    );
  }
}

ListComponentPagination.propTypes = {
  changePage: React.PropTypes.func,
  location: React.PropTypes.object,
  meta: React.PropTypes.object
};