/* global apiBaseUrl */

// global enumerations

export var ENUM_statuses = [
  { "id": "lead", "name": "Lead" },
  { "id": "active", "name": "Active" },
  { "id": "stemmed", "name": "Stemmed" },
  { "id": "delivered", "name": "Delivered" },
  { "id": "finalized", "name": "Finalized" },
  { "id": "canceled", "name": "Canceled" },
  { "id": "failed", "name": "Failed" },
  { "id": "contract", "name": "Contract" }
];

export var ENUM_currencies = [
  { "id": "EUR", "name": "EUR", "description": "Euros", "active": true },
  { "id": "USD", "name": "USD", "description": "US Dollars", "active": true },
  { "id": "RUB", "name": "RUB", "description": "Rubles", "active": true }
];

// id values are capitalized, because BE does that automatically too
export var ENUM_implementers = [
  { "id": "Woodtracker", "name": "Woodtracker Oy", "active": true },
  { "id": "Wega", "name": "Wega", "active": true }
];

export var ENUM_unitTypes = [
  { "id": "mt", "name": "mt", "description": "metric ton", "active": true },
  { "id": "mt (dry)", "name": "mt (dry)", "description": "metric ton (dry)", "active": true },
  { "id": "cbm (loose)", "name": "cbm (loose)", "description": "cubic meter (loose)", "active": true },
  { "id": "cbm (solid)", "name": "cbm (solid)", "description": "cubic meter (solid)", "active": true },
  { "id": "MWh (LHV)", "name": "MWh (LHV)", "description": "", "active": true }
];

export var ENUM_legPricingUnits = [
  { "id": "mt", "name": "mt", "description": "(per metric ton)", "active": true },
  { "id": "cbm", "name": "cbm", "description": "(per cubic meter)", "active": true },
  { "id": "MWh", "name": "MWh", "description": "", "active": true },
  { "id": "lump sum", "name": "lump sum", "description": "", "active": true }
];

export var ENUM_additional_costCostTypes = [
  { "id": "agency fee", "name": "Agency fee", "description": "", "active": true },
  { "id": "port fee", "name": "Port fee", "description": "", "active": true },
  { "id": "fairway due", "name": "Fairway due", "description": "", "active": true },
  { "id": "demurrage", "name": "Demurrage", "description": "", "active": true },
  { "id": "rebate", "name": "Rebate", "description": "", "active": true },
  { "id": "tax", "name": "Tax", "description": "", "active": true },
  { "id": "customs duty", "name": "Customs duty", "description": "", "active": true },
  { "id": "other", "name": "Other", "description": "", "active": true }
];

export var ENUM_creditTerms = [
  { "id": "7", "name": "7", "active": true },
  { "id": "14", "name": "14", "active": true },
  { "id": "21", "name": "21", "active": true },
  { "id": "30", "name": "30", "active": true },
  { "id": "45", "name": "45", "active": true }
];

export var ENUM_incoterms = [
  { "id": "1", "name": "EXW", "active": true },
  { "id": "2", "name": "FCA", "active": true },
  { "id": "3", "name": "CPT", "active": true },
  { "id": "4", "name": "CIP", "active": true },
  { "id": "5", "name": "DAT", "active": true },
  { "id": "6", "name": "DAP", "active": true },
  { "id": "7", "name": "DDP", "active": true },
  { "id": "8", "name": "FAS", "active": true },
  { "id": "9", "name": "FOB", "active": true },
  { "id": "10", "name": "CFR", "active": true },
  { "id": "11", "name": "CIF", "active": true }
];

export var ENUM_productTypes = [
  { "id": "pellet", "name": "Pellet", "active": true },
  { "id": "chip", "name": "Chip", "active": true },
  { "id": "log", "name": "Log", "active": true }
];

export var ENUM_freightMethods = [
  { "id": "train", "name": "Train", "ionIconClass": "ion-android-train", "active": true },
  { "id": "truck", "name": "Truck", "ionIconClass": "ion-android-bus", "active": true }, 
  { "id": "vessel", "name": "Vessel", "ionIconClass": "ion-android-boat", "active": true }
];

export var ENUM_freightInsuredBy = [
  { "id": "N/A", "name": "N/A", "active": true },
  { "id": "Buyer", "name": "Buyer", "active": true },
  { "id": "Seller", "name": "Seller", "active": true }
];