import React from 'react';
import { Button, ButtonGroup, Modal } from 'react-bootstrap';

export default class FormHeader extends React.Component {
  constructor (props) {
    super(props);
  }

  render () {
    const currentStep = this.props.currentStep,
      title = this.props.title;
    
    return(
      <Modal.Header closeButton={true}>
        <Modal.Title>{title}</Modal.Title>
        <ButtonGroup>
          {this.props.buttons && this.props.buttons.map(function(button, i){
            return <Button key={i} className={((currentStep - 1) === i) ? 'active':''}>{button}</Button>;
          })}
        </ButtonGroup>
      </Modal.Header>
    );
  }
}

FormHeader.propTypes = {
  buttons: React.PropTypes.array.isRequired,
  currentStep: React.PropTypes.number.isRequired,
  title: React.PropTypes.string.isRequired
};