/* global apiBaseUrl */

'use strict';

require('../../stylesheets/app.scss');
require('../../stylesheets/components/detailPage.scss');

import React from 'react';
import { Col, ControlLabel, Form, FormControl, FormGroup, Row } from 'react-bootstrap';
import ActionBar from '../../components/ActionBar';
import FormAddFileToList from '../../components/FormAddFileToList';
import { handleInputChange, addFileFormItem, deleteFileFormItem, fetchList, handleSessionStorage, showLoadingIndicator, parseFetchError, setItemActiveStatus, deleteItem, cancelEdit } from '../../actions/utility';

export default class Certificate extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      form: {
        active: null,
        name: '',
        document: '',
        documents: [],
        documentsToDelete: [],
        additional_info: ''
      },
      buttonVisibility: [true, true, false, false, true],
      requestPending: false
      // countries: [],
    };
    
    this.fetchCertificate = this.fetchCertificate.bind(this);
    this.updateCertificate = this.updateCertificate.bind(this);

    this.setItemActiveStatus = setItemActiveStatus.bind(this);
    this.deleteItem = deleteItem.bind(this);
    this.cancelEdit = cancelEdit.bind(this);

    this.handleInputChange = handleInputChange.bind(this);
    this.addFileFormItem = addFileFormItem.bind(this);
    this.deleteFileFormItem = deleteFileFormItem.bind(this);
    this.fetchList = fetchList.bind(this);
    this.handleSessionStorage = handleSessionStorage.bind(this);
    
    // this.fetchCertificate();
    
    this.buttons = [
      {
        formId: '',
        icon: 'ion-checkmark',
        onClick: ()=>this.updateCertificate(),
        refreshFunc: '',
        text: 'save changes'
      },
      {
        formId: '',
        icon: 'ion-power',
        onClick: ()=>this.setItemActiveStatus(false, 'certificate', 'certificates'),
        refreshFunc: '',
        text: 'deactivate certificate'
      },
      {
        formId: '',
        icon: 'ion-power',
        onClick: ()=>this.setItemActiveStatus(true, 'certificate', 'certificates'),
        refreshFunc: '',
        text: 'activate certificate'
      },
      {
        formId: '',
        icon: 'ion-android-delete',
        onClick: ()=>this.deleteItem('certificates'),
        refreshFunc: '',
        text: 'delete certificate'
      },
      {
        formId: '',
        icon: 'ion-android-cancel',
        onClick: ()=>this.cancelEdit('certificates'),
        refreshFunc: '',
        text: 'cancel'
      }
    ];
  }

  componentDidMount() {
    this.fetchCertificate();
  }
  
  fetchCertificate () {
    //fetch
    var token = localStorage.getItem('woodtracker_id_token'),
      admin = JSON.parse(localStorage.getItem('admin_boolean')),
      that = this,
      id = this.props.location.query.id;
      
    if (token) {
      var header = new Headers();
      header.append('Accept', 'application/json');
      header.append('Content-Type', 'application/json');
      header.append('Authorization', 'Bearer ' + token);
      
      var url = apiBaseUrl + '/certificates/' + id;
      
      var request = new Request(url, { 
        method: 'GET', 
        headers: header
      });
      
      that.setState({ ...that.state, loading: true });

      fetch(request).then(function(response) {
        that.setState({ ...that.state, loading: false });
        return response.json();
      }).then(function(json) {
        //console.log(json);
        if (admin) {
          (json.certificate.active) ?
            that.setState({ buttonVisibility: [true,true,false,false,true] }):
            that.setState({ buttonVisibility: [false,false,true,false,false] });
        } else {
          (json.certificate.active) ?
            that.setState({ buttonVisibility: [true,true,false,false,true] }):
            that.setState({ buttonVisibility: [false,false,true,false,false] });
        }
        that.setState({ form: {...that.state.form, ...json.certificate} });
      }).catch(function(err) {
        that.setState({ ...that.state, loading: false });
        parseFetchError(err);
      });
    }
  }
  
  updateCertificate () {
    //send new user form
    var token = localStorage.getItem('woodtracker_id_token'),
      that = this;

    if (token) {
      var header = new Headers();
      header.append('Accept', 'application/json');
      // Disable 'application/json' so that the multipart header is automatically set for the request.
      // header.append('Content-Type', 'application/json');
      header.append('Authorization', 'Bearer ' + token);
      
      var findIds = function (arr1, arr2, arr3) {
        for (let i = 0, j = arr2.length; i < j; i++) {
          for (let k = 0, l = arr3.length; k < l; k++) {
            if (typeof arr3[k] === 'string') {
              if (arr3[k] === arr2[i].name) {
                arr1.push(arr2[i].id);
              }
            } else {
              if (arr3[k].id === arr2[i].id) {
                arr1.push(arr2[i].id);
              }
            }
          }
        }
      };

      var certificate = {
        certificate: {
          name: this.state.form.name,
          additional_info: this.state.form.additional_info
        }
      };

      // Files to delete
      var documents_attributes = [];

      this.state.form.documentsToDelete.forEach(function(fileWrapper, i) {
        // Construct deletion list of already uploaded files (those that have 'id')
        if (typeof fileWrapper.id != 'undefined') {
          console.log("File to delete[" + i + "] = " + fileWrapper.id + ", " + fileWrapper.file_name);
          let docAttrs = {};
          docAttrs["id"] = fileWrapper.id;
          docAttrs["_destroy"] = true;
          documents_attributes.push(docAttrs);
        }
      });

      if (typeof documents_attributes != 'undefined' && documents_attributes.length > 0) {
        certificate.certificate.documents_attributes = documents_attributes;
      }

      //// DEBUG
      console.log(JSON.stringify(certificate));
      
      // var request = new Request(apiBaseUrl + '/certificates/' + this.state.form.id, { 
      //   method: 'PUT', 
      //   headers: header,
      //   body: JSON.stringify(certificate)
      // });

      var data = new FormData();
      // Append the form data as JSON (note. Does NOT set content-type to "application/json". Backend has to manually parse the stringified json)
      data.append("certificate", JSON.stringify(certificate.certificate));

      // Append files to upload in the request
      this.state.form.documents.forEach(function(fileWrapper, i) {
        if (fileWrapper.file) {
          console.log("Upload files[" + i + "] = " + fileWrapper.file.name + " (size: " + fileWrapper.file.size + " bytes) ");
          // Only append files with size set and greater than 0
          if (typeof fileWrapper.file != 'undefined' && typeof fileWrapper.file.size != 'undefined' && fileWrapper.file.size > 0) {
            data.append("file_" + i, fileWrapper.file);
          }
        }
      });

      var request = new Request(apiBaseUrl + '/certificates/' + this.state.form.id, { 
        method: 'PUT', 
        headers: header,
        body : data
      });

      that.setState({ ...that.state, requestPending: true });

      fetch(request).then(function(response) {
        that.setState({ ...that.state, requestPending: false });
        if (!response.ok) {
          //throw Error(response.statusText);
          return response.json().then(err => { throw err; });
        }
        //console.log(response);
        return response.text();
      }).then(function(/*text*/) {
        //console.log(text);
        //update the list timestamp to force new data to be loaded
        var r = confirm('Update successful. Do you want to stop editing this certificate?');
        if (r) {
          window.location.hash = '#/certificates';
        } else {
          // Must reload edit view if files were uploaded, etc.
          // window.location.hash = '#/ccertificate?id=' + id;
          that.fetchCertificate();
        }
        sessionStorage.setItem('certificatesListUpdated', new Date().getTime() - 300000);
      }).catch(function(err) {
        that.setState({ ...that.state, requestPending: false });
        parseFetchError(err);
      });
    }
  }
 
  render(){
    return (
      <div className={"detail-page certificate" + ((this.state.requestPending) ? " busy-blocking" : '')}>
        <ActionBar elements={ this.buttons } visibility={ this.state.buttonVisibility } />
        {(this.state.loading) ?
        <div className="detail-page-form loading" />
        :
        <div className="detail-page-form">
          <h1 className="detail-page-header">
            Certificate detail
            {(this.state.form.active !== null) &&
              <span className={'detail-page-order-status ' + (this.state.form.active ? 'stemmed' : 'canceled')}>
                { this.state.form.active ? 'Active' : 'Disabled' }
              </span>
            }
          </h1>
          <Form>
            <FormGroup conrolId="newCertificateInformation">
              <Row>
                <Col componentClass={ControlLabel} sm={4}>
                  Certificate details
                </Col>
                <Col sm={8}>
                  <ControlLabel>Name *</ControlLabel>
                  <FormControl id="name" type="text" value={this.state.form.name || ''} onChange={this.handleInputChange} readOnly={((!this.state.form.active) ? true : false)} />
                </Col>
              </Row>
              <Row>
                <Col componentClass={ControlLabel} sm={4}>
                  Additional files
                </Col>
                <Col sm={8}>
                  <FormAddFileToList 
                    inputId="document"
                    inputValue={this.state.form.document || ''}
                    inputOnChange={this.handleInputChange}
                    addToListFunc={()=>this.addFileFormItem('document','documents')}
                    deleteFromListFunc={(event)=>this.deleteFileFormItem(event,'documents', 'documentsToDelete')}
                    listArray={this.state.form.documents}
                    label=""
                    active={this.state.form.active} />
                </Col>
              </Row>
              <Row>
                <Col componentClass={ControlLabel} sm={4}>
                  Other
                </Col>
                <Col sm={8}>
                  <ControlLabel>Additional information</ControlLabel>
                  <FormControl id="additional_info" componentClass="textarea" value={this.state.form.additional_info || ''} placeholder="" onChange={this.handleInputChange} readOnly={((!this.state.form.active) ? true : false)} />
                </Col>
              </Row>
            </FormGroup>
          </Form>
        </div>}
      </div>
    );
  }
}

Certificate.propTypes = {
  location: React.PropTypes.object.isRequired //if the user id is missing hte user data cannot be fetched
};