import React from 'react';
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';

export default class FilterBarDropdown extends React.Component {
  constructor (props) {
    super(props);
  }

  render () {
    const arr = this.props.arr,
      id = this.props.id,
      label = this.props.label,
      selected = this.props.selected;

    return (
      <FormGroup className="filter-holder dropdown" controlId="formControlsSelect">
        <ControlLabel>{ label }</ControlLabel>
        <FormControl componentClass="select" placeholder="select" value={(selected) ? selected : ''} onChange={this.props.change}>
          <option value="all">All</option>
            {arr.map(function(item, i) {
              switch (id) {
              case 'countries':
                return <option 
                  id={item.country} 
                  key={i} 
                  value={item.country}>
                  {item.country}
                </option>;
              case 'role':
              case 'type':
                return <option 
                  id={item.id} 
                  key={i} 
                  value={item.id}>
                  {item.name}
                </option>;
              default:
                if (item.active) {
                  return <option 
                    key={i} 
                    id={item.id} 
                    value={item.name}>
                    {item.name}
                  </option>;
                }
              }
            })}
        </FormControl>
      </FormGroup>
    );
  }
}

FilterBarDropdown.propTypes = {
  arr: React.PropTypes.array,
  change: React.PropTypes.func,
  id: React.PropTypes.string, //string that determines if the array item needs some special treatments
  label: React.PropTypes.string,
  selected: React.PropTypes.string //string that determines the selected option value parsed from the url
};