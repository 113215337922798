'use strict';

require('../stylesheets/app.scss');

import React from 'react';
import { Button, Col, Form, FormControl, FormGroup, Row } from 'react-bootstrap';

export default class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.state = {
      email: ''
    };
  }
  
  handleChange (e) {
    this.setState({ [e.target.id]: e.target.value });
  }
  // check if enter is pushed when typing in the password input
  handleKeyPress (e) {
    if (e.charCode === 13) {
      this.props.forgotPassword(this.state.email, e);
    }
  }
  
  render () {
    return(
      <div className="forgotPassword">
        <Form className="login-form">
          <FormGroup>
            <Row>
              <Col sm={12}>
                <p className="info-block">Please enter your <b>email address</b> below and we will send you a link to reset your password.</p>
                <FormControl id="email" type="text" placeholder="Email" onChange={this.handleChange} onKeyPress={this.handleKeyPress} />
                <Button id="login-button" type="button" onClick={(event) => this.props.forgotPassword(this.state.email, event)}>
                  <span className="icon ion-log-in"></span>
                  Send
                </Button>
              </Col>
            </Row>
          </FormGroup>
        </Form>
      </div>
    );
  }
}

ForgotPassword.propTypes = { 
  forgotPassword: React.PropTypes.func
};