// Base calculations BEGIN

export function calculateEnergyContent (quantity, mwh_per_unit) {
    let result;

    // // If mwh_per_unit is not specified, assume value of 1
    // if (quantity && quantity > 0) {
    //     result = quantity * (mwh_per_unit ? mwh_per_unit : 1);
    // }
    // If mwh_per_unit is not specified, don't calculate
    if (quantity && quantity > 0
        && mwh_per_unit && mwh_per_unit > 0) {
        result = quantity * mwh_per_unit;
    }

    return result;
}

export function calculateEnergyContentWithRiskFactor (quantity, mwh_per_unit, risk_factor) {
    let result, resultEnergyContent;

    // Calculate energy content - (without risk factor)
    resultEnergyContent = calculateEnergyContent(quantity, mwh_per_unit);

    // Calculate energy content - (with risk factor)
    // // If risk_factor is not specified, assume value of 100
    // if (resultEnergyContent && resultEnergyContent > 0) {
    //     result = resultEnergyContent * ((risk_factor ? risk_factor : 100) / 100);
    // }
    // If risk_factor is not specified, don't calculate
    if (resultEnergyContent && resultEnergyContent > 0
        && risk_factor && risk_factor > 0) {
        result = resultEnergyContent * (risk_factor / 100);
    }

    return result;
}

export function calculateValueInEur (value, currency, exchange_rate) {
    let result;
    // Calculate Price in Eur
    // Example (desc.): ("Currency" * "Exchange Rate") 
    // Example (vals.): (22 * 0,82)
    if (value && value > 0 && currency) {
        let exchangeRate = ((!currency || currency == 'EUR') ? 1 : exchange_rate);
        if (exchangeRate && exchangeRate > 0) {
            result = (value / exchangeRate);
        }
    }

    return result;
}

export function calculatePricePerMWh (mwh_per_unit, price, currency, exchange_rate) {
    let result;
    // Calculate Price / MWh
    // Example (desc.): ("Currency" * "Exchange Rate") / "MWH/Unit"
    // Example (vals.): (22 * 0,82) / 3
    if (price && price > 0
        && currency) {
        let valueInEur = calculateValueInEur(price, currency, exchange_rate);
        // // If mwh_per_unit is not specified, assume value of 1
        // if ((valueInEur && valueInEur > 0)) {
        //     result = valueInEur / (mwh_per_unit ? mwh_per_unit : 1);
        // }
        // If mwh_per_unit is not specified, don't calculate
        if ((valueInEur && valueInEur > 0)
            && mwh_per_unit && mwh_per_unit > 0) {
            result = valueInEur / mwh_per_unit;
        }  
    }

    return (isFinite(result) ? result : null);
}

export function calculatePricePerMWhWithRiskFactor (mwh_per_unit, price, currency, exchange_rate, risk_factor) {
    let result, resultPricePerMWh;

    // Calculate Price / MWh - (without risk factor)
    resultPricePerMWh = calculatePricePerMWh(mwh_per_unit, price, currency, exchange_rate);

    // Calculate Price / MWh - (with risk factor)
    // Example (desc.): ("Currency" * "Exchange Rate") / ("MWH/Unit" * "Risk Factor")
    // Example (vals.): (22 * 0,82) / (3 * 0.9)

    // // If risk_factor is not specified, assume value of 100
    // if (resultPricePerMWh && resultPricePerMWh > 0) {
    //     result = resultPricePerMWh * ((risk_factor ? risk_factor : 100) / 100);
    // }
    // If risk_factor is not specified, don't calculate
    if (resultPricePerMWh && resultPricePerMWh > 0
        && risk_factor && risk_factor > 0) {
        result = resultPricePerMWh * (risk_factor / 100);
    }

    return result;
}

export function calculatePriceTotalPerMWh (pricePerMWh, priceLogisticsPerMWh, priceAdditionalCostsPerMWh) {
    let result = (pricePerMWh ? pricePerMWh : 0) + (priceLogisticsPerMWh ? priceLogisticsPerMWh : 0) + (priceAdditionalCostsPerMWh ? priceAdditionalCostsPerMWh : 0);
    return (isFinite(result) ? result : null);
}

// Calculate total energy cost by multiplying "total price per mwh" with "total energy content"
export function calculateTotalEnergyCost (pricePerMwh, mwh) {
    let result = (pricePerMwh ? pricePerMwh : 0) * (mwh ? mwh : 0);
    return (isFinite(result) ? result : null);
}

// // Calculate total energy cost by multiplying "total price per mwh" with "quantity"
// export function calculateTotalEnergyCost (pricePerMwh, quantity) {
//     return (pricePerMwh ? pricePerMwh : 0) * (quantity ? quantity : 0);
// }

// // Calculate total energy cost by multiplying "total price per mwh" with "quantity" considering "risk factor"
// export function calculateTotalEnergyCostWithRiskFactor (pricePerMwh, quantity, risk_factor) {
//     return (pricePerMwh ? pricePerMwh : 0) * ((quantity ? quantity : 0) * ((risk_factor ? risk_factor : 100) / 100));
// }

// // Calculate total energy cost by multiplying "price" and "quantity"
// export function calculateTotalEnergyCost(price, currency, exchange_rate, quantity) {
//     let result;

//     if (price && price > 0
//         && currency) {
//         let valueInEur = calculateValueInEur(price, currency, exchange_rate);
//         if ((valueInEur && valueInEur > 0)) {
//             result = (price ? price : 0) * (quantity ? quantity : 0);
//         }
//     }

//     return result;
// }

// // Calculate total energy cost by multiplying "price" and "quantity" considering "risk factor"
// export function calculateTotalEnergyCostWithRiskFactor(price, currency, exchange_rate, quantity, risk_factor) {
//     let result;

//     if (price && price > 0
//         && currency) {
//         let valueInEur = calculateValueInEur(price, currency, exchange_rate);
//         if ((valueInEur && valueInEur > 0) && (risk_factor && risk_factor > 0)) {
//             result = (price ? price : 0) * ((quantity ? quantity : 0) * ((risk_factor ? risk_factor : 100) / 100));
//         }
//     }

//     return result;

//     return 
// }

export function calculateEnergyContents(quantity_source, quantity_final, price_estimated_mwh_per_unit, quantity_estimated_mwh_per_unit, quantity_verified_mwh_per_unit, risk_factor, lead) {
    // If undefined, create new
    if (!lead.product_details) {
        lead.product_details = {};
    }
    let resultTotalEnergyContentSellerMWh, resultTotalEnergyContentIndicative, resultTotalEnergyContentVerified;

    // Calculate energy content - Indicative (without risk factor)
    resultTotalEnergyContentSellerMWh = calculateEnergyContent(quantity_source, quantity_estimated_mwh_per_unit);

    // Calculate energy content - Indicative (with risk factor)
    resultTotalEnergyContentIndicative = calculateEnergyContentWithRiskFactor(quantity_source, quantity_estimated_mwh_per_unit, risk_factor)

    // Calculate energy content - Verified
    resultTotalEnergyContentVerified = calculateEnergyContent(quantity_final, quantity_verified_mwh_per_unit);

    lead.product_details.sumTotalEnergyContentSellerMWh = resultTotalEnergyContentSellerMWh;
    lead.product_details.sumTotalEnergyContentIndicative = resultTotalEnergyContentIndicative;
    lead.product_details.sumTotalEnergyContentVerified = resultTotalEnergyContentVerified;

    return lead;
}

export function calculatePrices (price_estimated_mwh_per_unit, quantity_estimated_mwh_per_unit, quantity_verified_mwh_per_unit, quantity_source, quantity_final, risk_factor, price, currency, exchange_rate, lead) {
    // If undefined, create new
    if (!lead.product_details) {
        lead.product_details = {};
    }
    let 
    resultProductPricePerMWhIndicative,
    resultProductPricePerMWhVerified,
    resultPriceTotalPerMWhIndicative, resultPriceTotalPerMWhVerified, resultTotalEnergyCostIndicative, resultTotalEnergyCostVerified;

    // Calculate Price / MWh - Indicative
    resultProductPricePerMWhIndicative = calculatePricePerMWh(price_estimated_mwh_per_unit, price, currency, exchange_rate);
    // resultProductPricePerMWhIndicative = calculatePricePerMWhWithRiskFactor(quantity_estimated_mwh_per_unit, price, currency, exchange_rate, risk_factor);

    // Calculate Price / MWh - Verified
    resultProductPricePerMWhVerified = calculatePricePerMWh(price_estimated_mwh_per_unit, price, currency, exchange_rate);
    // resultProductPricePerMWhVerified = calculatePricePerMWh(quantity_verified_mwh_per_unit, price, currency, exchange_rate);

    // Calculate Total / MWH - Indicative
    resultPriceTotalPerMWhIndicative = calculatePriceTotalPerMWh(resultProductPricePerMWhIndicative, lead.product_details.sumLogisticsPerMWhIndicative, lead.product_details.sumAdditionalCostsPerMWhIndicative);

    // Calculate Total / MWH - Verified
    resultPriceTotalPerMWhVerified = calculatePriceTotalPerMWh(resultProductPricePerMWhVerified, lead.product_details.sumLogisticsPerMWhVerified, lead.product_details.sumAdditionalCostsPerMWhVerified);
    
    // Calculate Total Cost - Indicative
    // resultTotalEnergyCostIndicative = calculateTotalEnergyCost(price, currency, exchange_rate, quantity_source) + lead.product_details.sumLogisticsTotalPrice + lead.product_details.sumAdditionalCostsTotalPrice;
    // resultTotalEnergyCostIndicative = calculateTotalEnergyCostWithRiskFactor(resultPriceTotalPerMWhIndicative, quantity_source, risk_factor);
    resultTotalEnergyCostIndicative = calculateTotalEnergyCost(resultPriceTotalPerMWhIndicative, lead.product_details.sumTotalEnergyContentIndicative);

    // Calculate Total Cost - Verified
    // resultTotalEnergyCostVerified = calculateTotalEnergyCost(price, currency, exchange_rate, quantity_final) + lead.product_details.sumLogisticsTotalPrice + lead.product_details.sumAdditionalCostsTotalPrice;
    // resultTotalEnergyCostVerified = calculateTotalEnergyCost(resultPriceTotalPerMWhVerified, quantity_final);
    resultTotalEnergyCostVerified = calculateTotalEnergyCost(resultPriceTotalPerMWhVerified, lead.product_details.sumTotalEnergyContentVerified);

    lead.product_details.sumPerMWhIndicative = resultProductPricePerMWhIndicative;
    // lead.product_details.sumLogisticsPerMWhIndicative = resultProductPriceLogisticsPerMWhIndicative;
    // lead.product_details.sumAdditionalCostsPerMWhIndicative = resultProductPriceAdditionalCostsPerMWhIndicative;
    lead.product_details.sumPerMWhVerified = resultProductPricePerMWhVerified;
    // lead.product_details.sumLogisticsPerMWhVerified = resultProductPriceLogisticsPerMWhVerified;
    // lead.product_details.sumAdditionalCostsPerMWhVerified = resultProductPriceAdditionalCostsPerMWhVerified;
    lead.product_details.sumPriceTotalPerMWhIndicative = resultPriceTotalPerMWhIndicative;
    lead.product_details.sumPriceTotalPerMWhVerified = resultPriceTotalPerMWhVerified;
    lead.product_details.sumTotalEnergyCostIndicative = resultTotalEnergyCostIndicative;
    lead.product_details.sumTotalEnergyCostVerified = resultTotalEnergyCostVerified;

    return lead;
}

// Base calculations END

// Object calculations BEGIN

export function calculateProductDetailsEnergyContentMWhIndicative (product_details) {
    product_details.sumTotalEnergyContentIndicative = calculateEnergyContentWithRiskFactor(product_details.quantity_source, product_details.quantity_estimated_mwh_per_unit, product_details.energy_content_risk_factor);

    return product_details;
}

export function calculateProductDetailsTotalEnergyContentVerified (product_details) {
    product_details.sumTotalEnergyContentIndicative = calculateEnergyContentWithRiskFactor(product_details.quantity_source, product_details.quantity_verified_mwh_per_unit, product_details.energy_content_risk_factor);

    return product_details;
}

export function calculateProductDetailsPricePerMWhIndicative (product_details) {
    product_details.sumPriceTotalPerMWhIndicative = calculatePricePerMWh(product_details.price_estimated_mwh_per_unit, product_details.price, product_details.currency, product_details.exchange_rate);
    return product_details;
}

export function calculateProductDetailsPricePerMWhVerified (product_details) {
    product_details.sumPriceTotalPerMWhVerified = calculatePricePerMWh(product_details.quantity_verified_mwh_per_unit, product_details.price, product_details.currency, product_details.exchange_rate);
    return product_details;
}

export function calculateProductDetailsPriceTotalPerMWhVerified (product_details) {
    if (!product_details.sumPriceTotalPerMWhVerified) {
        product_details.sumPriceTotalPerMWhVerified = calculatePricePerMWh(product_details.quantity_verified_mwh_per_unit, product_details.price, product_details.currency, product_details.exchange_rate);
    }

    product_details.sumPriceTotalPerMWhIndicative = calculatePriceTotalPerMWh(product_details.sumPriceTotalPerMWhVerified, product_details.priceLogisticsPerMWh, product_details.priceAdditionalCostsPerMWh);

    return product_details;
}

export function calculateLeadEnergyContents (lead) {
    // If undefined, return without calculating
    if (!lead || !lead.product_details) {
        return;
    }

    calculateEnergyContents(lead.product_details.quantity_source, lead.product_details.quantity_final, lead.product_details.price_estimated_mwh_per_unit, lead.product_details.quantity_estimated_mwh_per_unit, lead.product_details.quantity_verified_mwh_per_unit, lead.product_details.energy_content_risk_factor, lead);

    return lead;
}

export function calculateLeadPrices (lead) {
    // If undefined, return without calculating
    if (!lead || !lead.product_details) {
        return;
    }

    calculatePrices(lead.product_details.price_estimated_mwh_per_unit, lead.product_details.quantity_estimated_mwh_per_unit, lead.product_details.quantity_verified_mwh_per_unit, lead.product_details.quantity_source, lead.product_details.quantity_final, lead.product_details.energy_content_risk_factor, lead.product_details.price, lead.product_details.currency, lead.product_details.exchange_rate, lead);

    return lead;
}

export function calculateProductDetailsEnergyContentsAndPrices (product_details) {
    // If undefined, return without calculating
    if (!product_details) {
        return;
    }

    calculateProductDetailsEnergyContents(product_details);
    calculateProductDetailsPrices(product_details);

    return product_details;
}

export function calculateLeadEnergyContentsAndPrices (lead) {
    // If undefined, return without calculating
    if (!lead) {
        return;
    }

    calculateLeadEnergyContents(lead);
    calculateLeadLegsCosts(lead);
    calculateLeadAdditionalCostsCosts(lead);
    calculateLeadPrices(lead);

    return lead;
}

export function calculateLegCosts (lead, leg) {
    // If undefined, return without calculating
    if (!leg) {
        return;
    }
    let valueInEur = calculateValueInEur(leg.freight_cost, leg.currency, leg.exchange_rate_to_eur);

    if (leg.pricing_unit && leg.pricing_unit.toUpperCase() == 'lump sum'.toUpperCase()) {
        leg.sumTotalFreightCost = valueInEur;
        leg.sumPerMWhIndicative = leg.sumTotalFreightCost / lead.product_details.sumTotalEnergyContentIndicative;
        leg.sumPerMWhVerified = leg.sumTotalFreightCost / lead.product_details.sumTotalEnergyContentVerified;
    } else if (leg.pricing_unit && leg.pricing_unit.toUpperCase() == 'MWh'.toUpperCase()) {
        leg.sumTotalFreightCost = valueInEur * leg.quantity;
        leg.sumPerMWhIndicative = leg.sumTotalFreightCost / lead.product_details.sumTotalEnergyContentIndicative;
        leg.sumPerMWhVerified = leg.sumTotalFreightCost / lead.product_details.sumTotalEnergyContentVerified;
    } else {
        leg.sumTotalFreightCost = valueInEur * leg.quantity;
        leg.sumPerMWhIndicative = leg.sumTotalFreightCost / lead.product_details.sumTotalEnergyContentIndicative;
        leg.sumPerMWhVerified = leg.sumTotalFreightCost / lead.product_details.sumTotalEnergyContentVerified;
    }
    
    return leg;
}

export function calculateLeadLegsCosts(lead) {
    // If undefined, return without calculating
    if (!lead) {
        return;
    }

    // If undefined, create new
    if (!lead.product_details) {
        lead.product_details = {};
    }

    let resultLogisticsPerMWhIndicative = 0;
    let resultLogisticsPerMWhVerified = 0;
    let resultLogisticsTotalPrice = 0;

    if (lead.logistics && lead.logistics.legs) {
        for (let i = 0; i < lead.logistics.legs.length; i++) {
            let leg = lead.logistics.legs[i];
            // if (!leg.sumPerMWhIndicative || !leg.sumPerMWhVerified) {
            calculateLegCosts(lead, leg);
            // }
            if (leg.sumPerMWhIndicative) {
                resultLogisticsPerMWhIndicative += leg.sumPerMWhIndicative;
            }
            if (leg.sumPerMWhVerified) {
                resultLogisticsPerMWhVerified += leg.sumPerMWhVerified;
            }
            if (leg.sumTotalFreightCost) {
                resultLogisticsTotalPrice += leg.sumTotalFreightCost;
            }
        }
    }    

    lead.product_details.sumLogisticsPerMWhIndicative = resultLogisticsPerMWhIndicative;
    lead.product_details.sumLogisticsPerMWhVerified = resultLogisticsPerMWhVerified;
    lead.product_details.sumLogisticsTotalPrice = resultLogisticsTotalPrice;

    return lead;
}

export function calculateAdditionalCostCosts (lead, additional_cost) {
    // If undefined, return without calculating
    if (!additional_cost) {
        return;
    }
    let valueInEur = calculateValueInEur(additional_cost.cost, additional_cost.currency, additional_cost.exchange_rate);

    if (additional_cost.pricing_unit && additional_cost.pricing_unit.toUpperCase() == 'lump sum'.toUpperCase()) {
        additional_cost.sumTotalCost = valueInEur;
        additional_cost.sumPerMWhIndicative = additional_cost.sumTotalCost / lead.product_details.sumTotalEnergyContentIndicative;
        additional_cost.sumPerMWhVerified = additional_cost.sumTotalCost / lead.product_details.sumTotalEnergyContentVerified;
    } else if (additional_cost.pricing_unit && additional_cost.pricing_unit.toUpperCase() == 'MWh'.toUpperCase()) {
        additional_cost.sumTotalCost = valueInEur * additional_cost.quantity;
        additional_cost.sumPerMWhIndicative = additional_cost.sumTotalCost / lead.product_details.sumTotalEnergyContentIndicative;
        additional_cost.sumPerMWhVerified = additional_cost.sumTotalCost / lead.product_details.sumTotalEnergyContentVerified;
    } else {
        additional_cost.sumTotalCost = valueInEur * additional_cost.quantity;
        additional_cost.sumPerMWhIndicative = additional_cost.sumTotalCost / lead.product_details.sumTotalEnergyContentIndicative;
        additional_cost.sumPerMWhVerified = additional_cost.sumTotalCost / lead.product_details.sumTotalEnergyContentVerified;
    }

    return additional_cost;
}

export function calculateLeadAdditionalCostsCosts(lead) {
    // If undefined, return without calculating
    if (!lead) {
        return;
    }

    // If undefined, create new
    if (!lead.product_details) {
        lead.product_details = {};
    }

    let resultAdditionalCostsPerMWhIndicative = 0;
    let resultAdditionalCostsPerMWhVerified = 0;
    let resultAdditionalCostsTotalPrice = 0;

    if (lead.additional_costs) {
        for (let i = 0; i < lead.additional_costs.length; i++) {
            let additional_cost = lead.additional_costs[i];
            // if (!additional_cost.sumPerMWhIndicative || !additional_cost.sumPerMWhVerified) {
            calculateAdditionalCostCosts(lead, additional_cost);
            // }
            if (additional_cost.sumPerMWhIndicative) {
                resultAdditionalCostsPerMWhIndicative += additional_cost.sumPerMWhIndicative;
            }
            if (additional_cost.sumPerMWhVerified) {
                resultAdditionalCostsPerMWhVerified += additional_cost.sumPerMWhVerified;
            }
            if (additional_cost.sumTotalCost) {
                resultAdditionalCostsTotalPrice += additional_cost.sumTotalCost;
            }
        }
    }    

    lead.product_details.sumAdditionalCostsPerMWhIndicative = resultAdditionalCostsPerMWhIndicative;
    lead.product_details.sumAdditionalCostsPerMWhVerified = resultAdditionalCostsPerMWhVerified;
    lead.product_details.sumAdditionalCostsTotalPrice = resultAdditionalCostsTotalPrice;

    return lead;
}

// Object calculations END