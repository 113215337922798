/* eslint-disable no-unused-vars */
import React from 'react';
import { render } from 'react-dom';

// Libraries for handling App state and routing
import { Route, IndexRoute, hashHistory /*browserHistory*/ } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';

import configureStore from './store/configureStore';

import Root from './containers/Root';
import App from './containers/App';

import DashboardPage from './pages/Dashboard';
import CounterpartiesPage from './pages/Counterparties';
import SitesPage from './pages/Sites';
import HubsPage from './pages/Hubs';
import AgentsPage from './pages/Agents';
import ProductsPage from './pages/Products';
import CertificatesPage from './pages/Certificates';
import ReportsPage from './pages/Reports';
import UsersPage from './pages/Users';

import LeadPage from './pages/detailPages/Lead';
import CounterpartyPage from './pages/detailPages/Counterparty';
import SitePage from './pages/detailPages/Site';
import HubPage from './pages/detailPages/Hub';
import AgentPage from './pages/detailPages/Agent';
import ProductPage from './pages/detailPages/Product';
import CertificatePage from './pages/detailPages/Certificate';
// import ReportPage from './pages/detailPages/Report';
import UserPage from './pages/detailPages/User';

import LoginPage from './pages/Login';
import SetPasswordPage from './pages/SetPassword';
import ForgotPasswordPage from './pages/ForgotPassword';

import NotFoundPage from './pages/NotFoundPage';

const store = configureStore();
const history = syncHistoryWithStore(hashHistory, store); //replaced browserHistory with hashHistory

render((
  <Root { ...{store, history} }>
    <Route path="/" component={App}>
      <IndexRoute components={{ view: DashboardPage, viewIndex: '1' }} />
      
      <Route path="/counterparties" components={{ view: CounterpartiesPage, viewIndex: '2' }} />
      <Route path="/sites" components={{ view: SitesPage, viewIndex: '3' }} />
      <Route path="/hubs" components={{ view: HubsPage, viewIndex: '4' }} />
      <Route path="/agents" components={{ view: AgentsPage, viewIndex: '5' }} />
      <Route path="/products" components={{ view: ProductsPage, viewIndex: '6' }} />
      <Route path="/certificates" components={{ view: CertificatesPage, viewIndex: '7' }} />
      <Route path="/reports" components={{ view: ReportsPage, viewIndex: '8' }} />
      <Route path="/users" components={{ view: UsersPage, viewIndex: '9' }} />
      
      <Route path="/lead" components={{ view: LeadPage, viewIndex: '1' }} />
      <Route path="/counterparty" components={{ view: CounterpartyPage, viewIndex: '2' }} />
      <Route path="/site" components={{ view: SitePage, viewIndex: '3' }} />
      <Route path="/hub" components={{ view: HubPage, viewIndex: '4' }} />
      <Route path="/agent" components={{ view: AgentPage, viewIndex: '5' }} />
      <Route path="/product" components={{ view: ProductPage, viewIndex: '6' }} />
      <Route path="/certificate" components={{ view: CertificatePage, viewIndex: '7' }} />
      {/* <Route path="/report" components={{ view: ReportsPage, viewIndex: '8' }} /> */}
      <Route path="/user" components={{ view: UserPage, viewIndex: '9' }} />
      
      <Route path="/login" components={{view: LoginPage, viewIndex: '0' }} />
      {/* <Route path="/confirm" components={{ view: SetPasswordPage, viewIndex: '0' }} />
      <Route path="/setpassword" components={{ view: SetPasswordPage, viewIndex: '0' }} />
      <Route path="/resetpassword" components={{ view: SetPasswordPage, viewIndex: '0' }} />
      <Route path="/forgotpassword" components={{ view: ForgotPasswordPage, viewIndex: '0' }} /> */}
      
      <Route path="*" component={NotFoundPage} />
    </Route>
  </Root>
), document.getElementById('app'));
