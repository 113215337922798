/* global apiBaseUrl */

'use strict';

import React from 'react';
import { Button, Col, ControlLabel, Modal, Row, Table } from 'react-bootstrap';
import Header from './FormHeader';
import { statusCheckListData } from '../actions/statusChangeChecklist';

require('../stylesheets/app.scss');
require('../stylesheets/components/formComponent.scss');

export default class FormStatusChangeChecklist extends React.Component {
  constructor (props) {
    super(props);
    
    this.state = {
      statusCheckListData: statusCheckListData
    };
  }

  render () {
    return(
      <Modal bsSize="large" id={this.props.formId} className={"form-component"} show={this.props.visible} onHide={() => this.props.close(this.props.formId)}>
        <Header 
          // buttons={['StatusChangeChecklist details','Done!']}
          // currentStep={this.state.step}
          title="Status change checklist" />
        <Modal.Body>
          <Table striped condensed hover>
            <thead>
              <tr>
                <th></th>
                <th className={this.props.highlightStatusRow == 'active' ? 'highlightStatusRow' : ''}>Active</th>
                <th className={this.props.highlightStatusRow == 'stemmed' ? 'highlightStatusRow' : ''}>Stemmed</th>
                <th className={this.props.highlightStatusRow == 'delivered' ? 'highlightStatusRow' : ''}>Delivered</th>
                <th className={this.props.highlightStatusRow == 'finalized' ? 'highlightStatusRow' : ''}>Finalized</th>
                <th className={this.props.highlightStatusRow == 'canceled' ? 'highlightStatusRow' : ''}>Canceled</th>
                <th className={this.props.highlightStatusRow == 'failed' ? 'highlightStatusRow' : ''}>Failed</th>
              </tr>
            </thead>
            <tbody>
              {this.state.statusCheckListData.map(function (listItem, i) {
                // console.log(i + " - " + listItem);
                return <tr id={'listRow' + i} key={'listRow' + i}>
                  <td>{listItem[0]}</td>
                  <td className={this.props.highlightStatusRow == 'active' ? 'highlightStatusRow' : ''}>{listItem[1]}</td>
                  <td className={this.props.highlightStatusRow == 'stemmed' ? 'highlightStatusRow' : ''}>{listItem[2]}</td>
                  <td className={this.props.highlightStatusRow == 'delivered' ? 'highlightStatusRow' : ''}>{listItem[3]}</td>
                  <td className={this.props.highlightStatusRow == 'finalized' ? 'highlightStatusRow' : ''}>{listItem[4]}</td>
                  <td className={this.props.highlightStatusRow == 'canceled' ? 'highlightStatusRow' : ''}>{listItem[5]}</td>
                  <td className={this.props.highlightStatusRow == 'failed' ? 'highlightStatusRow' : ''}>{listItem[6]}</td>
                </tr>;
              }, this)}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <div>
            <Button className="pull-right" onClick={this.props.toggle}>Ok</Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  }
}

FormStatusChangeChecklist.propTypes = {
  close: React.PropTypes.func.isRequired,
  formId: React.PropTypes.string.isRequired,
  toggle: React.PropTypes.func.isRequired,
  visible: React.PropTypes.bool.isRequired
};