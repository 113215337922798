/* global apiBaseUrl */

'use strict';

import React from 'react';
import { Col, ControlLabel, Form, FormControl, FormGroup, Modal, Row } from 'react-bootstrap';
import FormAddToList from './FormAddToList';
import FormAddContactToList from './FormAddContactToList';
import Header from './FormHeader';
import Footer from './FormFooter';
import { handleInputChange, addContactFormItem, addFormItem, deleteFormItem, fetchList, handleSessionStorage, parseFetchError } from '../actions/utility';

require('../stylesheets/app.scss');
require('../stylesheets/components/formComponent.scss');

export default class FormNewAgent extends React.Component {
  constructor (props) {
    super(props);
    
    this.handleStepChange = this.handleStepChange.bind(this);
    this.handleInputChange = handleInputChange.bind(this);
    this.addContactFormItem = addContactFormItem.bind(this);
    this.addFormItem = addFormItem.bind(this);
    this.deleteFormItem = deleteFormItem.bind(this);
    this.fetchList = fetchList.bind(this);
    this.handleSessionStorage = handleSessionStorage.bind(this);
    
    this.state = {
      form: {
        agentName: '',
        agentAddress: '',
        agentZip_code: '',
        agentCity: '',
        agentCountry: '',
        agentContactName: '',
        agentContactEmail: '',
        agentContactPhone: '',
        agentContacts: [],
        agentHub: '',
        agentHubs: [],
        additionalInformation: ''
      },
      countries: [],
      hubs: [],
      requestPending: false,
      sendResponse: false,
      step: 1
    };
  }
  
  handleStepChange (num) {
    //console.log(e.target);
    //console.log(num);
    var errorFound = false;
    var informError = function (id) {
      //console.log(id);
      //alert(id + ' is missing a value.');
      //add error class to all of the inputs that are missing information
      if (document.getElementById(id).className.indexOf('error') < 0) {
        document.getElementById(id).className += ' error';
      }  
      errorFound = true;
    };
    switch (num) {
    case 2:
      //check for the first views data
      for (let i in this.state.form) {
         //filter out the information that is not concerning step 1
        if (i !== 'additionalInformation' &&
            i !== 'agentContactName' &&
            i !== 'agentContactEmail' &&
            i !== 'agentContactPhone' &&
            i !== 'agentContacts' &&
            i !== 'agentHub' &&
            i !== 'agentHubs') {
          if (!this.state.form[i]) {
            informError(i);
          }
        }
      }
      break;
    }
    //user is not allowed to go forward if something is missing
    if (!errorFound && num === 2) {
      //send new agent form
      var token = localStorage.getItem('woodtracker_id_token');
      var that = this;
      if (token) {
        var header = new Headers();
        header.append('Accept', 'application/json');
        header.append('Content-Type', 'application/json');
        header.append('Authorization', 'Bearer ' + token);
        
        var findIds = function (arr1, arr2, arr3) {
          for (let i = 0, j = arr2.length; i < j; i++) {
            for (let k = 0, l = arr3.length; k < l; k++) {
              if (arr3[k] === arr2[i].name) {
                arr1.push(arr2[i].id);
              }
            }
          }
        };

        // IMPORTANT! This will break if we allow entities with non-unique "name" fields (because "name" is the value we carry in the form)
        var hubIds = [];
        //loop through the hubs to add the correct ids into the port JSON
        findIds(hubIds, this.state.hubs, this.state.form.agentHubs);
        //inform an error if the added hubs where not valid
        if (hubIds.length === 0 && this.state.form.agentHubs.length > 0) {
          document.getElementById('agentHub').className += ' error';
          alert('You must only use the suggested hubs.');
          return false;
        }
        //the backend doesn't accept empty arrays, add empty string to indicata that an array is empty
        if (hubIds.length === 0) {
          hubIds.push('');
        }
        
        var agent = {
          agent: {
            name: this.state.form.agentName,
            address: this.state.form.agentAddress,
            zip_code: this.state.form.agentZip_code,
            city: this.state.form.agentCity,
            country: this.state.form.agentCountry,
            contacts: this.state.form.agentContacts,
            hub_ids: hubIds,
            additional_info: this.state.form.additionalInformation
          }
        };
        
        var request = new Request(apiBaseUrl + '/agents', { 
          method: 'POST', 
          headers: header,
          body: JSON.stringify(agent)
        });
        
        //prevent calling fetch if request is pending
        if (!that.state.requestPending) {
          that.setState({ requestPending: true });
          fetch(request).then(function(response) {
            if (!response.ok) {
              //throw Error(response.statusText);
              return response.json().then(err => { throw err; });
            }
            //console.log(response);
            return response.text();
          }).then(function(/*text*/) {
            //console.log(text);
            that.setState({ step: num });
            that.setState({ sendResponse: true });
            that.setState({ requestPending: false });
            sessionStorage.agentsListUpdated = '';
          }).catch(function(err) {
            that.setState({ step: num });
            that.setState({ sendResponse: false });
            that.setState({ requestPending: false });
            parseFetchError(err);
          });
        }
      }
    } else if (!errorFound && num === 1) {
      this.setState({ step: num });
      this.setState({ sendResponse: false });
      this.setState({ requestPending: false });
    } else {
      alert('Data is missing. Please fill in all of the required data.');
    }
  }
  
  componentDidMount () {
    //fetch data
    if (this.props.visible) {
      this.handleSessionStorage('countries','countries',false,'');
      this.handleSessionStorage('hubs','hubs',false,'');
    }
  }

  render () {
    return(
      <Modal bsSize="large" id={this.props.formId} className={"form-component" + ((this.state.requestPending) ? " busy-blocking" : '')} backdrop={((this.state.requestPending) ? "static" : true)} keyboard={((this.state.requestPending) ? false : true)} show={this.props.visible} onHide={() => (this.state.step !== 2) ? this.props.close(this.props.formId) : this.props.toggle(this.props.formId)}>
        <Header 
          buttons={['Agent details','Done!']}
          currentStep={this.state.step}
          title="New agent" />
        <Modal.Body>
          {/* step 1 - agent information */}
          {this.state.step === 1 &&
            <Form>
              <FormGroup conrolId="newAgentInformation">
                <Row>
                  <Col componentClass={ControlLabel} sm={4} className="element-padding horizontal">
                    Agent details
                  </Col>
                  <Col sm={8}>
                    <Col sm={12} className="element-padding horizontal">
                      <ControlLabel>Name *</ControlLabel>
                      <FormControl id="agentName" type="text" value={this.state.form.agentName} onChange={this.handleInputChange} />
                    </Col>
                    <Col sm={12} className="element-padding horizontal">
                    <ControlLabel>Address *</ControlLabel>
                      <FormControl id="agentAddress" type="text" value={this.state.form.agentAddress || ''} onChange={this.handleInputChange} />
                    </Col>
                    <Col sm={6} className="element-padding horizontal">
                      <ControlLabel>Zip</ControlLabel>
                      <FormControl id="agentZip_code" type="text" value={this.state.form.agentZip_code || ''} onChange={this.handleInputChange} />
                    </Col>
                    <Col sm={6} className="element-padding horizontal">
                      <ControlLabel>City</ControlLabel>
                      <FormControl id="agentCity" type="text" value={this.state.form.agentCity || ''} onChange={this.handleInputChange} />
                    </Col>
                    <Col sm={12} className="element-padding horizontal">
                      <ControlLabel>Country *</ControlLabel>
                      <FormControl id="agentCountry" type="text" value={this.state.form.agentCountry} onChange={this.handleInputChange} list="agentCountriesDataList" />
                      {this.state.countries && 
                        <datalist id="agentCountriesDataList">
                        {this.state.countries.map(function(item, i){
                          return <option key={i} value={item.country} />;
                        },this)}
                        </datalist>
                      }
                    </Col>
                  </Col>
                </Row>
                <Row>
                  <Col componentClass={ControlLabel} sm={4} className="element-padding horizontal">
                    Contact info
                  </Col>
                  <Col sm={8} className="element-padding horizontal">
                    <FormAddContactToList 
                      inputName="agentContactName"
                      inputEmail="agentContactEmail"
                      inputPhone="agentContactPhone"
                      inputValueName={this.state.form.agentContactName || ''}
                      inputValueEmail={this.state.form.agentContactEmail || ''}
                      inputValuePhone={this.state.form.agentContactPhone || ''}
                      inputOnChange={this.handleInputChange}
                      dataList={false}
                      addToListFunc={()=>this.addContactFormItem('agentContactName','agentContactEmail','agentContactPhone','agentContacts')}
                      deleteFromListFunc={(event)=>this.deleteFormItem(event,'agentContacts')}
                      listArray={this.state.form.agentContacts}
                      label="" />
                  </Col>
                </Row>
                <Row>
                  <Col componentClass={ControlLabel} sm={4} className="element-padding horizontal">
                  Affiliated hubs
                  </Col>
                  <Col sm={8} className="element-padding horizontal">
                    <FormAddToList 
                        inputId="agentHub"
                        inputValue={this.state.form.agentHub || ''}
                        inputOnChange={this.handleInputChange}
                        dataList={true}
                        dataListId="hubsList"
                        dataListArray={this.state.hubs}
                        addToListFunc={()=>this.addFormItem('agentHub','agentHubs',this.state.hubs)}
                        deleteFromListFunc={(event)=>this.deleteFormItem(event,'agentHubs')}
                        listArray={this.state.form.agentHubs}
                        label=""
                        loading={this.state.loading} />
                  </Col>
                </Row>
                <Row>
                  <Col componentClass={ControlLabel} sm={4} className="element-padding horizontal">
                    Other
                  </Col>
                  <Col sm={8} className="element-padding horizontal">
                    <ControlLabel>Additional information</ControlLabel>
                    <FormControl id="additionalInformation" componentClass="textarea" value={this.state.form.additionalInformation} placeholder="" onChange={this.handleInputChange}/>
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          }
          {/* step 2 - done */}
          {this.state.step === 2 &&
            <div>
              {/* success case */}
              {this.state.sendResponse &&
                <div>
                  <h2 className="inquiry-done success">
                    The agent information was sent and stored succesfully.
                  </h2>
                  {/*<p className="inquiry-done-info">This window will close automatically in five seconds.</p>*/}
                </div>
              }
              {/* error case */}
              {!this.state.sendResponse &&
                <div>
                  <h2 className="inquiry-done error">
                    {/* TODO should show actual reasons for error */}
                    Something went wrong. Please try again in a few moments.
                  </h2>
                </div>
              }
            </div>
          }
        </Modal.Body>
        <Footer 
          currentStep={this.state.step}
          handleStepChange={(num)=>this.handleStepChange(num)}
          sendResponse={this.state.sendResponse}
          toggle={()=>this.props.toggle(this.props.formId)}
          totalSteps={2} />
      </Modal>
    );
  }
}

FormNewAgent.propTypes = {
  close: React.PropTypes.func.isRequired,
  formId: React.PropTypes.string.isRequired,
  toggle: React.PropTypes.func.isRequired,
  visible: React.PropTypes.bool.isRequired
};