/* global apiBaseUrl */

// utility functions
import jwt_decode from 'jwt-decode';
import { hashHistory } from 'react-router';

//generic handler for 2-way data binding on state level
export function handleChange (e) {
  this.setState({ [e.target.id]: e.target.value });
}

//generic handler for 2-way data binding within forms
export function handleInputChange (e, callback) {
  // console.log(e);
  var form = this.state.form;

  if (e.target.files) {
    // Handle files
    var files =  Array.from(e.target.files);

    if (typeof files != 'undefined') {
      // If only one element in the array, use it directly
      // Otherwise use the whole array
      if (files.length === 1) {
        // console.log("file = " + files[0].name);
        form[e.target.id] = files[0];
      } else {
        // files.forEach(function(file, i) {
        //   console.log("files[" + i + "] = " + file.name);
        // });
        form[e.target.id] = files;
      }

      // Clear the input field value, because the actual value is managed elsewhere (in the form)
      e.target.value = null;
    }
  } else if (e.target.selectedOptions) {
    // Multiple select options
    // console.log("handleInputChange -> e.target.selectedOptions = " + e.target.selectedOptions);
    var values = [];
    for (var i = 0, l = e.target.selectedOptions.length; i < l; i++) {
      if (e.target.selectedOptions[i].selected) {
        values.push(e.target.selectedOptions[i].value);
      }
    }

    // If only one selection, just use one value without array
    form[e.target.id] = (values.length === 1 ? values[0] : values);
  } else {
    // Normal value
    // console.log("handleInputChange -> e.target.value = " + e.target.value);
    form[e.target.id] = e.target.value;
  }
  // console.log("handleInputChange -> form[e.target.id] = " + form[e.target.id]);
  // //autofill region when country is selected
  // if (e.target.id === 'hubCountry' || e.target.id === 'country') {
  //   var value = e.target.value;
  //   for (let i = 0, j = this.state.countries.length; i < j; i++) {
  //     if (value === this.state.countries[i].country) {
  //       (e.target.id === 'hubCountry') ? 
  //         form.hubRegion = this.state.countries[i].region:
  //         form.region = this.state.countries[i].region;
  //       break;
  //     }
  //   }
  // }
  //check if the input has error class set to it and remove it
  if (e.target.className.indexOf('error') > -1) {
    e.target.className = e.target.className.replace('error','');
  }

  // Use setState updater callback explicitly if a callback is provided.
  // This ensures that the state is updated before executing the callback.
  if (callback) {
    // console.log("callback = " + callback);
    this.setState((prevState, props) => {
      return {'form': form};
    }, callback);
  } else {
    // console.log("no callback");
    this.setState({'form': form});
  }
}

//generic handler for 2-way data binding within forms (for file data)
export function handleFileInputChange (e) {
  //console.log(e);
  var files = Array.from(e.target.files);

  if (typeof files != 'undefined') {
    var form = this.state.form;

    // var file = files[0];
    // if (file != 'undefined' && file.size != 'undefined' && file.size > 0) {
    //   console.log(file.name + " (size: " + file.size + " bytes) ");
    // }

    // If only one element in the array, use it directly
    // Otherwise use the whole array
    if (files.length === 1) {
      // console.log("file = " + files[0].name);
      form[e.target.id] = files[0];
    } else {
      // files.forEach(function(file, i) {
      //   console.log("files[" + i + "] = " + file.name);
      // });
      form[e.target.id] = files;
    }
    this.setState({ 'form': form });
  }

  // Clear the input field value, because the actual value is managed elsewhere (in the form)
  e.target.value = null;

  //check if the input has error class set to it and remove it
  if (e.target.className.indexOf('error') > -1) {
    e.target.className = e.target.className.replace('error','');
  }
}

//generic function to handle data adding into the form data
//id is the object id within the form data
//list is the list id within the form data where the selected valuwe is to be added
//allowedValuesList is an optional list that contains allowed values / objects
export function addFormItem (id, list, allowedValuesList) {
  if (this.state.form[id].length === 0) {
    alert('Type in a value to add it into the form.');
    return;
  }
  
  var elem = document.getElementById(id);
  if (elem.className.indexOf('error') > -1) {
    elem.className = elem.className.replace('error','');
  }
  
  var form = this.state.form;

  // optinal check for accepted values
  if (typeof allowedValuesList != 'undefined') {
    let foundInAllowedValuesList = false;
    allowedValuesList.forEach(function(item, i) {
      let comparison = null;
      (typeof item === 'string') ? comparison = item : comparison = item.name;
      // console.log("allowedValuesList[" + i + "] = " + comparison);
      if (comparison === form[id]) {
        // console.log("allowedValuesList[" + i + "] = " + comparison + " === " + form[id] );
        foundInAllowedValuesList = true;
      }
    });
    if (!foundInAllowedValuesList) {
      alert("\'" + form[id] + '\' is not in the list of allowed values.');
      return;
    }
  }

  form[list].push(form[id]);
  form[id] = '';
  this.setState({ 'form': form });
}

//generic function to handle data adding into the form data
//sourceId is the object id within the form data
//listId is the list id within the form data where the selected valuwe is to be added
export function addFileFormItem (sourceId, listId) {
  if (typeof this.state.form[sourceId] === 'undefined' || !this.state.form[sourceId]) {
    alert('Browse for a file to add it into the form.');
    return;
  }
  
  var elem = document.getElementById(sourceId);
  if (elem.className.indexOf('error') > -1) {
    elem.className = elem.className.replace('error','');
  }
  
  var form = this.state.form;

  let fileWrapper = {};
  fileWrapper.file = form[sourceId];

  form[listId].push(fileWrapper);
  form[sourceId] = '';
  this.setState({ 'form': form });
}

//generic function to handle data adding into the form data
//id is an array of object ids within the form data
//list is the list id within the form data where the selected valuwe is to be added
export function addContactFormItem (name, email, phone, list) {
    if (this.state.form[name].length === 0) {
      alert('Type in a contact name to add it into the form.');
      return;
    }
    if (this.state.form[email].length === 0) {
      alert('Type in a contact email to add it into the form.');
      return;
    }
    if (this.state.form[phone].length === 0) {
      alert('Type in a contact phone to add it into the form.');
      return;
    }
    
    var elem = document.getElementById(name);
    if (elem.className.indexOf('error') > -1) {
      elem.className = elem.className.replace('error','');
    }
    elem = document.getElementById(email);
    if (elem.className.indexOf('error') > -1) {
      elem.className = elem.className.replace('error','');
    }
    elem = document.getElementById(phone);
    if (elem.className.indexOf('error') > -1) {
      elem.className = elem.className.replace('error','');
    }

    var form = this.state.form;

    var contact = {
      name: form[name],
      email: form[email],
      phone: form[phone],
    };
    // console.log("generateContact = " + JSON.stringify(contact));
    
    form[list].push(contact);
    form[name] = '';
    form[email] = '';
    form[phone] = '';
    this.setState({ 'form': form });
}

//generic function handle data removal from form data
export function deleteFormItem (e, list) {
  //confirm the deletion
  var r = confirm('Are you sure want to delete this item?');
  if (r) {
    var index = e.target.closest("ul > li").id;
    if (index) {
      // console.info("Deleting index " + index + " from list '" + list + "'.");
      var form = this.state.form;
      form[list].splice(index, 1);
      this.setState({ 'form': form });
    } else {
      throw new Error("No index value given. Not deleting item from list '" + list + "'.");
    }
  }
}

//generic function handle data removal from form data
// deletionList contains the files to delete on the server
export function deleteFileFormItem (e, list, deletionList) {
  //confirm the deletion
  var r = confirm('Are you sure want to delete this item?');
  if (r) {
    var index = e.target.closest("ul > li").id;
    if (index) {
      console.info("Deleting index " + index + " from list '" + list + "'.");
      var form = this.state.form;

      // Construct deletion list of already uploaded files (those that have 'id')
      let file = form[list][index];
      if (typeof file.id != 'undefined') {
        console.log("File to delete = " + file.id + ", " + file.file_name);
        form[deletionList].push(file);
      }

      form[list].splice(index, 1);
      this.setState({ 'form': form });
    } else {
      throw new Error("No index value given. Not deleting item from list '" + list + "'.");
    }
  }
}

// function to check if token is valid and refresh it according to the users selection
export function refreshToken () {
  var token = localStorage.getItem('woodtracker_id_token'),
    decoded = (token) ? jwt_decode(token) : null;
  
  if (!token) {
    document.querySelector('.logout-button.btn.btn-default').click();
    return false;
  }
  
  if (token && ((decoded.exp * 1000) - new Date() > 0)) {
    //everything alright go forward
    //console.log('token valid');
    return true;
  } else if (token && ((decoded.exp * 1000) - new Date() < 0)) {
    //token exists but might be expired -> fetch new token
    //console.log('token valid');
    if (localStorage.rememberMe === 'on') {
      //if remember me is selected automatically fetch new
      var header = new Headers();
      header.append('Accept', 'application/json');
      header.append('Content-Type', 'application/json');
      header.append('Authorization', 'Bearer ' + token);
      
      var request = new Request(apiBaseUrl + '/users/token', { 
        method: 'GET', 
        headers: header
      });
      
      fetch(request).then(function(response) {
        if (!response.ok) {
          return response.json().then(err => { throw err; });
        }
        return response.json();
      }).then(function(json) {
        //console.log(json);
        if (!json.hasOwnProperty('errors')) {
          localStorage.setItem('woodtracker_id_token', json.token);
          return true;
        } else {
          alert('ERROR: ' + json.errors);
          if (document.querySelector('.logout-button.btn.btn-default')) {
            document.querySelector('.logout-button.btn.btn-default').click();
          }
          return false;
        }
      }).catch(function(err) {
        parseFetchError(err);
        if (document.querySelector('.logout-button.btn.btn-default')) {
          document.querySelector('.logout-button.btn.btn-default').click();
        }
        return false;
      });
    } else {
      //else log user out and require a new login
      alert('ERROR: Your login token has expired. Please select \'Remember me\' if you want your token to be automatically updated.');
      if (document.querySelector('.logout-button.btn.btn-default')) {
        document.querySelector('.logout-button.btn.btn-default').click();
      }
    }
  }
}

//generic function to fetch full lists
//listId is a string defining the list that is going to be fetched
//listObj is a string defining the object where the results needs to be set
//sessionBool is a boolean to determine if data should be stored into sessionStorage
//queryString is a list formatting querystring sent to the backend
export function fetchList (listId, listObj, sessionBool) {
  //check token and refresh it if possible
  refreshToken();
  //fetch list
  var token = localStorage.getItem('woodtracker_id_token');
  var that = this;
  if (token) {
    var header = new Headers();
    header.append('Accept', 'application/json');
    header.append('Content-Type', 'application/json');
    header.append('Authorization', 'Bearer ' + token);
    
    var request = new Request(apiBaseUrl + '/' + listId, { 
      method: 'GET', 
      headers: header
    });
    
    that.setState({ ...that.state, loading: true });
    // var timeMsElapsed = new Date().getTime();
    // console.log("Fetching list: " + listId + ", listObj: " + listObj);

    fetch(request).then(function(response) {
      that.setState({ ...that.state, loading: false });
      // console.log("Fetched list: " + listId + ", listObj: " + listObj);
      // timeMsElapsed = new Date().getTime() - timeMsElapsed;
      // console.log("Fetched list: " + listId + ", listObj: " + listObj + ". Processing time = " + timeMsElapsed + " ms.");

      if (!response.ok) {
        return response.json().then(err => { throw err; });
      }
      return response.json();
    }).then(function(json) {
      // console.log(json);
      //console.log(that);
      //fetch specific inner list data if it is found with the listId. Otherwise throw an error.
      if (json.hasOwnProperty(listId)) {
        that.setState({ [listObj]: json[listId] });
      } else {
        throw new Error("No list with the id = \'" + listId + "\' found in the fetched data.");
      }
      //set list page meta if meta is found
      if (json.hasOwnProperty('meta')) {
        that.setState({ meta: json.meta });
      }
      //if fetched the countries list handle also the regions
      // if (listId === 'countries') {
      //   let arr = [];
      //   for (let i = 0, j = json.countries.length; i < j; i++) {
      //     if (arr.indexOf(json.countries[i].region) === -1) {
      //       arr.push(json.countries[i].region);
      //     }
      //   }
      //   that.setState({ regions: arr });
      // }

      //use sessionStorage to store the fetched items and allow refresh every 5 minutes or when data has changed
      if (sessionBool) {
        sessionStorage.setItem(listId + 'List', JSON.stringify(json[listId]));
        sessionStorage.setItem(listId + 'ListUpdated', new Date().getTime());
      }
    }).catch(function(err) {
      that.setState({ ...that.state, loading: false });
      //console.log('ERROR: ' + err);
      parseFetchError(err);
    });
  }
}

//generic function to fetch partial lists per page request
//doesn't have any kind of sessionStorage handling
//listId is a string defining the list that is going to be fetched
//listObj is a string defining the object where the results needs to be set
//queryString is a list formatting querystring sent to the backend
let queryId = '',
  queryStr = '';
export function fetchListPage (listId, listObj, queryString) {
  //check token and refresh it if possible
  refreshToken();
  //fetch list
  var token = localStorage.getItem('woodtracker_id_token');
  var that = this;

  if (token) {
    var header = new Headers();
    header.append('Accept', 'application/json');
    header.append('Content-Type', 'application/json');
    header.append('Authorization', 'Bearer ' + token);
    
    var request = new Request(apiBaseUrl + '/' + listId + queryString, { 
      method: 'GET', 
      headers: header
    });

    //// DEBUG
    // console.log("fetchListPage -> request = " + request);
    // console.log("fetchListPage -> query = " +
    //             "\n  queryId     = " + queryId +
    //             "\n  listId      = " + listId +
    //             "\n  queryStr    = " + queryStr +
    //             "\n  queryString = " + queryString
    //           );

    if (queryId !== listId || 
      queryStr !== queryString) {

      queryId = listId;
      queryStr = queryString;

      //// DEBUG
      // console.log("fetchListPage -> fetch(request), queryStr = " + queryStr);
      // console.log("fetchListPage -> Fetching list: " + listId + ", listObj: " + listObj);
      that.setState({ ...that.state, loading: true });
      // var timeMsElapsed = new Date().getTime();
      // console.log("Fetching list: " + listId + ", listObj: " + listObj);

      fetch(request).then(function(response) {
        that.setState({ ...that.state, loading: false });
        // console.log("Fetched list: " + listId + ", listObj: " + listObj);
        // timeMsElapsed = new Date().getTime() - timeMsElapsed;
        // console.log("Fetched list: " + listId + ", listObj: " + listObj + ". Processing time = " + timeMsElapsed + " ms.");

        //// DEBUG
        // console.log("fetchListPage -> Fetched list: " + listId + ", listObj: " + listObj + " DONE");
        // console.log("fetchListPage -> response = " + response.status + " " + response.statusText);

        if (!response.ok) {
          return response.json().then(err => { throw err; });
        }
        return response.json();
      }).then(function(json) {
        //// DEBUG
        // console.log("fetchListPage -> response.json = " + JSON.stringify(json));
        // console.log(that);
        //fetch specific inner list data if it is found with the listId. Otherwise throw an error.
        if (json.hasOwnProperty(listId)) {
          //// DEBUG
          // console.log("Found in response.json, listId = \'" + listId + "\', length = " + json[listId].length);
          that.setState({ [listObj]: json[listId] });
        } else {
          throw new Error("No list with the id = \'" + listId + "\' found in the fetched data.");
        }
        //set list page meta if meta is found
        if (json.hasOwnProperty('meta')) {
          //// DEBUG
          // console.log("Found in response.json, meta = " + JSON.stringify(json.meta));
          that.setState({ meta: json.meta });
        }

        queryId = '';
        queryStr = '';
      }).catch(function(err) {
        that.setState({ ...that.state, loading: false });
        // console.log('ERROR: ' + err);
        parseFetchError(err);
      });
    }
  }
}

// Fetch data and insert it into the state as 'form'
// id = the ide of the fetched entity
// type = the type of the fetched entity (effectively the endpoint path variable from where to fetch the data)
// formName = the name of the form object
// callback = the callback to execute after this function is done
export function fetchDataAsForm(id, type, formName, callback) {
  var updateState = function (that, formName, formOb, callback) {
    // Use setState updater callback explicitly if a callback is provided.
    // This ensures that the state is updated before executing the callback.
    if (callback) {
      // console.log("callback = " + callback);
      that.setState((prevState, props) => {
        return {...that.state,
          [formName]: formOb
          };
      }, callback);
    } else {
      // console.log("no callback");
      that.setState({...that.state,
        [formName]: formOb
        });
    }
  };

  if (!formName) {
    formName = 'form';
    console.log("No form name specified. Will use default '" + formName + "'.");
  }

  if (!id) {
    console.log("No id specified. Will not fetch.");
    // Null the form when not fetching
    updateState(this, formName, null, callback);
    return;
  }

  if (!type) {
    console.log("No type specified. Will not fetch.");
    // Null the form when not fetching
    updateState(this, formName, null, callback);
    return;
  }

  // console.log('Fetching. Id = ' + id + ', type = ' + type);

  var token = localStorage.getItem('woodtracker_id_token'),
    admin = JSON.parse(localStorage.getItem('admin_boolean')),
    that = this;
    
  if (token) {
    var header = new Headers();
    header.append('Accept', 'application/json');
    header.append('Content-Type', 'application/json');
    header.append('Authorization', 'Bearer ' + token);
    
    var url = apiBaseUrl + '/' + type + '/' + id;
    
    var request = new Request(url, { 
      method: 'GET', 
      headers: header
    });

    that.setState({ ...that.state, loading: true });

    fetch(request).then(function(response) {
      that.setState({ ...that.state, loading: false });
      return response.json();
    }).then(function(json) {
      // console.log('Fetched json = ' + json);

      let dataObject = json;

      // It is assumed that the json contains a nested object (just one) with the data
      if (Object.keys(json).length = 1) {
        dataObject = json[Object.keys(json)[0]];
        // console.log('Fetched json nested object "' + Object.keys(json)[0] + '" = ' + dataObject);
      }
      updateState(that, formName, dataObject, callback);
    }).catch(function(err) {
      that.setState({ ...that.state, loading: false });
      parseFetchError(err);
    });
  }
}

// Fetch data and insert it into the 'form' in the state under the varialbe names in the parameter 'formTargetVar'
// id = the ide of the fetched entity
// the type of the fetched entity (effectively the endpoint path variable from where to fetch the data)
// formName = the name of the form object
// formTargetVar = contains the name of the variable in the form into which the fetched data is inserted
// callback = the callback to execute after this function is done
export function fetchDataIntoForm(id, type, formName, formTargetVar, callback) {
  var updateState = function (that, formName, formTargetVar, dataObject, callback) {
    // Use setState updater callback explicitly if a callback is provided.
    // This ensures that the state is updated before executing the callback.
    if (callback) {
      // console.log("callback = " + callback);
      that.setState((prevState, props) => {
        return {...that.state,
          [formName]: {
            ...that.state[formName],
            [formTargetVar]: dataObject
          }};
      }, callback);
    } else {
      // console.log("no callback");
      that.setState({...that.state,
        [formName]: {
          ...that.state[formName],
          [formTargetVar]: dataObject
        }});
    }
  };

  if (!formName) {
    formName = 'form';
    console.log("No form name specified. Will use default '" + formName + "'.");
  }

  if (!id) {
    console.log("No id specified. Will not fetch.");
    // Null the form when not fetching
    updateState(this, formName, formTargetVar, null, callback);
    return;
  }

  if (!type) {
    console.log("No type specified. Will not fetch.");
    // Null the form when not fetching
    updateState(this, formName, formTargetVar, null, callback);
    return;
  }

  // console.log('Fetching. Id = ' + id + ', type = ' + type);

  //fetch
  var token = localStorage.getItem('woodtracker_id_token'),
    admin = JSON.parse(localStorage.getItem('admin_boolean')),
    that = this;
    
  if (token) {
    var header = new Headers();
    header.append('Accept', 'application/json');
    header.append('Content-Type', 'application/json');
    header.append('Authorization', 'Bearer ' + token);
    
    var url = apiBaseUrl + '/' + type + '/' + id;
    
    var request = new Request(url, { 
      method: 'GET', 
      headers: header
    });

    that.setState({ ...that.state, loading: true });

    fetch(request).then(function(response) {
      that.setState({ ...that.state, loading: false });
      return response.json();
    }).then(function(json) {
      // console.log('Fetched json = ' + json);

      let dataObject = json;

      // It is assumed that the json contains a nested object (just one) with the data
      if (Object.keys(json).length = 1) {
        dataObject = json[Object.keys(json)[0]];
        // console.log('Fetched json nested object "' + Object.keys(json)[0] + '" = ' + dataObject);
      }

      updateState(that, formName, formTargetVar, dataObject, callback);
    }).catch(function(err) {
      that.setState({ ...that.state, loading: false });
      parseFetchError(err);
    });
  }
}

// A delayed loading indicator that checks the boolean 'loading' in the state to determine whether a loading indicator should be shown. Because the state is used 'this' must be nboud to this method. Shows a loading indicator (adds classname "loading") in the element selected by the querySelector.
// Parameters:
//   delayMs - How long to wait until before showing the loading indicator. Defaults to 1000 ms if not defined or < 0.
//   querySelector - Select in which element to show the loading indicator. When undefined, uses "body" as default.
//   forceShowIndicator - When true, forces showing the loading indicator. When false, forces hiding the loading indicator. When this is defined, it overrides the value from 'loading' in the state. When this is not defined, uses the value from 'loading' in the state.
//
// Example function calls:
//   // Show loading indicator after waiting 1000 ms if 'loading' in the state is true (sets it to true in the function call). 'loading' could be changed to false by something else while this call is waiting, and if that happens, no indicator will be shown after the wait.
//   this.showDelayedLoadingIndicator(500, null, this.setState({ ...this.state, loading: true }));
//   // Show loading indicator immediately
//   this.showDelayedLoadingIndicator(0, null, true);
//   // Hide loading indicator immediately if 'loading' in state is false (sets it to false in the function call)
//   this.showDelayedLoadingIndicator(0, null, this.setState({ ...this.state, loading: false }));
//   // Hide loading indicator immediately.
//   this.showDelayedLoadingIndicator(0, null, false);
//   this.showDelayedLoadingIndicator(0, null);
export function showDelayedLoadingIndicator(delayMs, querySelector, forceShowIndicator) {
  if (typeof delayMs === 'undefined' || delayMs < 0) {
    delayMs = 500;
    // console.log("'delayMs' is 'undefined' or < 0. Using default = " + delayMs);
  }

  setTimeout(function() {
    let showIndicator = false;
    if (typeof forceShowIndicator != 'undefined') {
      // console.log("Using forceShowIndicator = " + forceShowIndicator);
      (forceShowIndicator) ? showIndicator = true : showIndicator = false;
    } else {
      if (typeof this.state.loading === 'undefined') {
        console.warn("'loading' in the state is 'undefined'. Can't show delayed loading indicator.");
      } else {
        showIndicator = this.state.loading;
      }
    }

    // console.log("Showing loading indicator = " + showIndicator);

    showLoadingIndicator(showIndicator, querySelector);
  }.bind(this), delayMs);
}

// Shows a loading indicator (adds classname "loading") in the element selected by the querySelector.
// Parameters:
//   show - When true, shows the loading indicator. When false, hides the loading indicator.
//   querySelector - Select in which element to show the loading indicator. When undefined, uses "body" as default.
export function showLoadingIndicator(show, querySelector) {
  let showIndicator = false;
  if (typeof show === 'undefined') {
    console.log("'show' is 'undefined'. Will use: false.")
    showIndicator = false;
  } else if (show) {
    showIndicator = true;
  }

  // console.log("querySelector = " + querySelector);
  const defaultQuerySelector = "body";
  // If no querySelector specified, use "body"
  if (!querySelector) {
    // console.log("No query selector specified. Using \"" + defaultQuerySelector + "\"");
    querySelector = defaultQuerySelector;
  }

  // If no element corresponding to querySelector found, use "body"
  if (!document.querySelector(querySelector)) {
    // console.log("No element found for the query selector \"" + querySelector + "\". Using \"" + defaultQuerySelector + "\"");
    querySelector = defaultQuerySelector;
  }

  if (document.querySelector(querySelector)) {
    if (showIndicator) {
      document.querySelector(querySelector).className += ' loading';
    } else {
      document.querySelector(querySelector).className = document.querySelector(querySelector).className.replace('loading','');
    }
  }
}

//function to fetch the latest traderInfo
export function fetchTraderInfo () {
  //check token and refresh it if possible
  refreshToken();
  //fetch current trader info
  var token = localStorage.getItem('woodtracker_id_token');
  var that = this;
  if (token) {
    var header = new Headers();
    header.append('Accept', 'application/json');
    header.append('Content-Type', 'application/json');
    header.append('Authorization', 'Bearer ' + token);
    
    var request = new Request(apiBaseUrl + '/profile', { 
      method: 'GET', 
      headers: header
    });
    
    fetch(request).then(function(response) {
      if (!response.ok) {
        return response.json().then(err => { throw err; });
      }
      return response.json();
    }).then(function(json) {
      //console.log(json);
      that.setState({ user: json.user });
    }).catch(function(err) {
      //console.log('ERROR: ' + err);
      parseFetchError(err);
    });
  }
}

//generic funtion to parse fetch error into easily readable form
//var alertCounter = 0;
//var alertString = '';
export function parseFetchError (err) {
  //console.log(err);
  //the checkup interval 
  /*var interval = setInterval(function() {
    alert(alertString);
    alertCounter = 0;
    alertString = '';
    clearInterval(interval);
  },2000);*/

  var errString = '';

  // This is for error JSON messages originating from the backend.
  if (err.error) {
    var objErrors = err.error;
    // Prepend an explicit "Error:" string.
    errString += 'Error:\n';
  
    for (let i in objErrors) {
      // If objErrors came in as a String instead of a JSON object.
      if (typeof objErrors[i] === 'string') {
        try {
          objErrors[i] = JSON.parse(objErrors[i]);
        } catch (err) {
          console.warn("Could not parse errors as json. Assuming contents to be a pure string message.")
        }
      }
      var key = String(Object.keys(objErrors[i]));
      var values = objErrors[i][key];
      if (values) {
        // console.log("key    = " + key);
        // console.log("values = " + values);
      
        //errString += (key[0].toUpperCase() + key.slice(1))  + ' '
      
        for (let i in values) {
          // console.log("value[" + i + "] = " + values[i]);
          errString += (key[0].toUpperCase() + key.slice(1)) + ' - ' + values[i] + '. ' + ((i < (values[i].length - 1)) ? '\n' : '');
        }
      } else {
        // Assume just pure string message
        errString += objErrors[i] + '. ' + ((i < (objErrors[i].length - 1)) ? '\n' : '');
      }
    }
  }

  // If no other error info is found, try to default to err.message / err (+ err.stack)
  // This is mainly for printing errors originating from frontend's throw Error() statements.
  if (errString.length < 1) {
    errString += (err.message ? err.message : (err ? err : "No error message available."));
    errString += (err.stack ? "\n" + err.stack : " " + "(No error stack available.)");
  }

  //// TODO - Should we alert here? Wega Fuels did so, but it gets spammy in dev.
  //// TODO - Instead we can log to console (better in dev, but might be harder for the customer to let us know about unforeseen problems)
  console.error(errString);
  alert(errString);
  //console.log(err);
  /*if (alertCounter < 1) {
    alert(errString);
  } else {
    alertString += errString;
    setInterval(interval);
  }
  alertCounter++;
  console.log(alertCounter);*/
} 

//generic funtion to handle the list views contents sessionStorage fetching
//listId is a string defining the list that is being handled
//listObj is a string defining the object where the results needs to be set
//sessionBool is a boolean to determine if data should be stored into sessionStorage
//queryString is a list formatting querystring sent to the backend
export function handleSessionStorage (listId, listObj, sessionBool) {
  //fetch the listId list
  //fetch new listId list if sessionStorage has nothing related to the list
  //fetch new listId list if data is older than 5 minutes -> 300000ms
  var that = this;
  if ((!sessionStorage[listId + 'List'] && !sessionStorage[listId + 'ListUpdated']) || 
    ((new Date().getTime() - sessionStorage[listId + 'ListUpdated']) > 300000)) {
    //set the old data into place and then fetch new
    if (sessionStorage[listId + 'List']) {
      that.setState({ 
        [listObj]: JSON.parse(sessionStorage[listId + 'List'])
      });
    }
    this.fetchList(listId,listObj,sessionBool);
  } else {
    var list = JSON.parse(sessionStorage[listId + 'List']);
    // if (listId === 'countries') {
    //   let arr = [];
    //   for (let i = 0, j = list.length; i < j; i++) {
    //     if (arr.indexOf(list[i].region) === -1) {
    //       arr.push(list[i].region);
    //     }
    //   }
    //   that.setState({ regions: arr });
    // }

    that.setState({ 
      [listObj]: list
    });
  }
}

//generic function to change the page on list view
export function changePage (listId, query) {
  //console.log(listId + ', ' + query);
  if (!query) {
    var route = this.props.route.path || '';
    var queryString = '';
    for (let i in this.props.location.query) {
      queryString += '&' + i + '=' + this.props.location.query[i];
    }
    this.props.history.push(route + queryString.replace('&','?').replace(/ /g,'+'));
    this.updateList(queryString.replace('&','?'), listId);
  } else {
    this.updateList(query, listId);
  }
}

// function to handle list view updating according to the set url parameters
export function updateList (query, listId) {
  if (!query && !this.props.location.query.per_page && !this.props.location.query.page) {
    this.props.location.query.per_page = 15;
    this.props.location.query.page = 1;
    hashHistory.replace(this.props.location);
    query = '?per_page=15&page=1';
    handleDebouncedCallback(() => this.fetchListPage(listId,'list',query));
    // this.fetchListPage(listId,'list',query);
  } else if (!query && this.props.location.query) {
    query = '';
    for (let i in this.props.location.query) {
      query += '&' + i + '=' + this.props.location.query[i];
    }
    handleDebouncedCallback(() => this.fetchListPage(listId,'list',query.replace('&','?')));
    // this.fetchListPage(listId,'list',query.replace('&','?'));
  } else {
    handleDebouncedCallback(() => this.fetchListPage(listId,'list',query));
    // this.fetchListPage(listId,'list',query);
  }
}

//set item active status
export function setItemActiveStatus (active, item, requestPathItem) {
  var token = localStorage.getItem('woodtracker_id_token'),
    admin = JSON.parse(localStorage.getItem('admin_boolean')),
    that = this,
    id = this.props.location.query.id;
  
  var r = true;
  if (!active) {
    r = confirm('Are you sure you want to deactivate this '+ item +'?');
  }
  if (token && r) {
    var header = new Headers();
    header.append('Accept', 'application/json');
    header.append('Content-Type', 'application/json');
    header.append('Authorization', 'Bearer ' + token);
    
    //var url = apiBaseUrl + '/' + item + '/' + id;
    var url = apiBaseUrl + '/' + requestPathItem + '/' + id + "/activate";
    const jsonObject = new Object();
    jsonObject[item] = {active: active};
    
    var request = new Request(url, { 
      method: 'PUT', 
      headers: header,
      body: JSON.stringify(jsonObject)
    });
    
    fetch(request).then(function(response) {
      if (!response.ok) {
        return response.json().then(err => { throw err; });
      }
      return response;
    }).then(function(/*response*/) {
      //console.log(response);
      var form = that.state.form;
      form.active = active;
      that.setState({ form: form });
      if (!active) {
        (admin) ? 
          that.setState({ buttonVisibility: [false,false,true,false,false] }) : 
          that.setState({ buttonVisibility: [false,false,true,false,false] })
          // window.location.hash = '#/' + requestPathItem;
          // window.location.hash = '#/' + item + '?id=' + id;
      } else {
        // that.setState({ form: form });
        that.setState({ buttonVisibility: [true,true,false,false,true] });
      }
      sessionStorage[requestPathItem + 'ListUpdated'] = new Date().getTime() - 300000;
      // sessionStorage.setItem(requestPathItem + 'ListUpdated', new Date().getTime() - 300000);
    }).catch(function(err) {
      parseFetchError(err);
    });
  }
}

//delete an item
export function deleteItem (item) {
  var token = localStorage.getItem('woodtracker_id_token'),
    admin = JSON.parse(localStorage.getItem('admin_boolean')),
    that = this,
    id = this.props.location.query.id;
  
  var r = confirm('Are you sure you want to delete this '+ item +'?');
  if (token && r) {
    var header = new Headers();
    header.append('Accept', 'application/json');
    header.append('Content-Type', 'application/json');
    header.append('Authorization', 'Bearer ' + token);
    
    var url = apiBaseUrl + '/' + item + '/' + id;
    
    var request = new Request(url, { 
      method: 'DELETE', 
      headers: header
    });
    
    fetch(request).then(function(response) {
      if (!response.ok) {
        return response.json().then(err => { throw err; });
      }
      return response;
    }).then(function(/*response*/) {
      //console.log(response);
      window.location.hash = '#/' + item;
      sessionStorage[item + 'ListUpdated'] = '';
    }).catch(function(err) {
      parseFetchError(err);
    });
  }
}

// Make a get request for a file resource
export function getFile (documentId, requestPathItem) {
  var token = localStorage.getItem('woodtracker_id_token'),
    that = this;
  // let link = document.createElement('a');
  // link.setAttribute('href', window.location.href);
  // var origin = window.location.origin; //link.protocol + "//" + link.hostname + (link.port ? ':' + link.port : '');
  // link = null;

  // console.log("origin = " + origin);
  
  if (token) {
    var header = new Headers();
    // header.append('Accept', 'application/json');
    header.append('Authorization', 'Bearer ' + token);
    // header.append('Origin', origin);
    
    var url = apiBaseUrl + '/' + requestPathItem + '/' + documentId;
    
    var request = new Request(url, { 
      method: 'GET', 
      headers: header
    });

    // Don't allow download if another one is pending
    if (!this.state.downloadPending) {
      that.setState({ ...that.state, downloadPending: true });
      var fileName;
      
      fetch(request).then(function(response) {
        // Don't set pending status false here, because the response hasn't yet been blobbed (which takes awhile for larger files). See the next .then() funtion below.
        // that.setState({ ...that.state, downloadPending: false });
        if (!response.ok) {
          return response.json().then(err => { throw err; });
        }

        // Try to get the file name from the 'content-disposition' header
        var headerContentDisposition = response.headers.get('content-disposition');
        //// DEBUG
        // headerContentDisposition = 'content-disposition:attachment; filename="work_beards.jpg"';
        // console.log("Found response header -> 'content-disposition' = " + headerContentDisposition);
        if (headerContentDisposition && headerContentDisposition.indexOf('filename=') > -1) {
          fileName = headerContentDisposition.substring(headerContentDisposition.lastIndexOf('filename=') + 9);
          fileName = fileName.replace(/['"]/g, '');
          console.info("Found file name = \"" + fileName + "\" in the 'content-disposition' response header.")
        }

        // Try to get filename from the url
        if (!fileName) { 
          if (response.url) {
            let link = document.createElement('a');
            link.setAttribute('href', response.url);
            if (link.pathname.lastIndexOf('/') > -1) {
              fileName = link.pathname.substring(link.pathname.lastIndexOf('/') + 1);
            } else {
              fileName = link.pathname;
            }
            link = null;
            console.info("Found file name = \"" + fileName + "\" in the response url.")
          } else {
            console.warn("Can't get file. Response does not contain url." + response);
            throw new Error("Can't get file. Response does not contain url." + response);
          }
        }

        // The ultimate filename
        console.info("Preparing download with file name = \"" + fileName + "\"");

        return response.blob();
      }).then(function(blob) {
        // set pending status false here, so that it is true for the whole duration of blobbing the response
        that.setState({ ...that.state, downloadPending: false });
        console.info("Downloading file = \"" + fileName + "\", size = \"" + blob.size + "\"");

        // console.log("Blob = " + blob);
        var url = URL.createObjectURL(blob);
        //// DEBUG - print the blob url for manual download testing.
        // console.log("Blob url = " + url);
        // // "window.location" won't work if the generated link requires special headers, etc.
        // window.location = url;

        var downloadLink = document.createElement('a');
        downloadLink.setAttribute('href', url );
        downloadLink.setAttribute('download', fileName);
        // downloadLink.setAttribute('target', '_blank');
        // downloadLink.style.display = 'none';
        // document.body.appendChild(downloadLink);
        downloadLink.click();
        // Revoke the link, so it can't be accessed after this download is finished.
        URL.revokeObjectURL(url);
        downloadLink = null;
      }).catch(function(err) {
        that.setState({ ...that.state, downloadPending: false });
        parseFetchError(err);
      });
    }
  }
}

// Cancel editing by updating location to the given path
export function cancelEdit (path) {
  var r = confirm('Are you sure you want to cancel editing? Form data will not be stored.');
  if (r) {
    window.location.hash = '#/' + path;
  }
}

//check for disabledEmails and remove them from the list
//arr1 the array disabled emails
//arr2 the array where the disabled emails need to be removed
export function filterOutDisabeldEmails (arr1, arr2) {
  if (arr1) {
    let i = arr2.length;
    while (i--) {
      for (let k = 0, l = arr1.length; k < l; k++) {
        if (arr2[i] === arr1[k]) {
          arr2.splice(i, 1);
        }
      }
    }
    //the backend can't handle empty empty arrays
    if (arr2.length === 0) {
      arr2.push('');
    }
  }
}

export function constructDefaultDate () {
  var currentDate = new Date(),
  currentMonth = ((currentDate.getUTCMonth() + 1) < 10) ? '0' + (currentDate.getUTCMonth() + 1) : (currentDate.getUTCMonth() + 1),
  currentDay = (currentDate.getUTCDate() < 10) ? '0' + currentDate.getUTCDate() : currentDate.getUTCDate();

  return currentDate.getFullYear() + '-' + currentMonth + '-' + currentDay;
}

// Preserves the 'expanded' value from the objects in the source array to the the objects in the destination array.
// IMPORTANT! The order of objects in the arrays must be the same!
export function preserveExpanded(arrSource, arrDest) {
  if (!arrSource) {
    console.warn("No source array provided. Will not attempt to preserve expanded state.");
    return;
  }
  if (!arrDest) {
    console.warn("No destination array provided. Will not attempt to preserve expanded state.");
    return;
  }

  for (let i = 0; i < arrSource.length; i++) {
    if (arrSource[i].expanded) {
      arrDest[i].expanded = arrSource[i].expanded;
    }
  }
}

// Reorders items in an array. Moves item denoted by itemId either up or down one step.
// itemId = The id of the item to move (the item must have an id field)
// before = When true, moves item up one step. When false, moves item down one step.
export function reorderItemsInArray (array, itemId, before) {
  if (!itemId) {
    console.warn("No itemId defined. Will not reorder array.");
    return array;
  }
  if (array) {
    for (let i = 0; i < array.length; i++) {
      let item = array[i];

      if (!item.id) {
        console.warn("Item has no id. Will not reorder item.");
        continue;
      }
      
      if (item.id === itemId) {
        let extracted = array.splice(i, 1)[0];
        if (before) {
          if (i > 0) {
            array.splice(i - 1, 0, extracted);
          } else {
            array.splice(0, 0, extracted);
          }
        } else {
          if (i < array.length) {
            array.splice(i + 1, 0, extracted);
          } else {
            array.splice(array.length, 0, extracted);
          }
        }
        // Don't continue processing array after first match has been handled.
        break;
      }
    }

    array.forEach(function(item, i) {
      // Make sure order is defined for all items
      // if (!item.order) {
        item.order = i;
      // }
    });

    return array;
  }
}

export function offset(el) {
  var rect = el.getBoundingClientRect(),
  scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
  scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

export function isInView(el, completelyVisible) {
  var rect = el.getBoundingClientRect();
  var elemTop = rect.top;
  var elemBottom = rect.bottom;

  var isVisible = false;

  if (completelyVisible) {
    // Only completely visible elements return true:
    isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
  } else {
    // Partially visible elements return true:
    isVisible = elemTop < window.innerHeight && elemBottom >= 0;
  }  
  return isVisible;
}

export var handleDebouncedCallback = debounce((callback) => {
  // console.log("callback = " + callback);
  callback();
}, 250);

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export function debounce(func, wait, immediate) {
  // console.log("debounce -> function : " + func);
  var timeout;
  return function() {
    var context = this, args = arguments;
    var later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    var callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};