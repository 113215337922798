'use strict';

require('../stylesheets/app.scss');

import React from 'react';
import { Button, Checkbox } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import ActionBar from '../components/ActionBar';
import FilterBar from '../components/FilterBar';
import ListComponent from '../components/ListComponent';
import { changePage, fetchList, fetchListPage, handleSessionStorage, updateList, parseFetchError } from '../actions/utility';
import { initializeLead } from '../actions/entities';
import { calculateLeadEnergyContentsAndPrices } from '../actions/calculations';

class Dashboard extends React.Component {
  constructor (props) {
    super(props);
    
    this.fetchList = fetchList.bind(this);
    this.fetchListPage = fetchListPage.bind(this);
    this.handleSessionStorage = handleSessionStorage.bind(this);
    
    this.changePage = changePage.bind(this);
    this.updateList = updateList.bind(this);

    this.changeLeadsPage = this.changeLeadsPage.bind(this);
    this.updateLeadsList = this.updateLeadsList.bind(this);

    this.handleRowToggle = this.handleRowToggle.bind(this);
    this.calculateSumsForSelectedRows = this.calculateSumsForSelectedRows.bind(this);

    this.viewNewLead = this.viewNewLead.bind(this);
    this.initializeLead = initializeLead.bind(this);

    this.state = { 
      list: [],
      sortColumn: 'id',
      sortDirection: 'ASC',
      sumMWh: (0).toFixed(2),
      sumValueEuros: (0).toFixed(2),
      sumEurosPerMwh: (0).toFixed(2),
      selectedRows: [],
      products: [],
      buttonVisibility: [true],
      meta: {}
    };
    
    this.buttons = [
      {
        icon: 'ion-plus',
        onClick: ()=>this.viewNewLead(),
        refreshFunc: ()=>console.log("////TODO - implement correct refreshFunc for New Lead button"),
        // refreshFunc: ()=>this.updateList(undefined, 'leads'),
        text: 'new lead'
      }
    ];

    //// TODO - disabled fetching (because we don't yet have these that were imported from Wega Fuel Trader)
    // this.updateList(undefined, 'leads');
  }

  componentDidMount () {
    // var query = '';
    // for (let i in this.props.location.query) {
    //   query += '&' + i + '=' + this.props.location.query[i];
    // }
    // this.fetchListPage('leads','list',query.replace('&','?'));
    // this.updateLeadsList(undefined, 'leads');
    this.initUrl();
    this.sortLeadsPage('leads', this.state.sortColumn);
    this.handleSessionStorage('products','products',false,'');
  }
  
  // componentWillReceiveProps (nextProps) {
  //   if (nextProps.location.action === 'POP') {
  //     //// TODO - disabled fetching (because we don't yet have these that were imported from Wega Fuel Trader)
  //     this.changePage('leads',nextProps.location.search);
  //   }
  // }

  initUrl () {
    // Default pagination
    this.props.location.query.per_page = 15;
    this.props.location.query.page = 1;
  }

  sortLeadsPage (listId, sortColumn, sortDirection, query) {
    this.props.location.query.sort = sortColumn;
    if (sortColumn === this.state.sortColumn && sortDirection) {
      sortDirection = this.getToggledSortDirection(sortDirection);
    } else {
      sortDirection = 'ASC';
    }

    if (sortDirection) {
      this.props.location.query.direction = sortDirection;
    }

    this.setState((prevState, props) => {
      return { ...prevState,
        sortColumn: sortColumn,
        sortDirection: sortDirection
      };
    }, this.changeLeadsPage(listId, query));
  }

  getToggledSortDirection(sortDirection) {
    if (!sortDirection) {
      sortDirection = 'ASC';
    }
    if (sortDirection === 'ASC') {
      return 'DESC';
    } else {
      return 'ASC';
    }

    return 'ASC';
  }

  changeLeadsPage (listId, query) {
    this.setState((prevState, props) => {
      return { ...prevState,
        selectedRows: []
      };
    }, this.calculateSumsForSelectedRows());

    this.changePage(listId, query);
  }

  updateLeadsList (query, listId) {
    this.setState((prevState, props) => {
      return { ...prevState,
        selectedRows: []
      };
    }, this.calculateSumsForSelectedRows());
    
    this.updateList(query, listId);
  }

  // Create new empty Lead and navigate to Lead View for the newly created Lead after creation.
  viewNewLead () {
    var token = localStorage.getItem('woodtracker_id_token');
    var that = this;

    var header = new Headers();
    header.append('Accept', 'application/json');
    header.append('Content-Type', 'application/json');
    header.append('Authorization', 'Bearer ' + token);

    var lead = {
      lead: initializeLead()
    };
    
    var request = new Request(apiBaseUrl + '/leads', { 
      method: 'POST', 
      headers: header,
      body: JSON.stringify(lead)
    });
    
    //prevent calling fetch if request is pending
    if (!that.state.loading) {
      that.setState({ loading: true });
      fetch(request).then(function(response) {
        if (!response.ok) {
          //throw Error(response.statusText);
          return response.json().then(err => { throw err; });
        }
        console.log(response);
        return response.json();
      }).then(function(json) {
        console.log(json);
        console.log("Created lead = " + json.lead.id);
        that.setState({ loading: false });
        sessionStorage.leadsListUpdated = '';
        
        // navigate to Lead View for the newly created Lead
        that.viewLead(json.lead.id);
      }).catch(function(err) {
        that.setState({ loading: false });
        parseFetchError(err);
      });
    }
  }

  // Navigate to Lead View for the provided Lead (id)
  viewLead (id, view_section) {
    // Add id if it was provided
    window.location.hash = '#/lead' + ((id) ? "?" + 'id=' + id + ((view_section) ? '&view_section=' + view_section : '') : '');
  }

  calculateSumsForSelectedRows (selectedRows) {
    let that = this;
    let sumMWh = 0,
    sumValueEuros = 0,
    sumEurosPerMwh = 0;

    // console.log("selectedRows = " + selectedRows);

    // calculate sums of checked rows
    if (selectedRows) {
      selectedRows.forEach(function(id, i) {
        if (that.state.list) {
          that.state.list.forEach(function(item, i) {
            if (item.id === id) {
              // mwh
              let valueMwh = Number.parseFloat(document.querySelector("[id='" + id + "mwh']").innerHTML);
              // console.log("valueMwh = " + valueMwh);
              if (valueMwh) {
                sumMWh += valueMwh;
              }

              // eurosPerMwh
              let valueValueEuros = Number.parseFloat(document.querySelector("[id='" + id + "valueEuros']").innerHTML);
              // console.log("valueValueEuros = " + valueValueEuros);
              if (valueValueEuros) {
                sumValueEuros += valueValueEuros;
              }

              // eurosPerMwh
              let valueEurosPerMwh = Number.parseFloat(document.querySelector("[id='" + id + "eurosPerMwh']").innerHTML);
              // console.log("valueEurosPerMwh = " + valueEurosPerMwh);
              if (valueEurosPerMwh) {
                sumEurosPerMwh += valueEurosPerMwh;
              }
            }
          });
        }
      });
    }

    // Rounding to decimals
    sumMWh = sumMWh.toFixed(2);
    sumValueEuros = sumValueEuros.toFixed(2);
    sumEurosPerMwh = sumEurosPerMwh.toFixed(2);
    
    // console.log("sumMWh = " + sumMWh);
    // console.log("sumValueEuros = " + sumValueEuros);
    // console.log("sumEurosPerMwh = " + sumEurosPerMwh);

    this.setState({ ...this.state,
      sumMWh: sumMWh,
      sumValueEuros: sumValueEuros,
      sumEurosPerMwh: sumEurosPerMwh
    });
  }

  handleRowToggle (e, id) {
    // console.log(e.target);
    // console.log("id = " + id);

    let newSelectedRows = [];

    if (id) {
      if (this.state.selectedRows.find(x => x == id)) {
        // Will remove all found occurrences
        newSelectedRows = this.state.selectedRows.filter(x => x !== id);
      } else {
        newSelectedRows = this.state.selectedRows.slice();
        newSelectedRows.push(id);
      }

      // this.setState({ ...this.state,
      //   selectedRows: newSelectedRows
      // });

      // calculate sums of checked rows as a callback
      this.setState((prevState, props) => {
        return {selectedRows: newSelectedRows};
      }, this.calculateSumsForSelectedRows(newSelectedRows));
    }
  }

  handleLink (e, id, view_section) {
    // Prevent further click events from propagating
    e.preventDefault();
    console.log(e.target);
    console.log("id = " + id);
    console.log("view_section = " + view_section);

    if (id) {
      this.viewLead(id, view_section);
    }
  }

  render () {
    return (
      <div className="dashboard">
        <ActionBar 
          elements={this.buttons} 
          visibility={this.state.buttonVisibility} />
        
        <FilterBar 
          changePage={()=>this.changeLeadsPage('leads')}
          elements={['text-search', 'date-filter']} 
          location={this.props.location} />
        
        <ListComponent 
          changePage={()=>this.changeLeadsPage('leads')}
          elements={['componentFilter','componentList','componentShow','componentPagination']} 
          location={this.props.location}
          loading={this.state.loading}
          meta={this.state.meta}>
          <div>
            <div>
              <div className="list-header">
                <span className="list-column listCheckbox" />
                <span className="list-column id" onClick={(event) => this.sortLeadsPage('leads', 'id', this.state.sortDirection)}>{this.state.sortColumn === 'id' ? ((!this.state.sortDirection || this.state.sortDirection === 'ASC') ? <span className={'ion-android-arrow-dropup'} /> : <span className={'ion-android-arrow-dropdown'} />) : ''} ID</span>

                <span className="list-column status" onClick={(event) => this.sortLeadsPage('leads', 'status', this.state.sortDirection)}>{this.state.sortColumn === 'status' ? ((!this.state.sortDirection || this.state.sortDirection === 'ASC') ? <span className={'ion-android-arrow-dropup'} /> : <span className={'ion-android-arrow-dropdown'} />) : ''} ST</span>

                <span className="list-column deal" onClick={(event) => this.sortLeadsPage('leads', 'deal', this.state.sortDirection)}>{this.state.sortColumn === 'deal' ? ((!this.state.sortDirection || this.state.sortDirection === 'ASC') ? <span className={'ion-android-arrow-dropup'} /> : <span className={'ion-android-arrow-dropdown'} />) : ''} Deal</span>

                <span className="list-column seller" onClick={(event) => this.sortLeadsPage('leads', 'seller', this.state.sortDirection)}>{this.state.sortColumn === 'seller' ? ((!this.state.sortDirection || this.state.sortDirection === 'ASC') ? <span className={'ion-android-arrow-dropup'} /> : <span className={'ion-android-arrow-dropdown'} />) : ''} Seller</span>

                <span className="list-column buyer" onClick={(event) => this.sortLeadsPage('leads', 'buyer', this.state.sortDirection)}>{this.state.sortColumn === 'buyer' ? ((!this.state.sortDirection || this.state.sortDirection === 'ASC') ? <span className={'ion-android-arrow-dropup'} /> : <span className={'ion-android-arrow-dropdown'} />) : ''} Buyer</span>

                <span className="list-column from" onClick={(event) => this.sortLeadsPage('leads', 'source', this.state.sortDirection)}>{this.state.sortColumn === 'source' ? ((!this.state.sortDirection || this.state.sortDirection === 'ASC') ? <span className={'ion-android-arrow-dropup'} /> : <span className={'ion-android-arrow-dropdown'} />) : ''} From</span>

                <span className="list-column to" onClick={(event) => this.sortLeadsPage('leads', 'destination', this.state.sortDirection)}>{this.state.sortColumn === 'destination' ? ((!this.state.sortDirection || this.state.sortDirection === 'ASC') ? <span className={'ion-android-arrow-dropup'} /> : <span className={'ion-android-arrow-dropdown'} />) : ''} To</span>

                <span className="list-column product" onClick={(event) => this.sortLeadsPage('leads', 'product', this.state.sortDirection)}>{this.state.sortColumn === 'product' ? ((!this.state.sortDirection || this.state.sortDirection === 'ASC') ? <span className={'ion-android-arrow-dropup'} /> : <span className={'ion-android-arrow-dropdown'} />) : ''} Product</span>

                <span className="list-column mwh" onClick={(event) => this.sortLeadsPage('leads', 'mwh', this.state.sortDirection)}>{this.state.sortColumn === 'mwh' ? ((!this.state.sortDirection || this.state.sortDirection === 'ASC') ? <span className={'ion-android-arrow-dropup'} /> : <span className={'ion-android-arrow-dropdown'} />) : ''} MWh</span>

                <span className="list-column valueEuros" onClick={(event) => this.sortLeadsPage('leads', 'value', this.state.sortDirection)}>{this.state.sortColumn === 'value' ? ((!this.state.sortDirection || this.state.sortDirection === 'ASC') ? <span className={'ion-android-arrow-dropup'} /> : <span className={'ion-android-arrow-dropdown'} />) : ''} Value €</span>

                <span className="list-column eurosPerMwh" onClick={(event) => this.sortLeadsPage('leads', 'eur_per_mwh', this.state.sortDirection)}>{this.state.sortColumn === 'eur_per_mwh' ? ((!this.state.sortDirection || this.state.sortDirection === 'ASC') ? <span className={'ion-android-arrow-dropup'} /> : <span className={'ion-android-arrow-dropdown'} />) : ''} €/MWh</span>

                <span className="list-column deadline" onClick={(event) => this.sortLeadsPage('leads', 'deadline', this.state.sortDirection)}>{this.state.sortColumn === 'deadline' ? ((!this.state.sortDirection || this.state.sortDirection === 'ASC') ? <span className={'ion-android-arrow-dropup'} /> : <span className={'ion-android-arrow-dropdown'} />) : ''} Deadline</span>

                <span className="list-column logistics">Logistics</span>
                <span className="list-column additionalCosts">Additional costs</span>
              </div>
              <ul>
                {(this.state.list.length === 0) &&
                  <li className="no-results">No results found.</li>
                }
                {this.state.list.map(function(listItem, i) {
                  // Do product_details calculations
                  listItem = calculateLeadEnergyContentsAndPrices(listItem);

                  return <li id={listItem.id} key={i}>
                    <span className="list-column listCheckbox">
                      <Checkbox inline id={listItem.id} key={listItem.id}
                        checked={(this.state.selectedRows.find(x => x == listItem.id) ? true : false)}
                        onChange={(event)=>this.handleRowToggle(event, listItem.id)} />
                    </span>
                    <Link to={'/lead?id=' + listItem.id}>
                      
                      {/* Id */}
                      <span className="list-column id">{listItem.id}</span>
                      
                      {/* Status */}
                      <span className={'list-column status status-' + listItem.status} />

                      {/* Deal */}
                      <span className={'list-column deal'} onClick={(event)=>this.handleLink(event, listItem.id, 'certificates')}>{(listItem.deal ? listItem.deal: '')}</span>
                      
                      {/* Seller */}
                      <span className="list-column seller" onClick={(event)=>this.handleLink(event, listItem.id, 'counterparties')}>{(listItem.seller && listItem.seller.id) ? listItem.seller.name :
                        <Button className="add-info-button">
                          <span className={'ion-plus'} /></Button>}
                      </span>
                      
                      {/* Buyer */}
                      <span className="list-column buyer" onClick={(event)=>this.handleLink(event, listItem.id, 'counterparties')}>{(listItem.buyer && listItem.buyer.id) ? listItem.buyer.name :
                        <Button className="add-info-button">
                          <span className={'ion-plus'} /></Button>}
                      </span>

                      {/* From */}
                      <span className="list-column from" onClick={(event)=>this.handleLink(event, listItem.id, 'counterparties')}>{(listItem.source && listItem.source.id) ? listItem.source.name :
                        <Button className="add-info-button">
                          <span className={'ion-plus'} /></Button>}
                      </span>

                      {/* To */}
                      <span className="list-column to" onClick={(event)=>this.handleLink(event, listItem.id, 'counterparties')}>{(listItem.destination && listItem.destination.id) ? listItem.destination.name :
                        <Button className="add-info-button">
                          <span className={'ion-plus'} /></Button>}
                      </span>

                      {/* Product */}
                      {/* Show product name. If not provided, show product + product_type. */}
                      <span className="list-column product" onClick={(event)=>this.handleLink(event, listItem.id, 'product')}>{((listItem.product_details && (listItem.product_details.product_name || listItem.product_details.product_id)) ?
                        (listItem.product_details.product_name ?
                          <span>{listItem.product_details.product_name}</span> :
                          (this.state.products.find(x => x.id == listItem.product_details.product_id).name + ', ' + listItem.product_details.product_type)) :
                            <Button className="add-info-button">
                            <span className={'ion-plus'} /></Button>)}
                      </span>
                      {/* Show product + product_type. */}
                      {/* <span className="list-column product" onClick={(event)=>this.handleLink(event, listItem.id, 'product')}>{(listItem.product_details && listItem.product_details.product_id) ? this.state.products.find(x => x.id == listItem.product_details.product_id).name + ', ' + listItem.product_details.product_type :
                        <Button className="add-info-button">
                          <span className={'ion-plus'} /></Button>}
                      </span> */}

                      {/* MWh */}
                      {/* Show verified value. If not provided, show indicative value. */}
                      <span className="list-column mwh" onClick={(event)=>this.handleLink(event, listItem.id, 'product')}>{((listItem.product_details && (listItem.product_details.sumTotalEnergyContentVerified || listItem.product_details.sumTotalEnergyContentIndicative)) ?
                        (listItem.product_details.sumTotalEnergyContentVerified ?
                          <span id={listItem.id + 'mwh'}>{listItem.product_details.sumTotalEnergyContentVerified.toFixed(2)}</span> :
                          (listItem.product_details.sumTotalEnergyContentIndicative ? <span id={listItem.id + 'mwh'} className="indicativeValue">{listItem.product_details.sumTotalEnergyContentIndicative.toFixed(2)}</span> :
                          false)) :
                            <Button className="add-info-button">
                            <span className={'ion-plus'} /></Button>)}
                      </span>
                      {/* Show verified value */}
                      {/* <span className="list-column mwh" id={listItem.id + 'mwh'} onClick={(event)=>this.handleLink(event, listItem.id, 'product')}>{((listItem.product_details && listItem.product_details.sumTotalEnergyContentVerified) ?
                        listItem.product_details.sumTotalEnergyContentVerified.toFixed(2) :
                            <Button className="add-info-button">
                            <span className={'ion-plus'} /></Button>)}
                      </span> */}

                      {/* Value Euros */}
                      {/* Show verified value. If not provided, show indicative value. */}
                      <span className="list-column valueEuros" onClick={(event)=>this.handleLink(event, listItem.id, 'product')}>{((listItem.product_details && (listItem.product_details.sumTotalEnergyCostVerified || listItem.product_details.sumTotalEnergyCostIndicative)) ?
                        (listItem.product_details.sumTotalEnergyCostVerified ?
                          <span id={listItem.id + 'valueEuros'}>{listItem.product_details.sumTotalEnergyCostVerified.toFixed(2)}</span> :
                          (listItem.product_details.sumTotalEnergyCostIndicative ? <span id={listItem.id + 'valueEuros'} className="indicativeValue">{listItem.product_details.sumTotalEnergyCostIndicative.toFixed(2)}</span> :
                          false)) :
                            <Button className="add-info-button">
                            <span className={'ion-plus'} /></Button>)}
                      </span>
                      {/* Show verified value */}
                      {/* <span className="list-column valueEuros" id={listItem.id + 'valueEuros'} onClick={(event)=>this.handleLink(event, listItem.id, 'product')}>{((listItem.product_details && listItem.product_details.sumTotalEnergyCostVerified) ?
                          listItem.product_details.sumTotalEnergyCostVerified.toFixed(2) :
                            <Button className="add-info-button">
                            <span className={'ion-plus'} /></Button>)}
                      </span> */}

                      {/* eurosPerMwh */}
                      {/* Show verified value. If not provided, show indicative value. */}
                      <span className="list-column eurosPerMwh" onClick={(event)=>this.handleLink(event, listItem.id, 'product')}>{((listItem.product_details && (listItem.product_details.sumPriceTotalPerMWhVerified || listItem.product_details.sumPriceTotalPerMWhIndicative)) ?
                        (listItem.product_details.sumPriceTotalPerMWhVerified ?
                          <span id={listItem.id + 'eurosPerMwh'}>{listItem.product_details.sumPriceTotalPerMWhVerified.toFixed(2)}</span> :
                          (listItem.product_details.sumPriceTotalPerMWhIndicative ? <span id={listItem.id + 'eurosPerMwh'} className="indicativeValue">{listItem.product_details.sumPriceTotalPerMWhIndicative.toFixed(2)}</span> :
                          false)) :
                            <Button className="add-info-button">
                            <span className={'ion-plus'} /></Button>)}
                      </span>
                      {/* Show verified value */}
                      {/* <span className="list-column eurosPerMwh" id={listItem.id + 'eurosPerMwh'} onClick={(event)=>this.handleLink(event, listItem.id, 'product')}>{((listItem.product_details && listItem.product_details.sumPriceTotalPerMWhVerified) ?
                          listItem.product_details.sumPriceTotalPerMWhVerified.toFixed(2) :
                            <Button className="add-info-button">
                            <span className={'ion-plus'} /></Button>)}
                      </span> */}

                      {/* Deadline */}
                      <span className="list-column deadline" onClick={(event)=>this.handleLink(event, listItem.id, 'certificates')}>{(listItem.date_of_delivery) ?
                        new Date(listItem.date_of_delivery).toLocaleDateString('en-GB',{ year: 'numeric', month: 'numeric', day: 'numeric' }).replace(/\//g,'.')
                        :
                        <Button className="add-info-button">
                          <span className={'ion-plus'} /></Button>}
                      </span>

                      {/* Logistics */}
                      <span className="list-column logistics" onClick={(event)=>this.handleLink(event, listItem.id, 'logistics')}>{(listItem.logistics && listItem.logistics.legs && listItem.logistics.legs.length > 0) ?
                        <Button className="add-info-button icons-logistics negative" disabled={true}>
                          <span className={'ion-social-euro'} /> <span className={'ion-android-boat'} /> <span className={'ion-android-train'} /></Button> 
                        :
                        <Button className="add-info-button icons-logistics">
                        <span className={'ion-plus'} /> <span className={'ion-android-boat'} /> <span className={'ion-android-train'} /></Button>}
                      </span>

                      {/* Additional costs */}
                      <span className="list-column additionalCosts" onClick={(event)=>this.handleLink(event, listItem.id, 'additional_costs')}>{(listItem.additional_costs && listItem.additional_costs.length > 0) ?
                        <Button className="add-info-button icons-additional-costs negative" disabled={true}>
                          <span className={'ion-social-euro'} /></Button> 
                        :
                        <Button className="add-info-button icons-additional-costs">
                        <span className={'ion-plus'} /> <span className={'ion-social-euro'} /></Button>}
                      </span>
                    </Link>
                  </li>;
                }, this)}
              </ul>
            </div>
            <div className="list-footer">
              <span className="list-column listCheckbox" />
              <span className="list-column sumOfSelected">Sum of selected: </span>
              <span className="list-column mwh">{this.state.sumMWh}</span>
              <span className="list-column valueEuros">{this.state.sumValueEuros}</span>
              <span className="list-column eurosPerMwh">{this.state.sumEurosPerMwh}</span>
            </div>
          </div>
        </ListComponent>
      </div>
    );
  }
}

Dashboard.propTypes = {
  location: React.PropTypes.object.isRequired
};

function mapStateToProps(state) {
  return state;
}

export default connect(mapStateToProps)(Dashboard);