'use strict';

require('../stylesheets/app.scss');

import React from 'react';
import { Link } from 'react-router';
import ActionBar from '../components/ActionBar';
import FilterBar from '../components/FilterBar';
import ListComponent from '../components/ListComponent';
import { changePage, fetchList, fetchListPage, handleSessionStorage, updateList } from '../actions/utility';

export default class Certificates extends React.Component {
  constructor (props) {
    super(props);
    
    this.fetchList = fetchList.bind(this); //requires the list id string
    this.fetchListPage = fetchListPage.bind(this);
    this.handleSessionStorage = handleSessionStorage.bind(this); //requires the list id string
    
    this.changePage = changePage.bind(this);
    this.updateList = updateList.bind(this);

    this.state = { 
      list: [],
      buttonVisibility: [true],
      meta: {}
    };
    
    this.buttons = [
      {
        formId: 'addNewCertificate',
        icon: 'ion-plus',
        refreshFunc: ()=>this.updateList(undefined, 'certificates'),
        text: 'add new certificate'
      }
    ];

    // //call list update on component init to make sure that the list is up to date
    // this.updateList(undefined, 'certificates');
  }

  componentDidMount() {
    //call list update on component init to make sure that the list is up to date
    this.updateList(undefined, 'certificates');
  }

  /*componentDidMount() {
    var query = '';
    for (let i in this.props.location.query) {
      query += '&' + i + '=' + this.props.location.query[i];
    }
    this.fetchListPage('certificates','list',query.replace('&','?'));
  }*/
  
  componentWillReceiveProps (nextProps) {
    if (nextProps.location.action === 'POP') {
      this.changePage('certificates',nextProps.location.search);
    }
  }

  render () {
    return (
      <div className="certificates">
        <ActionBar 
          elements={this.buttons} 
          visibility={this.state.buttonVisibility} />

        <FilterBar 
          changePage={()=>this.changePage('certificates')}
          elements={['text-search']}
          location={this.props.location} />
        
        <ListComponent 
          changePage={()=>this.changePage('certificates')}
          elements={['componentList','componentShow','componentPagination']} 
          location={this.props.location}
          loading={this.state.loading}
          meta={this.state.meta}>
          <div>
            <div className="list-header">
              <span className="list-column certificateCertificate">Certificate</span>
              <span className="list-column certificateAdditionalInfo">Additional info</span>
            </div>
            <ul>
              {(this.state.list.length === 0) &&
                <li className="no-results">No results found.</li>
              }
              {this.state.list.map(function(listItem, i) {
                return <li id={listItem.id} key={i} className={!listItem.active ? 'disabled' : ''}>
                  <Link to={'/certificate?id=' + listItem.id}>
                    <span className="list-column certificateCertificate">{ listItem.name }</span>
                    <span className="list-column certificateAdditionalInfo">{ listItem.additional_info }</span>
                  </Link>
                </li>;
              })}
            </ul>
          </div>
        </ListComponent>
      </div>
    );
  }
}

Certificates.propTypes = {
  location: React.PropTypes.object.isRequired
};