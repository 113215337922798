'use strict';

require('../stylesheets/app.scss');

import React from 'react';
import { Link } from 'react-router';
import { Button, Checkbox, Col, Form, FormControl, FormGroup, Row } from 'react-bootstrap';

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.state = {
      user: '',
      password: '',
      rememberMe: ''
    };
  }
  
  handleChange (e) {
    this.setState({ [e.target.id]: e.target.value });
  }
  // check if enter is pushed when typing in the password input
  handleKeyPress (e) {
    if (e.charCode === 13) {
      this.props.login(this.state.user, this.state.password, this.state.rememberMe, event);
    }
  }
  
  render () {
    return (
      <div className="login">
        <Form className="login-form">
          <FormGroup>
            <Row>
              <Col sm={12}>
                <div id="wega-logo"></div>
                {/*<ControlLabel>Username</ControlLabel>*/}
                <FormControl id="user" type="text" placeholder="Username" onChange={this.handleChange} />
                {/*<ControlLabel>Password</ControlLabel>*/}
                <FormControl id="password" type="password" placeholder="Password" onChange={this.handleChange} onKeyPress={this.handleKeyPress} />
                {/* <Checkbox id="rememberMe" onChange={this.handleChange}>
                  Remember me
                </Checkbox> */}
                <Button id="login-button" type="button" onClick={(event) => this.props.login(this.state.user, this.state.password, this.state.rememberMe, event)}>
                  <span className="icon ion-log-in"></span>
                  Login
                </Button>
                {/* //// TODO - Forgot Password link. Wega Fuels had it. Saving it if we need to re-enable it later */}
                {/* <Link id="forgotPassword" to="/forgotpassword">Forgot your password?</Link> */}
              </Col>
            </Row>
          </FormGroup>
        </Form>
      </div>
    );
  }
}

Login.propTypes = { 
  login: React.PropTypes.func
};