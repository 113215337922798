/* global apiBaseUrl */

import React, { Component, PropTypes } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
//import Explore from '../components/Explore';
import { Button, Navbar, Nav, NavItem } from 'react-bootstrap';
import { Link } from 'react-router';
import { resetErrorMessage } from '../actions';
import { showLoadingIndicator, parseFetchError} from '../actions/utility';

import LoginPage from '../pages/Login';
import SetPasswordPage from '../pages/SetPassword';
import ForgotPasswordPage from '../pages/ForgotPassword';

class App extends Component {
  constructor(props) {
    super(props);
    
    this.handleChange = this.handleChange.bind(this);
    this.handleDismissClick = this.handleDismissClick.bind(this);
    
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    
    this.forgotPassword = this.forgotPassword.bind(this);
    this.setPassword = this.setPassword.bind(this);

    this.state = {
      admin: localStorage.getItem('admin_boolean') ? JSON.parse(localStorage.getItem('admin_boolean')) : false,
      authenticated: localStorage.getItem('woodtracker_id_token') ? true : false 
    };
  }

  handleDismissClick(e) {
    this.props.resetErrorMessage();
    e.preventDefault();
  }

  handleChange(nextValue) {
    this.props.push('/' + nextValue );
  }
  
  login(username, password, rememberMe, e) {
    e.preventDefault();
    if (username === '' || password ==='') {
      //console.log('ERROR: Username or password missing.');
      alert('ERROR: Username or password missing.');
      return;
    }
    
    var that = this;
    var payload = {
      user: {
        email: username, 
        password: password,
        remember_me: (rememberMe === 'on') ? true : false
      }
    };
    
    var header = new Headers();
    header.append('Accept', 'application/json');
    header.append('Content-Type', 'application/json');
    
    var request = new Request(apiBaseUrl + '/users/sign_in', { 
      method: 'POST', 
      headers: header, 
      body: JSON.stringify(payload) 
    });

    //// DEBUG IMPORTANT - remove this when done. Exposes PASSWORD!
    // console.log("//// DEBUG IMPORTANT - remove this when done. Exposes PASSWORD!")
    // console.log(request);
    // console.log(JSON.stringify(payload));

    showLoadingIndicator(true, '.login-form');
    
    fetch(request).then(function(response) {
      if (response.status === 403){
        showLoadingIndicator(false, '.login-form');
        console.log(("Invalid username / password"));
      } else if (!response.ok) {
        return response.json().then(err => { throw err; });
      }
      return response.json();
    }).then(function(json) {
      //console.log(json);
      if (!json.hasOwnProperty('errors')) {
        localStorage.setItem('woodtracker_id_token', json.token);
        localStorage.setItem('admin_boolean', (json.user.role === "admin" ? true : false));
        //localStorage.setItem('activeKey', 1);
        localStorage.setItem('rememberMe', rememberMe); //possbile values '' & 'on'
        localStorage.setItem('user_id', json.user.id);
        that.setState({
          authenticated: true,
          admin: (json.user.role === "admin" ? true : false),
          userId: (json.user.id) ? json.user.id : ''
        });
        //reroute user to the trade view
        window.location.hash = '#/';
      } else {
        showLoadingIndicator(false, '.login-form');
        alert('ERROR: ' + json.errors);
      }
    }).catch(function(err) {
      //console.log('ERROR: ' + err);
      showLoadingIndicator(false, '.login-form');
      parseFetchError(err);
      //alert('ERROR: ' + err);
    });
  }
  
  logout(e) {
    e.preventDefault();
    this.setState({ 
      admin: false,
      authenticated: false 
    });
    localStorage.removeItem('woodtracker_id_token');
    localStorage.removeItem('admin_boolean');
    localStorage.removeItem('rememberMe');
    localStorage.removeItem('user_id');
    window.location.hash = '#/';
    //this.props.push({'/login'});
  }
  
  forgotPassword (email, e) {
    e.preventDefault();
    //clear localstorage just to be sure
    localStorage.clear();
    //console.log(email);
    if (email === '') {
      alert('ERROR: Email missing.');
      return;
    } 
    
    //var that = this;
    var payload = {
      email: email
    };
    
    var header = new Headers();
    header.append('Accept', 'application/json');
    header.append('Content-Type', 'application/json');
    
    var request = new Request(apiBaseUrl + '/reset_password/', { 
      method: 'POST', 
      headers: header, 
      body: JSON.stringify(payload) 
    });
    
    showLoadingIndicator(true, '.login-form');
    
    fetch(request).then(function(response) {
      if (!response.ok) {
        return response.json().then(err => { throw err; });
      }
      return response.json();
    }).then(function(json) {
      //console.log(json);
      if (!json.hasOwnProperty('errors')) {
        //console.log(json);
        //show info text for user to check their email
        var doc = document;
        doc.getElementById('email').style.display = 
          doc.getElementById('login-button').style.display = 'none';
        
        var infoBlock = doc.querySelector('.forgotPassword .info-block');
        infoBlock.innerHTML = 'Please check you <b>email</b> for a password reset link and further instructions.';
        infoBlock.style.margin = '0';
      } else {
        showLoadingIndicator(false, '.login-form');
        alert('ERROR: ' + json.errors);
      }
    }).catch(function(err) {
      //console.log('ERROR: ' + err);
      showLoadingIndicator(false, '.login-form');
      parseFetchError(err);
      //alert('ERROR: ' + err);
    });
  }
  
  setPassword (password, passwordConfirm, token, e) {
    e.preventDefault();
    //clear localstorage just to be sure
    localStorage.clear();
    if (password === '' || passwordConfirm ==='') {
      //console.log('ERROR: Username or password missing.');
      alert('ERROR: Password missing.');
      return;
    } else if (password.length < 6 || passwordConfirm < 6) {
      alert('ERROR: Password is too short.');
      return;
    } else if (password !== passwordConfirm) {
      alert('ERROR: Passwords do not match.');
      return;
    }
    //console.log(password +', '+ passwordConfirm);
    var that = this;
    var payload = {
      password: password,
      token: token
    };
    
    var header = new Headers();
    header.append('Accept', 'application/json');
    header.append('Content-Type', 'application/json');
    
    var request = new Request(apiBaseUrl + '/confirm/', { 
      method: 'POST', 
      headers: header, 
      body: JSON.stringify(payload) 
    });
    
    showLoadingIndicator(true, '.login-form');
    
    fetch(request).then(function(response) {
      if (!response.ok) {
        return response.json().then(err => { throw err; });
      }
      return response.json();
    }).then(function(json) {
      //console.log(json);
      if (!json.hasOwnProperty('errors')) {
        localStorage.setItem('woodtracker_id_token', json.token);
        localStorage.setItem('admin_boolean', json.user.admin);
        localStorage.setItem('activeKey', 1);
        localStorage.setItem('rememberMe', 'on'); //possbile values '' & 'on'
        that.setState({
          authenticated: true,
          admin: (json.user.admin) ? json.user.admin : false,
          userId:  (json.user.id) ? json.user.id : ''
        });
        //reroute user to the trade view
        window.location.hash = '#/';
      } else {
        showLoadingIndicator(false, '.login-form');
        alert('ERROR: ' + json.errors);
      }
    }).catch(function(err) {
      //console.log('ERROR: ' + err);
      showLoadingIndicator(false, '.login-form');
      //alert('ERROR: ' + err);
      parseFetchError(err);
    });
  }
  
  renderErrorMessage() {
    const { errorMessage } = this.props;

    if (!errorMessage) {
      return null;
    }

    return (
      <p style={{ backgroundColor: '#e99', padding: 10 }}>
        <b>{errorMessage}</b>
        {' '}
        (<a href="#"
            onClick={this.handleDismissClick}>
          Dismiss
        </a>)
      </p>
    );
  }

  render() {
    return (
      <div className="wrapper">
        {this.state.authenticated &&
          <Navbar>
            <Navbar.Header>
              <Navbar.Brand>
                <a href="/"></a>
              </Navbar.Brand>
            </Navbar.Header>
            <Nav pullRight activeKey={parseInt(this.props.viewIndex.type)}>
              <NavItem componentClass={Link} eventKey={1} href="/" to="/">Trade view</NavItem>
              <NavItem componentClass={Link} eventKey={2} href="/counterparties" to="/counterparties">Counterparties</NavItem>
              <NavItem componentClass={Link} eventKey={3} href="/sites" to="/sites">Sites</NavItem>
              <NavItem componentClass={Link} eventKey={4} href="/hubs" to="/hubs">Hubs</NavItem>
              <NavItem componentClass={Link} eventKey={5} href="/agents" to="/agents">Agents</NavItem>
              <NavItem componentClass={Link} eventKey={6} href="/products" to="/products">Products</NavItem>
              <NavItem componentClass={Link} eventKey={7} href="/certificates" to="/certificates">Certificates</NavItem>
              <NavItem componentClass={Link} eventKey={8} href="/reports" to="/reports">Reports</NavItem>
              {this.state.admin && 
                <NavItem componentClass={Link} eventKey={9} href="/users" to="/users">Users</NavItem>
              }
              {!this.state.admin && 
                <NavItem componentClass={Link} eventKey={9} href={'/user?id=profile'} to={'/user?id=profile'}>Profile</NavItem>
              }
              <Button className="logout-button" onClick={this.logout}>
                <span className="icon ion-log-out"></span>
                LOG OUT
              </Button>
            </Nav>
          </Navbar>
        }
      
        <div className="content">
          {this.renderErrorMessage()}
          {this.state.authenticated && this.props.view}
          {!this.state.authenticated && this.props.location.pathname === '/' && <LoginPage data={this.state} login={this.login}/>}
          {!this.state.authenticated && this.props.location.pathname === '/setpassword' && <SetPasswordPage data={this.state} type={'set'} setPassword={this.setPassword} token={this.props.location.query.token} />}
          {!this.state.authenticated && this.props.location.pathname === '/confirm' && <SetPasswordPage data={this.state} type={'set'} setPassword={this.setPassword} token={this.props.location.query.token} />}
          {!this.state.authenticated && this.props.location.pathname === '/resetpassword' && <SetPasswordPage data={this.state} type={'reset'} setPassword={this.setPassword} token={this.props.location.token} />}
          {!this.state.authenticated && this.props.location.pathname === '/forgotpassword' && <ForgotPasswordPage data={this.state} forgotPassword={this.forgotPassword} />}
        </div>
      </div>
    );
  }
}

App.propTypes = {
  // Injected by React Redux
  errorMessage: PropTypes.string,
  resetErrorMessage: PropTypes.func.isRequired,
  inputValue: PropTypes.string.isRequired,
  // Injected by React Router
  children: PropTypes.node,
  location: PropTypes.object,
  push: PropTypes.func,
  view: PropTypes.node.isRequired,
  viewIndex: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
  return {
    activeKey: state.activeKey,
    admin: state.admin,
    authenticated: state.authenticated,
    errorMessage: state.errorMessage,
    inputValue: ownProps.location.pathname.substring(1)
  };
}

export default connect(mapStateToProps, {
  resetErrorMessage, push
})(App);