import React from 'react';
import ListFilter from './ListComponentFilter';
import ListList from './ListComponentList';
import ListShow from './ListComponentShow';
import ListPagination from './ListComponentPagination';

require('../stylesheets/app.scss');
require('../stylesheets/components/listComponent.scss');

// this.props.element contains an array of elements that are to be shown in the ListComponent
// supported elements: componentFilter, componentList, componentShow, componentPagination

export default class ListComponent extends React.Component {
  constructor (props) {
    super(props);
  }
  
  render () {
    var location = this.props.location;
    var meta = null;
    if (typeof this.props.meta != 'undefined') {
      meta = this.props.meta;
    }
    
    return(
      <div className={"list-component"}>
        {this.props.elements.indexOf('componentFilter') !== -1 &&
          <ListFilter 
            changePage={this.props.changePage}
            location={location}
            meta={meta}/>
        }
        {(!this.props.loading) ?
          this.props.elements.indexOf('componentList') !== -1 &&
            <ListList>{this.props.children}</ListList>
          :
            <div className="loading" />
        }
        {this.props.elements.indexOf('componentShow') !== -1 &&
          <ListShow 
            changePage={this.props.changePage}
            location={location}
            meta={meta} />
        }
        {this.props.elements.indexOf('componentPagination') !== -1 &&
          <ListPagination 
            changePage={this.props.changePage}
            location={location}
            meta={meta} />
        }
      </div>
    );
  }
}

ListComponent.propTypes = {
  changePage: React.PropTypes.func,
  children: React.PropTypes.element.isRequired,
  elements: React.PropTypes.array.isRequired,
  location: React.PropTypes.object,
  meta: React.PropTypes.object
};