'use strict';

import React from 'react';
import { Link } from 'react-router';
import { Button, Col, ControlLabel, FormControl, Row } from 'react-bootstrap';
import { getFile } from '../actions/utility';

require('../stylesheets/app.scss');
require('../stylesheets/components/formComponent.scss');

export default class FormAddFileToList extends React.Component {
  constructor (props) { 
    super(props);

    this.getFile = getFile.bind(this);

    this.state = {
      downloadPending: false,
    };
  }

  processFileLink (e, itemId) {
    let documentId = itemId
    console.log("Processing file link. documentId = " + documentId);

    // Don't allow download if another one is pending
    if (!this.state.downloadPending) {
      this.getFile(documentId, "documents");
    }
  }
  
  render () {
    return (
      <div className={((this.props.loading) ? ' loading' : '') + ((this.state.downloadPending) ? " busy-blocking" : '')}>
        <Row className="add-item-holder">
        {this.props.label && 
          <ControlLabel>{this.props.label}</ControlLabel>
        }
        {(this.props.active !== false) &&
          <div>
            <Col sm={8}>
              <FormControl type="text" id={this.props.inputId} value={this.props.inputValue.name || ''} placeholder="Click browse to select a file and add to include the selected file to the form." />
            </Col>
            <Col sm={2}>
              <ControlLabel className="btn inputFilelabel"><span className={'icon ion-folder'}></span> Browse
                <FormControl className="inputfile" id={this.props.inputId} type='file' accept="application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, image/png, image/x-png, image/jpg, image/jpeg, image/pjpeg, image/bmp, image/gif" onChange={this.props.inputOnChange}/>
              </ControlLabel>
            </Col>
            <Col sm={2}>
                <Button className="addNew-button" onClick={this.props.addToListFunc}>
                  <span className={'icon ion-plus'}></span>
                  Add
                </Button>
            </Col>
          </div>
        }
          
        {(!this.props.listArray || this.props.listArray.length === 0) &&
          <Col className="fuelList" sm={12}>
            <ul className="empty-list-ul">
              <li className="empty-list-li">
                The file list is empty.
              </li>
            </ul>
          </Col>
        }

        {(this.props.listArray && this.props.listArray.length > 0) &&
          <Col className="fuelList" sm={12}>
            <ul>
              {this.props.listArray.map(function(item, i){
                return <li id={i} key={i} className={item.active === false ? 'disabled' : ''}>
                  {(item.id) &&
                    <span className="link" id={item.id} onClick={(event)=>this.processFileLink(event, item.id)}>
                      {(item.file) ? item.file.name : item.file_name}
                    </span>
                  }
                  {(!item.id) &&
                    <span>
                      {(item.file) ? item.file.name : item.file_name}
                    </span>
                  }
                  {(this.props.active !== false) &&
                    <span className="icon ion-android-delete" onClick={this.props.deleteFromListFunc}></span>
                  }
                </li>;
              },this)}
            </ul>
          </Col>
        }
        </Row>
      </div>
    );
  }
}

FormAddFileToList.propTypes = {
  inputId: React.PropTypes.string.isRequired, //element id used by data validation
  inputValue: React.PropTypes.object.isRequired, //the value within views state that is shown (not updated directly here)
  inputOnChange: React.PropTypes.func.isRequired, //the 2-way binding handler function
  
  addToListFunc: React.PropTypes.func.isRequired, //function to handle adding elements to the form list
  deleteFromListFunc: React.PropTypes.func.isRequired, //function to handle removing elements to the form list
  listArray: React.PropTypes.array.isRequired, //form list array that is updated by adding and removal

  label: React.PropTypes.string.isRequired //the label for he input and also used on the empty list notification text
};

