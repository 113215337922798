
/* global apiBaseUrl */

'use strict';

require('../../stylesheets/app.scss');
require('../../stylesheets/components/detailPage.scss');

import React from 'react';
import { Col, ControlLabel, Form, FormControl, FormGroup, Row } from 'react-bootstrap';
import ActionBar from '../../components/ActionBar';
import { handleInputChange, showLoadingIndicator, parseFetchError, cancelEdit } from '../../actions/utility';

export default class User extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      admin: JSON.parse(localStorage.getItem('admin_boolean')),
      form: {
        email: '',
        name: '',
        phone: '',
        role: '',
        // admin: '',
        // signature: '',
        // email_signature: '',
        additional_info: '',
        password: '',
        passwordConfirm: ''
      },
      buttonVisible_1: [true, true, true],
      buttonVisible_2: [true, true],
      requestPending: false
    };
    
    this.fetchUser = this.fetchUser.bind(this);
    this.updateUser = this.updateUser.bind(this);
    this.removeUser = this.removeUser.bind(this);
    this.cancelEdit = cancelEdit.bind(this);
    this.handleInputChange = handleInputChange.bind(this);
    this.handlePasswordConfirmChange = this.handlePasswordConfirmChange.bind(this);
    // this.fetchUser();
    
    this.adminButtons = [
      {
        formId: '',
        icon: 'ion-checkmark',
        onClick: ()=>this.updateUser(),
        refreshFunc: '',
        text: 'save changes'
      },
      {
        formId: '',
        icon: 'ion-android-delete',
        onClick: ()=>this.removeUser(),
        refreshFunc: '',
        text: 'remove user'
      },
      {
        formId: '',
        icon: 'ion-android-cancel',
        onClick: ()=>this.cancelEdit('users'),
        refreshFunc: '',
        text: 'cancel'
      }
    ];
    this.userButtons = [
      {
        formId: '',
        icon: 'ion-checkmark',
        onClick: ()=>this.updateUser(),
        refreshFunc: '',
        text: 'save changes'
      },
      {
        formId: '',
        icon: 'ion-android-cancel',
        onClick: ()=>this.cancelEdit(''),
        refreshFunc: '',
        text: 'cancel'
      }
    ];
  }

  componentDidMount () {
    this.fetchUser();
  }

  handlePasswordConfirmChange (e) {
    // console.log(e);
    // this.setState({passwordConfirm: e.target.value});
    this.setState({ form: { ...this.state.form, passwordConfirm: e.target.value } })
  }
  
  fetchUser () {
    //fetch users list
    var token = localStorage.getItem('woodtracker_id_token'),
      admin = this.state.admin,
      that = this,
      id = this.props.location.query.id;
      
    if (token) {
      var header = new Headers();
      header.append('Accept', 'application/json');
      header.append('Content-Type', 'application/json');
      header.append('Authorization', 'Bearer ' + token);
      
      var url = (admin) ? 
        apiBaseUrl + '/users/' + id :
        apiBaseUrl + '/profile/';
      
      var request = new Request(url, { 
        method: 'GET', 
        headers: header
      });
      
      // showLoadingIndicator(true);
      that.setState({ ...that.state, loading: true });

      fetch(request).then(function(response) {
        // showLoadingIndicator(false);
        that.setState({ ...that.state, loading: false });
        return response.json();
      }).then(function(json) {
        //console.log(json);
        that.setState({ form: {...that.state.form, ...json.user} });
      }).catch(function(err) {
        // showLoadingIndicator(false);
        that.setState({ ...that.state, loading: false });
        parseFetchError(err);
      });
    }
  }
  
  updateUser () {
    //send new user form
    var token = localStorage.getItem('woodtracker_id_token'),
      admin = JSON.parse(localStorage.getItem('admin_boolean')),
      that = this;
      
    if (token) {
      var header = new Headers();
      header.append('Accept', 'application/json');
      header.append('Content-Type', 'application/json');
      header.append('Authorization', 'Bearer ' + token);
        
      var user = {
        user: {
          email: this.state.form.email,
          name: this.state.form.name,
          phone: this.state.form.phone,
          role: this.state.form.role,
          // admin: JSON.parse(this.state.form.admin),
          // email_signature: this.state.form.email_signature,
          additional_info: this.state.form.additional_info,
        }
      };

      if (this.state.form.password) {
        if (this.state.form.password.length < 6 || this.state.form.password.length > 128) {
          document.getElementById('password').className += ' error';
          alert('The password must be at least 6 and less than 128 alphanumeric characters long.');
          return;
        } else if (this.state.form.password !== this.state.form.passwordConfirm) {
          document.getElementById('password').className += ' error';
          alert('The password and confirmation do not match.');
          return;
        } else {
          user.user.password = this.state.form.password;
        }
      }
      
      var request = new Request(apiBaseUrl + '/users/' + this.state.form.id, { 
        method: 'PUT', 
        headers: header,
        body: JSON.stringify(user)
      });
        
      fetch(request).then(function(response) {
        if (!response.ok) {
          //throw Error(response.statusText);
          return response.json().then(err => { throw err; });
        }
        //console.log(response);
        return response.text();
      }).then(function(/*text*/) {
        //console.log(text);
        //update the list timestamp to force new data to be loaded
        var r = (admin) ? 
          confirm('Update successful. Do you want to stop editing this user?'):
          confirm('Update successful. Do you want to return to trade view?');
        if (r) {
          (admin) ? 
            window.location.hash = '#/users':
            window.location.hash = '#/';
        } 
        sessionStorage.setItem('usersListUpdated', new Date().getTime() - 300000);

        // Remove error classes
        var currentError = document.querySelectorAll('.error');
        for (var i = 0; i < currentError.length; i++) {
          currentError[i].classList.remove('error');
        }
      }).catch(function(err) {
        parseFetchError(err);
      });
    }
  }
  
  removeUser () {
    var token = localStorage.getItem('woodtracker_id_token'),
      id = this.props.location.query.id;
    
    var r = confirm('Are you sure you want to remove this user?');
    if (token && r) {
      var header = new Headers();
      header.append('Accept', 'application/json');
      header.append('Content-Type', 'application/json');
      header.append('Authorization', 'Bearer ' + token);
      
      var url = apiBaseUrl + '/users/' + id;
      
      var request = new Request(url, { 
        method: 'DELETE', 
        headers: header
      });

      that.setState({ ...that.state, requestPending: true });
      
      fetch(request).then(function(response) {
        that.setState({ ...that.state, requestPending: false });
        if (!response.ok) {
          return response.json().then(err => { throw err; });
        }
        return response;
      }).then(function(/*response*/) {
        //console.log(response);
        //user is removed reroute user to users
        sessionStorage.usersListUpdated = '';
        window.location.hash = '#/users';
      }).catch(function(err) {
        that.setState({ ...that.state, requestPending: false });
        parseFetchError(err);
      });
    }
  }

  render(){
    return (
      <div className={"detail-page user" + ((this.state.requestPending) ? " busy-blocking" : '')}>
        {this.state.admin &&
          <ActionBar elements={ this.adminButtons } visibility={ this.state.buttonVisible_1 } />
        }
        {!this.state.admin &&
          <ActionBar elements={ this.userButtons } visibility={ this.state.buttonVisible_2 } />
        }
        {(this.state.loading) ?
        <div className="detail-page-form loading" />
        :
        <div className="detail-page-form">
          <h1 className="detail-page-header">User detail</h1>
          <Form>
            <FormGroup conrolId="newUserInformation">
              <Row>
                <Col componentClass={ControlLabel} sm={4}>
                  User details
                </Col>
                <Col sm={8}>
                  <ControlLabel>Email *</ControlLabel>
                  <FormControl id="email" type="text" value={this.state.form.email || ''} onChange={this.handleInputChange} />
                  <ControlLabel>Name</ControlLabel>
                  <FormControl id="name" type="text" value={this.state.form.name || ''} onChange={this.handleInputChange} />
                  <ControlLabel>Password</ControlLabel>
                  <p className="info-block">
                    The password must be at least <b>six</b> alphanumeric characters long.
                  </p>
                  <FormControl id="password" type="password" value={this.state.form.password || ''} onChange={this.handleInputChange} />
                  <ControlLabel>Confirm password</ControlLabel>
                  <FormControl id="passwordConfirm" type="password" value={this.state.form.passwordConfirm || ''} onChange={this.handlePasswordConfirmChange} />
                  <ControlLabel>Phone</ControlLabel>
                  <FormControl id="phone" type="tel" value={this.state.form.phone || ''} onChange={this.handleInputChange} />
                  
                  {this.state.admin &&
                    <FormGroup className="filter-holder dropdown" controlId="role">
                      <ControlLabel>Role *</ControlLabel>
                      <FormControl componentClass="select" placeholder="select" value={this.state.form.role || 'trader'} onChange={this.handleInputChange}>
                        <option hidden>Select user role</option>
                        <option value="trader">Trader</option>
                        <option value="admin">Admin</option>
                      </FormControl>
                    </FormGroup>
                  }
                </Col>
              </Row>
              <Row>
                <Col componentClass={ControlLabel} sm={4}>
                  Other
                </Col>
                <Col sm={8}>
                  {/* <ControlLabel>Email signature</ControlLabel>
                  <FormControl id="email_signature" componentClass="textarea" value={this.state.form.email_signature || ''} placeholder="Supports the following html tags <h2>, <p>, <br> <b>, <i>." onChange={this.handleInputChange}/> */}
                  <ControlLabel>Additional information</ControlLabel>
                  <FormControl id="additional_info" componentClass="textarea" value={this.state.form.additional_info || ''} placeholder="" onChange={this.handleInputChange}/>
                </Col>
              </Row>
            </FormGroup>
          </Form>
        </div>}
      </div>
    );
  }
}

User.propTypes = {
  location: React.PropTypes.object.isRequired //if the user id is missing hte user data cannot be fetched
};