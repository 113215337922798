import { CALL_API } from 'redux-api-middleware';

import { Schemas, normalizr } from './schemas';

const API_ROOT = 'https://api.github.com/';

function apiEndpoint(path) {
  return (path.indexOf(API_ROOT) === -1) ? API_ROOT + path : path;
}

export const USER_REQUEST = 'USER_REQUEST';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAILURE = 'USER_FAILURE';

// Fetches a single user from Github API.
// Relies on the redux-api-middleware and normalizr schemas
function fetchUser(login) {
  return {
    [CALL_API]: {
      method: 'GET',
      types: [
        USER_REQUEST,
        { type: USER_SUCCESS, payload: normalizr(Schemas.USER) },
        USER_FAILURE
      ],
      endpoint: apiEndpoint(`users/${login}`)
    }
  };
}

// Fetches a single user from Github API unless it is cached.
// Relies on Redux Thunk middleware.
export function loadUser(login, requiredFields = []) {
  return (dispatch, getState) => {
    const user = getState().entities.users[login];
    if (user && requiredFields.every(key => user.hasOwnProperty(key))) {
      return null;
    }

    return dispatch(fetchUser(login));
  };
}

export const REPO_REQUEST = 'REPO_REQUEST';
export const REPO_SUCCESS = 'REPO_SUCCESS';
export const REPO_FAILURE = 'REPO_FAILURE';

// Fetches a single repository from Github API.
// Relies on the redux-api-middleware and normalizr schemas
function fetchRepo(fullName) {
  return {
    [CALL_API]: {
      method: 'GET',
      types: [
        REPO_REQUEST,
        { type: REPO_SUCCESS, payload: normalizr(Schemas.REPO) },
        REPO_FAILURE
      ],
      endpoint: apiEndpoint(`repos/${fullName}`)
    }
  };
}

// Fetches a single repository from Github API unless it is cached.
// Relies on Redux Thunk middleware.
export function loadRepo(fullName, requiredFields = []) {
  return (dispatch, getState) => {
    const repo = getState().entities.repos[fullName];
    if (repo && requiredFields.every(key => repo.hasOwnProperty(key))) {
      return null;
    }

    return dispatch(fetchRepo(fullName));
  };
}

export const STARRED_REQUEST = 'STARRED_REQUEST';
export const STARRED_SUCCESS = 'STARRED_SUCCESS';
export const STARRED_FAILURE = 'STARRED_FAILURE';

// Fetches a page of starred repos by a particular user.
// Relies on the redux-api-middleware and normalizr schemas
function fetchStarred(login, nextPageUrl) {
  return {
    [CALL_API]: {
      method: 'GET',
      types: [
        { type: STARRED_REQUEST, meta: { login } },
        {
          type: STARRED_SUCCESS,
          meta: { login },
          payload: normalizr(Schemas.REPO_ARRAY)
        },
        { type: STARRED_FAILURE, meta: { login } }
      ],
      endpoint: apiEndpoint(`${nextPageUrl}`)
    }
  };
}

// Fetches a page of starred repos by a particular user.
// Bails out if page is cached and user didn’t specifically request next page.
// Relies on Redux Thunk middleware.
export function loadStarred(login, nextPage) {
  return (dispatch, getState) => {
    const {
      nextPageUrl = `users/${login}/starred`,
      pageCount = 0
    } = getState().pagination.starredByUser[login] || {};

    if (pageCount > 0 && !nextPage) {
      return null;
    }

    return dispatch(fetchStarred(login, nextPageUrl));
  };
}

export const STARGAZERS_REQUEST = 'STARGAZERS_REQUEST';
export const STARGAZERS_SUCCESS = 'STARGAZERS_SUCCESS';
export const STARGAZERS_FAILURE = 'STARGAZERS_FAILURE';

// Fetches a page of stargazers for a particular repo.
// Relies on the redux-api-middleware and normalizr schemas
function fetchStargazers(fullName, nextPageUrl) {
  return {
    [CALL_API]: {
      method: 'GET',
      types: [
        { type: STARGAZERS_REQUEST, meta: { fullName } },
        {
          type: STARGAZERS_SUCCESS,
          meta: { fullName },
          payload: normalizr(Schemas.USER_ARRAY)
        },
        { type: STARGAZERS_FAILURE, meta: { fullName } }
      ],
      endpoint: apiEndpoint(`${nextPageUrl}`)
    }
  };
}

// Fetches a page of stargazers for a particular repo.
// Bails out if page is cached and user didn’t specifically request next page.
// Relies on Redux Thunk middleware.
export function loadStargazers(fullName, nextPage) {
  return (dispatch, getState) => {
    const {
      nextPageUrl = `repos/${fullName}/stargazers`,
      pageCount = 0
    } = getState().pagination.stargazersByRepo[fullName] || {};

    if (pageCount > 0 && !nextPage) {
      return null;
    }

    return dispatch(fetchStargazers(fullName, nextPageUrl));
  };
}

export const RESET_ERROR_MESSAGE = 'RESET_ERROR_MESSAGE';

// Resets the currently visible error message.
export function resetErrorMessage() {
  return {
    type: RESET_ERROR_MESSAGE
  };
}
