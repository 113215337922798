/* global apiBaseUrl */

'use strict';

require('../../stylesheets/app.scss');
require('../../stylesheets/components/detailPage.scss');

import React from 'react';
import { Col, ControlLabel, Form, FormControl, FormGroup, Row } from 'react-bootstrap';
import ActionBar from '../../components/ActionBar';
import { handleInputChange, fetchList, handleSessionStorage, showLoadingIndicator, parseFetchError, setItemActiveStatus, deleteItem, cancelEdit } from '../../actions/utility';

export default class Product extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      form: {
        active: null,
        name:'',
        additional_info: ''
      },
      buttonVisibility: [true, true, false, false, true],
      requestPending: false
      // countries: [],
    };
    
    this.fetchProduct = this.fetchProduct.bind(this);
    this.updateProduct = this.updateProduct.bind(this);

    this.setItemActiveStatus = setItemActiveStatus.bind(this);
    this.deleteItem = deleteItem.bind(this);
    this.cancelEdit = cancelEdit.bind(this);

    this.handleInputChange = handleInputChange.bind(this);
    this.fetchList = fetchList.bind(this);
    this.handleSessionStorage = handleSessionStorage.bind(this);
    
    // this.fetchProduct();
    
    this.buttons = [
      {
        formId: '',
        icon: 'ion-checkmark',
        onClick: ()=>this.updateProduct(),
        refreshFunc: '',
        text: 'save changes'
      },
      {
        formId: '',
        icon: 'ion-power',
        onClick: ()=>this.setItemActiveStatus(false, 'product', 'products'),
        refreshFunc: '',
        text: 'deactivate product'
      },
      {
        formId: '',
        icon: 'ion-power',
        onClick: ()=>this.setItemActiveStatus(true, 'product', 'products'),
        refreshFunc: '',
        text: 'activate product'
      },
      {
        formId: '',
        icon: 'ion-android-delete',
        onClick: ()=>this.deleteItem('products'),
        refreshFunc: '',
        text: 'delete product'
      },
      {
        formId: '',
        icon: 'ion-android-cancel',
        onClick: ()=>this.cancelEdit('products'),
        refreshFunc: '',
        text: 'cancel'
      }
    ];
  }

  componentDidMount() {
    this.fetchProduct();
  }
  
  fetchProduct () {
    //fetch
    var token = localStorage.getItem('woodtracker_id_token'),
      admin = JSON.parse(localStorage.getItem('admin_boolean')),
      that = this,
      id = this.props.location.query.id;
      
    if (token) {
      var header = new Headers();
      header.append('Accept', 'application/json');
      header.append('Content-Type', 'application/json');
      header.append('Authorization', 'Bearer ' + token);
      
      var url = apiBaseUrl + '/products/' + id;
      
      var request = new Request(url, { 
        method: 'GET', 
        headers: header
      });
      
      that.setState({ ...that.state, loading: true });

      fetch(request).then(function(response) {
        that.setState({ ...that.state, loading: false });
        return response.json();
      }).then(function(json) {
        //console.log(json);
        if (admin) {
          (json.product.active) ?
            that.setState({ buttonVisibility: [true,true,false,false,true] }):
            that.setState({ buttonVisibility: [false,false,true,false,false] });
        } else {
          (json.product.active) ?
            that.setState({ buttonVisibility: [true,true,false,false,true] }):
            that.setState({ buttonVisibility: [false,false,true,false,false] });
        }
        that.setState({ form: {...that.state.form, ...json.product} });
      }).catch(function(err) {
        that.setState({ ...that.state, loading: false });
        parseFetchError(err);
      });
    }
  }
  
  updateProduct () {
    //send new user form
    var token = localStorage.getItem('woodtracker_id_token'),
      that = this;

    if (token) {
      var header = new Headers();
      header.append('Accept', 'application/json');
      header.append('Content-Type', 'application/json');
      header.append('Authorization', 'Bearer ' + token);
      
      var findIds = function (arr1, arr2, arr3) {
        for (let i = 0, j = arr2.length; i < j; i++) {
          for (let k = 0, l = arr3.length; k < l; k++) {
            if (typeof arr3[k] === 'string') {
              if (arr3[k] === arr2[i].name) {
                arr1.push(arr2[i].id);
              }
            } else {
              if (arr3[k].id === arr2[i].id) {
                arr1.push(arr2[i].id);
              }
            }
          }
        }
      };

      var product = {
        product: {
          name: this.state.form.name,
          additional_info: this.state.form.additional_info
        }
      };
      
      var request = new Request(apiBaseUrl + '/products/' + this.state.form.id, { 
        method: 'PUT', 
        headers: header,
        body: JSON.stringify(product)
      });

      that.setState({ ...that.state, requestPending: true });
        
      fetch(request).then(function(response) {
        that.setState({ ...that.state, requestPending: false });
        if (!response.ok) {
          //throw Error(response.statusText);
          return response.json().then(err => { throw err; });
        }
        //console.log(response);
        return response.text();
      }).then(function(/*text*/) {
        //console.log(text);
        //update the list timestamp to force new data to be loaded
        var r = confirm('Update successful. Do you want to stop editing this product?');
        if (r) {
          window.location.hash = '#/products';
        } 
        sessionStorage.setItem('productsListUpdated', new Date().getTime() - 300000);
      }).catch(function(err) {
        that.setState({ ...that.state, requestPending: false });
        parseFetchError(err);
      });
    }
  }
  
  render(){
    return (
      <div className={"detail-page product" + ((this.state.requestPending) ? " busy-blocking" : '')}>
        <ActionBar elements={ this.buttons } visibility={ this.state.buttonVisibility } />
        {(this.state.loading) ?
        <div className="detail-page-form loading" />
        :
        <div className="detail-page-form">
          <h1 className="detail-page-header">
            Product detail
            {(this.state.form.active !== null) &&
              <span className={'detail-page-order-status ' + (this.state.form.active ? 'stemmed' : 'canceled')}>
                { this.state.form.active ? 'Active' : 'Disabled' }
              </span>
            }
          </h1>
          <Form>
            <FormGroup conrolId="newProductInformation">
              <Row>
                <Col componentClass={ControlLabel} sm={4}>
                  Product details
                </Col>
                <Col sm={8}>
                  <ControlLabel>Name *</ControlLabel>
                  <FormControl id="name" type="text" value={this.state.form.name || ''} onChange={this.handleInputChange} readOnly={((!this.state.form.active) ? true : false)} />
                </Col>
              </Row>
              <Row>
                <Col componentClass={ControlLabel} sm={4}>
                  Other
                </Col>
                <Col sm={8}>
                  <ControlLabel>Additional information</ControlLabel>
                  <FormControl id="additional_info" componentClass="textarea" value={this.state.form.additional_info || ''} placeholder="" onChange={this.handleInputChange} readOnly={((!this.state.form.active) ? true : false)}/>
                </Col>
              </Row>
            </FormGroup>
          </Form>
        </div>}
      </div>
    );
  }
}

Product.propTypes = {
  location: React.PropTypes.object.isRequired //if the user id is missing hte user data cannot be fetched
};