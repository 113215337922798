/* global apiBaseUrl */

'use strict';

require('../../stylesheets/app.scss');
require('../../stylesheets/components/detailPage.scss');

import React from 'react';
import { Col, ControlLabel, Form, FormControl, FormGroup, Row } from 'react-bootstrap';
import ActionBar from '../../components/ActionBar';
import FormAddToList from '../../components/FormAddToList';
import FormAddContactToList from '../../components/FormAddContactToList';
import { handleInputChange, addContactFormItem, addFormItem, deleteFormItem, fetchList, handleSessionStorage, showLoadingIndicator, parseFetchError, setItemActiveStatus, deleteItem, cancelEdit } from '../../actions/utility';

export default class Agent extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      form: {
        active: null,
        name:'',
        address: '',
        country: '',
        contactName: '',
        contactEmail: '',
        contactPhone: '',
        contacts: [],
        hub: '',
        hubs: [],
        additional_info: ''        
      },
      countries: [],
      hubs: [],
      buttonVisibility: [true, true, false, false, true],
      requestPending: false
      // loading: false
    };
    
    this.fetchAgent = this.fetchAgent.bind(this);
    this.updateAgent = this.updateAgent.bind(this);

    this.setItemActiveStatus = setItemActiveStatus.bind(this);
    this.deleteItem = deleteItem.bind(this);
    this.cancelEdit = cancelEdit.bind(this);

    this.handleInputChange = handleInputChange.bind(this);
    this.addContactFormItem = addContactFormItem.bind(this);
    this.addFormItem = addFormItem.bind(this);
    this.deleteFormItem = deleteFormItem.bind(this);
    this.fetchList = fetchList.bind(this);
    this.handleSessionStorage = handleSessionStorage.bind(this);

    this.buttons = [
      {
        formId: '',
        icon: 'ion-checkmark',
        onClick: ()=>this.updateAgent(),
        refreshFunc: '',
        text: 'save changes'
      },
      {
        formId: '',
        icon: 'ion-power',
        onClick: ()=>this.setItemActiveStatus(false, 'agent', 'agents'),
        refreshFunc: '',
        text: 'deactivate agent'
      },
      {
        formId: '',
        icon: 'ion-power',
        onClick: ()=>this.setItemActiveStatus(true, 'agent', 'agents'),
        refreshFunc: '',
        text: 'activate agent'
      },
      {
        formId: '',
        icon: 'ion-android-delete',
        onClick: ()=>this.deleteItem('agents'),
        refreshFunc: '',
        text: 'delete agent'
      },
      {
        formId: '',
        icon: 'ion-android-cancel',
        onClick: ()=>this.cancelEdit('agents'),
        refreshFunc: '',
        text: 'cancel'
      }
    ];
  }

  componentDidMount () {
    this.fetchAgent();

    //fetch data 
    this.handleSessionStorage('countries','countries',false,'');
    this.handleSessionStorage('hubs','hubs',false,'');
  }
  
  fetchAgent () {
    //fetch
    var token = localStorage.getItem('woodtracker_id_token'),
      admin = JSON.parse(localStorage.getItem('admin_boolean')),
      that = this,
      id = this.props.location.query.id;
      
    if (token) {
      var header = new Headers();
      header.append('Accept', 'application/json');
      header.append('Content-Type', 'application/json');
      header.append('Authorization', 'Bearer ' + token);
      
      var url = apiBaseUrl + '/agents/' + id;
      
      var request = new Request(url, { 
        method: 'GET', 
        headers: header
      });

      that.setState({ ...that.state, loading: true });

      fetch(request).then(function(response) {
        that.setState({ ...that.state, loading: false });
        return response.json();
      }).then(function(json) {
        //console.log(json);
        // json.agent.counterparty = '';
        if (admin) {
          (json.agent.active) ?
            that.setState({ ...that.state, buttonVisibility: [true,true,false,false,true] }):
            that.setState({ ...that.state, buttonVisibility: [false,false,true,false,false] });
        } else {
          (json.agent.active) ?
            that.setState({ ...that.state, buttonVisibility: [true,true,false,false,true] }):
            that.setState({ ...that.state, buttonVisibility: [false,false,true,false,false] });
        }
        that.setState({ form: {...that.state.form, ...json.agent} });
      }).catch(function(err) {
        that.setState({ ...that.state, loading: false });
        parseFetchError(err);
      });
    }
  }
  
  updateAgent () {
    //send new user form
    var token = localStorage.getItem('woodtracker_id_token'),
      that = this;

    if (token) {
      var header = new Headers();
      header.append('Accept', 'application/json');
      header.append('Content-Type', 'application/json');
      header.append('Authorization', 'Bearer ' + token);
      
      var findIds = function (arr1, arr2, arr3) {
        for (let i = 0, j = arr2.length; i < j; i++) {
          for (let k = 0, l = arr3.length; k < l; k++) {
            if (typeof arr3[k] === 'string') {
              if (arr3[k] === arr2[i].name) {
                arr1.push(arr2[i].id);
              }
            } else {
              if (arr3[k].id === arr2[i].id) {
                arr1.push(arr2[i].id);
              }
            }
          }
        }
      };

      // IMPORTANT! This will break if we allow entities with non-unique "name" fields (because "name" is the value we carry in the form)
      var hubIds = [];
      //loop through the hubs to add the correct ids into the port JSON
      findIds(hubIds, this.state.hubs, this.state.form.hubs);
      //inform an error if the added hubs where not valid
      if (hubIds.length === 0 && this.state.form.hubs.length > 0) {
        document.getElementById('hub').className += ' error';
        alert('You must only use the suggested hubs.');
        return false;
      }
      //the backend doesn't accept empty arrays, add empty string to indicata that an array is empty
      if (hubIds.length === 0) {
        hubIds.push('');
      }

      var agent = {
        agent: {
          name: this.state.form.name,
          address: this.state.form.address,
          zip_code: this.state.form.zip_code,
          city: this.state.form.city,
          country: this.state.form.country,
          contacts: this.state.form.contacts,
          hub_ids: hubIds,
          additional_info: this.state.form.additional_info
        }
      };
      
      var request = new Request(apiBaseUrl + '/agents/' + this.state.form.id, { 
        method: 'PUT', 
        headers: header,
        body: JSON.stringify(agent)
      });

      that.setState({ ...that.state, requestPending: true });
        
      fetch(request).then(function(response) {
        that.setState({ ...that.state, requestPending: false });
        if (!response.ok) {
          //throw Error(response.statusText);
          return response.json().then(err => { throw err; });
        }
        //console.log(response);
        return response.text();
      }).then(function(/*text*/) {
        //console.log(text);
        //update the list timestamp to force new data to be loaded
        var r = confirm('Update successful. Do you want to stop editing this agent?');
        if (r) {
          window.location.hash = '#/agents';
        } 
        sessionStorage.setItem('agentsListUpdated', new Date().getTime() - 300000);
      }).catch(function(err) {
        that.setState({ ...that.state, requestPending: false });
        parseFetchError(err);
      });
    }
  }

  render(){
    return (
      <div className={"detail-page agent" + ((this.state.requestPending) ? " busy-blocking" : '')}>
        <ActionBar elements={ this.buttons } visibility={ this.state.buttonVisibility } />
        {(this.state.loading) ?
          <div className="detail-page-form loading" />
          :
          <div className="detail-page-form">
            <h1 className="detail-page-header">
              Agent detail
              {(this.state.form.active !== null) &&
                <span className={'detail-page-order-status ' + (this.state.form.active ? 'stemmed' : 'canceled')}>
                  { this.state.form.active ? 'Active' : 'Disabled' }
                </span>
              }
            </h1>
            <Form>
              <FormGroup conrolId="newAgentInformation">
                <Row>
                  <Col componentClass={ControlLabel} sm={4} className="element-padding horizontal">
                    Agent details
                  </Col>
                  <Col sm={8}>
                    <Col sm={12} className="element-padding horizontal">
                      <ControlLabel>Name *</ControlLabel>
                      <FormControl id="name" type="text" value={this.state.form.name || ''} onChange={this.handleInputChange} readOnly={((!this.state.form.active) ? true : false)} />
                    </Col>
                    <Col sm={12} className="element-padding horizontal">
                      <ControlLabel>Address *</ControlLabel>
                      <FormControl id="address" type="text" value={this.state.form.address || ''} onChange={this.handleInputChange} readOnly={((!this.state.form.active) ? true : false)} />
                    </Col>
                    <Col sm={6} className="element-padding horizontal">
                      <ControlLabel>Zip</ControlLabel>
                      <FormControl id="zip_code" type="text" value={this.state.form.zip_code || ''} onChange={this.handleInputChange} readOnly={((!this.state.form.active) ? true : false)} />
                    </Col>
                    <Col sm={6} className="element-padding horizontal">
                      <ControlLabel>City</ControlLabel>
                      <FormControl id="city" type="text" value={this.state.form.city || ''} onChange={this.handleInputChange} readOnly={((!this.state.form.active) ? true : false)} />
                    </Col>
                    <Col sm={12} className="element-padding horizontal">
                      <ControlLabel>Country *</ControlLabel>
                      <FormControl id="country" type="text" value={this.state.form.country || ''} onChange={this.handleInputChange} list="agentCountriesDataList" readOnly={((!this.state.form.active) ? true : false)} />
                      {this.state.countries && 
                        <datalist id="agentCountriesDataList">
                        {this.state.countries.map(function(item, i){
                          return <option key={i} value={item.country} />;
                        },this)}
                        </datalist>
                      }
                    </Col>
                  </Col>
                </Row>
                <Row>
                  <Col componentClass={ControlLabel} sm={4} className="element-padding horizontal">
                    Contact info
                  </Col>
                  <Col sm={8} className="element-padding horizontal">
                    <FormAddContactToList 
                      inputName="contactName"
                      inputEmail="contactEmail"
                      inputPhone="contactPhone"
                      inputValueName={this.state.form.contactName || ''}
                      inputValueEmail={this.state.form.contactEmail || ''}
                      inputValuePhone={this.state.form.contactPhone || ''}
                      inputOnChange={this.handleInputChange}
                      dataList={false}
                      addToListFunc={()=>this.addContactFormItem('contactName','contactEmail','contactPhone','contacts')}
                      deleteFromListFunc={(event)=>this.deleteFormItem(event,'contacts')}
                      listArray={this.state.form.contacts}
                      label=""
                      active={this.state.form.active}
                      loading={this.state.loading} />
                  </Col>
                </Row>
                <Row>
                  <Col componentClass={ControlLabel} sm={4} className="element-padding horizontal">
                    Affiliated hubs
                  </Col>
                  <Col sm={8} className="element-padding horizontal">
                    <FormAddToList 
                        inputId="hub"
                        inputValue={this.state.form.hub || ''}
                        inputOnChange={this.handleInputChange}
                        dataList={true}
                        dataListId="hubsList"
                        dataListArray={this.state.hubs}
                        addToListFunc={()=>this.addFormItem('hub','hubs',this.state.hubs)}
                        deleteFromListFunc={(event)=>this.deleteFormItem(event,'hubs')}
                        listArray={this.state.form.hubs}
                        label=""
                        active={this.state.form.active}
                        loading={this.state.loading} />
                  </Col>
                </Row>
                <Row>
                  <Col componentClass={ControlLabel} sm={4} className="element-padding horizontal">
                    Other
                  </Col>
                  <Col sm={8} className="element-padding horizontal">
                    <ControlLabel>Additional information</ControlLabel>
                    <FormControl id="additional_info" componentClass="textarea" value={this.state.form.additional_info || ''} placeholder="" onChange={this.handleInputChange} readOnly={((!this.state.form.active) ? true : false)} />
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          </div>
        }
      </div>
    );
  }
}

Agent.propTypes = {
  location: React.PropTypes.object.isRequired //if the user id is missing hte user data cannot be fetched
};