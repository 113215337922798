import React from 'react';
import { FormGroup, Checkbox } from 'react-bootstrap';

require('../stylesheets/app.scss');

export default class ListComponentFilter extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      selected: (this.props.location.query.status) ? this.props.location.query.status : 'all'
    };

    this.handlePageChange = this.handlePageChange.bind(this);
  }
  
  handlePageChange (e,value) {
    (value=== 'all') ? 
      delete this.props.location.query.status:
      this.props.location.query.status = value;
    this.setState({ selected: value });
    this.props.changePage();
  }
  
  render () {
    return(
      <div className="list-component-filter">
        <FormGroup>
          <Checkbox inline 
            checked={(this.state.selected === 'all' ? true : false)} 
            onChange={(event)=>this.handlePageChange(event, 'all')}>All</Checkbox>
          <Checkbox inline 
            className="status-lead ball-icon"
            checked={(this.state.selected === 'lead' ? true : false)} 
            onChange={(event)=>this.handlePageChange(event, 'lead')}>Lead</Checkbox>
          <Checkbox inline 
            className="status-active ball-icon"
            checked={(this.state.selected === 'active' ? true : false)} 
            onChange={(event)=>this.handlePageChange(event, 'active')}>Active</Checkbox>
          <Checkbox inline 
            className="status-stemmed ball-icon" 
            checked={(this.state.selected === 'stemmed' ? true : false)}
            onChange={(event)=>this.handlePageChange(event, 'stemmed')}>Stemmed</Checkbox>
          <Checkbox inline 
            className="status-delivered ball-icon" 
            checked={(this.state.selected === 'delivered' ? true : false)}
            onChange={(event)=>this.handlePageChange(event, 'delivered')}>Delivered</Checkbox>
          <Checkbox inline 
            className="status-finalized ball-icon"
            checked={(this.state.selected === 'finalized' ? true : false)} 
            onChange={(event)=>this.handlePageChange(event, 'finalized')}>Finalized</Checkbox>
          <Checkbox inline 
            className="status-cancelled ball-icon" 
            checked={(this.state.selected === 'canceled' ? true : false)}
            onChange={(event)=>this.handlePageChange(event, 'canceled')}>Canceled</Checkbox>
          <Checkbox inline 
            className="status-failed ball-icon"
            checked={(this.state.selected === 'failed' ? true : false)} 
            onChange={(event)=>this.handlePageChange(event, 'failed')}>Failed</Checkbox>
          <Checkbox inline 
            className="status-contract ball-icon"
            checked={(this.state.selected === 'contract' ? true : false)} 
            onChange={(event)=>this.handlePageChange(event, 'contract')}>Contract</Checkbox>
        </FormGroup>
      </div>
    );
  }
}

ListComponentFilter.propTypes = {
  changePage: React.PropTypes.func,
  location: React.PropTypes.object,
  meta: React.PropTypes.object
};