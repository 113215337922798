/* global apiBaseUrl */

'use strict';

import React from 'react';
import { Col, ControlLabel, Form, FormControl, FormGroup, Modal, Row } from 'react-bootstrap';
import FormAddToList from './FormAddToList';
import FormAddContactToList from './FormAddContactToList';
import FormAddFileToList from './FormAddFileToList';
import Header from './FormHeader';
import Footer from './FormFooter';
import { handleInputChange, addContactFormItem, addFileFormItem, deleteFileFormItem, addFormItem, deleteFormItem, fetchList, handleSessionStorage, parseFetchError } from '../actions/utility';

require('../stylesheets/app.scss');
require('../stylesheets/components/formComponent.scss');

export default class FormNewCounterparty extends React.Component {
  constructor (props) {
    super(props);
    
    this.handleStepChange = this.handleStepChange.bind(this);
    this.handleInputChange = handleInputChange.bind(this);
    this.addContactFormItem = addContactFormItem.bind(this);
    this.addFormItem = addFormItem.bind(this);
    this.deleteFormItem = deleteFormItem.bind(this);
    this.addFileFormItem = addFileFormItem.bind(this);
    this.deleteFileFormItem = deleteFileFormItem.bind(this);
    this.fetchList = fetchList.bind(this);
    this.handleSessionStorage = handleSessionStorage.bind(this);
    
    this.state = {
      form: {
        counterpartyName: '',
        counterpartyAddress: '',
        counterpartyZip_code: '',
        counterpartyCity: '',
        counterpartyCountry: '',
        counterpartyContactName: '',
        counterpartyContactEmail: '',
        counterpartyContactPhone: '',
        counterpartyContacts: [],
        counterpartySite: '',
        counterpartySites: [],
        counterpartyHub: '',
        counterpartyHubs: [],
        counterpartyDocument: '',
        counterpartyDocuments: [],
        additionalInformation: ''
      },
      countries: [],
      sites: [],
      hubs: [],
      requestPending: false,
      sendResponse: false,
      step: 1
    };
  }
  
  handleStepChange (num) {
    //console.log(e.target);
    //console.log(num);
    var errorFound = false;
    var informError = function (id) {
      //console.log(id);
      //alert(id + ' is missing a value.');
      //add error class to all of the inputs that are missing information
      if (document.getElementById(id).className.indexOf('error') < 0) {
        document.getElementById(id).className += ' error';
      }
      errorFound = true;
    };
    switch (num) {
    case 2:
      //check for the first views data
      for (let i in this.state.form) {
         //filter out the information that is not concerning step 1
        if (i !== 'additionalInformation' &&
            i !== 'counterpartyContactName' &&
            i !== 'counterpartyContactEmail' &&
            i !== 'counterpartyContactPhone' &&
            i !== 'counterpartyContacts' &&
            i !== 'counterpartySite' &&
            i !== 'counterpartySites' &&
            i !== 'counterpartyHub' &&
            i !== 'counterpartyHubs' &&
            i !== 'counterpartyDocument' &&
            i !== 'counterpartyDocuments'
          ) {
          if (!this.state.form[i]) {
            informError(i);
          }
        }
      }
      break;
    }
    //user is not allowed to go forward if something is missing
    if (!errorFound && num === 2) {
      //send new counterparty form
      var token = localStorage.getItem('woodtracker_id_token');
      var that = this;
      if (token) {
        var header = new Headers();
        header.append('Accept', 'application/json');
        // Disable 'application/json' so that the multipart header is automatically set for the request.
        // header.append('Content-Type', 'application/json');
        header.append('Authorization', 'Bearer ' + token);
        
        var findIds = function (arr1, arr2, arr3) {
          for (let i = 0, j = arr2.length; i < j; i++) {
            for (let k = 0, l = arr3.length; k < l; k++) {
              if (arr3[k] === arr2[i].name) {
                arr1.push(arr2[i].id);
              }
            }
          }
        };

        // IMPORTANT! This will break if we allow entities with non-unique "name" fields (because "name" is the value we carry in the form)
        var siteIds = [];
        //loop through the sites to add the correct ids into the port JSON
        findIds(siteIds, this.state.sites, this.state.form.counterpartySites);
        //inform an error if the added sites where not valid
        if (siteIds.length === 0 && this.state.form.counterpartySites.length > 0) {
          document.getElementById('counterpartySite').className += ' error';
          alert('You must only use the suggested sites.');
          return false;
        }
        //the backend doesn't accept empty arrays, add empty string to indicata that an array is empty
        if (siteIds.length === 0) {
          siteIds.push('');
        }

        // IMPORTANT! This will break if we allow entities with non-unique "name" fields (because "name" is the value we carry in the form)
        var hubIds = [];
        //loop through the hubs to add the correct ids into the port JSON
        findIds(hubIds, this.state.hubs, this.state.form.counterpartyHubs);
        //inform an error if the added hubs where not valid
        if (hubIds.length === 0 && this.state.form.counterpartyHubs.length > 0) {
          document.getElementById('counterpartyHub').className += ' error';
          alert('You must only use the suggested hubs.');
          return false;
        }
        //the backend doesn't accept empty arrays, add empty string to indicata that an array is empty
        if (hubIds.length === 0) {
          hubIds.push('');
        }
        
        var counterparty = {
          counterparty: {
            name: this.state.form.counterpartyName,
            address: this.state.form.counterpartyAddress,
            zip_code: this.state.form.counterpartyZip_code,
            city: this.state.form.counterpartyCity,
            country: this.state.form.counterpartyCountry,
            contacts: this.state.form.counterpartyContacts,
            site_ids: siteIds,
            hub_ids: hubIds,
            additional_info: this.state.form.additionalInformation
          }
        };
        
        // var request = new Request(apiBaseUrl + '/counterparties', { 
        //   method: 'POST', 
        //   headers: header,
        //   body: JSON.stringify(counterparty)
        // });

        var data = new FormData();
        // Append the form data as JSON (note. Does NOT set content-type to "application/json". Backend has to manually parse the stringified json)
        data.append("counterparty", JSON.stringify(counterparty.counterparty));
  
        // Append files to upload in the request
        this.state.form.counterpartyDocuments.forEach(function(fileWrapper, i) {
          if (fileWrapper.file) {
            console.log("Upload files[" + i + "] = " + fileWrapper.file.name + " (size: " + fileWrapper.file.size + " bytes) ");
            // Only append files with size set and greater than 0
            if (typeof fileWrapper.file != 'undefined' && typeof fileWrapper.file.size != 'undefined' && fileWrapper.file.size > 0) {
              data.append("file_" + i, fileWrapper.file);
            }
          }
        });
  
        var request = new Request(apiBaseUrl + '/counterparties', { 
          method: 'POST', 
          headers: header,
          body : data
        });
        
        //prevent calling fetch if request is pending
        if (!that.state.requestPending) {
          that.setState({ requestPending: true });
          fetch(request).then(function(response) {
            if (!response.ok) {
              //throw Error(response.statusText);
              return response.json().then(err => { throw err; });
            }
            //console.log(response);
            return response.text();
          }).then(function(/*text*/) {
            //console.log(text);
            that.setState({ step: num });
            that.setState({ sendResponse: true });
            that.setState({ requestPending: false });
            sessionStorage.counterpartiesListUpdated = '';
          }).catch(function(err) {
            that.setState({ step: num });
            that.setState({ sendResponse: false });
            that.setState({ requestPending: false });
            parseFetchError(err);
          });
        }
      }
    } else if (!errorFound && num === 1) {
      this.setState({ step: num });
      this.setState({ sendResponse: false });
      this.setState({ requestPending: false });
    } else {
      alert('Data is missing. Please fill in all of the required data.');
    }
  }
  
  componentDidMount () {
    //fetch data
    if (this.props.visible) {
      this.handleSessionStorage('countries','countries',false,'');
      this.handleSessionStorage('sites','sites',false,'');
      this.handleSessionStorage('hubs','hubs',false,'');
    }
  }

  render () {
    return(
      <Modal bsSize="large" id={this.props.formId} className={"form-component" + ((this.state.requestPending) ? " busy-blocking" : '')} backdrop={((this.state.requestPending) ? "static" : true)} keyboard={((this.state.requestPending) ? false : true)} show={this.props.visible} onHide={() => (this.state.step !== 2) ? this.props.close(this.props.formId) : this.props.toggle(this.props.formId)}>
        <Header 
          buttons={['Counterparty details','Done!']}
          currentStep={this.state.step}
          title="New counterparty" />
        <Modal.Body>
          {/* step 1 - counterparty information */}
          {this.state.step === 1 &&
            <Form>
              <FormGroup conrolId="newCounterpartyInformation">
                <Row>
                  <Col componentClass={ControlLabel} sm={4} className="element-padding horizontal">
                    Counterparty details
                  </Col>
                  <Col sm={8}>
                    <Col sm={12} className="element-padding horizontal"> 
                      <ControlLabel>Name *</ControlLabel>
                      <FormControl id="counterpartyName" type="text" value={this.state.form.counterpartyName} onChange={this.handleInputChange} />
                    </Col>
                    <Col sm={12} className="element-padding horizontal">
                      <ControlLabel>Address *</ControlLabel>
                      <FormControl id="counterpartyAddress" type="text" value={this.state.form.counterpartyAddress || ''}
                      onChange={this.handleInputChange} />
                    </Col>
                    <Col sm={6} className="element-padding horizontal">
                      <ControlLabel>Zip</ControlLabel>
                      <FormControl id="counterpartyZip_code" type="text" value={this.state.form.counterpartyZip_code || ''} onChange={this.handleInputChange} />
                    </Col>
                    <Col sm={6} className="element-padding horizontal">
                      <ControlLabel>City</ControlLabel>
                      <FormControl id="counterpartyCity" type="text" value={this.state.form.counterpartyCity || ''} onChange={this.handleInputChange} />
                    </Col>
                    <Col sm={12} className="element-padding horizontal">
                      <ControlLabel>Country *</ControlLabel>
                      <FormControl id="counterpartyCountry" type="text" value={this.state.form.counterpartyCountry} onChange={this.handleInputChange} list="counterpartyCountriesDataList" />
                      {this.state.countries && 
                        <datalist id="counterpartyCountriesDataList">
                        {this.state.countries.map(function(item, i){
                          return <option key={i} value={item.country} />;
                        },this)}
                        </datalist>
                      }
                    </Col>
                  </Col>
                </Row>
                <Row>
                  <Col componentClass={ControlLabel} sm={4} className="element-padding horizontal">
                    Contact info
                  </Col>
                  <Col sm={8} className="element-padding horizontal">
                    <FormAddContactToList 
                      inputName="counterpartyContactName"
                      inputEmail="counterpartyContactEmail"
                      inputPhone="counterpartyContactPhone"
                      inputValueName={this.state.form.counterpartyContactName || ''}
                      inputValueEmail={this.state.form.counterpartyContactEmail || ''}
                      inputValuePhone={this.state.form.counterpartyContactPhone || ''}
                      inputOnChange={this.handleInputChange}
                      dataList={false}
                      addToListFunc={()=>this.addContactFormItem('counterpartyContactName','counterpartyContactEmail','counterpartyContactPhone','counterpartyContacts')}
                      deleteFromListFunc={(event)=>this.deleteFormItem(event,'counterpartyContacts')}
                      listArray={this.state.form.counterpartyContacts}
                      label="" />
                  </Col>
                </Row>
                {/* <Row>
                  <Col componentClass={ControlLabel} sm={4} className="element-padding horizontal">
                    Affiliated sites
                  </Col>
                  <Col sm={8}>
                    <FormAddToList 
                        inputId="counterpartySite"
                        inputValue={this.state.form.counterpartySite || ''}
                        inputOnChange={this.handleInputChange}
                        dataList={true}
                        dataListId="sitesList"
                        dataListArray={this.state.sites}
                        addToListFunc={()=>this.addFormItem('counterpartySite','counterpartySites',this.state.sites)}
                        deleteFromListFunc={(event)=>this.deleteFormItem(event,'counterpartySites')}
                        listArray={this.state.form.counterpartySites}
                        label=""
                        loading={this.state.loading} />
                  </Col>
                </Row> */}
                <Row>
                  <Col componentClass={ControlLabel} sm={4} className="element-padding horizontal">
                    Affiliated hubs
                  </Col>
                  <Col sm={8} className="element-padding horizontal">
                    <FormAddToList 
                        inputId="counterpartyHub"
                        inputValue={this.state.form.counterpartyHub || ''}
                        inputOnChange={this.handleInputChange}
                        dataList={true}
                        dataListId="hubsList"
                        dataListArray={this.state.hubs}
                        addToListFunc={()=>this.addFormItem('counterpartyHub','counterpartyHubs',this.state.hubs)}
                        deleteFromListFunc={(event)=>this.deleteFormItem(event,'counterpartyHubs')}
                        listArray={this.state.form.counterpartyHubs}
                        label=""
                        loading={this.state.loading} />
                  </Col>
                </Row>
                <Row>
                  <Col componentClass={ControlLabel} sm={4} className="element-padding horizontal">
                    Terms of trade
                  </Col>
                  <Col sm={8} className="element-padding horizontal">
                    <FormAddFileToList 
                      inputId="counterpartyDocument"
                      inputValue={this.state.form.counterpartyDocument || ''}
                      inputOnChange={this.handleInputChange}
                      addToListFunc={()=>this.addFileFormItem('counterpartyDocument','counterpartyDocuments')}
                      deleteFromListFunc={(event)=>this.deleteFileFormItem(event,'counterpartyDocuments')}
                      listArray={this.state.form.counterpartyDocuments}
                      label="" />
                  </Col>
                </Row>
                <Row>
                  <Col componentClass={ControlLabel} sm={4} className="element-padding horizontal">
                    Other
                  </Col>
                  <Col sm={8} className="element-padding horizontal">
                    <ControlLabel>Additional information</ControlLabel>
                    <FormControl id="additionalInformation" componentClass="textarea" value={this.state.form.additionalInformation} placeholder="" onChange={this.handleInputChange}/>
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          }
          {/* step 2 - done */}
          {this.state.step === 2 &&
            <div>
              {/* success case */}
              {this.state.sendResponse &&
                <div>
                  <h2 className="inquiry-done success">
                    The counterparty information was sent and stored succesfully.
                  </h2>
                  {/*<p className="inquiry-done-info">This window will close automatically in five seconds.</p>*/}
                </div>
              }
              {/* error case */}
              {!this.state.sendResponse &&
                <div>
                  <h2 className="inquiry-done error">
                    {/* TODO should show actual reasons for error */}
                    Something went wrong. Please try again in a few moments.
                  </h2>
                </div>
              }
            </div>
          }
        </Modal.Body>
        <Footer 
          currentStep={this.state.step}
          handleStepChange={(num)=>this.handleStepChange(num)}
          sendResponse={this.state.sendResponse}
          toggle={()=>this.props.toggle(this.props.formId)}
          totalSteps={2} />
      </Modal>
    );
  }
}

FormNewCounterparty.propTypes = {
  close: React.PropTypes.func.isRequired,
  formId: React.PropTypes.string.isRequired,
  toggle: React.PropTypes.func.isRequired,
  visible: React.PropTypes.bool.isRequired
};