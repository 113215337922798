/* global apiBaseUrl */

'use strict';

import React from 'react';
import { Col, ControlLabel, Form, FormControl, FormGroup, Modal, Row } from 'react-bootstrap';
import FormAddToList from './FormAddToList';
import FormAddContactToList from './FormAddContactToList';
import Header from './FormHeader';
import Footer from './FormFooter';
import { handleInputChange, addContactFormItem, addFormItem, deleteFormItem, fetchList, handleSessionStorage, parseFetchError } from '../actions/utility';

require('../stylesheets/app.scss');
require('../stylesheets/components/formComponent.scss');

export default class FormNewHub extends React.Component {
  constructor (props) {
    super(props);
    
    this.handleStepChange = this.handleStepChange.bind(this);
    this.handleInputChange = handleInputChange.bind(this);
    this.handleAgentInputChange = this.handleAgentInputChange.bind(this);
    this.addContactFormItem = addContactFormItem.bind(this);
    this.addFormItem = addFormItem.bind(this);
    this.deleteFormItem = deleteFormItem.bind(this);
    this.fetchList = fetchList.bind(this);
    this.handleSessionStorage = handleSessionStorage.bind(this);
    
    this.state = {
      form: {
        hubName: '',
        hubAddress: '',
        agentZip_code: '',
        agentCity: '',
        hubCountry: '',
        hubContactName: '',
        hubContactEmail: '',
        hubContactPhone: '',
        hubContacts: [],
        hubAgent: '',
        hubAgents: [],
        hubCounterparty: '',
        hubCounterparties: [],
        additionalInformation: ''
      },
      countries: [],
      agents: [],
      counterparties: [],
      requestPending: false,
      sendResponse: false,
      step: 1
    };
  }
  
  handleAgentInputChange (e) {
    // console.log(e);
    this.setState({ form: { ...this.state.form, hubAgent_id: e.target.value } })
  }
  
  handleStepChange (num) {
    //console.log(e.target);
    //console.log(num);
    var errorFound = false;
    var informError = function (id) {
      //console.log(id);
      //alert(id + ' is missing a value.');
      //add error class to all of the inputs that are missing information
      if (document.getElementById(id).className.indexOf('error') < 0) {
        document.getElementById(id).className += ' error';
      }  
      errorFound = true;
    };
    switch (num) {
    case 2:
      //check for the first views data
      for (let i in this.state.form) {
         //filter out the information that is not concerning step 1
        if (i !== 'additionalInformation' &&
            i !== 'hubContactName' &&
            i !== 'hubContactEmail' &&
            i !== 'hubContactPhone' &&
            i !== 'hubContacts' &&
            i !== 'hubAgent' &&
            i !== 'hubAgents' &&
            i !== 'hubCounterparty' &&
            i !== 'hubCounterparties') {
          if (!this.state.form[i]) {
            informError(i);
          }
        }
      }
      break;
    }
    //user is not allowed to go forward if something is missing
    if (!errorFound && num === 2) {
      //send new hub form
      var token = localStorage.getItem('woodtracker_id_token');
      var that = this;
      if (token) {
        var header = new Headers();
        header.append('Accept', 'application/json');
        header.append('Content-Type', 'application/json');
        header.append('Authorization', 'Bearer ' + token);
        
        var findIds = function (arr1, arr2, arr3) {
          for (let i = 0, j = arr2.length; i < j; i++) {
            for (let k = 0, l = arr3.length; k < l; k++) {
              if (arr3[k] === arr2[i].name) {
                arr1.push(arr2[i].id);
              }
            }
          }
        };

        // IMPORTANT! This will break if we allow entities with non-unique "name" fields (because "name" is the value we carry in the form)
        var agentIds = [];
        //loop through the agents to add the correct ids into the port JSON
        findIds(agentIds, this.state.agents, this.state.form.hubAgents);
        //inform an error if the added agents where not valid
        if (agentIds.length === 0 && this.state.form.hubAgents.length > 0) {
          document.getElementById('hubAgent').className += ' error';
          alert('You must only use the suggested agents.');
          return false;
        }
        //the backend doesn't accept empty arrays, add empty string to indicata that an array is empty
        if (agentIds.length === 0) {
          agentIds.push('');
        }

        // IMPORTANT! This will break if we allow entities with non-unique "name" fields (because "name" is the value we carry in the form)
        var counterpartyIds = [];
        //loop through the counterparties to add the correct ids into the port JSON
        findIds(counterpartyIds, this.state.counterparties, this.state.form.hubCounterparties);
        //inform an error if the added counterparties where not valid
        if (counterpartyIds.length === 0 && this.state.form.hubCounterparties.length > 0) {
          document.getElementById('hubCounterparty').className += ' error';
          alert('You must only use the suggested counterparties.');
          return false;
        }
        //the backend doesn't accept empty arrays, add empty string to indicata that an array is empty
        if (counterpartyIds.length === 0) {
          counterpartyIds.push('');
        }
        
        var hub = {
          hub: {
            name: this.state.form.hubName,
            address: this.state.form.hubAddress,
            zip_code: this.state.form.agentZip_code,
            city: this.state.form.agentCity,
            country: this.state.form.hubCountry,
            contacts: this.state.form.hubContacts,
            agent_ids: agentIds,
            counterparty_ids: counterpartyIds,
            additional_info: this.state.form.additionalInformation
          }
        };
        
        var request = new Request(apiBaseUrl + '/hubs', { 
          method: 'POST', 
          headers: header,
          body: JSON.stringify(hub)
        });
        
        //prevent calling fetch if request is pending
        if (!that.state.requestPending) {
          that.setState({ requestPending: true });
          fetch(request).then(function(response) {
            if (!response.ok) {
              //throw Error(response.statusText);
              return response.json().then(err => { throw err; });
            }
            //console.log(response);
            return response.text();
          }).then(function(/*text*/) {
            //console.log(text);
            that.setState({ step: num });
            that.setState({ sendResponse: true });
            that.setState({ requestPending: false });
            sessionStorage.hubsListUpdated = '';
          }).catch(function(err) {
            that.setState({ step: num });
            that.setState({ sendResponse: false });
            that.setState({ requestPending: false });
            parseFetchError(err);
          });
        }
      }
    } else if (!errorFound && num === 1) {
      this.setState({ step: num });
      this.setState({ sendResponse: false });
      this.setState({ requestPending: false });
    } else {
      alert('Data is missing. Please fill in all of the required data.');
    }
  }
  
  componentDidMount () {
    //fetch data
    if (this.props.visible) {
      this.handleSessionStorage('countries','countries',false,'');
      this.handleSessionStorage('agents','agents',false,'');
      this.handleSessionStorage('counterparties','counterparties',false,'');
    }
  }

  render () {
    return(
      <Modal bsSize="large" id={this.props.formId} className={"form-component" + ((this.state.requestPending) ? " busy-blocking" : '')} backdrop={((this.state.requestPending) ? "static" : true)} keyboard={((this.state.requestPending) ? false : true)} show={this.props.visible} onHide={() => (this.state.step !== 2) ? this.props.close(this.props.formId) : this.props.toggle(this.props.formId)}>
        <Header 
          buttons={['Hub details','Done!']}
          currentStep={this.state.step}
          title="New hub" />
        <Modal.Body>
          {/* step 1 - hub information */}
          {this.state.step === 1 &&
            <Form>
              <FormGroup conrolId="newHubInformation">
                <Row>
                  <Col componentClass={ControlLabel} sm={4} className="element-padding horizontal">
                    Hub details
                  </Col>
                  <Col sm={8}>
                    <Col sm={12} className="element-padding horizontal">
                      <ControlLabel>Name *</ControlLabel>
                      <FormControl id="hubName" type="text" value={this.state.form.hubName} onChange={this.handleInputChange} />
                    </Col>
                    <Col sm={12} className="element-padding horizontal">
                      <ControlLabel>Address *</ControlLabel>
                      <FormControl id="hubAddress" type="text" value={this.state.form.hubAddress || ''} onChange={this.handleInputChange} />
                    </Col>
                    <Col sm={6} className="element-padding horizontal">
                      <ControlLabel>Zip</ControlLabel>
                      <FormControl id="agentZip_code" type="text" value={this.state.form.agentZip_code || ''} onChange={this.handleInputChange} />
                    </Col>
                    <Col sm={6} className="element-padding horizontal">
                      <ControlLabel>City</ControlLabel>
                      <FormControl id="agentCity" type="text" value={this.state.form.agentCity || ''} onChange={this.handleInputChange} />
                    </Col>
                    <Col sm={12} className="element-padding horizontal">
                      <ControlLabel>Country *</ControlLabel>
                      <FormControl id="hubCountry" type="text" value={this.state.form.hubCountry} onChange={this.handleInputChange} list="hubCountriesDataList" />
                      {this.state.countries && 
                        <datalist id="hubCountriesDataList">
                        {this.state.countries.map(function(item, i){
                          return <option key={i} value={item.country} />;
                        },this)}
                        </datalist>
                      }
                    </Col>
                  </Col>
                </Row>
                <Row>
                  <Col componentClass={ControlLabel} sm={4} className="element-padding horizontal">
                    Contact info
                  </Col>
                  <Col sm={8} className="element-padding horizontal">
                    <FormAddContactToList 
                      inputName="hubContactName"
                      inputEmail="hubContactEmail"
                      inputPhone="hubContactPhone"
                      inputValueName={this.state.form.hubContactName || ''}
                      inputValueEmail={this.state.form.hubContactEmail || ''}
                      inputValuePhone={this.state.form.hubContactPhone || ''}
                      inputOnChange={this.handleInputChange}
                      dataList={false}
                      addToListFunc={()=>this.addContactFormItem('hubContactName','hubContactEmail','hubContactPhone','hubContacts')}
                      deleteFromListFunc={(event)=>this.deleteFormItem(event,'hubContacts')}
                      listArray={this.state.form.hubContacts}
                      label="" />
                  </Col>
                </Row>
                <Row>
                  <Col componentClass={ControlLabel} sm={4} className="element-padding horizontal">
                    Affiliated agents
                  </Col>
                  <Col sm={8} className="element-padding horizontal">
                    <FormAddToList
                      inputId="hubAgent"
                      inputValue={this.state.form.hubAgent || ''}
                      inputOnChange={this.handleInputChange}
                      dataList={true}
                      dataListId="agentsList"
                      dataListArray={this.state.agents}
                      addToListFunc={()=>this.addFormItem('hubAgent','hubAgents',this.state.agents)}
                      deleteFromListFunc={(event)=>this.deleteFormItem(event,'hubAgents')}
                      listArray={this.state.form.hubAgents}
                      label=""
                      loading={this.state.loading} />
                  </Col>
                </Row>
                <Row>
                  <Col componentClass={ControlLabel} sm={4} className="element-padding horizontal">
                    Affiliated counterparties
                  </Col>
                  <Col sm={8} className="element-padding horizontal">
                    <FormAddToList
                      inputId="hubCounterparty"
                      inputValue={this.state.form.hubCounterparty || ''}
                      inputOnChange={this.handleInputChange}
                      dataList={true}
                      dataListId="counterpartiesList"
                      dataListArray={this.state.counterparties}
                      addToListFunc={()=>this.addFormItem('hubCounterparty','hubCounterparties',this.state.counterparties)}
                      deleteFromListFunc={(event)=>this.deleteFormItem(event,'hubCounterparties')}
                      listArray={this.state.form.hubCounterparties}
                      label=""
                      loading={this.state.loading} />
                  </Col>
                </Row>
                <Row>
                  <Col componentClass={ControlLabel} sm={4} className="element-padding horizontal">
                    Other
                  </Col>
                  <Col sm={8} className="element-padding horizontal">
                    <ControlLabel>Additional information</ControlLabel>
                    <FormControl id="additionalInformation" componentClass="textarea" value={this.state.form.additionalInformation} placeholder="" onChange={this.handleInputChange}/>
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          }
          {/* step 2 - done */}
          {this.state.step === 2 &&
            <div>
              {/* success case */}
              {this.state.sendResponse &&
                <div>
                  <h2 className="inquiry-done success">
                    The hub information was sent and stored succesfully.
                  </h2>
                  {/*<p className="inquiry-done-info">This window will close automatically in five seconds.</p>*/}
                </div>
              }
              {/* error case */}
              {!this.state.sendResponse &&
                <div>
                  <h2 className="inquiry-done error">
                    {/* TODO should show actual reasons for error */}
                    Something went wrong. Please try again in a few moments.
                  </h2>
                </div>
              }
            </div>
          }
        </Modal.Body>
        <Footer 
          currentStep={this.state.step}
          handleStepChange={(num)=>this.handleStepChange(num)}
          sendResponse={this.state.sendResponse}
          toggle={()=>this.props.toggle(this.props.formId)}
          totalSteps={2} />
      </Modal>
    );
  }
}

FormNewHub.propTypes = {
  close: React.PropTypes.func.isRequired,
  formId: React.PropTypes.string.isRequired,
  toggle: React.PropTypes.func.isRequired,
  visible: React.PropTypes.bool.isRequired
};