'use strict';

import React from 'react';
import NewCounterparty from '../components/FormNewCounterparty';
import NewSite from '../components/FormNewSite';
import NewHub from '../components/FormNewHub';
import NewProduct from '../components/FormNewProduct';
import NewCertificate from '../components/FormNewCertificate';
import NewAgent from '../components/FormNewAgent';
import NewUser from '../components/FormNewUser';
import StatusChangeChecklist from '../components/FormStatusChangeChecklist';
import LeadReport from '../components/FormLeadReport';

require('../stylesheets/app.scss');
//require('../stylesheets/components/formComponent.scss');

export default class FormComponent extends React.Component {
  constructor (props) {
    super(props);
  }
  
  render() {
    return(
      <div className="form-component">
        {/* new agent form */}
        {this.props.formId === 'addNewCounterparty' &&
          <NewCounterparty 
            close={this.props.close} 
            formId={this.props.formId} 
            toggle={this.props.toggle} 
            visible={this.props.visible} />
        }

        {/* add new site form */}
        {this.props.formId === 'addNewSite' &&
          <NewSite 
            close={this.props.close} 
            formId={this.props.formId} 
            toggle={this.props.toggle} 
            visible={this.props.visible} />
        }

        {/* add new hub form */}
        {this.props.formId === 'addNewHub' &&
          <NewHub 
            close={this.props.close} 
            formId={this.props.formId} 
            toggle={this.props.toggle} 
            visible={this.props.visible} />
        }

        {/* new product form */}
        {this.props.formId === 'addNewProduct' &&
          <NewProduct 
            close={this.props.close} 
            formId={this.props.formId} 
            toggle={this.props.toggle} 
            visible={this.props.visible} />
        }

        {/* new certificate form */}
        {this.props.formId === 'addNewCertificate' &&
          <NewCertificate 
            close={this.props.close} 
            formId={this.props.formId} 
            toggle={this.props.toggle} 
            visible={this.props.visible} />
        }

        {/* new agent form */}
        {this.props.formId === 'addNewAgent' &&
          <NewAgent 
            close={this.props.close} 
            formId={this.props.formId} 
            toggle={this.props.toggle} 
            visible={this.props.visible} />
        }
        
        {/* add new report form */}
        {/*this.props.formId === 'addNewReport' &&
          <AddNewReport close={this.props.close} formId={this.props.formId} toggle={this.props.toggle} visible={this.props.visible} />
        */}
        
        {/* add new user form */}
        {this.props.formId === 'addNewUser' &&
          <NewUser 
            close={this.props.close} 
            formId={this.props.formId} 
            toggle={this.props.toggle} 
            visible={this.props.visible} />
        }

        {/* status change checklist form */}
        {this.props.formId === 'statusChangeChecklist' &&
        <StatusChangeChecklist 
          close={this.props.close} 
          formId={this.props.formId} 
          toggle={this.props.toggle} 
          visible={this.props.visible}
          highlightStatusRow={this.props.highlightStatusRow} />
        }

        {/* lead report form */}
        {this.props.formId === 'leadReport' &&
        <LeadReport
          close={this.props.close}
          formId={this.props.formId}
          toggle={this.props.toggle}
          visible={this.props.visible}
          selectedLead={this.props.selectedLead}/>
        }
      </div>
    );
  }
}

FormComponent.propTypes = {
  close: React.PropTypes.func.isRequired,
  formId: React.PropTypes.string.isRequired,
  toggle: React.PropTypes.func.isRequired,
  visible: React.PropTypes.bool.isRequired
};