/* global apiBaseUrl */

// Initialize functions BEGIN

// Returns a new entity with default values
export function initializeLead () {
  let entity = {
    active: true,
    id: '',
    status: 'lead',
    implementer: '',
    trader_id: '',
    seller : initializeCounterparty(),
    seller_id: '',
    // seller_contact : initializeContact(),
    sellerContact_name: '',
    sellerContact_phone: '',
    sellerContact_email: '',
    source_type: 'Hub',
    source_id: '',
    buyer: initializeCounterparty(),
    buyer_id: '',
    // buyer_contact: initializeContact(),
    buyerContact_name: '',
    buyerContact_phone: '',
    buyerContact_email: '',
    destination_type: 'Hub',
    destination_id: '',
    product_details: initializeProductDetails(),
    date_of_delivery: '', //constructDefaultDate(),
    additional_info: '',
    deal: '',
    logistics: {
      id: '',
      legs: []
    },
    certificates: [],
    certificateDocument: '',
    lead_certificates: []
  }

  return entity;
}

// Returns a new entity with default values
export function initializeProductDetails () {
  let entity = {
    product_name: '',
    product_id: '',
    product_type: '',
    product_classification: '',
    price: '',
    unit: '',
    currency: '',
    exchange_rate: '',
    price_estimated_mwh_per_unit: '',
    credit_terms: '',
    quantity_source: '',
    quantity_final: '',
    unit_source: '',
    unit_final: '',
    quantity_estimated_mwh_per_unit: '',
    quantity_verified_mwh_per_unit: '',
    energy_content_risk_factor: '',
    productDocument: '',
    documents: [],
    sumTotalEnergyContentSellerMWh: '',
    sumPerMWhIndicative: '',
    sumPerMWhVerified: '',
    sumLogisticsPerMWhIndicative: '',
    sumLogisticsPerMWhVerified: '',
    sumLogisticsTotalPrice: '',
    sumAdditionalCostsPerMWhIndicative: '',
    sumAdditionalCostsPerMWhVerified: '',
    sumAdditionalCostsTotalPrice: '',
    sumTotalEnergyContentIndicative: '',
    sumTotalEnergyContentVerified: '',
    sumPriceTotalPerMWhIndicative: '',
    sumPriceTotalPerMWhVerified: '',
    sumTotalEnergyCostIndicative: '',
    sumTotalEnergyCostVerified: ''
  }

  return entity;
}

// Returns a new entity with default values
export function initializeCounterparty () {
  let entity = {
    active: true,
    id: '',
    name: '',
    address: '',
    contact: initializeContact()
  }

  return entity;
}

// Returns a new entity with default values
export function initializeContact () {
  let entity = {
    id: '',
    name: '',
    phone: '',
    email: ''
  }

  return entity;
}

// Returns a new entity with default values
export function initalizeLeg (id) {
  let entity = {
    id: (id ? id : ''),
    expanded: true,
    order: '',
    start_hub_id: '',
    end_hub_id: '',
    start_site_id: '',
    end_site_id: '',
    logistics_id: '',
    freight_method: '',
    freight_cost: '',
    currency: '',
    exchange_rate_to_eur: '',
    quantity: '',
    pricing_unit: '',
    share_of_total: '',
    credit_terms: '',
    incoterms_id: '',
    freight_insured_by: '',
    unit_id: '',
    additional_info: '',
    start_address: '',
    start_country: '',
    start_agent_id: '',
    start_agent_contact_name: '',
    start_agent_contact_phone: '',
    start_agent_contact_email: '',
    start_contact_name: '',
    start_contact_phone: '',
    start_contact_email: '',
    end_address: '',
    end_country: '',
    end_agent_id: '',
    end_agent_contact_name: '',
    end_agent_contact_phone: '',
    end_agent_contact_email: '',
    end_contact_name: '',
    end_contact_phone: '',
    end_contact_email: '',
    start_type: 'Hub',
    end_type: 'Hub',
    unit_name: '',
    date_of_departure: '',
    estimated_date_of_arrival: '',
    legDocument: '',
    documents: [],
    sumTotalFreightCost: '',
    sumPerMWhIndicative: '',
    sumPerMWhVerified: ''
  }

  return entity;
}

// Returns a new entity with default values
export function initalizeAdditional_cost (id) {
  let entity = {
    id: (id ? id : ''),
    expanded: true,
    order: '',
    lead_id: '',
    cost: '',
    cost_type: '',
    currency: '',
    exchange_rate: '',
    quantity: '',
    pricing_unit: '',
    share_of_total: '',
    additional_info: '',
    additional_costDocument: '',
    documents: [],
    sumTotalCost: '',
    sumPerMWhIndicative: '',
    sumPerMWhVerified: ''
  }

  return entity;
}

// Initialize functions END