/* global apiBaseUrl */

'use strict';

require('../../stylesheets/app.scss');
require('../../stylesheets/components/detailPage.scss');

import React from 'react';
import { Col, ControlLabel, Form, FormControl, FormGroup, Row } from 'react-bootstrap';
import ActionBar from '../../components/ActionBar';
import FormAddToList from '../../components/FormAddToList';
import FormAddContactToList from '../../components/FormAddContactToList';
import { handleInputChange, addContactFormItem, addFormItem, deleteFormItem, fetchList, handleSessionStorage, showLoadingIndicator, parseFetchError, setItemActiveStatus, deleteItem, cancelEdit } from '../../actions/utility';

export default class Hub extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      form: {
        active: null,
        name:'',
        address: '',
        country: '',
        contactName: '',
        contactEmail: '',
        contactPhone: '',
        contacts: [],
        agent: '',
        agents: [],
        counterparty: '',
        counterparties: [],
        additional_info: ''
      },
      countries: [],
      agents: [],
      counterparties: [],
      buttonVisibility: [true, true, false, false, true],
      requestPending: false
    };
    
    this.fetchHub = this.fetchHub.bind(this);
    this.updateHub = this.updateHub.bind(this);

    this.setItemActiveStatus = setItemActiveStatus.bind(this);
    this.deleteItem = deleteItem.bind(this);
    this.cancelEdit = cancelEdit.bind(this);

    this.handleInputChange = handleInputChange.bind(this);
    this.addContactFormItem = addContactFormItem.bind(this);
    this.addFormItem = addFormItem.bind(this);
    this.deleteFormItem = deleteFormItem.bind(this);
    this.fetchList = fetchList.bind(this);
    this.handleSessionStorage = handleSessionStorage.bind(this);
    
    // this.fetchHub();
    
    this.buttons = [
      {
        formId: '',
        icon: 'ion-checkmark',
        onClick: ()=>this.updateHub(),
        refreshFunc: '',
        text: 'save changes'
      },
      {
        formId: '',
        icon: 'ion-power',
        onClick: ()=>this.setItemActiveStatus(false, 'hub', 'hubs'),
        refreshFunc: '',
        text: 'deactivate hub'
      },
      {
        formId: '',
        icon: 'ion-power',
        onClick: ()=>this.setItemActiveStatus(true, 'hub', 'hubs'),
        refreshFunc: '',
        text: 'activate hub'
      },
      {
        formId: '',
        icon: 'ion-android-delete',
        onClick: ()=>this.deleteItem('hubs'),
        refreshFunc: '',
        text: 'delete hub'
      },
      {
        formId: '',
        icon: 'ion-android-cancel',
        onClick: ()=>this.cancelEdit('hubs'),
        refreshFunc: '',
        text: 'cancel'
      }
    ];
  }

  componentDidMount () {
    this.fetchHub();

    //fetch data 
    this.handleSessionStorage('countries','countries',false,'');
    this.handleSessionStorage('agents','agents',false,'');
    this.handleSessionStorage('counterparties','counterparties',false,'');
  }
  
  fetchHub () {
    //fetch
    var token = localStorage.getItem('woodtracker_id_token'),
      admin = JSON.parse(localStorage.getItem('admin_boolean')),
      that = this,
      id = this.props.location.query.id;
      
    if (token) {
      var header = new Headers();
      header.append('Accept', 'application/json');
      header.append('Content-Type', 'application/json');
      header.append('Authorization', 'Bearer ' + token);
      
      var url = apiBaseUrl + '/hubs/' + id;
      
      var request = new Request(url, { 
        method: 'GET', 
        headers: header
      });

      that.setState({ ...that.state, loading: true });
      
      fetch(request).then(function(response) {
        that.setState({ ...that.state, loading: false });
        return response.json();
      }).then(function(json) {
        //console.log(json);
        // json.hub.agent = '';
        if (admin) {
          (json.hub.active) ?
            that.setState({ ...that.state, buttonVisibility: [true,true,false,false,true] }):
            that.setState({ ...that.state, buttonVisibility: [false,false,true,false,false] });
        } else {
          (json.hub.active) ?
            that.setState({ ...that.state, buttonVisibility: [true,true,false,false,true] }):
            that.setState({ ...that.state, buttonVisibility: [false,false,true,false,false] });
        }
        that.setState({ form: {...that.state.form, ...json.hub} });
      }).catch(function(err) {
        that.setState({ ...that.state, loading: false });
        parseFetchError(err);
      });
    }
  }
  
  updateHub () {
    //send new user form
    var token = localStorage.getItem('woodtracker_id_token'),
      that = this;

    if (token) {
      var header = new Headers();
      header.append('Accept', 'application/json');
      header.append('Content-Type', 'application/json');
      header.append('Authorization', 'Bearer ' + token);
      
      var findIds = function (arr1, arr2, arr3) {
        for (let i = 0, j = arr2.length; i < j; i++) {
          for (let k = 0, l = arr3.length; k < l; k++) {
            if (typeof arr3[k] === 'string') {
              if (arr3[k] === arr2[i].name) {
                arr1.push(arr2[i].id);
              }
            } else {
              if (arr3[k].id === arr2[i].id) {
                arr1.push(arr2[i].id);
              }
            }
          }
        }
      };

      // IMPORTANT! This will break if we allow entities with non-unique "name" fields (because "name" is the value we carry in the form)
      var agentIds = [];
      //loop through the agents to add the correct ids into the port JSON
      findIds(agentIds, this.state.agents, this.state.form.agents);
      //inform an error if the added agents where not valid
      if (agentIds.length === 0 && this.state.form.agents.length > 0) {
        document.getElementById('agent').className += ' error';
        alert('You must only use the suggested agents.');
        return false;
      }
      //the backend doesn't accept empty arrays, add empty string to indicata that an array is empty
      if (agentIds.length === 0) {
        agentIds.push('');
      }

      // IMPORTANT! This will break if we allow entities with non-unique "name" fields (because "name" is the value we carry in the form)
      var counterpartyIds = [];
      //loop through the counterparties to add the correct ids into the port JSON
      findIds(counterpartyIds, this.state.counterparties, this.state.form.counterparties);
      //inform an error if the added counterparties where not valid
      if (counterpartyIds.length === 0 && this.state.form.counterparties.length > 0) {
        document.getElementById('counterparty').className += ' error';
        alert('You must only use the suggested counterparties.');
        return false;
      }
      //the backend doesn't accept empty arrays, add empty string to indicata that an array is empty
      if (counterpartyIds.length === 0) {
        counterpartyIds.push('');
      }

      var hub = {
        hub: {
          name: this.state.form.name,
          address: this.state.form.address,
          zip_code: this.state.form.zip_code,
          city: this.state.form.city,
          country: this.state.form.country,
          contacts: this.state.form.contacts,
          agent_ids: agentIds,
          counterparty_ids: counterpartyIds,
          additional_info: this.state.form.additional_info
        }
      };
      
      var request = new Request(apiBaseUrl + '/hubs/' + this.state.form.id, { 
        method: 'PUT', 
        headers: header,
        body: JSON.stringify(hub)
      });

      that.setState({ ...that.state, requestPending: true });
        
      fetch(request).then(function(response) {
        that.setState({ ...that.state, requestPending: false });
        if (!response.ok) {
          //throw Error(response.statusText);
          return response.json().then(err => { throw err; });
        }
        //console.log(response);
        return response.text();
      }).then(function(/*text*/) {
        //console.log(text);
        //update the list timestamp to force new data to be loaded
        var r = confirm('Update successful. Do you want to stop editing this hub?');
        if (r) {
          window.location.hash = '#/hubs';
        } 
        sessionStorage.setItem('hubsListUpdated', new Date().getTime() - 300000);
      }).catch(function(err) {
        that.setState({ ...that.state, requestPending: false });
        parseFetchError(err);
      });
    }
  }
  
  render(){
    return (
      <div className={"detail-page hub" + ((this.state.requestPending) ? " busy-blocking" : '')}>
        <ActionBar elements={ this.buttons } visibility={ this.state.buttonVisibility } />
        {(this.state.loading) ?
          <div className="detail-page-form loading" />
          :
          <div className="detail-page-form">
            <h1 className="detail-page-header">
              Hub detail
              {(this.state.form.active !== null) &&
                <span className={'detail-page-order-status ' + (this.state.form.active ? 'stemmed' : 'canceled')}>
                  { this.state.form.active ? 'Active' : 'Disabled' }
                </span>
              }
            </h1>
            <Form>
              <FormGroup conrolId="newHubInformation">
                <Row>
                  <Col componentClass={ControlLabel} sm={4} className="element-padding horizontal">
                    Hub details
                  </Col>
                  <Col sm={8}>
                    <Col sm={12} className="element-padding horizontal">  
                      <ControlLabel>Name *</ControlLabel>
                      <FormControl id="name" type="text" value={this.state.form.name || ''} onChange={this.handleInputChange} readOnly={((!this.state.form.active) ? true : false)} />
                    </Col>
                    <Col sm={12} className="element-padding horizontal">
                      <ControlLabel>Address *</ControlLabel>
                      <FormControl id="address" type="text" value={this.state.form.address || ''} onChange={this.handleInputChange} readOnly={((!this.state.form.active) ? true : false)} />
                    </Col>
                    <Col sm={6} className="element-padding horizontal">
                      <ControlLabel>Zip</ControlLabel>
                      <FormControl id="zip_code" type="text" value={this.state.form.zip_code || ''} onChange={this.handleInputChange} readOnly={((!this.state.form.active) ? true : false)} />
                    </Col>
                    <Col sm={6} className="element-padding horizontal">
                      <ControlLabel>City</ControlLabel>
                      <FormControl id="city" type="text" value={this.state.form.city || ''} onChange={this.handleInputChange} readOnly={((!this.state.form.active) ? true : false)} />
                    </Col>
                    <Col sm={12} className="element-padding horizontal">
                      <ControlLabel>Country *</ControlLabel>
                      <FormControl id="country" type="text" value={this.state.form.country || ''} onChange={this.handleInputChange} list="hubCountriesDataList" readOnly={((!this.state.form.active) ? true : false)} />
                      {this.state.countries && 
                        <datalist id="hubCountriesDataList">
                        {this.state.countries.map(function(item, i){
                          return <option key={i} value={item.country} />;
                        },this)}
                        </datalist>
                      }
                    </Col>
                  </Col>
                </Row>
                <Row>
                  <Col componentClass={ControlLabel} sm={4} className="element-padding horizontal">
                    Contact info
                  </Col>
                  <Col sm={8} className="element-padding horizontal">
                    <FormAddContactToList 
                      inputName="contactName"
                      inputEmail="contactEmail"
                      inputPhone="contactPhone"
                      inputValueName={this.state.form.contactName || ''}
                      inputValueEmail={this.state.form.contactEmail || ''}
                      inputValuePhone={this.state.form.contactPhone || ''}
                      inputOnChange={this.handleInputChange}
                      dataList={false}
                      addToListFunc={()=>this.addContactFormItem('contactName','contactEmail','contactPhone','contacts')}
                      deleteFromListFunc={(event)=>this.deleteFormItem(event,'contacts')}
                      listArray={this.state.form.contacts}
                      label=""
                      active={this.state.form.active}
                      loading={this.state.loading} />
                  </Col>
                </Row>
                <Row>
                  <Col componentClass={ControlLabel} sm={4} className="element-padding horizontal">
                    Affiliated agents
                  </Col>
                  <Col sm={8} className="element-padding horizontal">
                    <FormAddToList
                      inputId="agent"
                      inputValue={this.state.form.agent || ''}
                      inputOnChange={this.handleInputChange}
                      dataList={true}
                      dataListId="agentsList"
                      dataListArray={this.state.agents}
                      addToListFunc={()=>this.addFormItem('agent','agents',this.state.agents)}
                      deleteFromListFunc={(event)=>this.deleteFormItem(event,'agents')}
                      listArray={this.state.form.agents}
                      label=""
                      active={this.state.form.active}
                      loading={this.state.loading} />
                  </Col>
                </Row>
                <Row>
                  <Col componentClass={ControlLabel} sm={4} className="element-padding horizontal">
                    Affiliated counterparties
                  </Col>
                  <Col sm={8} className="element-padding horizontal">
                    <FormAddToList
                      inputId="counterparty"
                      inputValue={this.state.form.counterparty || ''}
                      inputOnChange={this.handleInputChange}
                      dataList={true}
                      dataListId="counterpartiesList"
                      dataListArray={this.state.counterparties}
                      addToListFunc={()=>this.addFormItem('counterparty','counterparties',this.state.counterparties)}
                      deleteFromListFunc={(event)=>this.deleteFormItem(event,'counterparties')}
                      listArray={this.state.form.counterparties}
                      label=""
                      active={this.state.form.active}
                      loading={this.state.loading} />
                  </Col>
                </Row>
                <Row>
                  <Col componentClass={ControlLabel} sm={4} className="element-padding horizontal">
                    Other
                  </Col>
                  <Col sm={8} className="element-padding horizontal">
                    <ControlLabel>Additional information</ControlLabel>
                    <FormControl id="additional_info" componentClass="textarea" value={this.state.form.additional_info || ''} placeholder="" onChange={this.handleInputChange} readOnly={((!this.state.form.active) ? true : false)} />
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          </div>
        }
      </div>
    );
  }
}

Hub.propTypes = {
  location: React.PropTypes.object.isRequired //if the user id is missing hte user data cannot be fetched
};