import React from 'react';
import { FormGroup, ControlLabel, FormControl } from 'react-bootstrap';

require('../stylesheets/app.scss');

const default_per_page = 15;

export default class ListComponentShow extends React.Component {
  constructor (props) {
    super(props);
    
    this.handlePageShowChange = this.handlePageShowChange.bind(this);
  }
  
  handlePageShowChange (e) {
    this.props.location.query.per_page = e.target.value;
    this.props.changePage();
  }

  render () {
    var meta = this.props.meta;
    
    return(
      <div className="list-component-show">
        <FormGroup controlId="selectedAmount">
          <ControlLabel>Show</ControlLabel>
          <FormControl componentClass="select" placeholder="select" value={(meta && meta.per_page ? meta.per_page : default_per_page)} onChange={this.handlePageShowChange}>
            <option value={15}>15</option>
            <option value={30}>30</option>
            <option value={45}>45</option>
            <option value={60}>60</option>
          </FormControl>
        </FormGroup>
      </div>
    );
  }
}

ListComponentShow.propTypes = {
  changePage: React.PropTypes.func,
  location: React.PropTypes.object,
  meta: React.PropTypes.object
};