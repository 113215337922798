import React from 'react';
import { ButtonGroup, Button } from 'react-bootstrap';
import FormComponent from '../components/FormComponent';

require('../stylesheets/app.scss');
require('../stylesheets/components/actionBar.scss');

// button.icon can be any icon defined in http://ionicons.com/, use classname
// button.text is a free text that can used to descripe the button action
// button.func is the actual function that button calls

let mount = false;

export default class ActionBar extends React.Component {
  constructor (props) {
    super(props);
    
    this.handleScroll = this.handleScroll.bind(this);

    //set state for form visibility control
    var obj = {};
    this.props.elements.map(function(button) {
      obj['showModal_' + button.formId] = false;
    }, this);
    obj.timestamp = new Date().getTime(); // use the timestamp as an unique id for the forms to make sure that the state object is formatted every time
    //obj.top = 0;

    this.state = obj;
  }
  
  toggleModal(id) {
    //a fix to ensure that within inquiry view the offer input loses its focus and stores the set value
    if (document.getElementById(id)) {
      document.getElementById(id).focus();
    }

    this.setState({ ['showModal_' + id]: !this.state['showModal_' + id] });
    this.setState({ timestamp: new Date().getTime() });

    for (let i = 0, j = this.props.elements.length; i < j; i++) {
      if (this.props.elements[i].formId === id) {
        if (this.props.elements[i].refreshFunc !== '') {
          this.props.elements[i].refreshFunc();
        }
      }
    }
  }

  closeModal(id, useConfirmation) {
    var r = (useConfirmation ? confirm('Are you sure you want to close this dialog? Form data will not be stored.') : true);
    if (r) { 
      this.setState({ ['showModal_' + id]: !this.state['showModal_' + id] });
      this.setState({ timestamp: new Date().getTime() });
    }
  }

  componentDidMount () {
    mount = true;
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount () {
    mount = false;
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll (e) {
    if (mount) {
      let scrollTop = e.srcElement.body.scrollTop;
      this.actionBarTop.className = (scrollTop > 80) ? 
        'action-bar fixed' : 'action-bar';
    }
  }

  render () {
    return(
      <div className="action-bar" ref={(ref) => this.actionBarTop = ref }>
        <ButtonGroup pullRight>
          {this.props.elements.map(function(button, i) {
            return <Button 
              key={i} 
              onClick={ () => (typeof button.formId != 'undefined' && button.formId.length > 0) ? this.toggleModal(button.formId) : button.onClick() }
              style={{ display : (!this.props.visibility[i]) ? 'none':'block' }}>
                <span className={'icon ' + button.icon}></span>
                {button.text}
              </Button>;
          }, this)}
        </ButtonGroup>
        
        {this.props.elements.map(function(button, i) {
          //only create new FormComponents if the formId exists
          //console.log(this.props.order.hasOwnProperty('id'));
          if (typeof button.formId != 'undefined' && button.formId !== '') {
            return <FormComponent 
            key={(this.state.timestamp + i)} 
            close={() => this.closeModal(button.formId, true)} 
            confirmation={ (button.confirmationFunc) ? button.confirmationFunc : null }
            formId={button.formId}
            toggle={() => this.toggleModal(button.formId)} 
            visible={this.state['showModal_' + button.formId]} />;
          }
        },this)}
      </div>
    );
  }
}

ActionBar.propTypes = {
  elements: React.PropTypes.array.isRequired,
  visibility: React.PropTypes.array
};