'use strict';

require('../stylesheets/app.scss');

import React from 'react';
import { Button, Checkbox, Col, Form, FormControl, FormGroup, Row } from 'react-bootstrap';

export default class SetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.state = {
      confirmPassword: '',
      password: '',
      showPassword: false,
      token: this.props.token
    };
  }
  
  handleChange (e) {
    (e.target.id === 'showPassword') ?
      this.setState({ [e.target.id]: e.target.checked }):
      this.setState({ [e.target.id]: e.target.value });
  }
  // check if enter is pushed when typing in the password input
  handleKeyPress (e) {
    if (e.charCode === 13) {
      this.props.setPassword(this.state.password, this.state.confirmPassword, this.state.token, e);
    }
  }
  
  render () {
    return(
      <div className="setPassword">
        <Form className="login-form">
          <FormGroup>
            <Row>
              <Col sm={12}>
                <p className="info-block">
                  Please enter your new passwords twice. The password must be at least <b>six</b> alphanumeric characters long.
                </p>
                <FormControl 
                  className={(()=>{
                    if (this.state.password.length === 0) {
                      return '';
                    } else if (this.state.password.length > 0 && this.state.password.length < 6) {
                      return 'error';
                    } else if (this.state.password.length >= 6) {
                      return 'ok';
                    }
                  })()}
                  id="password" 
                  type={!this.state.showPassword ? 'password' : 'text'} 
                  placeholder="Password" 
                  onChange={this.handleChange} />
                <FormControl 
                  className={(()=>{
                    if (this.state.confirmPassword.length === 0) {
                      return '';
                    } else if (this.state.confirmPassword.length > 0 && 
                      this.state.confirmPassword.length < 6) {
                      return 'error';
                    } else if (this.state.confirmPassword.length >= 6 && 
                      this.state.confirmPassword !== this.state.password) {
                      return 'error';
                    } else if (this.state.confirmPassword.length >= 6 && 
                      this.state.confirmPassword === this.state.password) {
                      return 'ok';
                    }
                  })()}
                  id="confirmPassword" 
                  type={!this.state.showPassword ? 'password' : 'text'} 
                  placeholder="Confirm password" 
                  onChange={this.handleChange} 
                  onKeyPress={this.handleKeyPress} />
                <Checkbox id="showPassword" onChange={this.handleChange}>
                  Show password
                </Checkbox>
                <Button id="login-button" type="button" onClick={(event) => this.props.setPassword(this.state.password, this.state.confirmPassword, this.state.token, event)}>
                  <span className="icon ion-log-in"></span>
                  {this.props.type === 'set' ? 'Set password & Login' : 'Reset password & Login'}
                </Button>
              </Col>
            </Row>
          </FormGroup>
        </Form>
      </div>
    );
  }
}

SetPassword.propTypes = {
  token: React.PropTypes.string,
  setPassword: React.PropTypes.func,
  type: React.PropTypes.string
};