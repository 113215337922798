/* global apiBaseUrl */

'use strict';

import React from 'react';
import { Col, ControlLabel, Form, FormControl, FormGroup, Modal, Row } from 'react-bootstrap';
import Header from './FormHeader';
import Footer from './FormFooter';
import { handleInputChange, parseFetchError } from '../actions/utility';

require('../stylesheets/app.scss');
require('../stylesheets/components/formComponent.scss');

export default class FormNewUser extends React.Component {
  constructor (props) {
    super(props);
    
    this.handleStepChange = this.handleStepChange.bind(this);
    this.handleInputChange = handleInputChange.bind(this);
    this.handlePasswordConfirmChange = this.handlePasswordConfirmChange.bind(this);
    
    this.state = {
      form: {
        userEmail: '',
        userName: '',
        userPhone: '',
        userRole: 'trader',
        // userAdmin: '',
        // signature: '',
        additionalInformation:'',
        userPassword: '',
        passwordConfirm: ''
      },
      sendResponse: false,
      step: 1
    };
  }

  handlePasswordConfirmChange (e) {
    // console.log(e);
    // this.setState({passwordConfirm: e.target.value});
    this.setState({ form: { ...this.state.form, passwordConfirm: e.target.value } })
  }  
  
  handleStepChange (num) {
    var errorFound = false;
    var informError = function (id) {
      //alert(id + ' is missing a value.');
      //add error class to all of the inputs that are missing information
      if (document.getElementById(id).className.indexOf('error') < 0) {
        document.getElementById(id).className += ' error';
      }  
      errorFound = true;
    };
    switch (num) {
    case 2:
      //check for the first views data
      for (let i in this.state.form) {
         //filter out the information that is not concerning step 1
        if (i !== 'additionalInformation' &&
            i !== 'userName' &&
            i !== 'userPhone' &&
            i !== 'passwordConfirm') {
          if (!this.state.form[i]) {
            informError(i);
          }
        }
      }
      break;
    }
    //user is not allowed to go forward if something is missing
    if (!errorFound && num === 2) {
      //send new user form
      var token = localStorage.getItem('woodtracker_id_token');
      var that = this;
      if (token) {
        var header = new Headers();
        header.append('Accept', 'application/json');
        header.append('Content-Type', 'application/json');
        header.append('Authorization', 'Bearer ' + token);
        
        var user = {
          user: {
            email: this.state.form.userEmail,
            name: this.state.form.userName,
            phone: this.state.form.userPhone,
            role: this.state.form.userRole,
            // admin: (this.state.form.userAdmin === 'trader') ? false : true,
            // email_signature: this.state.form.signature,
            additional_info: this.state.form.additionalInformation
          }
        };

        if (this.state.form.userPassword) {
          if (this.state.form.userPassword.length < 6 || this.state.form.userPassword.length > 128) {
            document.getElementById('userPassword').className += ' error';
            alert('The password must be at least 6 and less than 128 alphanumeric characters long.');
            return;
          } else if (this.state.form.userPassword !== this.state.form.passwordConfirm) {
            document.getElementById('userPassword').className += ' error';
            alert('The password and confirmation do not match.');
            return;
          } else {
            user.user.password = this.state.form.userPassword;
          }
        }
        
        var request = new Request(apiBaseUrl + '/users', { 
          method: 'POST', 
          headers: header,
          body: JSON.stringify(user)
        });
        
        //prevent calling fetch if request is pending
        if (!that.state.requestPending) {
          that.setState({ requestPending: true });
          fetch(request).then(function(response) {
            if (!response.ok) {
              //throw Error(response.statusText);
              return response.json().then(err => { throw err; });
            }
            //console.log(response);
            return response.text();
          }).then(function(/*text*/) {
            //console.log(text);
            that.setState({ step: num });
            that.setState({ sendResponse: true });
            that.setState({ requestPending: false });
          }).catch(function(err) {
            that.setState({ step: num });
            that.setState({ sendResponse: false });
            that.setState({ requestPending: false });
            parseFetchError(err);
          });
        }
      }
    } else if (!errorFound && num === 1) {
      this.setState({ step: num });
      this.setState({ sendResponse: false });
      this.setState({ requestPending: false });
    } else {
      alert('Data is missing. Please fill in all of the required data.');
    }
  }
  
  render () {
    return(
      <Modal bsSize="large" id={this.props.formId} className={"form-component" + ((this.state.requestPending) ? " busy-blocking" : '')} backdrop={((this.state.requestPending) ? "static" : true)} keyboard={((this.state.requestPending) ? false : true)} show={this.props.visible} onHide={() => (this.state.step !== 2) ? this.props.close(this.props.formId) : this.props.toggle(this.props.formId)}>
        <Header 
          buttons={['User details','Done!']}
          currentStep={this.state.step}
          title="New user" />
        <Modal.Body>
          {/* step 1 - user information */}
          {this.state.step === 1 &&
            <Form>
              <FormGroup conrolId="newUserInformation">
                <Row>
                  <Col componentClass={ControlLabel} sm={4}>
                    User details
                  </Col>
                  <Col sm={8}>
                    <ControlLabel>Email *</ControlLabel>
                    <FormControl id="userEmail" type="text" value={this.state.form.userEmail} onChange={this.handleInputChange} />
                    <ControlLabel>Name</ControlLabel>
                    <FormControl id="userName" type="text" value={this.state.form.userName} onChange={this.handleInputChange} />
                    <ControlLabel>Password</ControlLabel>
                    <p className="info-block">
                      The password must be at least <b>six</b> alphanumeric characters long.
                    </p>
                    <FormControl id="userPassword" type="password" value={this.state.form.userPassword} onChange={this.handleInputChange} />
                    <ControlLabel>Confirm password</ControlLabel>
                    <FormControl id="passwordConfirm" type="password" value={this.state.form.passwordConfirm} onChange={this.handlePasswordConfirmChange} />
                    <ControlLabel>Phone</ControlLabel>
                    <FormControl id="userPhone" type="tel" value={this.state.form.userPhone} onChange={this.handleInputChange} />
                    <FormGroup className="filter-holder dropdown" controlId="userRole">
                      <ControlLabel>Role *</ControlLabel>
                      <FormControl componentClass="select" placeholder="select" value={this.state.form.userRole} onChange={this.handleInputChange}>
                        <option hidden>Select user role</option>
                        <option value="trader">Trader</option>
                        <option value="admin">Admin</option>
                      </FormControl>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col componentClass={ControlLabel} sm={4}>
                    Other
                  </Col>
                  <Col sm={8}>
                    {/* <ControlLabel>Email signature</ControlLabel>
                    <FormControl 
                      id="signature" 
                      componentClass="textarea" 
                      value={this.state.form.signature} 
                      placeholder="Supports the following html tags <h2>, <p>, <b>, <i>" 
                      onChange={this.handleInputChange} /> */}
                    <ControlLabel>Additional information</ControlLabel>
                    <FormControl 
                      id="additionalInformation" 
                      componentClass="textarea" 
                      value={this.state.form.additionalInformation} 
                      placeholder="" 
                      onChange={this.handleInputChange} />
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          }
          {/* step 2 - done */}
          {this.state.step === 2 &&
            <div>
              {/* success case */}
              {this.state.sendResponse &&
                <div>
                  <h2 className="inquiry-done success">
                    The user information was sent and stored succesfully.
                  </h2>
                  {/*<p className="inquiry-done-info">This window will close automatically in five seconds.</p>*/}
                </div>
              }
              {/* error case */}
              {!this.state.sendResponse &&
                <div>
                  <h2 className="inquiry-done error">
                    {/* TODO should show actual reasons for error */}
                    Something went wrong. Please try again in a few moments.
                  </h2>
                </div>
              }
            </div>
          }
        </Modal.Body>
        <Footer 
          currentStep={this.state.step}
          handleStepChange={(num)=>this.handleStepChange(num)}
          sendResponse={this.state.sendResponse}
          toggle={()=>this.props.toggle(this.props.formId)}
          totalSteps={2} />
      </Modal>
    );
  }
}

FormNewUser.propTypes = {
  close: React.PropTypes.func.isRequired,
  formId: React.PropTypes.string.isRequired,
  toggle: React.PropTypes.func.isRequired,
  visible: React.PropTypes.bool.isRequired
};