/* global apiBaseUrl */

'use strict';

require('../../stylesheets/app.scss');
require('../../stylesheets/components/react-datetime.scss');
require('../../stylesheets/components/detailPage.scss');

import React from 'react';
import { Button, Col, ControlLabel, Form, FormControl, FormGroup, Grid, InputGroup, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router';
import DateTime from 'react-datetime';
import ActionBar from '../../components/ActionBar';
import FormComponent from '../../components/FormComponent';
import FormAddToList from '../../components/FormAddToList';
import FormAddFileToList from '../../components/FormAddFileToList';
import { addContactFormItem, addFormItem, deleteFormItem, fetchList, handleSessionStorage, showLoadingIndicator, parseFetchError, setItemActiveStatus, deleteItem, cancelEdit, constructDefaultDate, preserveExpanded, reorderItemsInArray, offset, handleDebouncedCallback } from '../../actions/utility';
import { initializeLead, initializeProductDetails, initializeCounterparty, initializeContact, initalizeLeg, initalizeAdditional_cost } from '../../actions/entities';
import { calculateLeadEnergyContentsAndPrices, calculateLegCosts, calculateAdditional_costCosts } from '../../actions/calculations';
import { ENUM_statuses, ENUM_currencies, ENUM_implementers, ENUM_unitTypes, ENUM_legPricingUnits, ENUM_additional_costCostTypes, ENUM_creditTerms, ENUM_incoterms, ENUM_productTypes, ENUM_freightMethods, ENUM_freightInsuredBy } from '../../actions/enums';

export default class Lead extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      forms: [
        // {
        // form: initializeLead()
        // }
      ],
      dateInputFormat: 'DD.MM.YYYY',
      dateMode: 'days',
      statuses: ENUM_statuses,
      implementers: ENUM_implementers,
      unitTypes: ENUM_unitTypes,
      legPricingUnits: ENUM_legPricingUnits,
      additional_costCostTypes: ENUM_additional_costCostTypes,
      currencies: ENUM_currencies,
      creditTerms: ENUM_creditTerms,
      incoterms: ENUM_incoterms,
      freightInsuredBy: ENUM_freightInsuredBy,
      productTypes: ENUM_productTypes,
      // productClassifications: ENUM_productClassifications,
      freightMethods: ENUM_freightMethods,
      countries: [],
      counterparties: [],
      products: [],
      certificates: [],
      leads: [],
      users: [],
      buttonVisibility: [true, true, false, true, false],
      requestPending: false,
      viewMode_leadDetailsHeader: 'summary',
      viewMode_leadStatus: 'full',
      view_section: '',
      sectionExpanded_counterparties: false,
      sectionExpanded_product: false,
      sectionExpanded_certificates: false,
      sectionExpanded_logistics: false,
      sectionExpanded_additional_costs: false,
      width_detailPageForm: '100%',
      padding_between_leads: '0px',
      colspan_sectionLabel: 2,
      colspan_sectionContent: 10,
      tooltipDelayShow: 300
      // countries: [],
    };

    this.handleResize = this.handleResize.bind(this);

    this.initializeLead = initializeLead.bind(this);
    this.initializeCounterparty = initializeCounterparty.bind(this);
    this.initializeContact = initializeContact.bind(this);
    this.initializeProductDetails = initializeProductDetails.bind(this);
    this.clearLead = this.clearLead.bind(this);

    this.handleDateLead = this.handleDateLead.bind(this);
    this.handleDateLeg = this.handleDateLeg.bind(this);
    this.clearDate = this.clearDate.bind(this);

    this.toggleModal = this.toggleModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    
    this.fetchLead = this.fetchLead.bind(this);
    this.populateLead = this.populateLead.bind(this);
    this.updateLead = this.updateLead.bind(this);
    this.updateLeads = this.updateLeads.bind(this);
    this.createNewNewLeadAndLink = this.createNewNewLeadAndLink.bind(this);
    this.openLeadAsLinked = this.openLeadAsLinked.bind(this);
    this.closeLead = this.closeLead.bind(this);
    this.fetchLinkedLeads = this.fetchLinkedLeads.bind(this);

    this.scrollTo = this.scrollTo.bind(this);
    this.leadNavigationScroll = this.leadNavigationScroll.bind(this);
    this.calculateUIWidths = this.calculateUIWidths.bind(this);

    this.setItemActiveStatus = setItemActiveStatus.bind(this);
    this.deleteItem = deleteItem.bind(this);
    this.cancelEdit = cancelEdit.bind(this);

    this.getFormByIndex = this.getFormByIndex.bind(this);
    this.handleLeadInputChange = this.handleLeadInputChange.bind(this);
    this.handleLegInputChange = this.handleLegInputChange.bind(this);
    this.addContactFormItem = addContactFormItem.bind(this);
    this.addFormItem = addFormItem.bind(this);
    this.deleteFormItem = deleteFormItem.bind(this);
    this.fetchList = fetchList.bind(this);
    this.handleSessionStorage = handleSessionStorage.bind(this);
    
    // this.fetchLead();
    
    this.buttons = [
      // {
      //   formId: '',
      //   icon: 'ion-checkmark',
      //   onClick: ()=>this.openLeadAsLinked(),
      //   refreshFunc: '',
      //   text: 'link lead'
      // },
      {
        icon: 'ion-plus',
        onClick: ()=>this.createNewNewLeadAndLink(),
        refreshFunc: '',
        // refreshFunc: ()=>this.updateList(undefined, 'leads'),
        text: 'link new lead'
      },
      {
        formId: '',
        icon: 'ion-checkmark',
        onClick: ()=>this.updateLeads(),
        refreshFunc: '',
        text: 'save all'
      },
      // {
      //   formId: '',
      //   icon: 'ion-checkmark',
      //   onClick: ()=>this.updateLead(),
      //   refreshFunc: '',
      //   text: 'save changes'
      // },
      {
        formId: '',
        icon: 'ion-android-delete',
        onClick: ()=>this.deleteItem(''),
        refreshFunc: '',
        text: 'delete lead'
      },
      {
        formId: 'leadReport',
        icon: 'ion-document',
        refreshFunc: '',
        // refreshFunc: ()=>console.log((this.state.forms && this.state.forms[0]) ? this.state.forms[0].id : ''),
        text: 'create a report',
        // selectedLead: ((this.state.forms && this.state.forms[0]) ? this.state.forms[0].id : '')
      },
      {
        formId: '',
        icon: 'ion-android-cancel',
        onClick: ()=>this.cancelEdit(''),
        refreshFunc: '',
        text: 'cancel'
      }
    ];
  }

  componentDidMount () {
    // console.log("##### componentDidMount #####");
    //fetch data 
    this.handleSessionStorage('countries','countries',false,'');
    this.handleSessionStorage('counterparties','counterparties',false,'');
    this.handleSessionStorage('products','products',false,'');
    this.handleSessionStorage('certificates','certificates',false,'');
    this.handleSessionStorage('hubs','hubs',false,'');
    this.handleSessionStorage('sites', 'sites', false, '');
    this.handleSessionStorage('leads', 'leads', false, '');
    this.handleSessionStorage('users', 'users', false, '');
    
    // Fetch lead for the id in the url
    let id = this.props.location.query.id;
    if (id) {
      this.fetchLead(id, () => this.fetchLinkedLeads());
    }

    if (this.props.location.query.view_section) {
      let view_section = this.props.location.query.view_section;
      // delete this.props.location.query.view_section;

      this.setState((prevState, props) => {
        return {
          ...this.state,
          view_section: view_section
        };
      }, () => this.toggleSubsectionVisibility(null, view_section, true));
    }  

    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  componentWillReceiveProps (nextProps) {
    // console.log("##### componentWillReceiveProps #####");

    // Fetch/clear lead when id changes in the url
    if (nextProps.location.action === 'POP') {
      let id = nextProps.location.query.id;

      if (id) {
        // If id changed, make sure we have a new "owner" Lead
        if (!this.state.forms || id !== this.state.forms[0].id) {
          // console.log("Owner lead changed to = " + id);

          // TODO - clear all leads and fetch one with the new id.
          // TODO - also mind the setState at the end of this method
          // this.clearLeads();
          // this.clearLeads(null, () => this.fetchLead(id));
          // this.fetchLead(id);
        }
      }
      // else {
      //   this.clearLeads();
      // }

      if (nextProps.location.query.view_section) {
        let view_section = nextProps.location.query.view_section;
        // delete nextProps.location.query.view_section;

        this.setState((prevState, props) => {
          return {
            ...this.state,
            view_section: view_section
          };
        }, () => this.toggleSubsectionVisibility(null, view_section, true));
      }  
    }
  }

  handleResize(e) {
    this.calculateUIWidths(this.state.forms.length);
  }

  componentDidUpdate () {
    // console.log("##### componentDidUpdate #####");

    if (this.state.view_section) {
      // Debounce - wait until all fast updates are done before scrolling (we want to only scroll once)
      handleDebouncedCallback(() => {
        this.scrollTo('#section-' + this.state.view_section);

        // Empty the view_section, so we don't start jumping around the UI after we've scrolled once
        this.setState((prevState, props) => {
          return {
            ...this.state,
            view_section: ''
          };
        });
      });
    }
  }

  scrollTo (view_section) {
    // console.log("##### scrollTo #####");

    if (!view_section) {
      // console.log("No view_section provided. Trying to parse from url parameters.");
      view_section = this.props.location.query.view_section;
    }

    if (!view_section) {
      // console.warn("No view_section provided. Will not scroll.");
      return;
    }
    
    // Consider all possible fixed elements on top of the view (.offsetHeight includes padding, scrollBar and borders)
    let marginNavBar = (document.querySelector('.navbar') ? document.querySelector('.navbar').offsetHeight : 80);
    let marginActionBar = (document.querySelector('.action-bar') ? document.querySelector('.action-bar').offsetHeight : 80);
    let marginLeadHeader = (document.querySelector('#lead-header') ? document.querySelector('#lead-header').offsetHeight : 233);

    // console.log("marginNavBar = " + marginNavBar);
    // console.log("marginActionBar = " + marginActionBar);
    // console.log("marginLeadHeader = " + marginLeadHeader);

    let marginTop = marginNavBar + marginActionBar + marginLeadHeader;

    // Default to id (#)
    if (view_section.indexOf('#') < 0) {
      view_section = '#' + view_section;
    }
    let element = document.querySelector(view_section);

    if (element) {
      let scrollPosition = new Number(offset(element).top);
      scrollPosition -= marginTop // Consider margin
      if (scrollPosition < 0) {
        scrollPosition = 0;
      }

      // console.log("scrollPosition = " + scrollPosition);
      window.scrollTo(0, scrollPosition);
    }  
  }

  leadNavigationScroll(e, targetSelector) {
    if (e) {
      e.preventDefault();
    }
    // console.log("##### leadNavigationScroll #####");
    // console.log("targetSelector = " + targetSelector);

    this.toggleSubsectionVisibility(null, targetSelector.replace('#section-', ''), true, () => this.scrollTo(targetSelector));
    // this.scrollTo(targetSelector);
  }

  //simple function to toggle the if the subsections should be open or closed
  toggleSubsectionVisibility(e, section, expanded, callback) {
    let valueKey = 'sectionExpanded_' + section;

    // If expanded not provided, toggle
    if (typeof expanded === 'undefined') {
      expanded = !this.state[valueKey];
    }

    // console.log(valueKey + ".expanded = " + expanded);

    // Use setState updater callback explicitly if a callback is provided.
    // This ensures that the state is updated before executing the callback.
    if (callback) {
      // console.log("callback = " + callback);
      this.setState((prevState, props) => {
        return {...this.state,
          [valueKey]: expanded
        };
      }, callback);
    } else {
      // console.log("no callback");
      this.setState({...this.state,
        [valueKey]: expanded
      });
    }
  }

  // toggleSubsectionVisibility (e) {
  //   e.preventDefault();
  //   //console.log(e);
    
  //   var bttn = e.target;
  //   bttn.className = (bttn.className.indexOf('open') === -1) ? 
  //     bttn.className + ' open':
  //     bttn.className.replace(' open','');
    
  //   var prnt = e.target.parentNode.parentNode.querySelector('.collapsible-content');
  //   prnt.className = (prnt.className.indexOf('closed') === -1) ? 
  //     prnt.className + ' closed':
  //     prnt.className.replace(' closed','');
  // }

  toggleLeadStatusVisibility (e, viewMode) {
    e.preventDefault();
    // console.log(e);
    // console.log("viewMode = " + viewMode);
    
    // var bttn = e.target;
    // bttn.className = (bttn.className.indexOf('open') === -1) ? 
    // bttn.className + ' open':
    // bttn.className.replace(' open','');

    this.setState({ ...this.state,
      viewMode_leadStatus: viewMode
    });
  }

  toggleLeadDetailsHeaderVisibility (e, viewMode) {
    e.preventDefault();
    // console.log(e);
    // console.log("viewMode = " + viewMode);

    this.setState({ ...this.state,
      viewMode_leadDetailsHeader: viewMode
    });
  }

  //simple function to toggle the if the subsections should be open or closed
  toggleWidgetVisibility(e, formIndex, widgetType, widgetIndex, expanded, callback) {
    formIndex = this.getDefaultIndex(formIndex);
    let form = this.getFormByIndex(formIndex);
    let widget;

    // console.log("Widget (" + widgetType + "[" + widgetIndex + "])");

    if (widgetType === 'leg') {
      widget = form.logistics.legs[widgetIndex];
      // widget = form.logistics.legs.find(elem => elem.id == widgetId);
    } else if (widgetType === 'additional_cost') {
      widget = form.additional_costs[widgetIndex];
      // widget = form.additional_costs.find(elem => elem.id == widgetId);
    }

    if (widget) {
      // console.log("Widget (" + widgetType + "[" + widgetIndex + "]) = " + widget);

      // If expanded not provided, toggle
      if (typeof expanded === 'undefined') {
        expanded = widget.expanded ? !widget.expanded : true;
      }
      // console.log("Widget expanded = " + expanded);
      widget.expanded = expanded;

      this.updateLeadFormInState(form, callback);

      // console.log(valueKey + ".expanded = " + expanded);
    }
  }

  toggleModal(id, highlightStatusRow) {
    //a fix to ensure that within inquiry view the offer input loses its focus and stores the set value
    if (document.getElementById(id)) {
      document.getElementById(id).focus();
    }

    let newVisible = !this.state['showModal_' + id];
    if (!newVisible) {
      highlightStatusRow = '';
    }

    this.setState({
      ['showModal_' + id]: !this.state['showModal_' + id],
      ['showModal_' + id + '_highlightStatusRow']: highlightStatusRow
    });
    // this.setState({ timestamp: new Date().getTime() });

    // for (let i = 0, j = this.props.elements.length; i < j; i++) {
    //   if (this.props.elements[i].formId === id) {
    //     if (this.props.elements[i].refreshFunc !== '') {
    //       this.props.elements[i].refreshFunc();
    //     }
    //   }
    // }
  }

  closeModal(id) {
    this.setState({
      ['showModal_' + id]: !this.state['showModal_' + id],
      ['showModal_' + id + '_highlightStatusRow']: ''
    });
    // this.setState({ timestamp: new Date().getTime() });
  }

  // getDefaultKey(key) {
  //   if (!key) {
  //     //// TODO DEBUG - default to 'form'. IMPORTANT! This must be removed when implementing actual "multiple Leads at once" functionality
  //     return 'form';
  //   } else {
  //     return key;
  //   }
  // }

  // getFormByKey(key) {
  //   key = this.getDefaultKey(key);
  //   return this.state.forms[key];
  // }

  getDefaultIndex(index) {
    if (!index) {
      //// TODO DEBUG - default to 'form'. IMPORTANT! This must be removed when implementing actual "multiple Leads at once" functionality
      return 0;
    } else {
      return index;
    }
  }

  getFormByIndex(index) {
    index = this.getDefaultIndex(index);
    return this.state.forms[index];
  }

  //handler for 2-way data binding within Leads
  handleLeadInputChange (e, formIndex, callback) {
    // console.log(e);
    // console.log("handleLeadInputChange");
    // console.log("handleLeadInputChange -> callback = " + callback);
    formIndex = this.getDefaultIndex(formIndex);
    let form = this.getFormByIndex(formIndex);
    let valueKey = e.target.id;
    let value;
    
    if (e.target.files) {
      // Handle files
      var files =  Array.from(e.target.files);

      if (typeof files != 'undefined') {
        // If only one element in the array, use it directly
        // Otherwise use the whole array
        if (files.length === 1) {
          // console.log("file = " + files[0].name);
          value = files[0];
        } else {
          // files.forEach(function(file, i) {
          //   console.log("files[" + i + "] = " + file.name);
          // });
          value = files;
        }

        // Clear the input field value, because the actual value is managed elsewhere (in the form)
        e.target.value = null;
      }
    } else if (e.target.selectedOptions) {
      // Multiple select options
      // console.log("handleLeadInputChange -> e.target.selectedOptions = " + e.target.selectedOptions);
      var values = [];
      for (var i = 0, l = e.target.selectedOptions.length; i < l; i++) {
        if (e.target.selectedOptions[i].selected) {
          values.push(e.target.selectedOptions[i].value);
        }
      }

      // If only one selection, just use one value without array
      value = (values.length === 1 ? values[0] : values);
      // form[valueKey] = (values.length === 1 ? values[0] : values);
    } else {
      // Normal value
      // console.log("handleLeadInputChange -> e.target.value = " + e.target.value);
      value = e.target.value;
      // form[valueKey] = e.target.value;
    }
    
    //check if the input has error class set to it and remove it
    if (e.target.className.indexOf('error') > -1) {
      e.target.className = e.target.className.replace('error','');
    }

    form[valueKey] = value;

    this.updateLeadFormInState(form, callback);
  }

  calculateUIWidths (itemCount, callback) {
    // Handle colspans when more than one lead is shown
    // Defauls (for only one lead) =
    let width_detailPageForm = '100%';
    let padding_between_leads = '0px';
    let colspan_sectionLabel = 2;
    let colspan_sectionContent = 12 - colspan_sectionLabel;
    let colspan_sectionContentPercentage = (100 / 12) * (10 - colspan_sectionLabel) + '%';
    let colspan_subField;

    if (itemCount && itemCount > 1) {
      width_detailPageForm = (480 + (itemCount * 480));
      padding_between_leads = Math.floor(window.innerWidth * 0.05);
      // console.log("innerWidth = " + innerWidth);
      // console.log("padding_between_leads = " + padding_between_leads);
      (!padding_between_leads || padding_between_leads > 120 ? padding_between_leads = 120 : (padding_between_leads < 60 ? padding_between_leads = 60 : false))
      // Make sure width isn't smaller than the window
      width_detailPageForm = (width_detailPageForm < window.innerWidth) ? '100%' : width_detailPageForm + (padding_between_leads * itemCount) + 'px';
      padding_between_leads = padding_between_leads + 'px';

      // Minimum colspan for label col = 2;
      colspan_sectionLabel = 2;
      // Divide the leftover (12 - 2 = 10) by array length
      // colspan_sectionContent = Math.floor(((12 - colspan_sectionLabel) / itemCount));

      // percentage for overriding Col width
      colspan_sectionContentPercentage = (100 / 12) * (((12 - colspan_sectionLabel) / itemCount)) + '%';

      // When sections get narrow enough, make fields span 12
      if (colspan_sectionContent < 5) {
        colspan_subField = 12;
      }
    } else {
      padding_between_leads = '0px';
      colspan_sectionContentPercentage = null;
      colspan_subField = null;
    }

    // Use setState updater callback explicitly if a callback is provided.
    // This ensures that the state is updated before executing the callback.
    if (callback) {
      // console.log("callback = " + callback);
      this.setState((prevState, props) => {
        return {
          ...this.state,
          width_detailPageForm: width_detailPageForm,
          padding_between_leads: padding_between_leads,
          colspan_sectionLabel: colspan_sectionLabel,
          // colspan_sectionContent: colspan_sectionContent,
          colspan_sectionContentPercentage: colspan_sectionContentPercentage,
          colspan_subField: colspan_subField
        };
      }, callback);
    } else {
      // console.log("no callback");
      this.setState({
        ...this.state,
        width_detailPageForm: width_detailPageForm,
        padding_between_leads: padding_between_leads,
        colspan_sectionLabel: colspan_sectionLabel,
        // colspan_sectionContent: colspan_sectionContent,
        colspan_sectionContentPercentage, colspan_sectionContentPercentage,
        colspan_subField: colspan_subField
      });
    }
  }

  // Lead BEGIN

  updateLeadFormInArray(formObject, array) {
    if (!formObject) {
      console.warn("formObject is undefined. Will not update state.");
      return;
    }

    // Create a new array from the old one (we don't want to mutate the one in state directly)
    let newFormsArray = array; //.slice();
    
    // Insert fetched data into formObject (in the forms array)
    if (!newFormsArray) {
      newFormsArray = [];
      newFormsArray.push(formObject);
    } else {
      let existingIndex = -1;
      for (var i = 0; i < newFormsArray.length; i++) {
        // Find possibly existing formObject
        if (newFormsArray[i].id === formObject.id) {
          existingIndex = i;
        }
      }
      if (existingIndex > -1) {
        // Replace existing formObject
        newFormsArray[existingIndex] = formObject;
      } else {
        // Add new formObject
        newFormsArray.push(formObject);
      }
    }

    return newFormsArray;
  }

  updateLeadFormInState(form, callback) {
    // console.log("updateLeadFormInState");
    // console.log("updateLeadFormInState -> callback = " + callback);

    if (!form) {
      console.warn("form is undefined. Will not update state.");
      return;
    }

    let newFormsArray = this.updateLeadFormInArray(form, this.state.forms.slice());    

    // Use setState updater callback explicitly if a callback is provided.
    // This ensures that the state is updated before executing the callback.
    if (callback) {
      // console.log("callback = " + callback);
      this.setState((prevState, props) => {
        return {
          forms: newFormsArray
        };
      }, callback);
    } else {
      // console.log("no callback");
      this.setState({
        forms: newFormsArray
      });
    }
  }

  // Clears the entity in the state
  clearLeads (callback) {
    // Create new forms array
    let newFormsArray = [];
    newFormsArray.push(initializeLead());

    // Use setState updater callback explicitly if a callback is provided.
    // This ensures that the state is updated before executing the callback.
    if (callback) {
      // console.log("callback = " + callback);
      this.setState((prevState, props) => {
        return { ...this.state,
          JUK: newFormsArray
        };
      }, callback);
    } else {
      // console.log("no callback");
      this.setState({ ...this.state,
        JUK: newFormsArray
      });
    }
  }

  // Clears the entity in the state
  clearLead (formIndex, callback) {
    formIndex = this.getDefaultIndex(formIndex);
    let entity = initializeLead();

    this.setState({
      forms: {
        ...this.state.forms,
        [formIndex]: entity
      }
    });
  }
  
  fetchLead (id, callback) {
    if (!id) {
      // If no id provided, try to get it from props.location.query (url params)
      id = this.props.location.query.id;
    }

    if (!id) {
      // No ID provided. Don't fetch.
      return;
    }

    // console.log("Fetching lead = " + id);

    var token = localStorage.getItem('woodtracker_id_token'),
      admin = JSON.parse(localStorage.getItem('admin_boolean')),
      that = this;
      
    if (token) {
      var header = new Headers();
      header.append('Accept', 'application/json');
      header.append('Content-Type', 'application/json');
      header.append('Authorization', 'Bearer ' + token);
      
      var url = apiBaseUrl + '/leads/' + id;
      
      var request = new Request(url, { 
        method: 'GET', 
        headers: header
      });

      that.setState({ ...that.state, loading: true });
      
      fetch(request).then(function(response) {
        that.setState({ ...that.state, loading: false });
        return response.json();
      }).then(function(json) {
        if (admin) {
          that.setState({ ...that.state, buttonVisibility: [true, true, false, true, false] });
        } else {
          that.setState({ ...that.state, buttonVisibility: [true, true, false, true, false] });
        }

        // Lead - populate values
        json.lead = that.populateLead(json.lead);

        let leadForm = that.state.forms.find(elem => elem.id == id);
        if (leadForm) {
          preserveExpanded(leadForm.logistics.legs, json.lead.logistics.legs);
          preserveExpanded(leadForm.additional_costs, json.lead.additional_costs);
        }  

        // Legs - populate values (Start/end type, agent objects, calculations, etc.)
        that.populateLogisticsValues(json.lead, json.lead.logistics);
        // Additional_costs - populate values (calculations, etc.)
        that.populateAdditional_costsValues(json.lead, json.lead.additional_costs);
        // Do all Lead calculations
        that.calculateLeadEnergyContentsAndPrices(json.lead);

        // Do product_details calculations
        // json.lead.product_details = calculateProductDetailsEnergyContentsAndPrices(json.lead.product_details);

        //"Multiple Leads at once" functionality - insert loaded json into the state inside 'forms' array
        let newFormsArray = that.updateLeadFormInArray(json.lead, that.state.forms.slice());

        // Handle colspans when more than one lead is shown
        that.calculateUIWidths(newFormsArray ? newFormsArray.length : 1);

        // Use setState updater callback explicitly if a callback is provided.
        // This ensures that the state is updated before executing the callback.
        if (callback) {
          // console.log("callback = " + callback);
          that.setState((prevState, props) => {
            return { ...that.state,
              forms: newFormsArray
            };
          }, callback);
        } else {
          // console.log("no callback");
          that.setState({ ...that.state,
            forms: newFormsArray
          });
        }
        
      }).catch(function(err) {
        that.setState({ ...that.state, loading: false });

        // Clear any possibly shown entity
        that.clearLead();
        parseFetchError(err);
      });
    }
  }

  populateLead(lead) {
    // Initialize new objects if undefined
    lead = { ...this.initializeLead(), ...lead };
    lead.seller = { ...this.initializeCounterparty(), ...lead.seller };
    lead.buyer = { ...this.initializeCounterparty(), ...lead.buyer };
    lead.product_details = { ...this.initializeProductDetails(), ...lead.product_details };

    // Initialize empty trader to be the currently logged in user.
    (!lead.trader_id ? lead.trader_id = localStorage.getItem('user_id') : false);

    // Use a Date object
    (lead.date_of_delivery ? lead.date_of_delivery = new Date(lead.date_of_delivery) : false);
    
    // Contacts
    lead.sellerContact_name = lead.seller.contact.name;
    lead.sellerContact_phone = lead.seller.contact.phone;
    lead.sellerContact_email = lead.seller.contact.email;
    lead.buyerContact_name = lead.buyer.contact.name;
    lead.buyerContact_phone = lead.buyer.contact.phone;
    lead.buyerContact_email = lead.buyer.contact.email;

    return lead;
  }
  
  updateLead(id, callback) {
    // console.log("Updating lead = " + id);
    
    //send new user form
    var token = localStorage.getItem('woodtracker_id_token'),
      that = this;

    if (token) {
      var header = new Headers();
      header.append('Accept', 'application/json');
      header.append('Content-Type', 'application/json');
      header.append('Authorization', 'Bearer ' + token);
      
      var findIds = function (arr1, arr2, arr3) {
        for (let i = 0, j = arr2.length; i < j; i++) {
          for (let k = 0, l = arr3.length; k < l; k++) {
            if (typeof arr3[k] === 'string') {
              if (arr3[k] === arr2[i].name) {
                arr1.push(arr2[i].id);
              }
            } else {
              if (arr3[k].id === arr2[i].id) {
                arr1.push(arr2[i].id);
              }
            }
          }
        }
      };

      let leadForm = this.state.forms.find(elem => elem.id == id);

      // IMPORTANT! This will break if we allow entities with non-unique "name" fields (because "name" is the value we carry in the form)
      var certificateIds = [];
      //loop through the agents to add the correct ids into the port JSON
      findIds(certificateIds, this.state.certificates, leadForm.certificates);
      //inform an error if the added agents where not valid
      if (certificateIds.length === 0 && leadForm.certificates.length > 0) {
        document.getElementById('certificate').className += ' error';
        alert('You must only use the suggested certificates.');
        return false;
      }
      //the backend doesn't accept empty arrays, add empty string to indicata that an array is empty
      if (certificateIds.length === 0) {
        certificateIds.push('');
      }

      var lead = {
        lead: {
          // id: leadForm.id,
          status: leadForm.status,
          implementer: leadForm.implementer,
          trader_id: leadForm.trader_id,
          seller_id: leadForm.seller_id,
          buyer_id: leadForm.buyer_id,
          seller_contact: {name: leadForm.sellerContact_name, phone: leadForm.sellerContact_phone, email: leadForm.sellerContact_email},
          buyer_contact: {name: leadForm.buyerContact_name, phone: leadForm.buyerContact_phone, email: leadForm.buyerContact_email},
          source_type: leadForm.source_type,
          source_id: leadForm.source_id,
          destination_type: leadForm.destination_type,
          destination_id: leadForm.destination_id,
          date_of_delivery: leadForm.date_of_delivery,
          additional_info: leadForm.additional_info,
          deal: leadForm.deal,
          certificate_ids: certificateIds
        }
      };

      // Add Product Details
      // if (leadForm.product_details.product_id) {
      lead.lead.product_details_attributes = {
        product_name: leadForm.product_details.product_name,
        product_id: leadForm.product_details.product_id,
        product_type: leadForm.product_details.product_type,
        product_classification: leadForm.product_details.product_classification,
        price: (leadForm.product_details.price ? new Number(leadForm.product_details.price): null),
        unit: leadForm.product_details.unit,
        currency: leadForm.product_details.currency,
        credit_terms: leadForm.product_details.credit_terms,
        quantity_source: (leadForm.product_details.quantity_source ? new Number(leadForm.product_details.quantity_source): null),
        quantity_final: (leadForm.product_details.quantity_final ? new Number(leadForm.product_details.quantity_final): null),
        unit_source: leadForm.product_details.unit_source,
        unit_final: leadForm.product_details.unit_final,
        energy_content_risk_factor: (leadForm.product_details.energy_content_risk_factor ? new Number(leadForm.product_details.energy_content_risk_factor): null),
        exchange_rate: (leadForm.product_details.exchange_rate ? new Number(leadForm.product_details.exchange_rate): null),
        price_estimated_mwh_per_unit: (leadForm.product_details.price_estimated_mwh_per_unit ? new Number(leadForm.product_details.price_estimated_mwh_per_unit): null),
        quantity_estimated_mwh_per_unit: (leadForm.product_details.quantity_estimated_mwh_per_unit ? new Number(leadForm.product_details.quantity_estimated_mwh_per_unit): null),
        quantity_verified_mwh_per_unit: (leadForm.product_details.quantity_verified_mwh_per_unit ? new Number(leadForm.product_details.quantity_verified_mwh_per_unit): null)
      };
      // } else {
      //   lead.lead.product_details_attributes = {product_id: ''};
      // }

      // Logistics (Legs) Details BEGIN
      if (leadForm.logistics && leadForm.logistics.legs) {
        lead.lead.logistics_attributes = {};
        // Handle leg details
        lead.lead.logistics_attributes.legs_attributes = [];
        leadForm.logistics.legs.forEach(function(leg, i) {
          lead.lead.logistics_attributes.legs_attributes[i] = {};

          // If id is not an integer, don't include an id
          // This is so that we can handle new legs created in the FE (with an id containing data other than an integer)
          if (Number.isInteger(leg.id)) {
            lead.lead.logistics_attributes.legs_attributes[i].id = leg.id;
          }

          // Repopulate order values
          lead.lead.logistics_attributes.legs_attributes[i].order = i;

          // Start
          if (leg.start_type === 'Hub') {
            lead.lead.logistics_attributes.legs_attributes[i].start_hub_id = leg.start_id;
          } else if (leg.start_type === 'Site') {
            lead.lead.logistics_attributes.legs_attributes[i].start_site_id = leg.start_id;
          }
          // End
          if (leg.end_type === 'Hub') {
            lead.lead.logistics_attributes.legs_attributes[i].end_hub_id = leg.end_id;
          } else if (leg.end_type === 'Site') {
            lead.lead.logistics_attributes.legs_attributes[i].end_site_id = leg.end_id;
          }

          lead.lead.logistics_attributes.legs_attributes[i].freight_method = leg.freight_method;
          lead.lead.logistics_attributes.legs_attributes[i].freight_cost = (leg.freight_cost ? new Number(leg.freight_cost) : null);
          lead.lead.logistics_attributes.legs_attributes[i].currency = leg.currency;
          lead.lead.logistics_attributes.legs_attributes[i].exchange_rate_to_eur = (leg.exchange_rate_to_eur ? new Number(leg.exchange_rate_to_eur) : null);
          lead.lead.logistics_attributes.legs_attributes[i].quantity = (leg.quantity ? new Number(leg.quantity) : null);
          lead.lead.logistics_attributes.legs_attributes[i].pricing_unit = leg.pricing_unit;
          lead.lead.logistics_attributes.legs_attributes[i].share_of_total = (leg.share_of_total ? new Number(leg.share_of_total) : null);
          lead.lead.logistics_attributes.legs_attributes[i].credit_terms = leg.credit_terms;
          lead.lead.logistics_attributes.legs_attributes[i].incoterms_id = leg.incoterms_id;
          lead.lead.logistics_attributes.legs_attributes[i].freight_insured_by = leg.freight_insured_by;
          lead.lead.logistics_attributes.legs_attributes[i].unit_id = leg.unit_id;
          lead.lead.logistics_attributes.legs_attributes[i].additional_info = leg.additional_info;
          lead.lead.logistics_attributes.legs_attributes[i].start_address = leg.start_address;
          lead.lead.logistics_attributes.legs_attributes[i].start_country = leg.start_country;
          lead.lead.logistics_attributes.legs_attributes[i].start_contact_name = leg.start_contact_name;
          lead.lead.logistics_attributes.legs_attributes[i].start_contact_phone = leg.start_contact_phone;
          lead.lead.logistics_attributes.legs_attributes[i].start_contact_email = leg.start_contact_email;
          lead.lead.logistics_attributes.legs_attributes[i].start_agent_id = leg.start_agent_id;
          lead.lead.logistics_attributes.legs_attributes[i].start_agent_contact_name = leg.start_agent_contact_name;
          lead.lead.logistics_attributes.legs_attributes[i].start_agent_contact_phone = leg.start_agent_contact_phone;
          lead.lead.logistics_attributes.legs_attributes[i].start_agent_contact_email = leg.start_agent_contact_email;
          lead.lead.logistics_attributes.legs_attributes[i].end_address = leg.end_address;
          lead.lead.logistics_attributes.legs_attributes[i].end_country = leg.end_country;
          lead.lead.logistics_attributes.legs_attributes[i].end_contact_name = leg.end_contact_name;
          lead.lead.logistics_attributes.legs_attributes[i].end_contact_phone = leg.end_contact_phone;
          lead.lead.logistics_attributes.legs_attributes[i].end_contact_email = leg.end_contact_email;
          lead.lead.logistics_attributes.legs_attributes[i].end_agent_id = leg.end_agent_id;
          lead.lead.logistics_attributes.legs_attributes[i].end_agent_contact_name = leg.end_agent_contact_name;
          lead.lead.logistics_attributes.legs_attributes[i].end_agent_contact_phone = leg.end_agent_contact_phone;
          lead.lead.logistics_attributes.legs_attributes[i].end_agent_contact_email = leg.end_agent_contact_email;
          lead.lead.logistics_attributes.legs_attributes[i].unit_name = leg.unit_name;
          lead.lead.logistics_attributes.legs_attributes[i].date_of_departure = leg.date_of_departure;
          lead.lead.logistics_attributes.legs_attributes[i].estimated_date_of_arrival = leg.estimated_date_of_arrival;
        });

        // Delete legs
        if (leadForm.legsToDelete) {
          leadForm.legsToDelete.forEach(function(leg, i) {
            // Construct deletion list of already existing legs (those that have 'id') && where the id is an integer (new legs created in FE don't have an integer id)
            if (typeof leg.id != 'undefined' && Number.isInteger(leg.id)) {
              // console.log("Leg to delete[" + i + "] = " + leg.id);
              let legAttrs = {};
              legAttrs["id"] = leg.id;
              legAttrs["_destroy"] = true;
              lead.lead.logistics_attributes.legs_attributes.push(legAttrs);
            }
          });

          // empty deletion list after processing
          leadForm.legsToDelete = [];
        }
      }
      // Logistics (Legs) Details END

      // Additional_costs Details BEGIN
      if (leadForm.additional_costs) {
        // Handle additional_cost details
        lead.lead.additional_costs_attributes = [];
        leadForm.additional_costs.forEach(function(additional_cost, i) {
          lead.lead.additional_costs_attributes[i] = {};

          // If id is not an integer, don't include an id
          // This is so that we can handle new additional_costs created in the FE (with an id containing data other than an integer)
          if (Number.isInteger(additional_cost.id)) {
            lead.lead.additional_costs_attributes[i].id = additional_cost.id;
          }

          // Repopulate order values
          lead.lead.additional_costs_attributes[i].order = i;

          lead.lead.additional_costs_attributes[i].cost_type = additional_cost.cost_type;
          lead.lead.additional_costs_attributes[i].cost = (additional_cost.cost ? new Number(additional_cost.cost) : null);
          lead.lead.additional_costs_attributes[i].currency = additional_cost.currency;
          lead.lead.additional_costs_attributes[i].exchange_rate = (additional_cost.exchange_rate ? new Number(additional_cost.exchange_rate) : null);
          lead.lead.additional_costs_attributes[i].quantity = (additional_cost.quantity ? new Number(additional_cost.quantity) : null);additional_cost.exchange_rate;
          lead.lead.additional_costs_attributes[i].pricing_unit = additional_cost.pricing_unit;
          lead.lead.additional_costs_attributes[i].share_of_total = (additional_cost.share_of_total ? new Number(additional_cost.share_of_total) : null);
          lead.lead.additional_costs_attributes[i].additional_info = additional_cost.additional_info;
        });

        // Delete additional_costs
        if (leadForm.additional_costsToDelete) {
          leadForm.additional_costsToDelete.forEach(function(additional_cost, i) {
            // Construct deletion list of already existing additional_costs (those that have 'id') && where the id is an integer (new additional_costs created in FE don't have an integer id)
            if (typeof additional_cost.id != 'undefined' && Number.isInteger(additional_cost.id)) {
              // console.log("Additional_cost to delete[" + i + "] = " + additional_cost.id);
              let additional_costAttrs = {};
              additional_costAttrs["id"] = additional_cost.id;
              additional_costAttrs["_destroy"] = true;
              lead.lead.additional_costs_attributes.push(additional_costAttrs);
            }
          });

          // empty deletion list after processing
          leadForm.additional_costsToDelete = [];
        }
      }
      // Additional_costs Details END

      // Construct requestUrl based on the status of the lead
      // First create the url that works for a general put request (currently not implemented in the api)
      let requestUrl = apiBaseUrl + '/leads/' + leadForm.id;
      // Next add the pattern that corresponds to the lead status
      requestUrl += '/update/' + leadForm.status;
      
      var request = new Request(requestUrl, { 
        method: 'PUT', 
        headers: header,
        body: JSON.stringify(lead)
      });

      that.setState({ ...that.state, requestPending: true });
        
      fetch(request).then(function(response) {
        that.setState({ ...that.state, requestPending: false });
        if (!response.ok) {
          //throw Error(response.statusText);
          return response.json().then(err => { throw err; });
        }
        //console.log(response);
        return response.text();
      }).then(function(/*text*/) {
        //console.log(text);
        //update the list timestamp to force new data to be loaded
        // var r = confirm('Update successful. Do you want to stop editing this lead?');
        // if (r) {
        //   window.location.hash = '#/';
        // } 
        sessionStorage.setItem('leadsListUpdated', new Date().getTime() - 300000);

        // Fetch updated lead (so that all data shows is accurate)
        that.fetchLead(leadForm.id, callback);
      }).catch(function(err) {
        that.setState({ ...that.state, requestPending: false });

        // Add lead id, so we know in which lead the rror is.
        err.error.unshift({
          lead: ["Error updating lead " + leadForm.id]
        });
        parseFetchError(err);
      });
    }
  }

  updateLeads(callback) {
    var that = this;

    if (!this.state.forms || this.state.forms.length < 1) {
      console.log("No leads to save.")
      return;
    }

    this.state.forms.forEach(function (lead, i) {
      that.updateLead(lead.id, callback);
    });    
  }

  createNewNewLeadAndLink () {
    var token = localStorage.getItem('woodtracker_id_token');
    var that = this;

    var header = new Headers();
    header.append('Accept', 'application/json');
    header.append('Content-Type', 'application/json');
    header.append('Authorization', 'Bearer ' + token);

    var lead = {
      lead: initializeLead()
    };
    
    var request = new Request(apiBaseUrl + '/leads', { 
      method: 'POST', 
      headers: header,
      body: JSON.stringify(lead)
    });
    
    //prevent calling fetch if request is pending
    if (!that.state.loading) {
      that.setState({ loading: true });
      fetch(request).then(function(response) {
        if (!response.ok) {
          //throw Error(response.statusText);
          return response.json().then(err => { throw err; });
        }
        // console.log(response);
        return response.json();
      }).then(function(json) {
        // console.log(json);
        console.log("Created lead = " + json.lead.id);
        that.setState({ loading: false });
        sessionStorage.leadsListUpdated = '';
        
        // Open the newly created Lead
        // that.fetchLead(json.lead.id);
        that.fetchLead(json.lead.id, () => that.saveLeadLink(that.state.forms[0].id, json.lead.id, 'link'));
      }).catch(function(err) {
        that.setState({ loading: false });
        parseFetchError(err);
      });
    }
  }

  openLeadAsLinked (e) {
    let valueKey = e.target.id;
    let leadId = e.target.value;

    if (this.state.forms.find(elem => elem.id == leadId)) {
      console.info("Lead " + leadId + " already open. Will not open another one.");
      return;
    }

    this.fetchLead(leadId, () => this.saveLeadLink(this.state.forms[0].id, leadId, 'link'));

    // // Use setState updater callback explicitly if a callback is provided.
    // // This ensures that the state is updated before executing the callback.
    // if (callback) {
    //   // console.log("callback = " + callback);
    //   this.setState((prevState, props) => {
    //     return {...this.state,
    //       [valueKey]: leadId
    //     };
    //   }, callback);
    // } else {
    //   // console.log("no callback");
    //   this.setState({...this.state,
    //     [valueKey]: leadId
    //   });
    // }
  }

  closeLead (formIndex, callback) {
    formIndex = this.getDefaultIndex(formIndex);

    // Do nothing if no forms defined OR form array length < 1 OR formIndex > form length
    if (!this.state.forms || this.state.forms.length < 1 || formIndex > (this.state.forms.length - 1)) {
      return;
    }
    // Do nothing if forms contains < than 2 elements
    if (this.state.forms.length < 2) {
      return;
    }

    var r = confirm('Are you sure want to close and unlink this lead??');
    if (!r) {
      return
    }
    
    // Create copy = don't mutate directly
    let forms = this.state.forms.slice();
    forms.splice(formIndex, 1);

    // Handle colspans when more than one lead is shown
    this.calculateUIWidths(forms ? forms.length : 1);

    // Use setState updater callback explicitly if a callback is provided.
    // This ensures that the state is updated before executing the callback.
    if (callback) {
      // console.log("callback = " + callback);
      this.setState((prevState, props) => {
        return {
          forms: forms
        };
      }, callback);
    } else {
      // console.log("no callback");
      this.setState({
        forms: forms
      });
    }
  }

  saveLeadLink(leadOwnerId, leadTargetLinkId, linkMethod) {
    // console.log("leadOwnerId = " + leadOwnerId + ", leadTargetLinkId = " + leadTargetLinkId);
    let requestMethod;

    if (linkMethod === 'link') {
      requestMethod = 'PUT';
    } else if (linkMethod === 'unlink') {
      requestMethod = 'DELETE';
    }

    if (!requestMethod) {
      logger.error("No link method specified. Will not handle linking.");
      return;
    }

    var token = localStorage.getItem('woodtracker_id_token');
    var that = this;

    var header = new Headers();
    header.append('Accept', 'application/json');
    header.append('Content-Type', 'application/json');
    header.append('Authorization', 'Bearer ' + token);

    var lead = {
      lead: {
        linked_lead_id: leadTargetLinkId
      }
    };

    var request = new Request(apiBaseUrl + '/leads/' + leadOwnerId + '/linked_lead', { 
      method: requestMethod, 
      headers: header,
      body: JSON.stringify(lead)
    });

    //prevent calling fetch if request is pending
    if (!that.state.loading) {
      that.setState({ loading: true });
      fetch(request).then(function(response) {
        if (!response.ok) {
          //throw Error(response.statusText);
          return response.json().then(err => { throw err; });
        }
        console.log(response);
        return;
      }).then(function() {
        that.setState({ loading: false });
        sessionStorage.leadsListUpdated = '';
      }).catch(function(err) {
        that.setState({ loading: false });
        parseFetchError(err);
      });
    }
  }

  fetchLinkedLeads (leadsArray) {
    // console.log("##### fetchLinkedLeads #####");
    var that = this;

    if (!leadsArray) {
      // Default to linked leads of the first "owner" lead if no array is provided
      leadsArray = this.state.forms[0].linked_leads;
    }

    if (!leadsArray || leadsArray.length < 1) {
      return;
    }

    // console.log("Fetching " + leadsArray.length + " linked lead(s).");
    leadsArray.forEach(function (lead, i) {
      that.fetchLead(lead.id);
    });
  }

  //generic function to handle data adding into the form data
  //id is the object id within the form data
  //list is the list id within the form data where the selected valuwe is to be added
  //allowedValuesList is an optional list that contains allowed values / objects
  addLeadFormItem (formIndex, id, list, allowedValuesList) {
    formIndex = this.getDefaultIndex(formIndex);
    let form = this.getFormByIndex(formIndex);

    if (form[id].length === 0) {
      alert('Type in a value to add it into the form.');
      return;
    }
    
    var elem = document.getElementById(id);
    if (elem.className.indexOf('error') > -1) {
      elem.className = elem.className.replace('error','');
    }

    // optinal check for accepted values
    if (typeof allowedValuesList != 'undefined') {
      let foundInAllowedValuesList = false;
      allowedValuesList.forEach(function(item, i) {
        let comparison = null;
        (typeof item === 'string') ? comparison = item : comparison = item.name;
        // console.log("allowedValuesList[" + i + "] = " + comparison);
        if (comparison === form[id]) {
          // console.log("allowedValuesList[" + i + "] = " + comparison + " === " + form[id] );
          foundInAllowedValuesList = true;
        }
      });
      if (!foundInAllowedValuesList) {
        alert("\'" + form[id] + '\' is not in the list of allowed values.');
        return;
      }
    }

    form[list].push(form[id]);
    form[id] = '';
    this.setState({ 'form': form });
  }

  //generic function handle data removal from form data
  deleteLeadFormItem(e, formIndex, list) {
    formIndex = this.getDefaultIndex(formIndex);
    let form = this.getFormByIndex(formIndex);

    //confirm the deletion
    var r = confirm('Are you sure want to delete this item?');
    if (r) {
      var index = e.target.closest("ul > li").id;
      if (index) {
        // console.info("Deleting index " + index + " from list '" + list + "'.");
        form[list].splice(index, 1);
        this.setState({ 'form': form });
      } else {
        throw new Error("No index value given. Not deleting item from list '" + list + "'.");
      }
    }
  }

  // Lead END

  // File BEGIN

  fileUpload(entityType, entityId, files, callback) {
    if (!entityType) {
      console.warn("No entity type specified. Will not attempt upload.");
      return;
    }
    if (!entityId) {
      console.warn("No entity id specified. Will not attempt upload.");
      return;
    }
    if (!files) {
      console.warn("No file(s) selected. Will not attempt upload.");
      return;
    }

    var token = localStorage.getItem('woodtracker_id_token'),
      that = this;

    if (token) {
      var header = new Headers();
      header.append('Accept', 'application/json');
      // Disable 'application/json' so that the multipart header is automatically set for the request.
      // header.append('Content-Type', 'application/json');
      header.append('Authorization', 'Bearer ' + token);

      var data = new FormData();

      // Append files to upload in the request
      if (Array.isArray(files)) {
        files.forEach(function (file, i) {
          if (file) {
            console.log("Preparing files for upload [" + i + "] = " + file.name + " (size: " + file.size + " bytes) ");
            // Only append files with size set and greater than 0
            if (typeof file != 'undefined' && typeof file.size != 'undefined' && file.size > 0) {
              data.append("documents", file);
            }
          }
        });
      } else {
        let file = files;
        console.log("Preparing file for upload = " + file.name + " (size: " + file.size + " bytes) ");
        if (typeof file != 'undefined' && typeof file.size != 'undefined' && file.size > 0) {
          data.append("documents", file);
        }
      }

      var request = new Request(apiBaseUrl + '/documents/' + entityType + "/" + entityId, { 
        method: 'POST', 
        headers: header,
        body: data
      });

      that.setState({ ...that.state, requestPending: true });
        
      fetch(request).then(function(response) {
        that.setState({ ...that.state, requestPending: false });
        if (!response.ok) {
          //throw Error(response.statusText);
          return response.json().then(err => { throw err; });
        }
        //console.log(response);
        return response.json();
      }).then(function (json) {
        console.log("Uploaded file = " + json);
        // callback with the created entity (contains the id of the created entity)
        callback(json);
      }).catch(function(err) {
        that.setState({ ...that.state, requestPending: false });
        parseFetchError(err);
      });
    }
  }

  // Delete uploaded file
  fileDelete(id, callback) {
    if (!id) {
      console.warn("No id specified. Will not attempt to delete.");
      return;
    }

    //confirm the deletion
    let r = confirm('Are you sure want to delete this item?');
    if (!r) {
      return;
    }

    var token = localStorage.getItem('woodtracker_id_token'),
      that = this;

    if (token) {
      var header = new Headers();
      header.append('Accept', 'application/json');
      // Disable 'application/json' so that the multipart header is automatically set for the request.
      // header.append('Content-Type', 'application/json');
      header.append('Authorization', 'Bearer ' + token);

      var request = new Request(apiBaseUrl + '/documents/' + id, { 
        method: 'DELETE',
        headers: header
      });

      that.setState({ ...that.state, requestPending: true });
        
      fetch(request).then(function(response) {
        that.setState({ ...that.state, requestPending: false });
        if (!response.ok) {
          //throw Error(response.statusText);
          return response.json().then(err => { throw err; });
        }
        //console.log(response);
        return response.json();
      }).then(function (json) {
        console.log("Deleted file = " + json);
        // callback with the created entity (contains the id of the created entity)
        callback(json);
      }).catch(function(err) {
        that.setState({ ...that.state, requestPending: false });
        parseFetchError(err);
      });
    }
  }

  // File END

  // Date BEGIN

  handleDateLead (formIndex, UIElementId, date, callback) {
    formIndex = this.getDefaultIndex(formIndex);
    let form = this.getFormByIndex(formIndex);

    // The variable name is the same as UIElementId
    let varName = UIElementId;
    form[varName] = date;

    this.updateLeadFormInState(form, callback);
    
    var elem = document.getElementById(UIElementId);
    if (elem.className.indexOf('error') > -1) {
      elem.className = elem.className.replace('error','');
    }
  }

  handleDateLeg(formIndex, legIndex, UIElementId, date, callback) {
    formIndex = this.getDefaultIndex(formIndex);
    let form = this.getFormByIndex(formIndex);
    let leg = form.logistics.legs[legIndex];

    // The variable name is everything after the first underscore in UIElementId
    // If no underscore is present, the variable name is the same as UIElementId
    let varName = UIElementId;
    if (UIElementId.indexOf('_') > -1) {
      varName = UIElementId.substring(UIElementId.indexOf('_') + 1);
    }  
    console.log("varName = " + varName);

    leg[varName] = date;

    this.updateLegInForm(formIndex, leg.Id, leg, callback);
    
    var elem = document.getElementById(UIElementId);
    if (elem.className.indexOf('error') > -1) {
      elem.className = elem.className.replace('error','');
    }
  }

  //clear date params
  clearDate (id) {
    let formIndex = this.getDefaultIndex(formIndex);
    //reset state
    var date = new Date();

    this.setState({
      forms: {
        ...this.state.forms,
        [formIndex]: {
          ...this.state.forms[formIndex],
          date_of_delivery: date
        }
      }
    });
    
    var elem = document.getElementById(id);
    if (elem.className.indexOf('error') > -1) {
      elem.className = elem.className.replace('error','');
    } 

    //reset the input state also
    this.refs.datePickerETA.setState({ inputValue: this.state.dateInputFormat });
  }

  // Date END

  // Seller BEGIN

  selectSellerFormData (formIndex, callback) {
    // console.log("selectSellerFormData");
    formIndex = this.getDefaultIndex(formIndex);
    let form = this.getFormByIndex(formIndex);
    // console.log("Selected seller id = " + form.seller_id);

    let seller = this.state.counterparties.find(elem => elem.id == form.seller_id);
    // console.log("Selected seller = " + seller);

    // Don't allow undefined entities in the form
    if (typeof seller === 'undefined') {
      seller = initializeCounterparty();
    }

    form.seller = seller;
    // Reset contact
    form.sellerContact_name = '';
    form.sellerContact_phone = '';
    form.sellerContact_email = '';
    form.source_id = '';

    this.updateLeadFormInState(form, callback);
  }

  selectSellerContactFormData(formIndex, callback) {
    formIndex = this.getDefaultIndex(formIndex);
    let form = this.getFormByIndex(formIndex);
    // console.log("Selected seller contact = " + form.sellerContact_name);
    
    let sellerContact = this.state.counterparties.find(x => x.id == form.seller_id).contacts.find(x => x.name == form.sellerContact_name);
    // console.log("Selected seller contact = " + sellerContact);

    // Don't allow undefined entities in the form
    // if (typeof sellerContact === 'undefined') {
    //   sellerContact = initializeContact();
    // }

    if (typeof sellerContact !== 'undefined') {
      form.sellerContact_name = sellerContact.name;
      form.sellerContact_phone = sellerContact.phone;
      form.sellerContact_email = sellerContact.email;

      this.updateLeadFormInState(form, callback);
    }  
  }

  selectSellerSourceType(formIndex, callback) {
    formIndex = this.getDefaultIndex(formIndex);
    let form = this.getFormByIndex(formIndex);

    // Reset the souce selection when source type changes
    form.source_id = '';

    this.updateLeadFormInState(form, callback);
  }

  // Seller END

  // Buyer BEGIN

  selectBuyerFormData (formIndex, callback) {
    // console.log("selectBuyerFormData");
    formIndex = this.getDefaultIndex(formIndex);
    let form = this.getFormByIndex(formIndex);
    // console.log("Selected buyer id = " + form.buyer_id);

    let buyer = this.state.counterparties.find(elem => elem.id == form.buyer_id);
    // console.log("Selected buyer = " + buyer);

    // Don't allow undefined entities in the form
    if (typeof buyer === 'undefined') {
      buyer = initializeCounterparty();
    }

    form.buyer = buyer;
    // Reset contact
    form.buyerContact_name = '';
    form.buyerContact_phone = '';
    form.buyerContact_email = '';
    form.destination_id = '';

    this.updateLeadFormInState(form, callback);
  }

  selectBuyerContactFormData(formIndex, callback) {
    formIndex = this.getDefaultIndex(formIndex);
    let form = this.getFormByIndex(formIndex);
    // console.log("Selected buyer contact = " + form.buyerContact_name);
    
    let buyerContact = this.state.counterparties.find(x => x.id == form.buyer_id).contacts.find(x => x.name == form.buyerContact_name);
    // console.log("Selected buyer contact = " + buyerContact);

    // Don't allow undefined entities in the form
    // if (typeof buyerContact === 'undefined') {
    //   buyerContact = initializeContact();
    // }

    if (typeof buyerContact !== 'undefined') {
      form.buyerContact_name = buyerContact.name;
      form.buyerContact_phone = buyerContact.phone;
      form.buyerContact_email = buyerContact.email;

      this.updateLeadFormInState(form, callback);
    }  
  }

  selectBuyerDestinationType(formIndex, callback) {
    formIndex = this.getDefaultIndex(formIndex);
    let form = this.getFormByIndex(formIndex);

    // Reset the souce selection when destination type changes
    form.destination_id = '';

    this.updateLeadFormInState(form, callback);
  }

  // Buyer END

  // Product BEGIN

  //handler for 2-way data binding within Product Details
  handleProductDetailsInputChange (e, formIndex, callback) {
    // console.log(e);
    formIndex = this.getDefaultIndex(formIndex);
    var form = this.getFormByIndex(formIndex);
    let valueKey = e.target.id;
    let value;

    if (e.target.files) {
      // Handle files
      var files =  Array.from(e.target.files);

      if (typeof files != 'undefined') {
        // If only one element in the array, use it directly
        // Otherwise use the whole array
        if (files.length === 1) {
          // console.log("file = " + files[0].name);
          value = files[0];
        } else {
          // files.forEach(function(file, i) {
          //   console.log("files[" + i + "] = " + file.name);
          // });
          value = files;
        }

        // Clear the input field value, because the actual value is managed elsewhere (in the form)
        e.target.value = null;
      }
    } else if (e.target.selectedOptions) {
      // Multiple select options
      // console.log("handleLeadInputChange -> e.target.selectedOptions = " + e.target.selectedOptions);
      var values = [];
      for (var i = 0, l = e.target.selectedOptions.length; i < l; i++) {
        if (e.target.selectedOptions[i].selected) {
          values.push(e.target.selectedOptions[i].value);
        }
      }

      // If only one selection, just use one value without array
      value = (values.length === 1 ? values[0] : values);
      // form[valueKey] = (values.length === 1 ? values[0] : values);
    } else {
      // Normal value
      // console.log("handleLeadInputChange -> e.target.value = " + e.target.value);
      value = e.target.value;
      // form[valueKey] = e.target.value;
    }
    
    //check if the input has error class set to it and remove it
    if (e.target.className.indexOf('error') > -1) {
      e.target.className = e.target.className.replace('error','');
    }

    form.product_details[valueKey] = value;
    
    this.updateLeadFormInState(form, callback);
  }
  
  selectProductFormData(formIndex, callback) {
    formIndex = this.getDefaultIndex(formIndex);
    let form = this.getFormByIndex(formIndex);
    // console.log("Selected product id = " + this.state.forms[formIndex].product_details.product_id);

    // var product = this.state.products.find(elem => elem.id == this.state.forms[formIndex].product_details.product_id);
    // console.log("Selected product = " + product);

    // Reset the product type selection when product changes
    form.product_details.product_type = '';

    this.updateLeadFormInState(form, callback);
  }

  fileUploadProductDetails(leadFormIndex) {
    this.fileUpload('product_details', this.state.forms[leadFormIndex].product_details.id, this.state.forms[leadFormIndex].product_details.productDocument, (createdDocuments)=>this.addProductDetailsFileFormItem(leadFormIndex, 'productDocument', 'documents', createdDocuments));
  }

  fileDeleteProductDetails(e, leadFormIndex) {
    let entityIndex = e.target.closest("ul > li").id;
    this.fileDelete(this.state.forms[leadFormIndex].product_details['documents'][entityIndex].id, (deletedDocuments)=>this.deleteProductDetailsFileFormItem(entityIndex, leadFormIndex, 'documents', deletedDocuments));
  }

  //sourceId is the object id within the form data
  //listId is the list id within the form data where the selected valuwe is to be added
  addProductDetailsFileFormItem(formIndex, sourceId, listId, createdDocuments, callback) {
    formIndex = this.getDefaultIndex(formIndex);
    var form = this.getFormByIndex(formIndex);

    // createdDocuments should contain id(s) of the created entity(s)
    if (createdDocuments && createdDocuments.documents) {
      for (let i = 0; i < createdDocuments.documents.length; i++) {
        let fileWrapper = {};

        fileWrapper.id = createdDocuments.documents[0].id;
        fileWrapper.file_name = createdDocuments.documents[0].file_name;

        form.product_details[listId].push(fileWrapper);
      }

      // Clear the input field value, because it has been added to the list already
      form.product_details[sourceId] = '';
    }

    this.updateLeadFormInState(form, callback);
  }

  // deletionList contains the files to delete on the server
  deleteProductDetailsFileFormItem(entityIndex, formIndex, list, deletedDocuments, callback) {
    formIndex = this.getDefaultIndex(formIndex);
    var form = this.getFormByIndex(formIndex);

    // deletedDocuments should contain id(s) of the created entity(s)
    if (deletedDocuments && deletedDocuments.document) {
      if (entityIndex) {
        form.product_details[list].splice(entityIndex, 1);
        this.updateLeadFormInState(form, callback);
      }
    } 
  }

  // Product END

  // Certificate BEGIN

  fileUploadCertificates(leadFormIndex) {
    this.fileUpload('lead', this.state.forms[leadFormIndex].id, this.state.forms[leadFormIndex].certificateDocument, (createdDocuments)=>this.addCertificatesFileFormItem(leadFormIndex, 'certificateDocument', 'lead_certificates', createdDocuments));
  }

  fileDeleteCertificates(e, leadFormIndex) {
    let entityIndex = e.target.closest("ul > li").id;
    this.fileDelete(this.state.forms[leadFormIndex]['lead_certificates'][entityIndex].id, (deletedDocuments)=>this.deleteCertificatesFileFormItem(entityIndex, leadFormIndex, 'lead_certificates', deletedDocuments));
  }

  //sourceId is the object id within the form data
  //listId is the list id within the form data where the selected valuwe is to be added
  addCertificatesFileFormItem(formIndex, sourceId, listId, createdDocuments, callback) {
    formIndex = this.getDefaultIndex(formIndex);
    var form = this.getFormByIndex(formIndex);

    // createdDocuments should contain id(s) of the created entity(s)
    if (createdDocuments && createdDocuments.documents) {
      for (let i = 0; i < createdDocuments.documents.length; i++) {
        let fileWrapper = {};

        fileWrapper.id = createdDocuments.documents[0].id;
        fileWrapper.file_name = createdDocuments.documents[0].file_name;

        form[listId].push(fileWrapper);
      }

      // Clear the input field value, because it has been added to the list already
      form[sourceId] = '';
    }

    this.updateLeadFormInState(form, callback);
  }

  // deletionList contains the files to delete on the server
  deleteCertificatesFileFormItem(entityIndex, formIndex, list, deletedDocuments, callback) {
    formIndex = this.getDefaultIndex(formIndex);
    var form = this.getFormByIndex(formIndex);

    // deletedDocuments should contain id(s) of the created entity(s)
    if (deletedDocuments && deletedDocuments.document) {
      if (entityIndex) {
        form[list].splice(entityIndex, 1);
        this.updateLeadFormInState(form, callback);
      }
    } 
  }

  // Certificate END

  // Legs BEGIN

  //handler for 2-way data binding within Leads
  handleLegInputChange (e, formIndex, legId, callback) {
    // console.log(e);
    // console.log("handleLegInputChange");
    // console.log("handleLegInputChange -> callbak = " + callback);
    formIndex = this.getDefaultIndex(formIndex);
    let form = this.getFormByIndex(formIndex);
    let valueKey = e.target.id;
    let value;
    
    if (e.target.files) {
      // Handle files
      var files =  Array.from(e.target.files);

      if (typeof files != 'undefined') {
        // If only one element in the array, use it directly
        // Otherwise use the whole array
        if (files.length === 1) {
          // console.log("file = " + files[0].name);
          value = files[0];
        } else {
          // files.forEach(function(file, i) {
          //   console.log("files[" + i + "] = " + file.name);
          // });
          value = files;
        }

        // Clear the input field value, because the actual value is managed elsewhere (in the form)
        e.target.value = null;
      }
    } else if (e.target.selectedOptions) {
      // Multiple select options
      // console.log("handleLegInputChange -> e.target.selectedOptions = " + e.target.selectedOptions);
      var values = [];
      for (var i = 0, l = e.target.selectedOptions.length; i < l; i++) {
        if (e.target.selectedOptions[i].selected) {
          values.push(e.target.selectedOptions[i].value);
        }
      }

      // If only one selection, just use one value without array
      value = (values.length === 1 ? values[0] : values);
      // form[valueKey] = (values.length === 1 ? values[0] : values);
    } else {
      // Normal value
      // console.log("handleLegInputChange -> e.target.value = " + e.target.value);
      value = e.target.value;
      // form[valueKey] = e.target.value;
    }

    let leg = form.logistics.legs.find(elem => elem.id == legId);

    if (leg) {
      leg[valueKey] = value;
    }
    
    //check if the input has error class set to it and remove it
    if (e.target.className.indexOf('error') > -1) {
      e.target.className = e.target.className.replace('error','');
    }

    this.updateLeadFormInState(form, callback);
  }

  updateLegInForm (formIndex, legKey, leg, callback) {
    formIndex = this.getDefaultIndex(formIndex);
    let form = this.getFormByIndex(formIndex);

    if (form.logistics && form.logistics.legs) {
      form.logistics.legs.forEach(function(itemLeg, i) {
        if (itemLeg.id == legKey) {
          form.logistics.legs[i] = leg;
        }
      });
    }

    this.updateLeadFormInState(form, callback)
  }

  updateLegValueInForm (formIndex, legId, valueKey, value, callback) {
    formIndex = this.getDefaultIndex(formIndex);
    let form = this.getFormByIndex(formIndex);

    let leg = form.logistics.legs.find(elem => elem.id == legId);

    if (leg) {
      leg[valueKey] = value;
    }

    this.updateLeadFormInState(form, callback)
  }

  updateLogisticsForLead(leadId, callback) {
    // console.log("Updating logistics for lead = " + leadId);
    
    //send new user form
    var token = localStorage.getItem('woodtracker_id_token'),
      that = this;

    if (token) {
      var header = new Headers();
      header.append('Accept', 'application/json');
      header.append('Content-Type', 'application/json');
      header.append('Authorization', 'Bearer ' + token);

      let leadForm = this.state.forms.find(elem => elem.id == leadId);
      
      var lead = {
        lead: {
        }
      };

      // Logistics (Legs) Details BEGIN
      if (leadForm.logistics && leadForm.logistics.legs) {
        lead.lead.logistics_attributes = {};
        // Handle leg details
        lead.lead.logistics_attributes.legs_attributes = [];
        leadForm.logistics.legs.forEach(function(leg, i) {
          lead.lead.logistics_attributes.legs_attributes[i] = {};

          // If id is not an integer, don't include an id
          // This is so that we can handle new legs created in the FE (with an id containing data other than an integer)
          if (Number.isInteger(leg.id)) {
            lead.lead.logistics_attributes.legs_attributes[i].id = leg.id;
          }

          // Repopulate order values
          lead.lead.logistics_attributes.legs_attributes[i].order = i;

          // Start
          if (leg.start_type === 'Hub') {
            lead.lead.logistics_attributes.legs_attributes[i].start_hub_id = leg.start_id;
          } else if (leg.start_type === 'Site') {
            lead.lead.logistics_attributes.legs_attributes[i].start_site_id = leg.start_id;
          }
          // End
          if (leg.end_type === 'Hub') {
            lead.lead.logistics_attributes.legs_attributes[i].end_hub_id = leg.end_id;
          } else if (leg.end_type === 'Site') {
            lead.lead.logistics_attributes.legs_attributes[i].end_site_id = leg.end_id;
          }

          lead.lead.logistics_attributes.legs_attributes[i].freight_method = leg.freight_method;
          lead.lead.logistics_attributes.legs_attributes[i].freight_cost = (leg.freight_cost ? new Number(leg.freight_cost) : null);
          lead.lead.logistics_attributes.legs_attributes[i].currency = leg.currency;
          lead.lead.logistics_attributes.legs_attributes[i].exchange_rate_to_eur = (leg.exchange_rate_to_eur ? new Number(leg.exchange_rate_to_eur) : null);
          lead.lead.logistics_attributes.legs_attributes[i].quantity = (leg.quantity ? new Number(leg.quantity) : null);
          lead.lead.logistics_attributes.legs_attributes[i].pricing_unit = leg.pricing_unit;
          lead.lead.logistics_attributes.legs_attributes[i].share_of_total = (leg.share_of_total ? new Number(leg.share_of_total) : null);
          lead.lead.logistics_attributes.legs_attributes[i].credit_terms = leg.credit_terms;
          lead.lead.logistics_attributes.legs_attributes[i].incoterms_id = leg.incoterms_id;
          lead.lead.logistics_attributes.legs_attributes[i].freight_insured_by = leg.freight_insured_by;
          lead.lead.logistics_attributes.legs_attributes[i].unit_id = leg.unit_id;
          lead.lead.logistics_attributes.legs_attributes[i].additional_info = leg.additional_info;
          lead.lead.logistics_attributes.legs_attributes[i].start_address = leg.start_address;
          lead.lead.logistics_attributes.legs_attributes[i].start_country = leg.start_country;
          lead.lead.logistics_attributes.legs_attributes[i].start_contact_name = leg.start_contact_name;
          lead.lead.logistics_attributes.legs_attributes[i].start_contact_phone = leg.start_contact_phone;
          lead.lead.logistics_attributes.legs_attributes[i].start_contact_email = leg.start_contact_email;
          lead.lead.logistics_attributes.legs_attributes[i].start_agent_id = leg.start_agent_id;
          lead.lead.logistics_attributes.legs_attributes[i].start_agent_contact_name = leg.start_agent_contact_name;
          lead.lead.logistics_attributes.legs_attributes[i].start_agent_contact_phone = leg.start_agent_contact_phone;
          lead.lead.logistics_attributes.legs_attributes[i].start_agent_contact_email = leg.start_agent_contact_email;
          lead.lead.logistics_attributes.legs_attributes[i].end_address = leg.end_address;
          lead.lead.logistics_attributes.legs_attributes[i].end_country = leg.end_country;
          lead.lead.logistics_attributes.legs_attributes[i].end_contact_name = leg.end_contact_name;
          lead.lead.logistics_attributes.legs_attributes[i].end_contact_phone = leg.end_contact_phone;
          lead.lead.logistics_attributes.legs_attributes[i].end_contact_email = leg.end_contact_email;
          lead.lead.logistics_attributes.legs_attributes[i].end_agent_id = leg.end_agent_id;
          lead.lead.logistics_attributes.legs_attributes[i].end_agent_contact_name = leg.end_agent_contact_name;
          lead.lead.logistics_attributes.legs_attributes[i].end_agent_contact_phone = leg.end_agent_contact_phone;
          lead.lead.logistics_attributes.legs_attributes[i].end_agent_contact_email = leg.end_agent_contact_email;
          lead.lead.logistics_attributes.legs_attributes[i].unit_name = leg.unit_name;
          lead.lead.logistics_attributes.legs_attributes[i].date_of_departure = leg.date_of_departure;
          lead.lead.logistics_attributes.legs_attributes[i].estimated_date_of_arrival = leg.estimated_date_of_arrival;
        });

        // Delete legs
        if (leadForm.legsToDelete) {
          leadForm.legsToDelete.forEach(function(leg, i) {
            // Construct deletion list of already existing legs (those that have 'id') && where the id is an integer (new legs created in FE don't have an integer id)
            if (typeof leg.id != 'undefined' && Number.isInteger(leg.id)) {
              // console.log("Leg to delete[" + i + "] = " + leg.id);
              let legAttrs = {};
              legAttrs["id"] = leg.id;
              legAttrs["_destroy"] = true;
              lead.lead.logistics_attributes.legs_attributes.push(legAttrs);
            }
          });

          // empty deletion list after processing
          leadForm.legsToDelete = [];
        }
      }
      // Logistics (Legs) Details END

      // Construct requestUrl based on the status of the lead
      // First create the url that works for a general put request (currently not implemented in the api)
      let requestUrl = apiBaseUrl + '/leads/' + leadForm.id;
      // Next add the pattern that corresponds to the lead status
      requestUrl += '/update/' + leadForm.status;
      
      var request = new Request(requestUrl, { 
        method: 'PUT', 
        headers: header,
        body: JSON.stringify(lead)
      });

      that.setState({ ...that.state, requestPending: true });
        
      fetch(request).then(function(response) {
        that.setState({ ...that.state, requestPending: false });
        if (!response.ok) {
          //throw Error(response.statusText);
          return response.json().then(err => { throw err; });
        }
        //console.log(response);
        return response.json();
      }).then(function(json) {
        //console.log(json);
        //update the list timestamp to force new data to be loaded
        // var r = confirm('Update successful. Do you want to stop editing this lead?');
        // if (r) {
        //   window.location.hash = '#/';
        // } 
        sessionStorage.setItem('leadsListUpdated', new Date().getTime() - 300000);

        let lead = json[Object.keys(json)[0]];

        // Lead - populate values
        lead = that.populateLead(lead);

        preserveExpanded(leadForm.logistics.legs, lead.logistics.legs);
        leadForm.logistics = lead.logistics;

        // Legs - populate data (Start/end type, agent objects, calculations, etc.)
        that.populateLogisticsValues(leadForm, leadForm.logistics);


        that.updateLeadFormInState(leadForm, callback);
      }).catch(function(err) {
        that.setState({ ...that.state, requestPending: false });

        // Add lead id, so we know in which lead the rror is.
        err.error.unshift({
          lead: ["Error updating lead " + leadForm.id]
        });
        parseFetchError(err);
      });
    }
  }

  selectLegPointFormData (formIndex, legId, pointType, pointId) {
    formIndex = this.getDefaultIndex(formIndex);
    let form = this.getFormByIndex(formIndex);

    let leg = form.logistics.legs.find(elem => elem.id == legId);

    // This makes it so that giving an empty ('') the value gets propagated to all components watching it.
    if (typeof pointId !== 'undefined') {
      if (pointType === 'start') {
        leg.start_id = pointId;
      } else if (pointType === 'end') {
        leg.end_id = pointId;
      }
    }

    // console.log("Selected " + pointType + " id = " + pointId);

    let point;

    if (pointType === 'start') {
      if (leg.start_type === 'Hub') {
        point = this.state.hubs.find(elem => elem.id == pointId);
      } else if (leg.start_type === 'Site') {
        point = this.state.sites.find(elem => elem.id == pointId);
      }
      // console.log("Selected " + pointType + " point (" + leg.start_type + ") = " + point);
    } else if (pointType === 'end') {
      if (leg.end_type === 'Hub') {
        point = this.state.hubs.find(elem => elem.id == pointId);
      } else if (leg.end_type === 'Site') {
        point = this.state.sites.find(elem => elem.id == pointId);
      }
      // console.log("Selected " + pointType + " (" + leg.end_type + ") = " + point);
    }

    if (pointType === 'start') {
      if (point) {
        // Include zip & city in the address field - this might need to be changed later
        leg.start_address = point.address + ((point.zip_code || point.city) ? ', ' + (point.zip_code ? point.zip_code : '') + ' ' + (point.city ? point.city : '') : '');
        leg.start_country = point.country;
      } else {
        leg.start_address = '';
        leg.start_country = '';
      }
    } else if (pointType === 'end') {
      if (point) {
        // Include zip & city in the address field - this might need to be changed later
        leg.end_address = point.address + ((point.zip_code || point.city) ? ', ' + (point.zip_code ? point.zip_code : '') + ' ' + (point.city ? point.city : '') : '');
        leg.end_country = point.country;
      } else {
        leg.end_address = '';
        leg.end_country = '';
      }
    }

    // Reset connected values
    if (pointType === 'start') {
      leg.start_contact_name = '';
      leg.start_contact_phone = '';
      leg.start_contact_email = '';
      leg.start_agent_id = '';
      leg.start_agent_contact_name = '';
      leg.start_agent_contact_phone = '';
      leg.start_agent_contact_email = '';
    } else if (pointType === 'end') {
      leg.end_contact_name = '';
      leg.end_contact_phone = '';
      leg.end_contact_email = '';
      leg.end_agent_id = '';
      leg.end_agent_contact_name = '';
      leg.end_agent_contact_phone = '';
      leg.end_agent_contact_email = '';
    }

    this.updateLegInForm(formIndex, legId, leg);
  }

  selectLegPointContactFormData (formIndex, legId, pointType, pointContact_name) {
    formIndex = this.getDefaultIndex(formIndex);
    let form = this.getFormByIndex(formIndex);

    let leg = form.logistics.legs.find(elem => elem.id == legId);

    // This makes it so that giving an empty ('') the value gets propagated to all components watching it.
    if (typeof pointContact_name !== 'undefined') {
      if (pointType === 'start') {
        leg.start_contact_name = pointContact_name;
      } else if (pointType === 'end') {
        leg.end_contact_name = pointContact_name;
      }
    }

    // console.log("Selected " + pointType + " contact = " + pointContact_name);
    
    let pointContact;
    
    if (pointType === 'start') {
      if (leg.start_type === 'Hub') {
        pointContact = this.state.hubs.find(x => x.id == leg.start_id).contacts.find(x => x.name == leg.start_contact_name);
      } else if (leg.start_type === 'Site') {
        pointContact = this.state.sites.find(x => x.id == leg.start_id).contacts.find(x => x.name == leg.start_contact_name);
      }
    } else if (pointType === 'end') {
      if (leg.end_type === 'Hub') {
        pointContact = this.state.hubs.find(x => x.id == leg.end_id).contacts.find(x => x.name == leg.end_contact_name);
      } else if (leg.end_type === 'Site') {
        pointContact = this.state.sites.find(x => x.id == leg.end_id).contacts.find(x => x.name == leg.end_contact_name);
      }
    }
    
    // Don't allow undefined entities in the form
    // if (typeof pointContact === 'undefined') {
    //   pointContact = initializeContact();
    // }
    // console.log("Selected " + pointType + " contact = " + pointContact.name);

    if (typeof pointContact !== 'undefined') {
      if (pointType === 'start') {
        leg.start_contact_name = pointContact.name;
        leg.start_contact_phone = pointContact.phone;
        leg.start_contact_email = pointContact.email;
      } else if (pointType === 'end') {
        leg.end_contact_name = pointContact.name;
        leg.end_contact_phone = pointContact.phone;
        leg.end_contact_email = pointContact.email;
      }

      this.updateLegInForm(formIndex, legId, leg);
    }  
  }

  selectLegPointAgentFormData (formIndex, legId, pointType, pointId) {
    formIndex = this.getDefaultIndex(formIndex);
    let form = this.getFormByIndex(formIndex);

    let leg = form.logistics.legs.find(elem => elem.id == legId);

    // Reset connected values
    if (pointType === 'start') {
      leg.start_agent_contact_name = '';
      leg.start_agent_contact_phone = '';
      leg.start_agent_contact_email = '';
    } else if (pointType === 'end') {
      leg.end_agent_contact_name = '';
      leg.end_agent_contact_phone = '';
      leg.end_agent_contact_email = '';
    }

    this.updateLegInForm(formIndex, legId, leg);
  }

  selectLegPointAgentContactFormData (formIndex, legId, pointType, pointAgentId, pointAgentContact_name) {
    formIndex = this.getDefaultIndex(formIndex);
    let form = this.getFormByIndex(formIndex);

    let leg = form.logistics.legs.find(elem => elem.id == legId);

    // This makes it so that giving an empty ('') the value gets propagated to all components watching it.
    if (typeof pointAgentContact_name !== 'undefined') {
      if (pointType === 'start') {
        leg.start_agent_contact_name = pointAgentContact_name;
      } else if (pointType === 'end') {
        leg.end_agent_contact_name = pointAgentContact_name;
      }
    }

    // console.log("Selected " + pointType + " agent_contact = " + pointAgentContact_name);
    
    let pointAgentContact;
    
    if (pointType === 'start') {
      if (leg.start_type === 'Hub') {
        let pointAgent = this.state.hubs.find(x => x.id == leg.start_id).agents.find(x => x.id == pointAgentId);
        ((pointAgent && pointAgent.contacts) ? pointAgentContact = pointAgent.contacts.find(x => x.name == pointAgentContact_name) : false);
      } else if (leg.start_type === 'Site') {
        let pointAgent = this.state.sites.find(x => x.id == leg.start_id).agents.find(x => x.id == pointAgentId);
        ((pointAgent && pointAgent.contacts) ? pointAgentContact = pointAgent.contacts.find(x => x.name == pointAgentContact_name) : false);
      }
    } else if (pointType === 'end') {
      if (leg.end_type === 'Hub') {
        let pointAgent = this.state.hubs.find(x => x.id == leg.end_id).agents.find(x => x.id == pointAgentId);
        ((pointAgent && pointAgent.contacts) ? pointAgentContact = pointAgent.contacts.find(x => x.name == pointAgentContact_name) : false);
      } else if (leg.end_type === 'Site') {
        let pointAgent = this.state.sites.find(x => x.id == leg.end_id).agents.find(x => x.id == pointAgentId);
        ((pointAgent && pointAgent.contacts) ? pointAgentContact = pointAgent.contacts.find(x => x.name == pointAgentContact_name) : false);
      }
    }
    
    // Don't allow undefined entities in the form
    // if (typeof pointAgentContact === 'undefined') {
    //   pointAgentContact = initializeAgentContact();
    // }
    // console.log("Selected " + pointType + " agent_contact = " + pointAgentContact.name);

    if (typeof pointAgentContact !== 'undefined') {
      if (pointType === 'start') {
        leg.start_agent_contact_name = pointAgentContact.name;
        leg.start_agent_contact_phone = pointAgentContact.phone;
        leg.start_agent_contact_email = pointAgentContact.email;
      } else if (pointType === 'end') {
        leg.end_agent_contact_name = pointAgentContact.name;
        leg.end_agent_contact_phone = pointAgentContact.phone;
        leg.end_agent_contact_email = pointAgentContact.email;
      }

      this.updateLegInForm(formIndex, legId, leg);
    }  
  }

  populateLogisticsValues(lead, logistics) {
    if (logistics && logistics.legs) {
      for (let i = 0; i < logistics.legs.length; i++) {
        this.populateLegValues(lead, logistics.legs[i]);
      }
    }

    // Do all Lead calculations
    this.calculateLeadEnergyContentsAndPrices(lead);

    // return logistics;
  }
  
  populateLegValues(lead, leg) {
    if (leg) {
      // Start/end type & id
      (leg.start_site_id ? (leg.start_id = leg.start_site_id) : (leg.start_id = leg.start_hub_id));
      (leg.start_site_id ? (leg.start_type = 'Site') : (leg.start_type = 'Hub'));
      (leg.end_site_id ? (leg.end_id = leg.end_site_id) : (leg.end_id = leg.end_hub_id));
      (leg.end_site_id ? (leg.end_type = 'Site') : (leg.end_type = 'Hub'));
      // Agent (+ agent contact)
      if (leg.start_agent) {
        leg.start_agent_id = leg.start_agent.id;
        leg.start_agent_contact_name = leg.start_agent.contact_name;
        leg.start_agent_contact_phone = leg.start_agent.contact_phone;
        leg.start_agent_contact_email = leg.start_agent.contact_email;
      }
      if (leg.end_agent) {
        leg.end_agent_id = leg.end_agent.id;
        leg.end_agent_contact_name = leg.end_agent.contact_name;
        leg.end_agent_contact_phone = leg.end_agent.contact_phone;
        leg.end_agent_contact_email = leg.end_agent.contact_email;
      }  
      // Calculations
      // leg = calculateLegCosts(lead, leg);

      // Use a Date object
      (leg.date_of_departure ? leg.date_of_departure = new Date(leg.date_of_departure) : false);
      (leg.estimated_date_of_arrival ? leg.estimated_date_of_arrival = new Date(leg.estimated_date_of_arrival) : false);
    }

    // return leg;
  }

  createNewLeg(leadId, legs, callback) {
    // console.log("Creating new leg(s) for lead = " + leadId);
    
    //send new user form
    var token = localStorage.getItem('woodtracker_id_token'),
      that = this;

    if (token) {
      var header = new Headers();
      header.append('Accept', 'application/json');
      header.append('Content-Type', 'application/json');
      header.append('Authorization', 'Bearer ' + token);

      let leadForm = this.state.forms.find(elem => elem.id == leadId);

      var lead = {
        lead: {
        }
      };

      // Logistics (Legs) Details BEGIN
      if (legs) {
        lead.lead.logistics_attributes = {};
        // Handle leg details
        lead.lead.logistics_attributes.legs_attributes = [];
        legs.forEach(function(leg, i) {
          lead.lead.logistics_attributes.legs_attributes[i] = {};

          // If id is not an integer, don't include an id
          // This is so that we can handle new legs created in the FE (with an id containing data other than an integer)
          if (Number.isInteger(leg.id)) {
            lead.lead.logistics_attributes.legs_attributes[i].id = leg.id;
          }

          // Make sure order is set
          if (!leg.order || !Number.isInteger(leg.order)) {
            lead.lead.logistics_attributes.legs_attributes[i].order = i;
          } else {
            lead.lead.logistics_attributes.legs_attributes[i].order = leg.order;
          }
        });
      }
      // Logistics (Legs) Details END

      // Construct requestUrl based on the status of the lead
      // First create the url that works for a general put request (currently not implemented in the api)
      let requestUrl = apiBaseUrl + '/leads/' + leadId + '/logistics';
      
      var request = new Request(requestUrl, { 
        method: 'POST', 
        headers: header,
        body: JSON.stringify(lead)
      });

      that.setState({ ...that.state, requestPending: true });
        
      fetch(request).then(function(response) {
        that.setState({ ...that.state, requestPending: false });
        if (!response.ok) {
          //throw Error(response.statusText);
          return response.json().then(err => { throw err; });
        }
        //console.log(response);
        return response.json();
      }).then(function(json) {
        // console.log(json);
        //update the list timestamp to force new data to be loaded
        // var r = confirm('Update successful. Do you want to stop editing this lead?');
        // if (r) {
        //   window.location.hash = '#/';
        // } 
        sessionStorage.setItem('leadsListUpdated', new Date().getTime() - 300000);

        // Fetch updated lead (so that all data shows is accurate)
        // that.fetchLead(leadForm.id);
        // Lead - populate values
        json.lead = that.populateLead(json.lead);

        // Legs - populate data (Start/end type, agent objects, calculations, etc.)
        that.populateLogisticsValues(json.lead, json.lead.logistics);
        preserveExpanded(leadForm.logistics.legs, json.lead.logistics.legs);
        
        // Add new item to list (don't touch existing items)
        if (!leadForm.logistics) {
          leadForm.logistics = {};
        }
        if (!leadForm.logistics.legs) {
          leadForm.logistics.legs = [];
        }
        if (json.lead.logistics && json.lead.logistics.legs) {
          for (let i = 0; i < json.lead.logistics.legs.length; i++) {
            let exists = false;
            for (let j = 0; j < leadForm.logistics.legs.length; j++) {
              if (json.lead.logistics.legs[i].id === leadForm.logistics.legs[j].id) {
                exists = true;
                break;
              }
            }

            if (!exists) {
              // Make sure the newly created item(s) are expanded in the UI
              json.lead.logistics.legs[i].expanded = true;
              leadForm.logistics.legs.push(json.lead.logistics.legs[i]);
            }
          }
        }

        that.updateLeadFormInState(leadForm, callback);
      }).catch(function(err) {
        that.setState({ ...that.state, requestPending: false });

        // Add lead id, so we know in which lead the rror is.
        err.error.unshift({
          lead: ["Error updating leg(s) for lead " + leadId]
        });
        parseFetchError(err);
      });
    }
  }

  // Adds a leg to the end of the legs array in lead's logistics object.
  addLeg (formIndex, callback) {
    formIndex = this.getDefaultIndex(formIndex);
    let form = this.getFormByIndex(formIndex);

    if (!form.logistics.legs) {
      form.logistics.legs = [];
    }
    if (!form.logistics) {
      form.logistics = {
        legs: []
      }
    }

    let leg = initalizeLeg("newLeg" + form.logistics.legs.length);
    leg.order = form.logistics.legs.length;
    leg.expanded = true;
    
    // Push to the UI array (not yet saved in the backend)
    // form.logistics.legs.push(leg);

    // Update FE form, don't save
    // this.updateLeadFormInState(form, callback);
    // Update / save logistics
    this.createNewLeg(form.id, [leg], callback);
  }

  // Removes a leg from the legs array in lead's logistics object.
  // Does not save the lead
  removeLeg (formIndex, legId, callback) {
    var r = confirm('Are you sure want to delete this leg?');
    if (r) {
      formIndex = this.getDefaultIndex(formIndex);
      let form = this.getFormByIndex(formIndex);

      // Keep track of deleted legs
      if (!form.legsToDelete) {
        form.legsToDelete = [];
      }

      if (form.logistics && form.logistics.legs) {
        form.logistics.legs.forEach(function(leg, i) {
          //   console.log("legs[" + i + "] = " + leg.id);

          if (leg.id === legId) {
            form.logistics.legs.splice(i, 1);

            // If id is not an integer, don't include an id
            // This is so that we can handle new legs created in the FE (with an id containing data other than an integer)
            if (Number.isInteger(leg.id)) { 
              // Keep track of deleted legs - the ones whose deletion to push to the backend
              form.legsToDelete.push(leg);
            }
          }
        });

        // this.updateLeadFormInState(form, callback);
      }

      this.updateLogisticsForLead(form.id);
    }
  }

  // Reorders legs in the legs array in lead's logistics object.
  // Does not save the legs array or the lead
  reorderLegs (formIndex, legId, before, callback) {
    formIndex = this.getDefaultIndex(formIndex);
    let form = this.getFormByIndex(formIndex);

    if (form.logistics && form.logistics.legs && form.logistics.legs.length > 1) {
      reorderItemsInArray(form.logistics.legs, legId, before);
      // this.updateLeadFormInState(form, callback);
      this.updateLogisticsForLead(form.id);
    }
  }

  fileUploadLeg(leadFormIndex, legIndex) {
    this.fileUpload('leg', this.state.forms[leadFormIndex].logistics.legs[legIndex].id, this.state.forms[leadFormIndex].logistics.legs[legIndex].legDocument, (createdDocuments)=>this.addLegFileFormItem(leadFormIndex, legIndex, 'legDocument', 'documents', createdDocuments));
  }

  fileDeleteLeg(e, leadFormIndex, legIndex) {
    let entityIndex = e.target.closest("ul > li").id;
    this.fileDelete(this.state.forms[leadFormIndex].logistics.legs[legIndex]['documents'][entityIndex].id, (deletedDocuments)=>this.deleteLegFileFormItem(entityIndex, leadFormIndex, legIndex, 'documents', deletedDocuments));
  }

  //sourceId is the object id within the form data
  //listId is the list id within the form data where the selected valuwe is to be added
  addLegFileFormItem(formIndex, legIndex, sourceId, listId, createdDocuments, callback) {
    formIndex = this.getDefaultIndex(formIndex);
    var form = this.getFormByIndex(formIndex);

    // createdDocuments should contain id(s) of the created entity(s)
    if (createdDocuments && createdDocuments.documents) {
      for (let i = 0; i < createdDocuments.documents.length; i++) {
        let fileWrapper = {};

        fileWrapper.id = createdDocuments.documents[0].id;
        fileWrapper.file_name = createdDocuments.documents[0].file_name;

        form.logistics.legs[legIndex][listId].push(fileWrapper);
      }

      // Clear the input field value, because it has been added to the list already
      form.logistics.legs[legIndex][sourceId] = '';
    }

    this.updateLeadFormInState(form, callback);
  }

  // deletionList contains the files to delete on the server
  deleteLegFileFormItem(entityIndex, formIndex, legIndex, list, deletedDocuments, callback) {
    formIndex = this.getDefaultIndex(formIndex);
    var form = this.getFormByIndex(formIndex);

    // deletedDocuments should contain id(s) of the created entity(s)
    if (deletedDocuments && deletedDocuments.document) {
      if (entityIndex) {
        form.logistics.legs[legIndex][list].splice(entityIndex, 1);
        this.updateLeadFormInState(form, callback);
      }
    } 
  }

  // Legs END

  // Additional_cost BEGIN

  //handler for 2-way data binding within Leads
  handleAdditional_costInputChange (e, formIndex, Additional_costId, callback) {
    // console.log(e);
    // console.log("handleAdditional_costInputChange");
    // console.log("handleAdditional_costInputChange -> callbak = " + callback);
    formIndex = this.getDefaultIndex(formIndex);
    let form = this.getFormByIndex(formIndex);
    let valueKey = e.target.id;
    let value;
    
    if (e.target.files) {
      // Handle files
      var files =  Array.from(e.target.files);

      if (typeof files != 'undefined') {
        // If only one element in the array, use it directly
        // Otherwise use the whole array
        if (files.length === 1) {
          // console.log("file = " + files[0].name);
          value = files[0];
        } else {
          // files.forEach(function(file, i) {
          //   console.log("files[" + i + "] = " + file.name);
          // });
          value = files;
        }

        // Clear the input field value, because the actual value is managed elsewhere (in the form)
        e.target.value = null;
      }
    } else if (e.target.selectedOptions) {
      // Multiple select options
      // console.log("handleAdditional_costInputChange -> e.target.selectedOptions = " + e.target.selectedOptions);
      var values = [];
      for (var i = 0, l = e.target.selectedOptions.length; i < l; i++) {
        if (e.target.selectedOptions[i].selected) {
          values.push(e.target.selectedOptions[i].value);
        }
      }

      // If only one selection, just use one value without array
      value = (values.length === 1 ? values[0] : values);
      // form[valueKey] = (values.length === 1 ? values[0] : values);
    } else {
      // Normal value
      // console.log("handleAdditional_costInputChange -> e.target.value = " + e.target.value);
      value = e.target.value;
      // form[valueKey] = e.target.value;
    }

    let Additional_cost = form.additional_costs.find(elem => elem.id == Additional_costId);

    if (Additional_cost) {
      Additional_cost[valueKey] = value;
    }
    
    //check if the input has error class set to it and remove it
    if (e.target.className.indexOf('error') > -1) {
      e.target.className = e.target.className.replace('error','');
    }

    this.updateLeadFormInState(form, callback);
  }
  
  updateAdditional_costInForm (formIndex, additional_costKey, additional_cost, callback) {
    formIndex = this.getDefaultIndex(formIndex);
    let form = this.getFormByIndex(formIndex);

    if (form.additional_costs) {
      form.additional_costs.forEach(function(itemAdditional_cost, i) {
        if (itemAdditional_cost.id == additional_costKey) {
          form.additional_costs[i] = additional_cost;
        }
      });
    }

    this.updateLeadFormInState(form, callback)
  }

  updateAdditional_costValueInForm (formIndex, additional_costId, valueKey, value, callback) {
    formIndex = this.getDefaultIndex(formIndex);
    let form = this.getFormByIndex(formIndex);

    let additional_cost = form.additional_costs.find(elem => elem.id == additional_costId);

    if (additional_cost) {
      additional_cost[valueKey] = value;
    }

    this.updateLeadFormInState(form, callback)
  }

  updateLeadAdditional_costsForLead(leadId, callback) {
    // console.log("Updating additional_costs in lead = " + leadId);
    
    //send new user form
    var token = localStorage.getItem('woodtracker_id_token'),
      that = this;

    if (token) {
      var header = new Headers();
      header.append('Accept', 'application/json');
      header.append('Content-Type', 'application/json');
      header.append('Authorization', 'Bearer ' + token);

      let leadForm = this.state.forms.find(elem => elem.id == leadId);

      var lead = {
        lead: {
        }
      };

      // Additional_costs Details BEGIN
      if (leadForm.additional_costs) {
        // Handle additional_cost details
        lead.lead.additional_costs_attributes = [];
        leadForm.additional_costs.forEach(function(additional_cost, i) {
          lead.lead.additional_costs_attributes[i] = {};

          // If id is not an integer, don't include an id
          // This is so that we can handle new additional_costs created in the FE (with an id containing data other than an integer)
          if (Number.isInteger(additional_cost.id)) {
            lead.lead.additional_costs_attributes[i].id = additional_cost.id;
          }

          // Repopulate order values
          lead.lead.additional_costs_attributes[i].order = i;

          lead.lead.additional_costs_attributes[i].cost_type = additional_cost.cost_type;
          lead.lead.additional_costs_attributes[i].cost = (additional_cost.cost ? new Number(additional_cost.cost) : null);
          lead.lead.additional_costs_attributes[i].currency = additional_cost.currency;
          lead.lead.additional_costs_attributes[i].exchange_rate = (additional_cost.exchange_rate ? new Number(additional_cost.exchange_rate) : null);
          lead.lead.additional_costs_attributes[i].quantity = (additional_cost.quantity ? new Number(additional_cost.quantity) : null);additional_cost.exchange_rate;
          lead.lead.additional_costs_attributes[i].pricing_unit = additional_cost.pricing_unit;
          lead.lead.additional_costs_attributes[i].share_of_total = (additional_cost.share_of_total ? new Number(additional_cost.share_of_total) : null);
          lead.lead.additional_costs_attributes[i].additional_info = additional_cost.additional_info;
        });

        // Delete additional_costs
        if (leadForm.additional_costsToDelete) {
          leadForm.additional_costsToDelete.forEach(function(additional_cost, i) {
            // Construct deletion list of already existing additional_costs (those that have 'id') && where the id is an integer (new additional_costs created in FE don't have an integer id)
            if (typeof additional_cost.id != 'undefined' && Number.isInteger(additional_cost.id)) {
              // console.log("Additional_cost to delete[" + i + "] = " + additional_cost.id);
              let additional_costAttrs = {};
              additional_costAttrs["id"] = additional_cost.id;
              additional_costAttrs["_destroy"] = true;
              lead.lead.additional_costs_attributes.push(additional_costAttrs);
            }
          });

          // empty deletion list after processing
          leadForm.additional_costsToDelete = [];
        }
      }
      // Additional_costs Details END

      // Construct requestUrl based on the status of the lead
      // First create the url that works for a general put request (currently not implemented in the api)
      let requestUrl = apiBaseUrl + '/leads/' + leadForm.id;
      // Next add the pattern that corresponds to the lead status
      requestUrl += '/update/' + leadForm.status;
      
      var request = new Request(requestUrl, { 
        method: 'PUT', 
        headers: header,
        body: JSON.stringify(lead)
      });

      that.setState({ ...that.state, requestPending: true });
        
      fetch(request).then(function(response) {
        that.setState({ ...that.state, requestPending: false });
        if (!response.ok) {
          //throw Error(response.statusText);
          return response.json().then(err => { throw err; });
        }
        //console.log(response);
        return response.json();
      }).then(function(json) {
        //console.log(text);
        //update the list timestamp to force new data to be loaded
        // var r = confirm('Update successful. Do you want to stop editing this lead?');
        // if (r) {
        //   window.location.hash = '#/';
        // } 
        sessionStorage.setItem('leadsListUpdated', new Date().getTime() - 300000);

        let lead = json[Object.keys(json)[0]];

        // Lead - populate values
        lead = that.populateLead(lead);

        preserveExpanded(leadForm.additional_costs, lead.additional_costs);
        leadForm.additional_costs = lead.additional_costs;

        // Legs - populate data (Start/end type, agent objects, calculations, etc.)
        that.populateAdditional_costsValues(leadForm, leadForm.additional_costs);

        that.updateLeadFormInState(leadForm, callback);
      }).catch(function(err) {
        that.setState({ ...that.state, requestPending: false });

        // Add lead id, so we know in which lead the rror is.
        err.error.unshift({
          lead: ["Error updating lead " + leadForm.id]
        });
        parseFetchError(err);
      });
    }
  }

  populateAdditional_costsValues(lead, additional_costs) {
    if (additional_costs) {
      for (let i = 0; i < additional_costs.length; i++) {
        this.populateAdditional_costValues(lead, additional_costs[i]);
      }
    }

    // Do all Lead calculations
    this.calculateLeadEnergyContentsAndPrices(lead);

    // return additional_costs;
  }
  
  populateAdditional_costValues(lead, additional_cost) {
    if (additional_cost) {
      // Calculations
      // additional_cost = calculateAdditional_costCosts(lead, additional_cost);
    }

    // return additional_cost;
  }

  createNewAdditional_cost(leadId, additional_costs, callback) {
    // console.log("Creating new leg(s) for lead = " + leadId);
    
    //send new user form
    var token = localStorage.getItem('woodtracker_id_token'),
      that = this;

    if (token) {
      var header = new Headers();
      header.append('Accept', 'application/json');
      header.append('Content-Type', 'application/json');
      header.append('Authorization', 'Bearer ' + token);

      let leadForm = this.state.forms.find(elem => elem.id == leadId);

      var lead = {
        lead: {
        }
      };

      // Additional_costs Details BEGIN
      if (additional_costs) {
        // Handle additional_cost details
        lead.lead.additional_costs_attributes = [];
        additional_costs.forEach(function(additional_cost, i) {
          lead.lead.additional_costs_attributes[i] = {};

          // If id is not an integer, don't include an id
          // This is so that we can handle new additional_costs created in the FE (with an id containing data other than an integer)
          if (Number.isInteger(additional_cost.id)) {
            lead.lead.additional_costs_attributes[i].id = additional_cost.id;
          }

          // Make sure order is set
          if (!additional_cost.order || !Number.isInteger(additional_cost.order)) {
            lead.lead.additional_costs_attributes[i].order = i;
          } else {
            lead.lead.additional_costs_attributes[i].order = additional_cost.order;
          }
        });
      }
      // Additional_costs Details END

      // Construct requestUrl based on the status of the lead
      // First create the url that works for a general put request (currently not implemented in the api)
      let requestUrl = apiBaseUrl + '/leads/' + leadId + '/additional_costs';
      
      var request = new Request(requestUrl, { 
        method: 'POST', 
        headers: header,
        body: JSON.stringify(lead)
      });

      that.setState({ ...that.state, requestPending: true });
        
      fetch(request).then(function(response) {
        that.setState({ ...that.state, requestPending: false });
        if (!response.ok) {
          //throw Error(response.statusText);
          return response.json().then(err => { throw err; });
        }
        //console.log(response);
        return response.json();
      }).then(function(json) {
        console.log(json);
        //update the list timestamp to force new data to be loaded
        // var r = confirm('Update successful. Do you want to stop editing this lead?');
        // if (r) {
        //   window.location.hash = '#/';
        // } 
        sessionStorage.setItem('leadsListUpdated', new Date().getTime() - 300000);

        // Fetch updated lead (so that all data shows is accurate)
        // that.fetchLead(leadForm.id);
        // Lead - populate values
        json.lead = that.populateLead(json.lead);

        // Additional_costs - populate values (calculations, etc.)
        that.populateAdditional_costsValues(json.lead, json.lead.additional_costs);
        preserveExpanded(leadForm.additional_costs, json.lead.additional_costs);

        // Add new item to list (don't touch existing items)
        if (!leadForm.additional_costs) {
          leadForm.additional_costs = [];
        }
        if (json.lead.additional_costs) {
          for (let i = 0; i < json.lead.additional_costs.length; i++) {
            let exists = false;
            for (let j = 0; j < leadForm.additional_costs.length; j++) {
              if (json.lead.additional_costs[i].id === leadForm.additional_costs[j].id) {
                exists = true;
                break;
              }
            }

            if (!exists) {
              // Make sure the newly created item(s) are expanded in the UI
              json.lead.additional_costs[i].expanded = true;
              leadForm.additional_costs.push(json.lead.additional_costs[i]);
            }
          }
        }  

        that.updateLeadFormInState(leadForm, callback);
      }).catch(function(err) {
        that.setState({ ...that.state, requestPending: false });

        // Add lead id, so we know in which lead the rror is.
        err.error.unshift({
          lead: ["Error updating additional_cost(s) for lead " + leadId]
        });
        parseFetchError(err);
      });
    }
  }

  // Adds an additional_cost to the additional_cost array in the lead object.
  addAdditional_cost (formIndex, callback) {
    formIndex = this.getDefaultIndex(formIndex);
    let form = this.getFormByIndex(formIndex);

    if (!form.additional_costs) {
      form.additional_costs = [];
    }

    let additional_cost = initalizeAdditional_cost("newAdditional_cost" + form.additional_costs.length);
    additional_cost.order = form.additional_costs.length;
    additional_cost.expanded = true;
    
    // Push to the UI array (not yet saved in the backend)
    // form.additional_costs.push(additional_cost);

    // Update FE form, don't save
    // this.updateLeadFormInState(form, callback);
    // Update / save additional_costs
    this.createNewAdditional_cost(form.id, [additional_cost], callback);
  }

  // Removes an additional_cost from the additional_cost array in the lead object.
  // Does not save the lead
  removeAdditional_cost (formIndex, additional_costId, callback) {
    var r = confirm('Are you sure want to delete this additional_cost?');
    if (r) {
      formIndex = this.getDefaultIndex(formIndex);
      let form = this.getFormByIndex(formIndex);

      // Keep track of deleted additional_costs
      if (!form.additional_costsToDelete) {
        form.additional_costsToDelete = [];
      }

      if (form.additional_costs) {
        form.additional_costs.forEach(function(additional_cost, i) {
          // console.log("additional_costs[" + i + "] = " + additional_cost.id);

          if (additional_cost.id === additional_costId) {
            form.additional_costs.splice(i, 1);

            // If id is not an integer, don't include an id
            // This is so that we can handle new additional_costs created in the FE (with an id containing data other than an integer)
            if (Number.isInteger(additional_cost.id)) { 
              // Keep track of deleted additional_costs - the ones whose deletion to push to the backend
              form.additional_costsToDelete.push(additional_cost);
            }
          }
        });

        // this.updateLeadFormInState(form, callback);
      }

      this.updateLeadAdditional_costsForLead(form.id);
    }
  }

  // Reorders additional_costs in the additional_cost array in the lead object.
  // Does not save the additional_costs array or the lead
  reorderAdditional_costs (formIndex, additional_costId, before, callback) {
    formIndex = this.getDefaultIndex(formIndex);
    let form = this.getFormByIndex(formIndex);

    if (form.additional_costs && form.additional_costs.length > 1) {
      reorderItemsInArray(form.additional_costs, additional_costId, before);
      // this.updateLeadFormInState(form, callback);
      this.updateLeadAdditional_costsForLead(form.id);
    }
  }

  fileUploadAdditional_cost(leadFormIndex, additional_costIndex) {
    this.fileUpload('additional_cost', this.state.forms[leadFormIndex].additional_costs[additional_costIndex].id, this.state.forms[leadFormIndex].additional_costs[additional_costIndex].additional_costDocument, (createdDocuments)=>this.addAdditional_costFileFormItem(leadFormIndex, additional_costIndex, 'additional_costDocument', 'documents', createdDocuments));
  }

  fileDeleteAdditional_cost(e, leadFormIndex, additional_costIndex) {
    let entityIndex = e.target.closest("ul > li").id;
    this.fileDelete(this.state.forms[leadFormIndex].additional_costs[additional_costIndex]['documents'][entityIndex].id, (deletedDocuments)=>this.deleteAdditional_costFileFormItem(entityIndex, leadFormIndex, additional_costIndex, 'documents', deletedDocuments));
  }

  //sourceId is the object id within the form data
  //listId is the list id within the form data where the selected valuwe is to be added
  addAdditional_costFileFormItem(formIndex, additional_costIndex, sourceId, listId, createdDocuments, callback) {
    formIndex = this.getDefaultIndex(formIndex);
    var form = this.getFormByIndex(formIndex);

    // createdDocuments should contain id(s) of the created entity(s)
    if (createdDocuments && createdDocuments.documents) {
      for (let i = 0; i < createdDocuments.documents.length; i++) {
        let fileWrapper = {};

        fileWrapper.id = createdDocuments.documents[0].id;
        fileWrapper.file_name = createdDocuments.documents[0].file_name;

        form.additional_costs[additional_costIndex][listId].push(fileWrapper);
      }

      // Clear the input field value, because it has been added to the list already
      form.additional_costs[additional_costIndex][sourceId] = '';
    }

    this.updateLeadFormInState(form, callback);
  }

  // deletionList contains the files to delete on the server
  deleteAdditional_costFileFormItem(entityIndex, formIndex, additional_costIndex, list, deletedDocuments, callback) {
    formIndex = this.getDefaultIndex(formIndex);
    var form = this.getFormByIndex(formIndex);

    // deletedDocuments should contain id(s) of the created entity(s)
    if (deletedDocuments && deletedDocuments.document) {
      if (entityIndex) {
        form.additional_costs[additional_costIndex][list].splice(entityIndex, 1);
        this.updateLeadFormInState(form, callback);
      }
    } 
  }

  // Additional_cost END

  // Calculate BEGIN

  calculateLeadEnergyContentsAndPrices (form, callback) {
    let lead = calculateLeadEnergyContentsAndPrices(form);

    // for (let i = 0; i < form.logistics.legs.length; i++) {
    //   calculateLegCosts(form, form.logistics.legs[i]);
    // }

    // for (let i = 0; i < form.additional_costs.length; i++) {
    //   calculateAdditional_costCosts(form, form.additional_costs[i]);
    // }

    form = lead;

    this.updateLeadFormInState(form, callback);
  }

  // calculateLogisticsLegCosts (formIndex, leg) {
  //   formIndex = this.getDefaultIndex(formIndex);
  //   let lead = this.state.forms[formIndex];
  //   leg = calculateLegCosts(lead, leg);

  //   this.updateLegInForm(formIndex, leg.id, leg);
  // }

  // calculateAdditional_costCosts (formIndex, additional_cost) {
  //   formIndex = this.getDefaultIndex(formIndex);
  //   let lead = this.state.forms[formIndex];
  //   additional_cost = calculateAdditional_costCosts(lead, additional_cost);

  //   this.updateAdditional_costInForm(formIndex, additional_cost.id, additional_cost);
  // }

  // Calculate END
  
  render() {
    // Dynamic width (+ padding between columns) for 'subsection-content'
    var subsectionStyle = {};
    (this.state.colspan_sectionContentPercentage ? (subsectionStyle.width = this.state.colspan_sectionContentPercentage) : null);
    (this.state.padding_between_leads ? (subsectionStyle.paddingRight = this.state.padding_between_leads) : null);

    return (
      <div className={"detail-page leadView" + ((this.state.requestPending) ? " busy-blocking" : '')}>
        <ActionBar elements={this.buttons} visibility={this.state.buttonVisibility} />

        {/* Status change checklist modal */}
        <FormComponent 
          key={'statusChangeChecklist'} //{(this.state.timestamp + i)}
          close={() => this.closeModal('statusChangeChecklist')} 
          formId={'statusChangeChecklist'} 
          toggle={() => this.toggleModal('statusChangeChecklist')} 
          visible={this.state['showModal_' + 'statusChangeChecklist']}
          highlightStatusRow={this.state['showModal_' + 'statusChangeChecklist' + '_highlightStatusRow']} />

        {/* Credit terms datalist */}
        <datalist id="creditTermsDataList">
          {this.state.creditTerms &&
            this.state.creditTerms.map(function (item, i) {
              return <option key={i} value={item.name} />;
            }, this)
          }
        </datalist>
        
        
        {(this.state.loading) ?
          <div className="detail-page-form loading" style={{ width: this.state.width_detailPageForm }} />
          :
          <div className="detail-page-form" style={{ width: this.state.width_detailPageForm }}>
        
            {/* <div className="TODO">
              <ul>
                <li>counterparties = {this.state.counterparties ? this.state.counterparties.length: 'undefined'}</li>
                <li>products = {this.state.products ? this.state.products.length : 'undefined'}</li>
                <li>certificates = {this.state.certificates ? this.state.certificates.length : 'undefined'}</li>
                <li>hubs = {his.state.hubs ? this.state.hubs.length : 'undefined'}</li>
                <li>sites = {this.state.sites ? this.state.sites.length : 'undefined'}</li>
              </ul>
            </div> */}

            <h1 className="detail-page-header">
              Lead
            </h1>

            <Form>
              <FormGroup conrolId="newLeadInformation">

                {/* Lead view header BEGIN */}                
                <div id="lead-header" className="subsection-detail-holder">
                  
                  {/* Lead navigation BEGIN */}
                  <Row className="lead-navigation text-center">
                    <Col sm={10}>
                      <span><Link onClick={(event) => this.leadNavigationScroll(event, '#section-counterparties')}>Counterparties</Link></span>
                      <span><Link onClick={(event) => this.leadNavigationScroll(event, '#section-product')}>Product details</Link></span>
                      <span><Link onClick={(event) => this.leadNavigationScroll(event, '#section-certificates')}>Certificates</Link></span>
                      <span><Link onClick={(event) => this.leadNavigationScroll(event, '#section-logistics')}>Logistics</Link></span>
                      <span><Link onClick={(event) => this.leadNavigationScroll(event, '#section-additional_costs')}>Additional costs</Link></span>
                    </Col>
                    <Col sm={2}>
                      <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-lead-close">{'Open existing lead' + (this.state.forms && this.state.forms.length > 0 ? ' and link to lead ' + this.state.forms[0].id : '')}</Tooltip>} delayShow={this.state.tooltipDelayShow}>
                        <div>
                          <FormControl id="openLeadAsLinked" className="lead-nav-control" componentClass="select" placeholder="link lead" value={this.state.openLeadAsLinked != undefined ? this.state.openLeadAsLinked : ''} onChange={(event) => this.openLeadAsLinked(event)}>
                            <option value=''></option>
                            {this.state.leads.map((item, i) => (
                              <option value={item.id} key={'lead' + item.id}>{'Lead ' + item.id}</option>
                            ))}
                          </FormControl>
                        </div>
                      </OverlayTrigger>
                    </Col>  
                  </Row>
                  {/* Lead navigation END */}

                  {/* Lead id & status form BEGIN */}
                  <div id="section-lead">
                    <div className='collapsible-content'>

                      <Row id="lead-details-header-summary" className={'collapsible-content' + (this.state.viewMode_leadDetailsHeader === 'summary' ? '' : ' closed')} >
                      <Row className="element-padding bottom">
                          <Col className='subsection-label lead-status-toggle' sm={this.state.colspan_sectionLabel}>
                            <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-lead-close">Click to expand lead details header</Tooltip>} delayShow={this.state.tooltipDelayShow}>
                              <Link onClick={(event) => this.toggleLeadDetailsHeaderVisibility(event, 'full')}><span className="icon ion-ios-arrow-down" /> Lead ID</Link>
                            </OverlayTrigger>
                          </Col>
                          {this.state.forms.map((leadForm, leadFormIndex) => (
                            <Col key={'leadId' + leadForm.id} className='subsection-content' sm={this.state.colspan_sectionContent} style={subsectionStyle}>
                              <Row className="lead-id-compressed">
                                <Col sm={7} className='element-padding horizontal'>
                                  <Col sm={12} className='element-padding right'>  
                                    <span className="lead-id-header element-padding right">{(this.state.forms[leadFormIndex].id) ? 'Lead ' + this.state.forms[leadFormIndex].id : 'New lead'}</span>

                                    <span className="lead-id-header-sub element-padding horizontal">{(this.state.forms[leadFormIndex].seller && this.state.forms[leadFormIndex].seller.name) ? 'From ' + this.state.forms[leadFormIndex].seller.name + ' ' : false}{(this.state.forms[leadFormIndex].buyer && this.state.forms[leadFormIndex].buyer.name) ? 'to ' + this.state.forms[leadFormIndex].buyer.name : false}</span>
                                  </Col>
                                  <Col sm={12} className='element-padding right'>  
                                    <span className="lead-id-header-sub element-padding right">Ind.: <span className="stronger">{(this.state.forms[leadFormIndex].product_details.sumTotalEnergyContentIndicative) ? this.state.forms[leadFormIndex].product_details.sumTotalEnergyContentIndicative.toFixed(2) : '--.--'} MWh
                                    ({(this.state.forms[leadFormIndex].product_details.sumPriceTotalPerMWhIndicative) ? this.state.forms[leadFormIndex].product_details.sumPriceTotalPerMWhIndicative.toFixed(2) : '--.--'} € / MWh)</span></span>
                                    
                                    <span className="lead-id-header-sub element-padding right">Ver.: <span className="stronger">{(this.state.forms[leadFormIndex].product_details.sumTotalEnergyContentVerified) ? this.state.forms[leadFormIndex].product_details.sumTotalEnergyContentVerified.toFixed(2) : '--.--'} MWh
                                    ({(this.state.forms[leadFormIndex].product_details.sumPriceTotalPerMWhVerified) ? this.state.forms[leadFormIndex].product_details.sumPriceTotalPerMWhVerified.toFixed(2) : '--.--'} € / MWh)</span></span>
                                  </Col>
                                </Col>
                                <Col sm={3} className='element-padding horizontal'>
                                  <FormControl id="status" className="lead-id-control" componentClass="select" placeholder="select" value={this.state.forms[leadFormIndex].status != undefined ? this.state.forms[leadFormIndex].status : ''} onChange={(event) => this.handleLeadInputChange(event, leadFormIndex, () => this.toggleModal('statusChangeChecklist', this.state.forms[leadFormIndex].status))} >
                                    {this.state.statuses.map((item, i) => (
                                      <option value={item.id} key={i}>{item.name}</option>
                                    ))}
                                  </FormControl>
                                </Col>
                                <Col sm={2} className='element-padding horizontal'>
                                  {/* Don't allow closing the first ("owner") Lead */ }
                                  {(this.state.forms && this.state.forms.length > 1 && this.state.forms[0].id != leadForm.id) ?
                                    <Col>
                                      <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-lead-close">{'Unlink lead ' + leadForm.id}</Tooltip>} delayShow={this.state.tooltipDelayShow}>
                                        <Button className={'lead-header-control bgRed ion-link'} onClick={() => this.closeLead(leadFormIndex, () => this.saveLeadLink(this.state.forms[0].id, leadForm.id, 'unlink'))}></Button>
                                      </OverlayTrigger>
                                    </Col>
                                    : false
                                  }  
                                  <Col>
                                    <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-lead-close">{'Save lead ' + leadForm.id}</Tooltip>} delayShow={this.state.tooltipDelayShow}>
                                      <Button className={'lead-header-control bgGreen ion-checkmark'} onClick={() => this.updateLead(leadForm.id)}></Button>
                                    </OverlayTrigger>
                                  </Col>
                                </Col>
                              </Row>
                            </Col>
                          ))}
                        </Row>
                      </Row>  

                      <Row id="lead-details-header-full" className={'collapsible-content' + (this.state.viewMode_leadDetailsHeader === 'full' ? '' : ' closed')} >
                        
                        {/* Lead details form BEGIN */}
                        <Row className={'element-padding bottom'} >
                          <Col className='subsection-label lead-status-toggle' sm={this.state.colspan_sectionLabel}>
                            <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-lead-close">Click to collapse lead details header</Tooltip>} delayShow={this.state.tooltipDelayShow}>
                              <Link onClick={(event) => this.toggleLeadDetailsHeaderVisibility(event, 'summary')}><span className="icon ion-ios-arrow-up" /> Lead ID</Link>
                            </OverlayTrigger>
                          </Col>
                          {this.state.forms.map((leadForm, leadFormIndex) => (
                            <Col key={'leadId' + leadForm.id} className='subsection-content' sm={this.state.colspan_sectionContent} style={subsectionStyle}>
                              <Row className="lead-id">
                                <Col sm={7} className='element-padding horizontal'>
                                  <Col sm={12} className='element-padding right'>  
                                    <span className="lead-id-header element-padding right">{(this.state.forms[leadFormIndex].id) ? 'Lead ' + this.state.forms[leadFormIndex].id : 'New lead'}</span>
                                  </Col>
                                  <Col sm={12} className='element-padding right'>  
                                    <span className="lead-id-header-sub element-padding right">{(this.state.forms[leadFormIndex].seller && this.state.forms[leadFormIndex].seller.name) ? 'From ' + this.state.forms[leadFormIndex].seller.name + ' ' : false}{(this.state.forms[leadFormIndex].buyer && this.state.forms[leadFormIndex].buyer.name) ? 'to ' + this.state.forms[leadFormIndex].buyer.name : false}</span>
                                  </Col>
                                </Col>
                                <Col sm={3} className='element-padding horizontal'>
                                  <FormControl id="status" className="lead-id-control" componentClass="select" placeholder="select" value={this.state.forms[leadFormIndex].status != undefined ? this.state.forms[leadFormIndex].status : ''} onChange={(event) => this.handleLeadInputChange(event, leadFormIndex, () => this.toggleModal('statusChangeChecklist', this.state.forms[leadFormIndex].status))} >
                                    {this.state.statuses.map((item, i) => (
                                      <option value={item.id} key={i}>{item.name}</option>
                                    ))}
                                  </FormControl>
                                </Col>
                                <Col sm={2} className='element-padding horizontal'>
                                  {/* Don't allow closing the first ("owner") Lead */ }
                                  {(this.state.forms && this.state.forms.length > 1 && this.state.forms[0].id != leadForm.id) ?
                                    <Col>
                                      <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-lead-close">{'Unlink lead ' + leadForm.id}</Tooltip>} delayShow={this.state.tooltipDelayShow}>
                                        <Button className={'lead-header-control bgRed ion-link'} onClick={() => this.closeLead(leadFormIndex, () => this.saveLeadLink(this.state.forms[0].id, leadForm.id, 'unlink'))}></Button>
                                      </OverlayTrigger>
                                    </Col>
                                    : false
                                  }  
                                  <Col>
                                    <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-lead-close">{'Save lead ' + leadForm.id}</Tooltip>} delayShow={this.state.tooltipDelayShow}>
                                      <Button className={'lead-header-control bgGreen ion-checkmark'} onClick={() => this.updateLead(leadForm.id)}></Button>
                                    </OverlayTrigger>
                                  </Col>
                                </Col>
                              </Row>
                            </Col>
                          ))}
                        </Row>

                        <Row id="lead-status-summary" className={'collapsible-content' + (this.state.viewMode_leadStatus === 'summary' ? '' : ' closed')}>
                          <Col className='subsection-label lead-status-toggle' sm={this.state.colspan_sectionLabel}>
                            <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-lead-close">Click to show lead status</Tooltip>} delayShow={this.state.tooltipDelayShow}>
                              <Link onClick={(event) => this.toggleLeadStatusVisibility(event, 'full')}><span className="icon ion-ios-arrow-down" /> Lead Summary</Link>
                            </OverlayTrigger>
                          </Col>
                          {this.state.forms.map((leadForm, leadFormIndex) => (
                            <Col key={'leadStatus' + leadForm.id} className='subsection-content element-padding horizontal' sm={this.state.colspan_sectionContent} style={subsectionStyle}>
                              
                              {/* Indicative summary BEGIN */}
                              <Col sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                                <Col className="info-status" sm={12}>
                                  <Row className="info-status-header-small">Current indicative total cost: </Row>
                                  <Row className={"info-status-header" + (!this.state.forms[leadFormIndex].product_details.sumPriceTotalPerMWhIndicative ? ' unspecified' : '')}>{(this.state.forms[leadFormIndex].product_details.sumPriceTotalPerMWhIndicative) ? this.state.forms[leadFormIndex].product_details.sumPriceTotalPerMWhIndicative.toFixed(2) : '--.--'} € / MWh
                                  </Row>
                                </Col>
                              </Col>
                              {/* Indicative summary END */}

                              {/* Verified summary BEGIN */}                            
                              <Col sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                                <Col className="info-status" sm={12}>
                                  <Row className="info-status-header-small">Verified total cost: </Row>
                                  <Row className={"info-status-header" + (!this.state.forms[leadFormIndex].product_details.sumPriceTotalPerMWhVerified ? ' unspecified' : '')}>{(this.state.forms[leadFormIndex].product_details.sumPriceTotalPerMWhVerified) ? this.state.forms[leadFormIndex].product_details.sumPriceTotalPerMWhVerified.toFixed(2) : '--.--'} € / MWh
                                  </Row>
                                </Col>
                              </Col>
                              {/* Verified summary END */}  
                              
                            </Col>
                          ))}
                        </Row>

                        <Row id="lead-status-full" className={'collapsible-content' + (this.state.viewMode_leadStatus === 'full' ? '' : ' closed')}>
                          <Col className='subsection-label lead-status-toggle' sm={this.state.colspan_sectionLabel}>
                            <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-lead-close">Click to show lead summary</Tooltip>} delayShow={this.state.tooltipDelayShow}>
                              <Link onClick={(event) => this.toggleLeadStatusVisibility(event, 'summary')}><span className="icon ion-ios-arrow-up" /> Lead Status</Link>
                            </OverlayTrigger>
                          </Col>
                          {this.state.forms.map((leadForm, leadFormIndex) => (
                            <Col key={'leadStatus' + leadForm.id} className='subsection-content element-padding horizontal' sm={this.state.colspan_sectionContent} style={subsectionStyle}>

                              {/* Indicative status BEGIN */}
                              <Col sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>

                                <Col className="info-status" sm={12}>
                                  <Row className="info-status-header-small">Total energy content (indicative): </Row>
                                  <Row className={"info-status-header" + (!this.state.forms[leadFormIndex].product_details.sumTotalEnergyContentIndicative ? ' unspecified' : '')}>{(this.state.forms[leadFormIndex].product_details.sumTotalEnergyContentIndicative) ? this.state.forms[leadFormIndex].product_details.sumTotalEnergyContentIndicative.toFixed(2) : '--.--'} MWh
                                  </Row>
                                </Col>
                                
                                <Col className="info-status" sm={12}>
                                  <Row className="info-status-header-small">Current indicative total cost: </Row>
                                  <Row className={"info-status-header" + (!this.state.forms[leadFormIndex].product_details.sumPriceTotalPerMWhIndicative ? ' unspecified' : '')}>{(this.state.forms[leadFormIndex].product_details.sumPriceTotalPerMWhIndicative) ? this.state.forms[leadFormIndex].product_details.sumPriceTotalPerMWhIndicative.toFixed(2) : '--.--'} € / MWh
                                  </Row>
                                </Col>

                                <Col className="info-status" sm={12}>                            
                                  <Row className={'' + (!this.state.forms[leadFormIndex].product_details.sumPerMWhIndicative ? ' unspecified' : '')}>
                                    <Col sm={6}>
                                      <span className="info-status-header-small">Product price: </span>
                                    </Col>
                                    <Col sm={6}>
                                      <span className="info-status-header-small" >{(this.state.forms[leadFormIndex].product_details.sumPerMWhIndicative) ? this.state.forms[leadFormIndex].product_details.sumPerMWhIndicative.toFixed(2) : '--.--'} € / MWh</span>
                                    </Col>
                                  </Row>
                                  <Row className={(!this.state.forms[leadFormIndex].product_details.sumLogisticsPerMWhIndicative ? ' unspecified' : '')}>
                                    <Col sm={6}>
                                      <span className="info-status-header-small">Logistics: </span>
                                    </Col>
                                    <Col sm={6}>
                                      <span className="info-status-header-small">{(this.state.forms[leadFormIndex].product_details.sumLogisticsPerMWhIndicative) ? this.state.forms[leadFormIndex].product_details.sumLogisticsPerMWhIndicative.toFixed(2) : '--.--'} € / MWh</span>
                                    </Col>
                                  </Row>
                                  <Row className={(!this.state.forms[leadFormIndex].product_details.sumAdditionalCostsPerMWhIndicative ? ' unspecified' : '')}>
                                    <Col sm={6}>
                                      <span className="info-status-header-small">Additional costs: </span>
                                    </Col>
                                    <Col sm={6}>
                                      <span className="info-status-header-small">{(this.state.forms[leadFormIndex].product_details.sumAdditionalCostsPerMWhIndicative) ? this.state.forms[leadFormIndex].product_details.sumAdditionalCostsPerMWhIndicative.toFixed(2) : '--.--'} € / MWh</span>
                                    </Col>
                                  </Row>
                                  <Row className={(!this.state.forms[leadFormIndex].product_details.sumTotalEnergyCostIndicative ? ' unspecified' : '')}>
                                    <Col sm={6}>
                                      <span className="info-status-header-small">Total price euros: </span>
                                    </Col>
                                    <Col sm={6}>
                                      <span className="info-status-header-small">{(this.state.forms[leadFormIndex].product_details.sumTotalEnergyCostIndicative) ? this.state.forms[leadFormIndex].product_details.sumTotalEnergyCostIndicative.toFixed(2) : '--.--'} €</span>
                                    </Col>
                                  </Row>
                                </Col>

                              </Col>
                              {/* Indicative status END */}

                              {/* Verified status BEGIN */}                              
                              <Col sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                                
                                <Col className="info-status" sm={12}>
                                  <Row className="info-status-header-small">Total energy content (verified): </Row>
                                  <Row className={"info-status-header" + (!this.state.forms[leadFormIndex].product_details.sumTotalEnergyContentVerified ? ' unspecified' : '')}>{(this.state.forms[leadFormIndex].product_details.sumTotalEnergyContentVerified) ? this.state.forms[leadFormIndex].product_details.sumTotalEnergyContentVerified.toFixed(2) : '--.--'} MWh
                                  </Row>
                                </Col>
                                
                                <Col className="info-status" sm={12}>
                                  <Row className="info-status-header-small">Verified total cost: </Row>
                                  <Row className={"info-status-header" + (!this.state.forms[leadFormIndex].product_details.sumPriceTotalPerMWhVerified ? ' unspecified' : '')}>{(this.state.forms[leadFormIndex].product_details.sumPriceTotalPerMWhVerified) ? this.state.forms[leadFormIndex].product_details.sumPriceTotalPerMWhVerified.toFixed(2) : '--.--'} € / MWh
                                  </Row>
                                </Col>

                                <Col className="info-status" sm={12}>  
                                  <Row className={(!this.state.forms[leadFormIndex].product_details.sumPerMWhVerified ? ' unspecified' : '')}>
                                    <Col sm={6}>
                                      <span className="info-status-header-small">Product price: </span>
                                    </Col>
                                    <Col sm={6}>
                                      <span className="info-status-header-small">{(this.state.forms[leadFormIndex].product_details.sumPerMWhVerified) ? this.state.forms[leadFormIndex].product_details.sumPerMWhVerified.toFixed(2) : '--.--'} € / MWh</span>
                                    </Col>
                                  </Row>
                                  <Row className={(!this.state.forms[leadFormIndex].product_details.sumLogisticsPerMWhVerified ? ' unspecified' : '')}>
                                    <Col sm={6}>
                                      <span className="info-status-header-small">Logistics: </span>
                                    </Col>
                                    <Col sm={6}>
                                      <span className="info-status-header-small">{(this.state.forms[leadFormIndex].product_details.sumLogisticsPerMWhVerified) ? this.state.forms[leadFormIndex].product_details.sumLogisticsPerMWhVerified.toFixed(2) : '--.--'} € / MWh</span>
                                    </Col>
                                  </Row>
                                  <Row className={(!this.state.forms[leadFormIndex].product_details.sumAdditionalCostsPerMWhVerified ? ' unspecified' : '')}>
                                    <Col sm={6}>
                                      <div className="info-status-header-small">Additional costs: </div>
                                    </Col>
                                    <Col sm={6}>
                                      <span className="info-status-header-small">{(this.state.forms[leadFormIndex].product_details.sumAdditionalCostsPerMWhVerified) ? this.state.forms[leadFormIndex].product_details.sumAdditionalCostsPerMWhVerified.toFixed(2) : '--.--'} € / MWh</span>
                                    </Col>
                                  </Row>
                                  <Row className={(!this.state.forms[leadFormIndex].product_details.sumTotalEnergyCostVerified ? ' unspecified' : '')}>
                                    <Col sm={6}>
                                      <span className="info-status-header-small">Total price euros: </span>
                                    </Col>
                                    <Col sm={6}>
                                      <span className="info-status-header-small">{(this.state.forms[leadFormIndex].product_details.sumTotalEnergyCostVerified) ? this.state.forms[leadFormIndex].product_details.sumTotalEnergyCostVerified.toFixed(2) : '--.--'} €</span>
                                    </Col>
                                  </Row>
                                </Col>

                              </Col>
                              {/* Verified status END */}  
                              
                            </Col>
                          ))}
                        </Row>
                      </Row>
                      {/* Lead details form END */}

                    </div>
                  </div>
                  {/* Lead id & status form END */}

                </div>
                {/* Lead view header END */} 
                
                {/* Counterparties form BEGIN */}
                <div id="section-counterparties" className='subsection-detail-holder'>
                  <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-lead-close">Expand / collapse</Tooltip>} delayShow={this.state.tooltipDelayShow}>
                    <h1 className="subsection-header" onClick={(event) => this.toggleSubsectionVisibility(event, 'counterparties')}>
                    Counterparties
                      <Button className={'subsection-toggle ion-ios-arrow-down' + (!this.state.sectionExpanded_counterparties ? '' : ' open')}></Button>
                    </h1>
                  </OverlayTrigger>  
                  <div className={'collapsible-content' + (!this.state.sectionExpanded_counterparties ? ' closed' : '')}>

                    {/* Implementer form BEGIN */}
                    <Row >
                      <Col className='subsection-label' sm={this.state.colspan_sectionLabel}>
                        Implementer
                      </Col>
                      {this.state.forms.map((leadForm, leadFormIndex) => (
                        <Col key={'leadImplementer' + leadForm.id} className='subsection-content' sm={this.state.colspan_sectionContent} style={subsectionStyle}>
                          <Col className='element-padding horizontal'>  
                            <ControlLabel>Implementer</ControlLabel>
                            <FormControl id="implementer" componentClass="select" placeholder="select" value={this.state.forms[leadFormIndex].implementer != undefined ?  this.state.forms[leadFormIndex].implementer : ''} onChange={(event) => this.handleLeadInputChange(event, leadFormIndex)} >
                              <option value=''></option>
                              {this.state.implementers.map((item, i) => (
                                <option value={item.id} key={i} disabled={((!item.active) ? true : false)}>{item.name}</option>
                              ))}
                            </FormControl>
                          </Col>
                        </Col>
                        
                      ))}
                    </Row>
                    {/* Implementer form END */}

                    {/* Trader form BEGIN */}
                    <Row >
                      <Col className='subsection-label' sm={this.state.colspan_sectionLabel}>
                        Trader
                      </Col>
                      {this.state.forms.map((leadForm, leadFormIndex) => (
                        <Col key={'leadTrader' + leadForm.id} className='subsection-content' sm={this.state.colspan_sectionContent} style={subsectionStyle}>
                          <Col className='element-padding horizontal'>  
                            <ControlLabel>Trader</ControlLabel>
                            <FormControl id="trader_id" componentClass="select" placeholder="select" value={this.state.forms[leadFormIndex].trader_id != undefined ?  this.state.forms[leadFormIndex].trader_id : ''} onChange={(event) => this.handleLeadInputChange(event, leadFormIndex)} >
                              <option value=''></option>
                              {this.state.users.map((item, i) => (
                                <option value={item.id} key={i}>{item.name ? item.name : item.email.split('@')[0]}</option>
                              ))}
                            </FormControl>
                          </Col>
                        </Col>
                        
                      ))}
                    </Row>
                    {/* Trader form END */}

                    {/* Seller form BEGIN */}
                    <Row>
                      <Col id='seller' className='subsection-label' sm={this.state.colspan_sectionLabel}>
                        Seller details
                      </Col>
                      {this.state.forms.map((leadForm, leadFormIndex) => (
                        <Col key={'leadSeller' + leadForm.id} className='subsection-content' sm={this.state.colspan_sectionContent} style={subsectionStyle}>
                          <Col className='element-padding horizontal'>
                            <ControlLabel>Counterparty</ControlLabel>
                            <FormControl id="seller_id" componentClass="select" placeholder="select" value={this.state.forms[leadFormIndex].seller_id != undefined ? this.state.forms[leadFormIndex].seller_id : ''} onChange={(event) => this.handleLeadInputChange(event, leadFormIndex, () => this.selectSellerFormData(leadFormIndex))} >
                              <option value=''></option>
                              {this.state.counterparties.map((item, i) => (
                                <option value={item.id} key={i} disabled={((!item.active) ? true : false)}>{item.name}</option>
                              ))}
                            </FormControl>
                          </Col>

                          <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                            <ControlLabel>Address</ControlLabel>
                            <FormControl id='sellerAddress' type="text" value={ this.state.forms[leadFormIndex].seller.address + ((this.state.forms[leadFormIndex].seller.zip_code || this.state.forms[leadFormIndex].seller.city) ? ', ' + (this.state.forms[leadFormIndex].seller.zip_code ? this.state.forms[leadFormIndex].seller.zip_code : '') + ' ' + (this.state.forms[leadFormIndex].seller.city ? this.state.forms[leadFormIndex].seller.city : '') : '') } readOnly />
                          </Col>
                          <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                            <ControlLabel>Country</ControlLabel>
                            <FormControl id='sellerCountry' type="text" value={this.state.forms[leadFormIndex].seller.country != undefined ? this.state.forms[leadFormIndex].seller.country : ''} readOnly />
                          </Col>
                          <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                            <ControlLabel>Contact person</ControlLabel>
                            <FormControl id="sellerContact_name" type="text" value={this.state.forms[leadFormIndex].sellerContact_name != undefined ? this.state.forms[leadFormIndex].sellerContact_name : ''} onChange={(event) => this.handleLeadInputChange(event, leadFormIndex, () => this.selectSellerContactFormData(leadFormIndex))} readOnly={((!this.state.forms[leadFormIndex].seller_id) ? true : false)} list="sellerContactDataList" />
                            <datalist id="sellerContactDataList">
                              {(this.state.forms[leadFormIndex].seller_id && this.state.counterparties && this.state.counterparties.find(x => x.id == this.state.forms[leadFormIndex].seller_id)) &&
                                this.state.counterparties.find(x => x.id == this.state.forms[leadFormIndex].seller_id).contacts.map((item, i) => (
                                  <option key={i} value={item.name} />
                                ), this)
                              }
                            </datalist>
                          </Col>
                          <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 3}>
                            <ControlLabel>Phone</ControlLabel>
                            <FormControl id='sellerContact_phone' type="text" value={this.state.forms[leadFormIndex].sellerContact_phone != undefined ? this.state.forms[leadFormIndex].sellerContact_phone : ''} onChange={(event) => this.handleLeadInputChange(event, leadFormIndex)} readOnly={((!this.state.forms[leadFormIndex].seller_id) ? true : false)} />
                          </Col>
                          <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 3}>
                            <ControlLabel>Email</ControlLabel>
                            <FormControl id='sellerContact_email' type="text" value={this.state.forms[leadFormIndex].sellerContact_email != undefined ? this.state.forms[leadFormIndex].sellerContact_email : ''} onChange={(event) => this.handleLeadInputChange(event, leadFormIndex)} readOnly={((!this.state.forms[leadFormIndex].seller_id) ? true : false)} />
                          </Col>

                          <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 3}>
                            <ControlLabel>Type</ControlLabel>
                            <FormControl id="source_type" componentClass="select" placeholder="select" value={this.state.forms[leadFormIndex].source_type != undefined ? this.state.forms[leadFormIndex].source_type : ''} onChange={(event) => this.handleLeadInputChange(event, leadFormIndex, () => this.selectSellerSourceType(leadFormIndex))} disabled={((!this.state.forms[leadFormIndex].seller_id) ? true : false)} >
                              <option value='Hub'>Hub</option>
                              <option value='Site'>Site</option>
                            </FormControl>
                          </Col>

                          <Col id='source' className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 9}>
                            <ControlLabel>Source</ControlLabel>
                            <FormControl id="source_id" componentClass="select" placeholder="select" value={this.state.forms[leadFormIndex].source_id != undefined ? this.state.forms[leadFormIndex].source_id : ''} onChange={(event) => this.handleLeadInputChange(event, leadFormIndex)} disabled={((!this.state.forms[leadFormIndex].seller_id || !this.state.forms[leadFormIndex].source_type) ? true : false)} >
                              <option value=''></option>
                              {this.state.forms[leadFormIndex].source_type === 'Hub' && this.state.forms[leadFormIndex].seller_id && this.state.counterparties &&
                                this.state.counterparties.find(x => x.id == this.state.forms[leadFormIndex].seller_id) ?
                                this.state.counterparties.find(x => x.id == this.state.forms[leadFormIndex].seller_id).hubs.map((item, i) => (
                                  <option value={item.id} key={i}>{item.name}</option>
                                )) : false}
                              {this.state.forms[leadFormIndex].source_type === 'Site' && this.state.forms[leadFormIndex].seller_id && this.state.counterparties &&
                                this.state.counterparties.find(x => x.id == this.state.forms[leadFormIndex].seller_id) ?
                                this.state.counterparties.find(x => x.id == this.state.forms[leadFormIndex].seller_id).sites.map((item, i) => (
                                  <option value={item.id} key={i}>{item.name}</option>
                                )) : false}
                            </FormControl>
                          </Col>
                        </Col>
                      ))}
                    </Row>
                    {/* Seller form END */}

                    {/* Buyer form BEGIN */}
                    <Row>
                      <Col id='buyer' className='subsection-label' sm={this.state.colspan_sectionLabel}>
                        Buyer details
                      </Col>
                      {this.state.forms.map((leadForm, leadFormIndex) => (
                        <Col key={'leadBuyer' + leadForm.id} className='subsection-content' sm={this.state.colspan_sectionContent} style={subsectionStyle}>
                          <Col className='element-padding horizontal'>
                            <ControlLabel>Counterparty</ControlLabel>
                            <FormControl id="buyer_id" componentClass="select" placeholder="select" value={this.state.forms[leadFormIndex].buyer_id != undefined ? this.state.forms[leadFormIndex].buyer_id : ''} onChange={(event) => this.handleLeadInputChange(event, leadFormIndex, () => this.selectBuyerFormData(leadFormIndex))} >
                              <option value=''></option>
                              {this.state.counterparties.map((item, i) => (
                                <option value={item.id} key={i} disabled={((!item.active) ? true : false)}>{item.name}</option>
                              ))}
                            </FormControl>
                          </Col>

                          <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                            <ControlLabel>Address</ControlLabel>
                            <FormControl id='buyerAddress' type="text" value={ this.state.forms[leadFormIndex].buyer.address + ((this.state.forms[leadFormIndex].buyer.zip_code || this.state.forms[leadFormIndex].buyer.city) ? ', ' + (this.state.forms[leadFormIndex].buyer.zip_code ? this.state.forms[leadFormIndex].buyer.zip_code : '') + ' ' + (this.state.forms[leadFormIndex].buyer.city ? this.state.forms[leadFormIndex].buyer.city : '') : '') } readOnly />
                          </Col>
                          <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                            <ControlLabel>Country</ControlLabel>
                            <FormControl id='buyerCountry' type="text" value={this.state.forms[leadFormIndex].buyer.country != undefined ? this.state.forms[leadFormIndex].buyer.country : ''} readOnly />
                          </Col>
                          <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                            <ControlLabel>Contact person</ControlLabel>
                            <FormControl id="buyerContact_name" type="text" value={this.state.forms[leadFormIndex].buyerContact_name != undefined ? this.state.forms[leadFormIndex].buyerContact_name : ''} onChange={(event) => this.handleLeadInputChange(event, leadFormIndex, () => this.selectBuyerContactFormData(leadFormIndex))} readOnly={((!this.state.forms[leadFormIndex].buyer_id) ? true : false)} list="buyerContactDataList" />
                            <datalist id="buyerContactDataList">
                              {(this.state.forms[leadFormIndex].buyer_id && this.state.counterparties && this.state.counterparties.find(x => x.id == this.state.forms[leadFormIndex].buyer_id)) &&
                                this.state.counterparties.find(x => x.id == this.state.forms[leadFormIndex].buyer_id).contacts.map((item, i) => (
                                  <option key={i} value={item.name} />
                                ), this)
                              }
                            </datalist>
                          </Col>
                          <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 3}>
                            <ControlLabel>Phone</ControlLabel>
                            <FormControl id='buyerContact_phone' type="text" value={this.state.forms[leadFormIndex].buyerContact_phone != undefined ? this.state.forms[leadFormIndex].buyerContact_phone : ''} onChange={(event) => this.handleLeadInputChange(event, leadFormIndex)} readOnly={((!this.state.forms[leadFormIndex].buyer_id) ? true : false)} />
                          </Col>
                          <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 3}>
                            <ControlLabel>Email</ControlLabel>
                            <FormControl id='buyerContact_email' type="text" value={this.state.forms[leadFormIndex].buyerContact_email != undefined ? this.state.forms[leadFormIndex].buyerContact_email : ''} onChange={(event) => this.handleLeadInputChange(event, leadFormIndex)} readOnly={((!this.state.forms[leadFormIndex].buyer_id) ? true : false)} />
                          </Col>

                          <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 3}>
                            <ControlLabel>Type</ControlLabel>
                            <FormControl id="destination_type" componentClass="select" placeholder="select" value={this.state.forms[leadFormIndex].destination_type != undefined ? this.state.forms[leadFormIndex].destination_type : ''} onChange={(event) => this.handleLeadInputChange(event, leadFormIndex, () => this.selectBuyerDestinationType(leadFormIndex))} disabled={((!this.state.forms[leadFormIndex].buyer_id) ? true : false)} >
                              <option value='Hub'>Hub</option>
                              <option value='Site'>Site</option>
                            </FormControl>
                          </Col>

                          <Col id='destination' className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 9}>
                            <ControlLabel>Destination</ControlLabel>
                            <FormControl id="destination_id" componentClass="select" placeholder="select" value={this.state.forms[leadFormIndex].destination_id != undefined ? this.state.forms[leadFormIndex].destination_id : ''} onChange={(event) => this.handleLeadInputChange(event, leadFormIndex)} disabled={((!this.state.forms[leadFormIndex].buyer_id || !this.state.forms[leadFormIndex].source_type) ? true : false)} >
                              <option value=''></option>
                              {this.state.forms[leadFormIndex].destination_type === 'Hub' && this.state.forms[leadFormIndex].buyer_id && this.state.counterparties &&
                                this.state.counterparties.find(x => x.id == this.state.forms[leadFormIndex].buyer_id) ?
                                this.state.counterparties.find(x => x.id == this.state.forms[leadFormIndex].buyer_id).hubs.map((item, i) => (
                                  <option value={item.id} key={i}>{item.name}</option>
                                )) : false}
                              {this.state.forms[leadFormIndex].destination_type === 'Site' && this.state.forms[leadFormIndex].buyer_id && this.state.counterparties &&
                                this.state.counterparties.find(x => x.id == this.state.forms[leadFormIndex].buyer_id) ?
                                this.state.counterparties.find(x => x.id == this.state.forms[leadFormIndex].buyer_id).sites.map((item, i) => (
                                  <option value={item.id} key={i}>{item.name}</option>
                                )) : false}
                            </FormControl>
                          </Col>
                        </Col>
                      ))}
                    </Row>
                    {/* Buyer form END */}

                  </div>
                </div>
                {/* Counterparties form END */}

                {/* Product details form BEGIN */}
                <div id="section-product" className='subsection-detail-holder'>
                  <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-lead-close">Expand / collapse</Tooltip>} delayShow={this.state.tooltipDelayShow}>
                    <h1 className="subsection-header" onClick={(event) => this.toggleSubsectionVisibility(event, 'product')}>
                    Product details
                    <Button className={'subsection-toggle ion-ios-arrow-down' + (!this.state.sectionExpanded_product ? '' : ' open')}></Button>
                    </h1>
                  </OverlayTrigger>  
                  <div className={'collapsible-content' + (!this.state.sectionExpanded_product ? ' closed' : '')}>

                    {/* Product details form BEGIN */}
                    <Row>
                      <Col id='product' className='subsection-label' sm={this.state.colspan_sectionLabel}>
                        Product details
                      </Col>
                      {this.state.forms.map((leadForm, leadFormIndex) => (
                        <Col key={'leadProductDetails' + leadForm.id} className='subsection-content' sm={this.state.colspan_sectionContent} style={subsectionStyle}>

                          <Col className='element-padding horizontal'>
                            <ControlLabel>Product name </ControlLabel>
                            <FormControl id='product_name' value={this.state.forms[leadFormIndex].product_details.product_name != undefined ? this.state.forms[leadFormIndex].product_details.product_name : ''} onChange={(event) => this.handleProductDetailsInputChange(event, leadFormIndex, () => this.calculateLeadEnergyContentsAndPrices(this.state.forms[leadFormIndex]))} />
                          </Col>
                          
                          <Col className='element-padding horizontal'>
                            <ControlLabel>Product</ControlLabel>
                            <FormControl id="product_id" componentClass="select" placeholder="select" value={this.state.forms[leadFormIndex].product_details.product_id != undefined ? this.state.forms[leadFormIndex].product_details.product_id : ''} onChange={(event) => this.handleProductDetailsInputChange(event, leadFormIndex, () => this.selectProductFormData(leadFormIndex))} >
                              <option value=''></option>
                              {this.state.products.map((item, i) => (
                                <option value={item.id} key={i} disabled={((!item.active) ? true : false)}>{item.name}</option>
                              ))}
                            </FormControl>
                          </Col>
                          
                          <Col className='element-padding horizontal'>
                            <ControlLabel>Product type</ControlLabel>
                            <FormControl id="product_type" componentClass="select" placeholder="select" value={this.state.forms[leadFormIndex].product_details.product_type != undefined ? this.state.forms[leadFormIndex].product_details.product_type : ''} onChange={(event) => this.handleProductDetailsInputChange(event, leadFormIndex)} disabled={((!this.state.forms[leadFormIndex].product_details.product_id) ? true : false)} >
                              <option value=''></option>
                              {this.state.productTypes.map((item, i) => (
                                <option value={item.id} key={i} disabled={((!item.active) ? true : false)}>{item.name}</option>
                              ))}
                            </FormControl>
                          </Col>

                          {/* Hidden (just in case we need to add this back in) */}
                          {/* <Col className='element-padding horizontal'>
                            <ControlLabel>Product classification</ControlLabel>
                            <FormControl id="product_classification" componentClass="select" placeholder="select" value={this.state.forms[leadFormIndex].product_details.product_classification != undefined ? this.state.forms[leadFormIndex].product_details.product_classification : ''} onChange={(event) => this.handleProductDetailsInputChange(event, leadFormIndex)} >
                              <option value=''></option>
                              {this.state.productClassifications.map((item, i) => (
                                <option value={item.id} key={i} disabled={((!item.active) ? true : false)}>{item.name}</option>
                              ))}
                            </FormControl>
                          </Col> */}

                          <Col className='element-padding horizontal'>
                            <ControlLabel>Product attachments</ControlLabel>
                            <FormAddFileToList 
                              inputId="productDocument"
                              inputValue={this.state.forms[leadFormIndex].product_details.productDocument || {name: ''}}
                              inputOnChange={(event) => this.handleProductDetailsInputChange(event, leadFormIndex)}
                              addToListFunc={()=>this.fileUploadProductDetails(leadFormIndex)}
                              deleteFromListFunc={(event)=>this.fileDeleteProductDetails(event, leadFormIndex)}
                              listArray={this.state.forms[leadFormIndex].product_details.documents}
                              label=""
                              active={this.state.forms[leadFormIndex].active} />
                            {/* <FormAddFileToList 
                              inputId="productDocument"
                              inputValue={this.state.forms[leadFormIndex].product_details.productDocument || {name: ''}}
                              inputOnChange={(event) => this.handleProductDetailsInputChange(event, leadFormIndex)}
                              addToListFunc={()=>this.addProductDetailsFileFormItem(leadFormIndex, 'productDocument','documents')}
                              deleteFromListFunc={(event)=>this.deleteProductDetailsFileFormItem(event, leadFormIndex, 'documents', 'documentsToDelete')}
                              listArray={this.state.forms[leadFormIndex].product_details.documents}
                              label=""
                              active={this.state.forms[leadFormIndex].active} /> */}
                          </Col>
                        </Col>
                      ))}
                    </Row>
                    {/* Product details form END */}

                    {/* Product price form BEGIN */}
                    <Row>
                      <Col className='subsection-label' sm={this.state.colspan_sectionLabel}>
                        Product price
                      </Col>
                      {this.state.forms.map((leadForm, leadFormIndex) => (
                        <Col key={'leadProductPrice' + leadForm.id} className='subsection-content' sm={this.state.colspan_sectionContent} style={subsectionStyle}>
                          <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                            <ControlLabel>Price</ControlLabel>
                            <FormControl id='price' type="number" step={0.01} value={this.state.forms[leadFormIndex].product_details.price != undefined ? this.state.forms[leadFormIndex].product_details.price : ''} onChange={(event) => this.handleProductDetailsInputChange(event, leadFormIndex, () => this.calculateLeadEnergyContentsAndPrices(this.state.forms[leadFormIndex]))} />
                          </Col>

                          <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                            <ControlLabel>Unit</ControlLabel>
                            <FormControl id="unit" componentClass="select" placeholder="select" value={this.state.forms[leadFormIndex].product_details.unit != undefined ? this.state.forms[leadFormIndex].product_details.unit : ''} onChange={(event) => this.handleProductDetailsInputChange(event, leadFormIndex)} >
                              <option value=''></option>
                              {this.state.unitTypes.map((item, i) => (
                                <option value={item.id} key={i} disabled={((!item.active) ? true : false)}>{item.name}</option>
                              ))}
                            </FormControl>
                          </Col>

                          <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                            <ControlLabel>Currency</ControlLabel>
                            <FormControl id="currency" componentClass="select" placeholder="select" value={this.state.forms[leadFormIndex].product_details.currency != undefined ? this.state.forms[leadFormIndex].product_details.currency : ''} onChange={(event) => this.handleProductDetailsInputChange(event, leadFormIndex, () => this.calculateLeadEnergyContentsAndPrices(this.state.forms[leadFormIndex]))} >
                              <option value=''></option>
                              {this.state.currencies.map((item, i) => (
                                <option value={item.id} key={i} disabled={((!item.active) ? true : false)}>{item.name}</option>
                              ))}
                            </FormControl>
                          </Col>

                          <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                            <ControlLabel>Exchange rate (to euros)</ControlLabel>
                            <FormControl id='exchange_rate' type="number" step={0.01} value={this.state.forms[leadFormIndex].product_details.exchange_rate != undefined ? this.state.forms[leadFormIndex].product_details.exchange_rate : ''} readOnly={(!this.state.forms[leadFormIndex].product_details.currency || this.state.forms[leadFormIndex].product_details.currency === 'EUR')} onChange={(event) => this.handleProductDetailsInputChange(event, leadFormIndex, () => this.calculateLeadEnergyContentsAndPrices(this.state.forms[leadFormIndex]))} />
                          </Col>

                          <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                            <ControlLabel>Estimated MWh/unit</ControlLabel>
                            <FormControl id='price_estimated_mwh_per_unit' type="number" step={0.01} value={this.state.forms[leadFormIndex].product_details.price_estimated_mwh_per_unit != undefined ? this.state.forms[leadFormIndex].product_details.price_estimated_mwh_per_unit : ''} onChange={(event) => this.handleProductDetailsInputChange(event, leadFormIndex, () => this.calculateLeadEnergyContentsAndPrices(this.state.forms[leadFormIndex]))} />
                          </Col>

                          <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                            <ControlLabel>Credit terms</ControlLabel>
                            <FormControl id="credit_terms" type="text" value={this.state.forms[leadFormIndex].product_details.credit_terms != undefined ? this.state.forms[leadFormIndex].product_details.credit_terms : ''} onChange={(event) => this.handleProductDetailsInputChange(event, leadFormIndex)} list="creditTermsDataList" />
                          </Col>

                        </Col>
                      ))}
                    </Row>
                    {/* Product price form END */}

                    {/* Product quantity BEGIN */}
                    <Row>
                      <Col className='subsection-label' sm={this.state.colspan_sectionLabel}>
                        Product quantity
                      </Col>
                      {this.state.forms.map((leadForm, leadFormIndex) => (
                        <Col key={'leadProductQuantity' + leadForm.id} className='subsection-content' sm={this.state.colspan_sectionContent} style={subsectionStyle}>
                          <Col className='element-padding horizontal' sm={6}>
                            <ControlLabel>Quantity (source)</ControlLabel>
                            <FormControl id='quantity_source' type="number" step={1} value={this.state.forms[leadFormIndex].product_details.quantity_source != undefined ? this.state.forms[leadFormIndex].product_details.quantity_source : ''} onChange={(event) => this.handleProductDetailsInputChange(event, leadFormIndex, () => this.calculateLeadEnergyContentsAndPrices(this.state.forms[leadFormIndex]))} />
                          </Col>
                          <Col className='element-padding horizontal' sm={6}>
                            <ControlLabel>Quantity (final)</ControlLabel>
                            <FormControl id='quantity_final' type="number" step={1} value={this.state.forms[leadFormIndex].product_details.quantity_final != undefined ? this.state.forms[leadFormIndex].product_details.quantity_final : ''} onChange={(event) => this.handleProductDetailsInputChange(event, leadFormIndex, () => this.calculateLeadEnergyContentsAndPrices(this.state.forms[leadFormIndex]))} />
                          </Col>

                          <Col className='element-padding horizontal' sm={6}>
                            <ControlLabel>Unit</ControlLabel>
                            <FormControl id="unit_source" componentClass="select" placeholder="select" value={this.state.forms[leadFormIndex].product_details.unit_source != undefined ? this.state.forms[leadFormIndex].product_details.unit_source : ''} onChange={(event) => this.handleProductDetailsInputChange(event, leadFormIndex, () => this.calculateLeadEnergyContentsAndPrices(this.state.forms[leadFormIndex]))} >
                              <option value=''></option>
                              {this.state.unitTypes.map((item, i) => (
                                <option value={item.id} key={i} disabled={((!item.active) ? true : false)}>{item.name}</option>
                              ))}
                            </FormControl>
                          </Col>
                          <Col className='element-padding horizontal' sm={6}>
                            <ControlLabel>Unit</ControlLabel>
                            <FormControl id="unit_final" componentClass="select" placeholder="select" value={this.state.forms[leadFormIndex].product_details.unit_final != undefined ? this.state.forms[leadFormIndex].product_details.unit_final : ''} onChange={(event) => this.handleProductDetailsInputChange(event, leadFormIndex, () => this.calculateLeadEnergyContentsAndPrices(this.state.forms[leadFormIndex]))} >
                              <option value=''></option>
                              {this.state.unitTypes.map((item, i) => (
                                <option value={item.id} key={i} disabled={((!item.active) ? true : false)}>{item.name}</option>
                              ))}
                            </FormControl>
                          </Col>

                          <Col className='element-padding horizontal' sm={6}>
                            <ControlLabel>Estimated MWh/unit</ControlLabel>
                            <FormControl id='quantity_estimated_mwh_per_unit' type="number" step={0.01} value={this.state.forms[leadFormIndex].product_details.quantity_estimated_mwh_per_unit != undefined ? this.state.forms[leadFormIndex].product_details.quantity_estimated_mwh_per_unit : ''} onChange={(event) => this.handleProductDetailsInputChange(event, leadFormIndex, () => this.calculateLeadEnergyContentsAndPrices(this.state.forms[leadFormIndex]))} />
                          </Col>
                          <Col className='element-padding horizontal' sm={6}>
                            <ControlLabel>Verified MWh/unit</ControlLabel>
                            <FormControl id='quantity_verified_mwh_per_unit' type="number" step={0.01} value={this.state.forms[leadFormIndex].product_details.quantity_verified_mwh_per_unit != undefined ? this.state.forms[leadFormIndex].product_details.quantity_verified_mwh_per_unit : ''} onChange={(event) => this.handleProductDetailsInputChange(event, leadFormIndex, () => this.calculateLeadEnergyContentsAndPrices(this.state.forms[leadFormIndex]))} />
                          </Col>

                          <Row>
                            <Col className='element-padding horizontal' sm={6}>
                              <ControlLabel>Total energy content (seller, MWh)</ControlLabel>
                              <FormControl id='sumTotalEnergyContentSellerMWh' type="number" step={1} value={(this.state.forms[leadFormIndex].product_details.sumTotalEnergyContentSellerMWh) ? this.state.forms[leadFormIndex].product_details.sumTotalEnergyContentSellerMWh.toFixed(2) : ''} onChange={(event) => this.handleProductDetailsInputChange(event, leadFormIndex, () => this.calculateLeadEnergyContentsAndPrices(this.state.forms[leadFormIndex]))} readOnly />
                            </Col>
                          </Row>

                          <Row>
                            <Col className='element-padding horizontal' sm={6}>
                              <ControlLabel>Energy content risk factor (%)</ControlLabel>
                              <InputGroup>
                                <FormControl id='energy_content_risk_factor' type="number" step={1} min={0} max={100} value={this.state.forms[leadFormIndex].product_details.energy_content_risk_factor != undefined ? this.state.forms[leadFormIndex].product_details.energy_content_risk_factor : ''} onChange={(event) => this.handleProductDetailsInputChange(event, leadFormIndex, () => this.calculateLeadEnergyContentsAndPrices(this.state.forms[leadFormIndex]))} />
                                <InputGroup.Addon>%</InputGroup.Addon>
                              </InputGroup>
                            </Col>
                          </Row>

                          <Col className='element-padding horizontal' sm={6}>
                            <ControlLabel>Total energy content (indicative, MWh)</ControlLabel>
                            <FormControl id='sumTotalEnergyContentIndicative' type="number" step={1} value={(this.state.forms[leadFormIndex].product_details.sumTotalEnergyContentIndicative) ? this.state.forms[leadFormIndex].product_details.sumTotalEnergyContentIndicative.toFixed(2) : ''} onChange={(event) => this.handleProductDetailsInputChange(event, leadFormIndex)} readOnly />
                          </Col>
                          <Col className='element-padding horizontal' sm={6}>
                            <ControlLabel>Total energy content (verified, MWh)</ControlLabel>
                            <FormControl id='sumTotalEnergyContentVerified' type="number" step={1} value={(this.state.forms[leadFormIndex].product_details.sumTotalEnergyContentVerified) ?this.state.forms[leadFormIndex].product_details.sumTotalEnergyContentVerified.toFixed(2) : ''} onChange={(event) => this.handleProductDetailsInputChange(event, leadFormIndex)} readOnly />
                          </Col>

                        </Col>
                      ))}
                    </Row>
                    {/* Product quantity form END */}

                  </div>
                </div>
                {/* Product details form END */}

                {/* Certificates, ETA, additional info form BEGIN */}
                <div id="section-certificates" className='subsection-detail-holder'>  
                  <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-lead-close">Expand / collapse</Tooltip>} delayShow={this.state.tooltipDelayShow}>
                    <h1 className="subsection-header" onClick={(event) => this.toggleSubsectionVisibility(event, 'certificates')}>
                    Certificates, ETA, additional info
                    <Button className={'subsection-toggle ion-ios-arrow-down' + (!this.state.sectionExpanded_certificates ? '' : ' open')}></Button>
                    </h1>
                  </OverlayTrigger>
                  <div className={'collapsible-content' + (!this.state.sectionExpanded_certificates ? ' closed' : '')}>

                    {/* Certificates form BEGIN */}
                    <Row>
                      <Col className='subsection-label' sm={this.state.colspan_sectionLabel}>
                        Certificates
                      </Col>
                      {this.state.forms.map((leadForm, leadFormIndex) => (
                        <Col key={'leadCertificate' + leadForm.id} className='subsection-content' sm={this.state.colspan_sectionContent} style={subsectionStyle}>
                          <Col className='element-padding horizontal'>
                            <ControlLabel>Certificate</ControlLabel>
                            <FormAddToList
                              inputId="certificate"
                              inputValue={this.state.forms[leadFormIndex].certificate != undefined ? this.state.forms[leadFormIndex].certificate : ''}
                              inputOnChange={(event)=>this.handleLeadInputChange(event, leadFormIndex)}
                              dataList={true}
                              dataListId="certificatesList"
                              dataListArray={this.state.certificates}
                              addToListFunc={()=>this.addLeadFormItem(leadFormIndex, 'certificate', 'certificates', this.state.certificates)}
                              deleteFromListFunc={(event)=>this.deleteLeadFormItem(event, leadFormIndex, 'certificates')}
                              listArray={this.state.forms[leadFormIndex].certificates}
                              label=""
                              active={this.state.forms[leadFormIndex].active}
                              loading={this.state.loading} />
                            {/* <FormControl id="certificate_id" componentClass="select" placeholder="select" value={this.state.forms[leadFormIndex].certificate_id != undefined ? this.state.forms[leadFormIndex].certificate_id : ''} onChange={(event)=>this.handleLeadInputChange(event, leadFormIndex)} >
                              <option value=''></option>
                              {this.state.certificates.map((item, i) => (
                                <option value={item.id} key={i} disabled={((!item.active) ? true : false)}>{item.name}</option>
                              ))}
                            </FormControl> */}
                          </Col>

                          <Col className='element-padding horizontal'>
                            <ControlLabel>Upload certificate</ControlLabel>
                            <FormAddFileToList 
                              inputId="certificateDocument"
                              inputValue={this.state.forms[leadFormIndex].certificateDocument || {name: ''}}
                              inputOnChange={(event) => this.handleLeadInputChange(event, leadFormIndex)}
                              addToListFunc={()=>this.fileUploadCertificates(leadFormIndex)}
                              deleteFromListFunc={(event)=>this.fileDeleteCertificates(event, leadFormIndex)}
                              listArray={this.state.forms[leadFormIndex].lead_certificates}
                              label=""
                              active={this.state.forms[leadFormIndex].active} />
                          </Col>
                        </Col>
                      ))}
                    </Row>
                    {/* Certificates form END */}

                    {/* Date of delivery form BEGIN */}
                    <Row>
                      <Col id='deadline' className='subsection-label' sm={this.state.colspan_sectionLabel}>
                        Date of delivery
                      </Col>
                      {this.state.forms.map((leadForm, leadFormIndex) => (
                        <Col key={'leadDateOfDelivery' + leadForm.id} className='subsection-content' sm={this.state.colspan_sectionContent} style={subsectionStyle}>
                          <Col className='element-padding horizontal'>
                            <ControlLabel>ETA</ControlLabel>
                            <InputGroup>
                              <DateTime inputProps={{ id: 'date_of_delivery', placeholder: this.state.dateInputFormat }}
                                className="date-input"
                                value={this.state.forms[leadFormIndex].date_of_delivery}
                                dateFormat={this.state.dateInputFormat}
                                timeFormat={false}
                                viewMode={this.state.dateMode}
                                closeOnSelect={true}
                                utc={true}
                                locale={"en"}
                                onChange={(date) => this.handleDateLead(leadFormIndex,'date_of_delivery', date)} />
                              <InputGroup.Addon><span className={'icon ion-calendar'}></span></InputGroup.Addon>
                            </InputGroup>

                            {/* <Button className="clear-date-filter" onClick={this.clearDate}>Clear</Button> */}
                          </Col>
                        </Col>
                      ))}
                    </Row>
                    {/* Date of delivery form END */}

                    {/* Deal form BEGIN */}
                    <Row>
                      <Col className='subsection-label' sm={this.state.colspan_sectionLabel}>
                        Deal
                      </Col>
                      {this.state.forms.map((leadForm, leadFormIndex) => (
                        <Col key={'leadAdditionalInfo' + leadForm.id} className='subsection-content' sm={this.state.colspan_sectionContent} style={subsectionStyle}>
                          <Col className='element-padding horizontal'>
                            <ControlLabel>Deal </ControlLabel>
                            <FormControl id='deal' value={this.state.forms[leadFormIndex].deal != undefined ? this.state.forms[leadFormIndex].deal : ''} onChange={(event) => this.handleLeadInputChange(event, leadFormIndex)} />
                          </Col>
                        </Col>
                      ))}
                    </Row>
                    {/* Deal form END */}

                    {/* Additional info form BEGIN */}
                    <Row>
                      <Col className='subsection-label' sm={this.state.colspan_sectionLabel}>
                        Additional info
                      </Col>
                      {this.state.forms.map((leadForm, leadFormIndex) => (
                        <Col key={'leadAdditionalInfo' + leadForm.id} className='subsection-content' sm={this.state.colspan_sectionContent} style={subsectionStyle}>
                          <Col className='element-padding horizontal'>
                            <ControlLabel>Additional info</ControlLabel>
                            <FormControl id="additional_info" componentClass="textarea" value={this.state.forms[leadFormIndex].additional_info != undefined ? this.state.forms[leadFormIndex].additional_info : ''} placeholder="" onChange={(event) => this.handleLeadInputChange(event, leadFormIndex)} />
                          </Col>
                        </Col>
                      ))}
                    </Row>
                    {/* Additional info form END */}

                  </div>
                </div>
                {/* Certificates, ETA, additional info form END */}

                {/* Logistics form BEGIN */}
                <div id="section-logistics" className='subsection-detail-holder'>
                  <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-lead-close">Expand / collapse</Tooltip>} delayShow={this.state.tooltipDelayShow}>
                    <h1 className="subsection-header" onClick={(event) => this.toggleSubsectionVisibility(event, 'logistics')}>
                    Logistics
                    <Button className={'subsection-toggle ion-ios-arrow-down' + (!this.state.sectionExpanded_logistics ? '' : ' open')}></Button>
                    </h1>
                    </OverlayTrigger>  
                  <div className={'collapsible-content' + (!this.state.sectionExpanded_logistics ? ' closed' : '')}>

                    {/* Legs BEGIN */}
                    <Row>
                      <Col id='logistics' className='subsection-label' sm={this.state.colspan_sectionLabel}>
                        Leg details
                      </Col>
                      {this.state.forms.map((leadForm, leadFormIndex) => (
                        <Col key={'leadLegs' + leadForm.id} className='subsection-content' sm={this.state.colspan_sectionContent} style={subsectionStyle}>
                          <Col>
                            
                            <div>
                              {(this.state.forms[leadFormIndex] && this.state.forms[leadFormIndex].logistics && this.state.forms[leadFormIndex].logistics.legs) ? this.state.forms[leadFormIndex].logistics.legs.map((leg, legIndex) => (
                                <div key={'leg' + leg.id} className='lead-widget'>
                                  {/* Leg header BEGIN */}
                                  <h1 className='lead-widget-header'>
                                    <OverlayTrigger placement="left" overlay={<Tooltip id="tooltip-leg-collapse">Expand / collapse</Tooltip>} delayShow={this.state.tooltipDelayShow}>  
                                      <span onClick={(event) => this.toggleWidgetVisibility(event, leadFormIndex, 'leg', legIndex)}>
                                        <Button className={"lead-widget-header-button ion-ios-arrow-down" + (!leg.expanded ? '' : ' open')}></Button>
                                        <span className="lead-widget-header-columns leg">Leg {legIndex + 1}</span>
                                        <span className="lead-widget-header-columns from">{'FROM: '}
                                          {(this.state.hubs && leg.start_id && leg.start_type === 'Hub') ? this.state.hubs.find(elem => elem.id == leg.start_id).name : false}
                                          {(this.state.sites && leg.start_id && leg.start_type === 'Site') ? this.state.sites.find(elem => elem.id == leg.start_id).name : false}
                                        </span>
                                        <span className="lead-widget-header-columns to">{'TO: '}
                                          {(this.state.hubs && leg.end_id && leg.end_type === 'Hub') ? this.state.hubs.find(elem => elem.id == leg.end_id).name : false}
                                          {(this.state.sites && leg.end_id && leg.end_type === 'Site') ? this.state.sites.find(elem => elem.id == leg.end_id).name : false}
                                        </span>
                                      </span>
                                      </OverlayTrigger>  
                                    <span className="floatRight">
                                      <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-leg-collapse">Toggle edit</Tooltip>} delayShow={this.state.tooltipDelayShow}>
                                        <Button className="lead-widget-header-button ion-edit" onClick={(event) => this.toggleWidgetVisibility(event, leadFormIndex, 'leg', legIndex)}></Button>
                                      </OverlayTrigger>
                                      
                                      <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-leg-collapse">Move this leg up</Tooltip>} delayShow={this.state.tooltipDelayShow}>
                                        <Button className="lead-widget-header-button ion-android-arrow-up" onClick={() => this.reorderLegs(leadFormIndex, leg.id, true)}></Button>
                                      </OverlayTrigger>
                                      
                                      <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-leg-collapse">Move this leg down</Tooltip>} delayShow={this.state.tooltipDelayShow}>
                                        <Button className="lead-widget-header-button ion-android-arrow-down" onClick={() => this.reorderLegs(leadFormIndex, leg.id, false)}></Button>
                                      </OverlayTrigger>

                                      <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-leg-collapse">Delete this leg</Tooltip>} delayShow={this.state.tooltipDelayShow}>
                                        <Button className="lead-widget-header-button ion-android-delete" onClick={() => this.removeLeg(leadFormIndex, leg.id, () => this.calculateLeadEnergyContentsAndPrices(this.state.forms[leadFormIndex]))}></Button>
                                      </OverlayTrigger>
                                    </span>   
                                  </h1>
                                  {/* Leg header END */}

                                  {/* Leg info BEGIN */}
                                  <div className='lead-widget-sub-contents info-status'>
                                    <Grid fluid={true}>
                                      <Col sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                                        <Row className='element-padding vertical'>
                                          <Col className='element-padding horizontal' xs={10}>
                                            <Row>
                                              <Col xs={3} className='info-status-header-small'>
                                                From:
                                              </Col>
                                              <Col xs={9}>
                                                {(this.state.hubs && leg.start_id && leg.start_type === 'Hub') ? this.state.hubs.find(elem => elem.id == leg.start_id).name : false}
                                                {(this.state.sites && leg.start_id && leg.start_type === 'Site') ? this.state.sites.find(elem => elem.id == leg.start_id).name : false}
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col xs={3} className='info-status-header-small'>
                                                To:
                                            </Col>
                                              <Col xs={9}>
                                                {(this.state.hubs && leg.end_id && leg.end_type === 'Hub') ? this.state.hubs.find(elem => elem.id == leg.end_id).name : false}
                                                {(this.state.sites && leg.end_id && leg.end_type === 'Site') ? this.state.sites.find(elem => elem.id == leg.end_id).name : false}
                                              </Col>
                                            </Row>
                                          </Col>
                                          <Col xs={2}>
                                            {(leg.freight_method) ? <span className={'freight-method-icon ' + this.state.freightMethods.find(elem => elem.id.toUpperCase() == leg.freight_method.toUpperCase()).ionIconClass}></span> : false}
                                          </Col>
                                        </Row>
                                      </Col>
                                      <Col sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                                        <Col className='element-padding all-sides' xs={(this.state.colspan_subField) ? this.state.colspan_subField : 8}>
                                          <Row>
                                            <Col sm={12} className='info-status-header-small'>
                                              Indicative freight cost:
                                          </Col>
                                          </Row>
                                          <Row>
                                            <Col sm={12} className={'info-status-header' + (!leg.sumTotalFreightCost ? ' unspecified' : '')}>
                                              {(leg.sumTotalFreightCost) ? leg.sumTotalFreightCost.toFixed(2) : '--.--'} {' €'}
                                            </Col>
                                          </Row>
                                        </Col >
                                        {(this.state.forms[leadFormIndex].logistics.legs[legIndex].pricing_unit === 'lump sum' && this.state.forms[leadFormIndex].logistics.legs[legIndex].share_of_total) &&
                                          <Col className='element-padding all-sides' xs={(this.state.colspan_subField) ? this.state.colspan_subField : 4}>
                                            <Row>
                                              <Col sm={12} className='info-status-header-small'>
                                                Share of cost:
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col sm={12} className='info-status-header'>
                                                {leg.share_of_total + ' %'}
                                              </Col>
                                            </Row>
                                          </Col>
                                        }
                                      </Col>
                                    </Grid>
                                  </div>
                                  {/* Leg info END */}

                                  <div className={'collapsible-content' + (!leg.expanded ? ' closed' : '')}>

                                    {/* Leg details form BEGIN */}
                                    <FormGroup conrolId="legInformation">
                                      <div className="lead-widget-sub-contents lead-widget-details">
                                        <Row>
                                          {/* Leg from BEGIN */}
                                          <Col sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                                            <Col className='element-padding horizontal'>
                                              <ControlLabel>From</ControlLabel>
                                            </Col>

                                            <Col className='element-padding horizontal' xs={(this.state.colspan_subField) ? this.state.colspan_subField : 3}>
                                              <FormControl id="start_type" componentClass="select" placeholder="select" value={this.state.forms[leadFormIndex].logistics.legs[legIndex].start_type != undefined ? this.state.forms[leadFormIndex].logistics.legs[legIndex].start_type : ''} onChange={(event) => this.handleLegInputChange(event, leadFormIndex, leg.id, () => this.selectLegPointFormData(leadFormIndex, leg.id, 'start', ''))} >
                                                <option value='Hub'>Hub</option>
                                                <option value='Site'>Site</option>
                                              </FormControl>
                                            </Col>

                                            <Col className='element-padding horizontal' xs={(this.state.colspan_subField) ? this.state.colspan_subField : 9}>
                                              <FormControl id="start_id" componentClass="select" placeholder="select" value={this.state.forms[leadFormIndex].logistics.legs[legIndex].start_id != undefined ? this.state.forms[leadFormIndex].logistics.legs[legIndex].start_id : ''} onChange={(event) => this.handleLegInputChange(event, leadFormIndex, leg.id, () => this.selectLegPointFormData(leadFormIndex, leg.id, 'start', this.state.forms[leadFormIndex].logistics.legs[legIndex].start_id))} >
                                                <option value=''></option>
                                                {(leg.start_type === 'Hub' && this.state.hubs) ?
                                                  this.state.hubs.map((item, i) => (
                                                    <option value={item.id} key={i} disabled={((!item.active) ? true : false)}>{item.name}</option>
                                                  )) : false}
                                                {(leg.start_type === 'Site' && this.state.sites) ?
                                                  this.state.sites.map((item, i) => (
                                                    <option value={item.id} key={i} disabled={((!item.active) ? true : false)}>{item.name}</option>
                                                  )) : false}
                                              </FormControl>
                                            </Col>

                                            <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                                              <ControlLabel>Address</ControlLabel>
                                              <FormControl id='start_address' type="text" value={this.state.forms[leadFormIndex].logistics.legs[legIndex].start_address != undefined ? this.state.forms[leadFormIndex].logistics.legs[legIndex].start_address : ''} onChange={(event) => this.handleLegInputChange(event, leadFormIndex, leg.id)} />
                                            </Col>
                                            <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                                              <ControlLabel>Country</ControlLabel>
                                              <FormControl id="start_country" type="text" value={this.state.forms[leadFormIndex].logistics.legs[legIndex].start_country!= undefined ? this.state.forms[leadFormIndex].logistics.legs[legIndex].start_country : ''} onChange={(event) => this.handleLegInputChange(event, leadFormIndex, leg.id)} list="legCountriesDataList" />
                                              {this.state.countries && 
                                                <datalist id="legCountriesDataList">
                                                {this.state.countries.map(function(item, i){
                                                  return <option key={i} value={item.country} />;
                                                },this)}
                                                </datalist>
                                              }
                                            </Col>

                                            <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 12}>
                                              <ControlLabel>Contact person</ControlLabel>
                                              <FormControl id="start_contact_name" type="text" value={this.state.forms[leadFormIndex].logistics.legs[legIndex].start_contact_name != undefined ? this.state.forms[leadFormIndex].logistics.legs[legIndex].start_contact_name : ''} onChange={(event) => this.handleLegInputChange(event, leadFormIndex, leg.id, () => this.selectLegPointContactFormData(leadFormIndex, leg.id, 'start', this.state.forms[leadFormIndex].logistics.legs[legIndex].start_contact_name))} readOnly={((!this.state.forms[leadFormIndex].logistics.legs[legIndex].start_id) ? true : false)} list="startContactDataList" />
                                              <datalist id="startContactDataList">
                                                {(this.state.forms[leadFormIndex].logistics.legs[legIndex].start_type === 'Hub' && this.state.forms[leadFormIndex].logistics.legs[legIndex].start_id && this.state.hubs && this.state.hubs.find(x => x.id == this.state.forms[leadFormIndex].logistics.legs[legIndex].start_id)) &&
                                                  this.state.hubs.find(x => x.id == this.state.forms[leadFormIndex].logistics.legs[legIndex].start_id).contacts.map((item, i) => (
                                                    <option key={i} value={item.name} />
                                                  ), this)
                                                }
                                                {(this.state.forms[leadFormIndex].logistics.legs[legIndex].start_type === 'Site' && this.state.forms[leadFormIndex].logistics.legs[legIndex].start_id && this.state.sites && this.state.sites.find(x => x.id == this.state.forms[leadFormIndex].logistics.legs[legIndex].start_id)) &&
                                                  this.state.sites.find(x => x.id == this.state.forms[leadFormIndex].logistics.legs[legIndex].start_id).contacts.map((item, i) => (
                                                    <option key={i} value={item.name} />
                                                  ), this)
                                                }
                                              </datalist>
                                            </Col>
                                            <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                                              <ControlLabel>Phone</ControlLabel>
                                              <FormControl id='start_contact_phone' type="text" value={this.state.forms[leadFormIndex].logistics.legs[legIndex].start_contact_phone != undefined ? this.state.forms[leadFormIndex].logistics.legs[legIndex].start_contact_phone : ''} onChange={(event) => this.handleLegInputChange(event, leadFormIndex, leg.id)} readOnly={((!this.state.forms[leadFormIndex].logistics.legs[legIndex].start_id) ? true : false)} />
                                            </Col>
                                            <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                                              <ControlLabel>Email</ControlLabel>
                                              <FormControl id='start_contact_email' type="text" value={this.state.forms[leadFormIndex].logistics.legs[legIndex].start_contact_email != undefined ? this.state.forms[leadFormIndex].logistics.legs[legIndex].start_contact_email : ''} onChange={(event) => this.handleLegInputChange(event, leadFormIndex, leg.id)} readOnly={((!this.state.forms[leadFormIndex].logistics.legs[legIndex].start_id) ? true : false)} />
                                            </Col>

                                            <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 12}>
                                              <ControlLabel>Agent</ControlLabel>
                                              <FormControl id="start_agent_id" componentClass="select" placeholder="select" value={this.state.forms[leadFormIndex].logistics.legs[legIndex].start_agent_id != undefined ? this.state.forms[leadFormIndex].logistics.legs[legIndex].start_agent_id : ''} onChange={(event) => this.handleLegInputChange(event, leadFormIndex, leg.id, () => this.selectLegPointAgentFormData(leadFormIndex, leg.id, 'start', this.state.forms[leadFormIndex].logistics.legs[legIndex].start_id))} disabled={((leg.start_type === 'Hub' && leg.start_id) ? false : true)} >
                                                <option value=''></option>
                                                {(this.state.forms[leadFormIndex].logistics.legs[legIndex].start_type === 'Hub' && this.state.forms[leadFormIndex].logistics.legs[legIndex].start_id && this.state.hubs && this.state.hubs.find(x => x.id == this.state.forms[leadFormIndex].logistics.legs[legIndex].start_id)) ?
                                                  this.state.hubs.find(x => x.id == this.state.forms[leadFormIndex].logistics.legs[legIndex].start_id).agents.map((item, i) => (
                                                    <option value={item.id} key={i}>{item.name}</option>
                                                  )) : false}
                                              </FormControl>
                                            </Col>
                                            <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 12}>
                                              <ControlLabel>Agent contact person</ControlLabel>
                                              <FormControl id="start_agent_contact_name" type="text" value={this.state.forms[leadFormIndex].logistics.legs[legIndex].start_agent_contact_name != undefined ? this.state.forms[leadFormIndex].logistics.legs[legIndex].start_agent_contact_name : ''} onChange={(event) => this.handleLegInputChange(event, leadFormIndex, leg.id, () => this.selectLegPointAgentContactFormData(leadFormIndex, leg.id, 'start', this.state.forms[leadFormIndex].logistics.legs[legIndex].start_agent_id, this.state.forms[leadFormIndex].logistics.legs[legIndex].start_agent_contact_name))} readOnly={((!this.state.forms[leadFormIndex].logistics.legs[legIndex].start_agent_id) ? true : false)} list="startAgentContactDataList" />
                                              <datalist id="startAgentContactDataList">
                                                {(this.state.forms[leadFormIndex].logistics.legs[legIndex].start_type === 'Hub' && this.state.forms[leadFormIndex].logistics.legs[legIndex].start_id && this.state.hubs && this.state.forms[leadFormIndex].logistics.legs[legIndex].start_agent_id && this.state.hubs.find(x => x.id == this.state.forms[leadFormIndex].logistics.legs[legIndex].start_id).agents.find(x => x.id == this.state.forms[leadFormIndex].logistics.legs[legIndex].start_agent_id) && this.state.hubs.find(x => x.id == this.state.forms[leadFormIndex].logistics.legs[legIndex].start_id).agents.find(x => x.id == this.state.forms[leadFormIndex].logistics.legs[legIndex].start_agent_id).contacts) &&
                                                  this.state.hubs.find(x => x.id == this.state.forms[leadFormIndex].logistics.legs[legIndex].start_id).agents.find(x => x.id == this.state.forms[leadFormIndex].logistics.legs[legIndex].start_agent_id).contacts.map((item, i) => (
                                                    <option key={i} value={item.name} />
                                                  ), this)
                                                }
                                              </datalist>
                                            </Col>
                                            <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                                              <ControlLabel>Agent contact phone</ControlLabel>
                                              <FormControl id='start_agent_contact_phone' type="text" value={this.state.forms[leadFormIndex].logistics.legs[legIndex].start_agent_contact_phone != undefined ? this.state.forms[leadFormIndex].logistics.legs[legIndex].start_agent_contact_phone : ''} onChange={(event) => this.handleLegInputChange(event, leadFormIndex, leg.id)} readOnly={((!this.state.forms[leadFormIndex].logistics.legs[legIndex].start_agent_id) ? true : false)} />
                                            </Col>
                                            <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                                              <ControlLabel>Agent contact email</ControlLabel>
                                              <FormControl id='start_agent_contact_email' type="text" value={this.state.forms[leadFormIndex].logistics.legs[legIndex].start_agent_contact_email != undefined ? this.state.forms[leadFormIndex].logistics.legs[legIndex].start_agent_contact_email : ''} onChange={(event) => this.handleLegInputChange(event, leadFormIndex, leg.id)} readOnly={((!this.state.forms[leadFormIndex].logistics.legs[legIndex].start_agent_id) ? true : false)} />
                                            </Col>
                                          </Col>
                                          {/* Leg from END */}

                                          {/* Leg to BEGIN */}
                                          <Col sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                                            <Col className='element-padding horizontal'>
                                              <ControlLabel>To</ControlLabel>
                                            </Col>

                                            <Col className='element-padding horizontal' xs={(this.state.colspan_subField) ? this.state.colspan_subField : 3}>
                                              <FormControl id="end_type" componentClass="select" placeholder="select" value={this.state.forms[leadFormIndex].logistics.legs[legIndex].end_type != undefined ? this.state.forms[leadFormIndex].logistics.legs[legIndex].end_type : ''} onChange={(event) => this.handleLegInputChange(event, leadFormIndex, leg.id, () => this.selectLegPointFormData(leadFormIndex, leg.id, 'end', ''))} >
                                                <option value='Hub'>Hub</option>
                                                <option value='Site'>Site</option>
                                              </FormControl>
                                            </Col>

                                            <Col className='element-padding horizontal' xs={(this.state.colspan_subField) ? this.state.colspan_subField : 9}>
                                              <FormControl id="end_id" componentClass="select" placeholder="select" value={this.state.forms[leadFormIndex].logistics.legs[legIndex].end_id != undefined ? this.state.forms[leadFormIndex].logistics.legs[legIndex].end_id : ''} onChange={(event) => this.handleLegInputChange(event, leadFormIndex, leg.id, () => this.selectLegPointFormData(leadFormIndex, leg.id, 'end', this.state.forms[leadFormIndex].logistics.legs[legIndex].end_id))} >
                                                <option value=''></option>
                                                {(leg.end_type === 'Hub' && this.state.hubs) ?
                                                  this.state.hubs.map((item, i) => (
                                                    <option value={item.id} key={i} disabled={((!item.active) ? true : false)}>{item.name}</option>
                                                  )) : false}
                                                {(leg.end_type === 'Site' && this.state.sites) ?
                                                  this.state.sites.map((item, i) => (
                                                    <option value={item.id} key={i} disabled={((!item.active) ? true : false)}>{item.name}</option>
                                                  )) : false}
                                              </FormControl>
                                            </Col>

                                            <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                                              <ControlLabel>Address</ControlLabel>
                                              <FormControl id='end_address' type="text" value={this.state.forms[leadFormIndex].logistics.legs[legIndex].end_address != undefined ? this.state.forms[leadFormIndex].logistics.legs[legIndex].end_address : ''} onChange={(event) => this.handleLegInputChange(event, leadFormIndex, leg.id)} />
                                            </Col>
                                            <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                                              <ControlLabel>Country</ControlLabel>
                                              <FormControl id="end_country" type="text" value={this.state.forms[leadFormIndex].logistics.legs[legIndex].end_country != undefined ? this.state.forms[leadFormIndex].logistics.legs[legIndex].end_country : ''} onChange={(event) => this.handleLegInputChange(event, leadFormIndex, leg.id)} list="legCountriesDataList" />
                                              {this.state.countries && 
                                                <datalist id="legCountriesDataList">
                                                {this.state.countries.map(function(item, i){
                                                  return <option key={i} value={item.country} />;
                                                },this)}
                                                </datalist>
                                              }
                                            </Col>

                                            <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 12}>
                                              <ControlLabel>Contact person</ControlLabel>
                                              <FormControl id="end_contact_name" type="text" value={this.state.forms[leadFormIndex].logistics.legs[legIndex].end_contact_name != undefined ? this.state.forms[leadFormIndex].logistics.legs[legIndex].end_contact_name : ''} onChange={(event) => this.handleLegInputChange(event, leadFormIndex, leg.id, () => this.selectLegPointContactFormData(leadFormIndex, leg.id, 'end', this.state.forms[leadFormIndex].logistics.legs[legIndex].end_contact_name))} readOnly={((!this.state.forms[leadFormIndex].logistics.legs[legIndex].end_id) ? true : false)} list="endContactDataList" />
                                              <datalist id="endContactDataList">
                                                {(this.state.forms[leadFormIndex].logistics.legs[legIndex].end_type === 'Hub' && this.state.forms[leadFormIndex].logistics.legs[legIndex].end_id && this.state.hubs && this.state.hubs.find(x => x.id == this.state.forms[leadFormIndex].logistics.legs[legIndex].end_id)) &&
                                                  this.state.hubs.find(x => x.id == this.state.forms[leadFormIndex].logistics.legs[legIndex].end_id).contacts.map((item, i) => (
                                                    <option key={i} value={item.name} />
                                                  ), this)
                                                }
                                                {(this.state.forms[leadFormIndex].logistics.legs[legIndex].end_type === 'Site' && this.state.forms[leadFormIndex].logistics.legs[legIndex].end_id && this.state.sites && this.state.sites.find(x => x.id == this.state.forms[leadFormIndex].logistics.legs[legIndex].end_id)) &&
                                                  this.state.sites.find(x => x.id == this.state.forms[leadFormIndex].logistics.legs[legIndex].end_id).contacts.map((item, i) => (
                                                    <option key={i} value={item.name} />
                                                  ), this)
                                                }
                                              </datalist>
                                            </Col>
                                            <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                                              <ControlLabel>Phone</ControlLabel>
                                              <FormControl id='end_contact_phone' type="text" value={this.state.forms[leadFormIndex].logistics.legs[legIndex].end_contact_phone != undefined ? this.state.forms[leadFormIndex].logistics.legs[legIndex].end_contact_phone : ''} onChange={(event) => this.handleLegInputChange(event, leadFormIndex, leg.id)} readOnly={((!this.state.forms[leadFormIndex].logistics.legs[legIndex].end_id) ? true : false)} />
                                            </Col>
                                            <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                                              <ControlLabel>Email</ControlLabel>
                                              <FormControl id='end_contact_email' type="text" value={this.state.forms[leadFormIndex].logistics.legs[legIndex].end_contact_email != undefined ? this.state.forms[leadFormIndex].logistics.legs[legIndex].end_contact_email : ''} onChange={(event) => this.handleLegInputChange(event, leadFormIndex, leg.id)} readOnly={((!this.state.forms[leadFormIndex].logistics.legs[legIndex].end_id) ? true : false)} />
                                            </Col>

                                            <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 12}>
                                              <ControlLabel>Agent</ControlLabel>
                                              <FormControl id="end_agent_id" componentClass="select" placeholder="select" value={this.state.forms[leadFormIndex].logistics.legs[legIndex].end_agent_id != undefined ? this.state.forms[leadFormIndex].logistics.legs[legIndex].end_agent_id : ''} onChange={(event) => this.handleLegInputChange(event, leadFormIndex, leg.id, () => this.selectLegPointAgentFormData(leadFormIndex, leg.id, 'end', this.state.forms[leadFormIndex].logistics.legs[legIndex].end_id))} disabled={((leg.end_type === 'Hub' && leg.end_id) ? false : true)} >
                                                <option value=''></option>
                                                {(this.state.forms[leadFormIndex].logistics.legs[legIndex].end_type === 'Hub' && this.state.forms[leadFormIndex].logistics.legs[legIndex].end_id && this.state.hubs && this.state.hubs.find(x => x.id == this.state.forms[leadFormIndex].logistics.legs[legIndex].end_id)) ?
                                                  this.state.hubs.find(x => x.id == this.state.forms[leadFormIndex].logistics.legs[legIndex].end_id).agents.map((item, i) => (
                                                    <option value={item.id} key={i}>{item.name}</option>
                                                  )) : false}
                                              </FormControl>
                                            </Col>
                                            <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 12}>
                                              <ControlLabel>Agent contact person</ControlLabel>
                                              <FormControl id="end_agent_contact_name" type="text" value={this.state.forms[leadFormIndex].logistics.legs[legIndex].end_agent_contact_name != undefined ? this.state.forms[leadFormIndex].logistics.legs[legIndex].end_agent_contact_name : ''} onChange={(event) => this.handleLegInputChange(event, leadFormIndex, leg.id, () => this.selectLegPointAgentContactFormData(leadFormIndex, leg.id, 'end', this.state.forms[leadFormIndex].logistics.legs[legIndex].end_agent_id, this.state.forms[leadFormIndex].logistics.legs[legIndex].end_agent_contact_name))} readOnly={((!this.state.forms[leadFormIndex].logistics.legs[legIndex].end_agent_id) ? true : false)} list="endAgentContactDataList" />
                                              <datalist id="endAgentContactDataList">
                                                {(this.state.forms[leadFormIndex].logistics.legs[legIndex].end_type === 'Hub' && this.state.forms[leadFormIndex].logistics.legs[legIndex].end_id && this.state.hubs && this.state.forms[leadFormIndex].logistics.legs[legIndex].end_agent_id && this.state.hubs.find(x => x.id == this.state.forms[leadFormIndex].logistics.legs[legIndex].end_id).agents.find(x => x.id == this.state.forms[leadFormIndex].logistics.legs[legIndex].end_agent_id) && this.state.hubs.find(x => x.id == this.state.forms[leadFormIndex].logistics.legs[legIndex].end_id).agents.find(x => x.id == this.state.forms[leadFormIndex].logistics.legs[legIndex].end_agent_id).contacts) &&
                                                  this.state.hubs.find(x => x.id == this.state.forms[leadFormIndex].logistics.legs[legIndex].end_id).agents.find(x => x.id == this.state.forms[leadFormIndex].logistics.legs[legIndex].end_agent_id).contacts.map((item, i) => (
                                                    <option key={i} value={item.name} />
                                                  ), this)
                                                }
                                              </datalist>
                                            </Col>
                                            <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                                              <ControlLabel>Agent contact phone</ControlLabel>
                                              <FormControl id='end_agent_contact_phone' type="text" value={this.state.forms[leadFormIndex].logistics.legs[legIndex].end_agent_contact_phone != undefined ? this.state.forms[leadFormIndex].logistics.legs[legIndex].end_agent_contact_phone : ''} onChange={(event) => this.handleLegInputChange(event, leadFormIndex, leg.id)} readOnly={((!this.state.forms[leadFormIndex].logistics.legs[legIndex].end_agent_id) ? true : false)} />
                                            </Col>
                                            <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                                              <ControlLabel>Agent contact email</ControlLabel>
                                              <FormControl id='end_agent_contact_email' type="text" value={this.state.forms[leadFormIndex].logistics.legs[legIndex].end_agent_contact_email != undefined ? this.state.forms[leadFormIndex].logistics.legs[legIndex].end_agent_contact_email : ''} onChange={(event) => this.handleLegInputChange(event, leadFormIndex, leg.id)} readOnly={((!this.state.forms[leadFormIndex].logistics.legs[legIndex].end_agent_id) ? true : false)} />
                                            </Col>
                                          </Col>
                                          {/* Leg to END */}
                                        
                                          {/* Leg freight method BEGIN */}
                                          <Col className='element-padding horizontal' xs={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                                            <ControlLabel>Freight method</ControlLabel>
                                            <FormControl id="freight_method" componentClass="select" placeholder="select" value={this.state.forms[leadFormIndex].logistics.legs[legIndex].freight_method != undefined ? this.state.forms[leadFormIndex].logistics.legs[legIndex].freight_method : ''} onChange={(event) => this.handleLegInputChange(event, leadFormIndex, leg.id)} >
                                              <option value=''></option>
                                              {(this.state.freightMethods) ?
                                                this.state.freightMethods.map((item, i) => (
                                                  <option value={item.id} key={i}>{item.name}</option>
                                                )) : false}
                                            </FormControl>
                                          </Col>
                                          {/* Leg freight method END */}

                                          {/* Leg freight cost BEGIN */}
                                          <Col className='element-padding horizontal' xs={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                                            <ControlLabel>Freight cost</ControlLabel>
                                            <FormControl id='freight_cost' type="number" step={0.01} value={this.state.forms[leadFormIndex].logistics.legs[legIndex].freight_cost != undefined ? this.state.forms[leadFormIndex].logistics.legs[legIndex].freight_cost : ''} onChange={(event) => this.handleLegInputChange(event, leadFormIndex, leg.id, () => this.calculateLeadEnergyContentsAndPrices(this.state.forms[leadFormIndex]))} />
                                          </Col>
                                          {/* Leg freight cost END */}

                                          {/* Leg currency BEGIN */}
                                          <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                                            <ControlLabel>Currency</ControlLabel>
                                            <FormControl id="currency" componentClass="select" placeholder="select" value={this.state.forms[leadFormIndex].logistics.legs[legIndex].currency != undefined ? this.state.forms[leadFormIndex].logistics.legs[legIndex].currency : ''} onChange={(event) => this.handleLegInputChange(event, leadFormIndex, leg.id, () => this.calculateLeadEnergyContentsAndPrices(this.state.forms[leadFormIndex]))} >
                                              <option value=''></option>
                                              {this.state.currencies.map((item, i) => (
                                                <option value={item.id} key={i} disabled={((!item.active) ? true : false)}>{item.name}</option>
                                              ))}
                                            </FormControl>
                                          </Col>
                                          {/* Leg currency END */}

                                          {/* Leg exchange rate BEGIN */}
                                          <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                                            <ControlLabel>Exchange rate (to euros)</ControlLabel>
                                            <FormControl id='exchange_rate_to_eur' type="number" step={0.01} value={this.state.forms[leadFormIndex].logistics.legs[legIndex].exchange_rate_to_eur != undefined ? this.state.forms[leadFormIndex].logistics.legs[legIndex].exchange_rate_to_eur : ''} readOnly={(!this.state.forms[leadFormIndex].logistics.legs[legIndex].currency || this.state.forms[leadFormIndex].logistics.legs[legIndex].currency === 'EUR')} onChange={(event) => this.handleLegInputChange(event, leadFormIndex, leg.id, () => this.calculateLeadEnergyContentsAndPrices(this.state.forms[leadFormIndex]))} />
                                          </Col>
                                          {/* Leg exchange rate END */}

                                          {/* Leg quantity BEGIN */}
                                          <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                                            <ControlLabel>Quantity</ControlLabel>
                                            <FormControl id='quantity' type="number" step={1} value={this.state.forms[leadFormIndex].logistics.legs[legIndex].quantity != undefined ? this.state.forms[leadFormIndex].logistics.legs[legIndex].quantity : ''} onChange={(event) => this.handleLegInputChange(event, leadFormIndex, leg.id, () => this.calculateLeadEnergyContentsAndPrices(this.state.forms[leadFormIndex]))} readOnly={(this.state.forms[leadFormIndex].logistics.legs[legIndex].pricing_unit === 'lump sum')}/>
                                          </Col>
                                          {/* Leg quantity END */}

                                          {/* Leg pricing unit BEGIN */}
                                          <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                                            <ControlLabel>Unit</ControlLabel>
                                            <FormControl id="pricing_unit" componentClass="select" placeholder="select" value={this.state.forms[leadFormIndex].logistics.legs[legIndex].pricing_unit != undefined ? this.state.forms[leadFormIndex].logistics.legs[legIndex].pricing_unit : ''} onChange={(event) => this.handleLegInputChange(event, leadFormIndex, leg.id, () => this.calculateLeadEnergyContentsAndPrices(this.state.forms[leadFormIndex]))} >
                                              <option value=''></option>
                                              {this.state.legPricingUnits.map((item, i) => (
                                                <option value={item.id} key={i} disabled={((!item.active) ? true : false)}>{item.name}</option>
                                              ))}
                                            </FormControl>
                                          </Col>
                                          {/* Leg pricing unit END */}

                                          {/* Leg share of freight cost BEGIN */}
                                          <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                                            <ControlLabel>Share of freight cost (if lump sum)</ControlLabel>
                                            <InputGroup>
                                              <FormControl id='share_of_total' type="number" step={1} min={0} max={100} value={this.state.forms[leadFormIndex].logistics.legs[legIndex].share_of_total != undefined ? this.state.forms[leadFormIndex].logistics.legs[legIndex].share_of_total : ''} onChange={(event) => this.handleLegInputChange(event, leadFormIndex, leg.id)} readOnly={(this.state.forms[leadFormIndex].logistics.legs[legIndex].pricing_unit !== 'lump sum')} />
                                              <InputGroup.Addon>%</InputGroup.Addon>
                                            </InputGroup>
                                          </Col>
                                          {/* Leg share of freight cost END */}

                                          {/* Leg credit terms BEGIN */}
                                          <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                                            <ControlLabel>Credit terms</ControlLabel>
                                            <FormControl id="credit_terms" type="text" value={this.state.forms[leadFormIndex].logistics.legs[legIndex].credit_terms != undefined ? this.state.forms[leadFormIndex].logistics.legs[legIndex].credit_terms : ''} onChange={(event) => this.handleLegInputChange(event, leadFormIndex, leg.id)} list="creditTermsDataList" />
                                          </Col>
                                          {/* Leg credit terms BEGIN */}

                                          {/* Leg incoterms BEGIN */}
                                          <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                                            <ControlLabel>Incoterms</ControlLabel>
                                            <FormControl id="incoterms_id" componentClass="select" placeholder="select" value={this.state.forms[leadFormIndex].logistics.legs[legIndex].incoterms_id != undefined ? this.state.forms[leadFormIndex].logistics.legs[legIndex].incoterms_id : ''} onChange={(event) => this.handleLegInputChange(event, leadFormIndex, leg.id)} >
                                              <option value=''></option>
                                              {this.state.incoterms.map((item, i) => (
                                                <option value={item.id} key={i} disabled={((!item.active) ? true : false)}>{item.name}</option>
                                              ))}
                                            </FormControl>
                                          </Col>
                                          {/* Leg incoterms BEGIN */}

                                          {/* Leg freight insured by BEGIN */}
                                          <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                                            <ControlLabel>Freight insured by</ControlLabel>
                                            <FormControl id="freight_insured_by" componentClass="select" placeholder="select" value={this.state.forms[leadFormIndex].logistics.legs[legIndex].freight_insured_by != undefined ? this.state.forms[leadFormIndex].logistics.legs[legIndex].freight_insured_by : ''} onChange={(event) => this.handleLegInputChange(event, leadFormIndex, leg.id)} >
                                              {this.state.freightInsuredBy.map((item, i) => (
                                                <option value={item.id} key={i} disabled={((!item.active) ? true : false)}>{item.name}</option>
                                              ))}
                                            </FormControl>
                                          </Col>
                                          {/* Leg freight insured by BEGIN */}

                                          {/* Leg unit id BEGIN */}
                                          <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                                            <ControlLabel>Unit ID</ControlLabel>
                                            <FormControl id="unit_id" type="text" value={this.state.forms[leadFormIndex].logistics.legs[legIndex].unit_id != undefined ? this.state.forms[leadFormIndex].logistics.legs[legIndex].unit_id : ''} placeholder="Type unit id" onChange={(event) => this.handleLegInputChange(event, leadFormIndex, leg.id)} />
                                          </Col>
                                          {/* Leg unit id END */}

                                          {/* Departure date form BEGIN */}
                                          <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                                            <ControlLabel>Departure date</ControlLabel>
                                            <InputGroup>
                                              <DateTime inputProps={{ id: 'leg' + leg.id + '_date_of_departure', placeholder: this.state.dateInputFormat }}
                                                className="date-input"
                                                value={this.state.forms[leadFormIndex].logistics.legs[legIndex].date_of_departure}
                                                dateFormat={this.state.dateInputFormat}
                                                timeFormat={false}
                                                viewMode={this.state.dateMode}
                                                closeOnSelect={true}
                                                utc={true}
                                                locale={"en"}
                                                onChange={(date) => this.handleDateLeg(leadFormIndex,legIndex,'leg' + leg.id + '_date_of_departure', date)} />
                                              <InputGroup.Addon><span className={'icon ion-calendar'}></span></InputGroup.Addon>
                                            </InputGroup>

                                            {/* <Button className="clear-date-filter" onClick={this.clearDate}>Clear</Button> */}
                                          </Col>
                                          {/* Departure date form END */}

                                          {/* Estimated time of arrival  form BEGIN */}
                                          <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                                            <ControlLabel>Estimated time of arrival </ControlLabel>
                                            <InputGroup>
                                              <DateTime inputProps={{ id: 'leg' + leg.id + '_estimated_date_of_arrival', placeholder: this.state.dateInputFormat }}
                                                className="date-input"
                                                value={this.state.forms[leadFormIndex].logistics.legs[legIndex].estimated_date_of_arrival}
                                                dateFormat={this.state.dateInputFormat}
                                                timeFormat={false}
                                                viewMode={this.state.dateMode}
                                                closeOnSelect={true}
                                                utc={true}
                                                locale={"en"}
                                                onChange={(date) => this.handleDateLeg(leadFormIndex,legIndex,'leg' + leg.id + '_estimated_date_of_arrival', date)} />
                                              <InputGroup.Addon><span className={'icon ion-calendar'}></span></InputGroup.Addon>
                                            </InputGroup>

                                            {/* <Button className="clear-date-filter" onClick={this.clearDate}>Clear</Button> */}
                                          </Col>
                                          {/* Estimated time of arrival  form END */}

                                          {/* Leg related files BEGIN */}                                    <Col className='element-padding horizontal' sm={12}>
                                            <ControlLabel>Related files</ControlLabel>
                                            <FormAddFileToList 
                                            inputId="legDocument"
                                            inputValue={this.state.forms[leadFormIndex].logistics.legs[legIndex].legDocument || {name: ''}}
                                            inputOnChange={(event) => this.handleLegInputChange(event, leadFormIndex, leg.id)}
                                            addToListFunc={()=>this.fileUploadLeg(leadFormIndex, legIndex)}
                                            deleteFromListFunc={(event)=>this.fileDeleteLeg(event, leadFormIndex, legIndex)}
                                            listArray={this.state.forms[leadFormIndex].logistics.legs[legIndex].documents}
                                            label=""
                                            active={this.state.forms[leadFormIndex].active} />
                                          </Col>
                                          {/* Leg related files END */}

                                          {/* Leg additional information BEGIN */}
                                          <Col className='element-padding horizontal' sm={12}>
                                            <ControlLabel>Additional information</ControlLabel>
                                            <FormControl id="additional_info" componentClass="textarea" value={this.state.forms[leadFormIndex].logistics.legs[legIndex].additional_info != undefined ? this.state.forms[leadFormIndex].logistics.legs[legIndex].additional_info : ''} placeholder="" onChange={(event) => this.handleLegInputChange(event, leadFormIndex, leg.id)} />
                                          </Col>
                                          {/* Leg additional information END */}
                                        
                                        </Row>
                                
                                      </div>
                                    </FormGroup>
                                    {/* Leg details form END */}

                                  </div>
                                </div>
                              )) : false}
                            </div>

                          </Col>

                          <Col className='lead-widget input-field-padding'>
                            <Button className="full-width-add-button ion-plus" onClick={() => this.addLeg(leadFormIndex)}> Add leg</Button>
                          </Col>
                        </Col>
                      ))}
                    </Row>
                    {/* Legs END */}

                  </div>
                </div>
                {/* Logistics form END */}

                {/* Additional costs form BEGIN */}
                <div id="section-additional_costs" className='subsection-detail-holder'>
                  <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-lead-close">Expand / collapse</Tooltip>} delayShow={this.state.tooltipDelayShow}>
                  <h1 className="subsection-header" onClick={(event) => this.toggleSubsectionVisibility(event, 'additional_costs')}>
                    Additional costs
                    <Button className={'subsection-toggle ion-ios-arrow-down' + (!this.state.sectionExpanded_additional_costs ? '' : ' open')}></Button>
                    </h1>
                  </OverlayTrigger>  
                  <div className={'collapsible-content' + (!this.state.sectionExpanded_additional_costs ? ' closed' : '')}>

                    {/* Additional costs BEGIN */}
                    <Row>
                      <Col id='additional_costs' className='subsection-label' sm={this.state.colspan_sectionLabel}>
                        Additional cost details
                      </Col>
                      {this.state.forms.map((leadForm, leadFormIndex) => (
                        <Col key={'leadAdditionalCosts' + leadForm.id} className='subsection-content' sm={this.state.colspan_sectionContent} style={subsectionStyle}>
                          <Col>

                            <div>
                              {(this.state.forms[leadFormIndex] && this.state.forms[leadFormIndex].additional_costs) ? this.state.forms[leadFormIndex].additional_costs.map((additional_cost, additional_costIndex) => (
                                <div key={'additional_cost' + additional_cost.id} className='lead-widget'>
                                  {/* Additional_cost header BEGIN */}
                                  <h1 className='lead-widget-header'>
                                    <OverlayTrigger placement="left" overlay={<Tooltip id="tooltip-additional_cost-collapse">Expand / collapse</Tooltip>} delayShow={this.state.tooltipDelayShow}>  
                                      <span onClick={(event) => this.toggleWidgetVisibility(event, leadFormIndex, 'additional_cost', additional_costIndex)}>
                                          <Button className={"lead-widget-header-button ion-ios-arrow-down" + (!additional_cost.expanded ? '' : ' open')}></Button>
                                          <span className="lead-widget-header-columns additional_cost">{additional_cost.cost_type ? (this.state.additional_costCostTypes.find(elem => elem.id.toUpperCase() == additional_cost.cost_type.toUpperCase()).name) : 'Additional cost ' + (additional_costIndex + 1)}</span>
                                        </span>
                                      </OverlayTrigger>  
                                    <span className="floatRight">
                                      <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-leg-collapse">Toggle edit</Tooltip>} delayShow={this.state.tooltipDelayShow}>
                                        <Button className="lead-widget-header-button ion-edit" onClick={(event) => this.toggleWidgetVisibility(event, leadFormIndex, 'additional_cost', additional_costIndex)}></Button>
                                      </OverlayTrigger>
                                      
                                      <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-additional_cost-collapse">Move this additional cost up</Tooltip>} delayShow={this.state.tooltipDelayShow}>
                                        <Button className="lead-widget-header-button ion-android-arrow-up" onClick={() => this.reorderAdditional_costs(leadFormIndex, additional_cost.id, true)}></Button>
                                      </OverlayTrigger>

                                      <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-additional_cost-collapse">Move this additional cost down</Tooltip>} delayShow={this.state.tooltipDelayShow}>
                                        <Button className="lead-widget-header-button ion-android-arrow-down" onClick={() => this.reorderAdditional_costs(leadFormIndex, additional_cost.id, false)}></Button>
                                      </OverlayTrigger>

                                      <OverlayTrigger placement="bottom" overlay={<Tooltip id="tooltip-additional_cost-collapse">Delete this additional_cost</Tooltip>} delayShow={this.state.tooltipDelayShow}>
                                        <Button className="lead-widget-header-button ion-android-delete" onClick={() => this.removeAdditional_cost(leadFormIndex, additional_cost.id, () => this.calculateLeadEnergyContentsAndPrices(this.state.forms[leadFormIndex]))}></Button>
                                      </OverlayTrigger>
                                    </span>
                                  </h1>
                                  {/* Additional_cost header END */}

                                  {/* Additional_cost info BEGIN */}
                                  <div className='lead-widget-sub-contents info-status'>
                                    <Grid fluid={true}>
                                      <Col sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                                        <Row className='element-padding vertical'>
                                          <Col className='element-padding horizontal' xs={10}>
                                            <Row>
                                              <Col xs={3} className='info-status-header-small'>
                                                Type:
                                              </Col>
                                              <Col xs={9}>
                                                {additional_cost.cost_type ? (this.state.additional_costCostTypes.find(elem => elem.id.toUpperCase() == additional_cost.cost_type.toUpperCase()).name) : ''}
                                              </Col>
                                            </Row>
                                          </Col>
                                        </Row>
                                      </Col>
                                      <Col sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                                        <Col className='element-padding all-sides' xs={(this.state.colspan_subField) ? this.state.colspan_subField : 8}>
                                          <Row>
                                            <Col sm={12} className='info-status-header-small'>
                                              Indicative cost:
                                          </Col>
                                          </Row>
                                          <Row>
                                            <Col sm={12} className={'info-status-header' + (!additional_cost.sumTotalCost ? ' unspecified' : '')}>
                                              {(additional_cost.sumTotalCost) ? additional_cost.sumTotalCost.toFixed(2) : '--.--'} {' €'}
                                            </Col>
                                          </Row>
                                        </Col >
                                        {(this.state.forms[leadFormIndex].additional_costs[additional_costIndex].pricing_unit === 'lump sum' && this.state.forms[leadFormIndex].additional_costs[additional_costIndex].share_of_total) &&
                                          <Col className='element-padding all-sides' xs={(this.state.colspan_subField) ? this.state.colspan_subField : 4}>
                                            <Row>
                                              <Col sm={12} className='info-status-header-small'>
                                                Share of cost:
                                              </Col>
                                            </Row>
                                            <Row>
                                              <Col sm={12} className='info-status-header'>
                                                {additional_cost.share_of_total + ' %'}
                                              </Col>
                                            </Row>
                                          </Col>
                                        }
                                      </Col>
                                    </Grid>
                                  </div>
                                  {/* Additional_cost info END */}

                                  <div className={'collapsible-content' + (!additional_cost.expanded ? ' closed' : '')}>

                                    {/* Additional_cost details form BEGIN */}
                                    <FormGroup conrolId="additional_costInformation">
                                      <div className="lead-widget-sub-contents lead-widget-details">
                                        <Row>  
                                          {/* Additional_cost cost type BEGIN */}
                                          <Col className='element-padding horizontal' xs={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                                            <ControlLabel>Cost type</ControlLabel>
                                            <FormControl id="cost_type" componentClass="select" placeholder="select" value={this.state.forms[leadFormIndex].additional_costs[additional_costIndex].cost_type != undefined ? this.state.forms[leadFormIndex].additional_costs[additional_costIndex].cost_type : ''} onChange={(event) => this.handleAdditional_costInputChange(event, leadFormIndex, additional_cost.id)} >
                                              <option value=''></option>
                                              {(this.state.additional_costCostTypes) ?
                                                this.state.additional_costCostTypes.map((item, i) => (
                                                  <option value={item.id} key={i}>{item.name}</option>
                                                )) : false}
                                            </FormControl>
                                          </Col>
                                          {/* Additional_cost cost type END */}

                                          {/* Additional_cost cost BEGIN */}
                                          <Col className='element-padding horizontal' xs={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                                            <ControlLabel>Cost</ControlLabel>
                                            <FormControl id='cost' type="number" step={0.01} value={this.state.forms[leadFormIndex].additional_costs[additional_costIndex].cost != undefined ? this.state.forms[leadFormIndex].additional_costs[additional_costIndex].cost : ''} onChange={(event) => this.handleAdditional_costInputChange(event, leadFormIndex, additional_cost.id, () => this.calculateLeadEnergyContentsAndPrices(this.state.forms[leadFormIndex]))} />
                                          </Col>
                                          {/* Additional_cost cost END */}

                                          {/* Additional_cost currency BEGIN */}
                                          <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                                            <ControlLabel>Currency</ControlLabel>
                                            <FormControl id="currency" componentClass="select" placeholder="select" value={this.state.forms[leadFormIndex].additional_costs[additional_costIndex].currency != undefined ? this.state.forms[leadFormIndex].additional_costs[additional_costIndex].currency : ''} onChange={(event) => this.handleAdditional_costInputChange(event, leadFormIndex, additional_cost.id, () => this.calculateLeadEnergyContentsAndPrices(this.state.forms[leadFormIndex]))} >
                                              <option value=''></option>
                                              {this.state.currencies.map((item, i) => (
                                                <option value={item.id} key={i} disabled={((!item.active) ? true : false)}>{item.name}</option>
                                              ))}
                                            </FormControl>
                                          </Col>
                                          {/* Additional_cost currency END */}

                                          {/* Additional_cost exchange rate BEGIN */}
                                          <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                                            <ControlLabel>Exchange rate (to euros)</ControlLabel>
                                            <FormControl id='exchange_rate' type="number" step={0.01} value={this.state.forms[leadFormIndex].additional_costs[additional_costIndex].exchange_rate != undefined ? this.state.forms[leadFormIndex].additional_costs[additional_costIndex].exchange_rate : ''} readOnly={(!this.state.forms[leadFormIndex].additional_costs[additional_costIndex].currency || this.state.forms[leadFormIndex].additional_costs[additional_costIndex].currency === 'EUR')} onChange={(event) => this.handleAdditional_costInputChange(event, leadFormIndex, additional_cost.id, () => this.calculateLeadEnergyContentsAndPrices(this.state.forms[leadFormIndex]))} />
                                          </Col>
                                          {/* Additional_cost exchange rate END */}

                                          {/* Additional_cost quantity BEGIN */}
                                          <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                                            <ControlLabel>Quantity</ControlLabel>
                                            <FormControl id='quantity' type="number" step={1} value={this.state.forms[leadFormIndex].additional_costs[additional_costIndex].quantity != undefined ? this.state.forms[leadFormIndex].additional_costs[additional_costIndex].quantity : ''} onChange={(event) => this.handleAdditional_costInputChange(event, leadFormIndex, additional_cost.id, () => this.calculateLeadEnergyContentsAndPrices(this.state.forms[leadFormIndex]))} readOnly={(this.state.forms[leadFormIndex].additional_costs[additional_costIndex].pricing_unit === 'lump sum')}/>
                                          </Col>
                                          {/* Additional_cost quantity END */}

                                          {/* Additional_cost pricing unit BEGIN */}
                                          <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                                            <ControlLabel>Pricing unit</ControlLabel>
                                            <FormControl id="pricing_unit" componentClass="select" placeholder="select" value={this.state.forms[leadFormIndex].additional_costs[additional_costIndex].pricing_unit != undefined ? this.state.forms[leadFormIndex].additional_costs[additional_costIndex].pricing_unit : ''} onChange={(event) => this.handleAdditional_costInputChange(event, leadFormIndex, additional_cost.id, () => this.calculateLeadEnergyContentsAndPrices(this.state.forms[leadFormIndex]))} >
                                              <option value=''></option>
                                              {this.state.legPricingUnits.map((item, i) => (
                                                <option value={item.id} key={i} disabled={((!item.active) ? true : false)}>{item.name}</option>
                                              ))}
                                            </FormControl>
                                          </Col>
                                          {/* Additional_cost pricing unit END */}

                                          {/* Additional_cost share of freight cost BEGIN */}
                                          <Col className='element-padding horizontal' sm={(this.state.colspan_subField) ? this.state.colspan_subField : 6}>
                                            <ControlLabel>Share of freight cost (if lump sum)</ControlLabel>
                                            <InputGroup>
                                              <FormControl id='share_of_total' type="number" step={1} min={0} max={100} value={this.state.forms[leadFormIndex].additional_costs[additional_costIndex].share_of_total != undefined ? this.state.forms[leadFormIndex].additional_costs[additional_costIndex].share_of_total : ''} onChange={(event) => this.handleAdditional_costInputChange(event, leadFormIndex, additional_cost.id)} readOnly={(this.state.forms[leadFormIndex].additional_costs[additional_costIndex].pricing_unit !== 'lump sum')} />
                                              <InputGroup.Addon>%</InputGroup.Addon>
                                            </InputGroup>
                                          </Col>
                                          {/* Additional_cost share of freight cost END */}

                                          {/* Additional_cost related files BEGIN */}
                                          <Col className='element-padding horizontal' sm={12}>
                                            <ControlLabel>Related files</ControlLabel>
                                            <FormAddFileToList 
                                            inputId="additional_costDocument"
                                            inputValue={this.state.forms[leadFormIndex].additional_costs[additional_costIndex].additional_costDocument || {name: ''}}
                                            inputOnChange={(event) => this.handleAdditional_costInputChange(event, leadFormIndex, additional_cost.id)}
                                            addToListFunc={()=>this.fileUploadAdditional_cost(leadFormIndex, additional_costIndex)}
                                            deleteFromListFunc={(event)=>this.fileDeleteAdditional_cost(event, leadFormIndex, additional_costIndex)}
                                            listArray={this.state.forms[leadFormIndex].additional_costs[additional_costIndex].documents}
                                            label=""
                                            active={this.state.forms[leadFormIndex].active} />
                                          </Col>
                                          {/* Additional_cost related files END */}

                                          {/* Additional_cost additional information BEGIN */}
                                          <Col className='element-padding horizontal' sm={12}>
                                            <ControlLabel>Additional information</ControlLabel>
                                            <FormControl id="additional_info" componentClass="textarea" value={this.state.forms[leadFormIndex].additional_costs[additional_costIndex].additional_info != undefined ? this.state.forms[leadFormIndex].additional_costs[additional_costIndex].additional_info : ''} placeholder="" onChange={(event) => this.handleAdditional_costInputChange(event, leadFormIndex, additional_cost.id)} />
                                          </Col>
                                          {/* Additional_cost additional information END */}

                                        </Row>

                                      </div>
                                    </FormGroup>
                                    {/* Additional_cost details form END */}

                                  </div>
                                </div>
                              )) : false}
                            </div>

                          </Col>

                          <Col className='lead-widget input-field-padding'>
                            <Button className="full-width-add-button ion-plus" onClick={() => this.addAdditional_cost(leadFormIndex)}> Add additional cost</Button>
                          </Col>
                        </Col>
                      ))}
                    </Row>
                    {/* Additional costs END */}

                  </div>
                </div>
                {/* Additional costs form END */}
                 
              </FormGroup>
            </Form>
          </div>
        }
      </div>
    );
  }
}

Lead.propTypes = {
  location: React.PropTypes.object.isRequired //if the user id is missing hte user data cannot be fetched
};