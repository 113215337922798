import React from 'react';

require('../stylesheets/app.scss');

export default class ListComponentList extends React.Component {
  render () {
    return(
      <div className="list-component-list">
        {this.props.children}
      </div>
    );
  }
}

ListComponentList.propTypes = {
  children: React.PropTypes.element.isRequired
};