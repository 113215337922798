/* global apiBaseUrl */

export var statusCheckListData = [
  ["Fixed counterparties",                         'X', '-', '-', '-', '-', '-'],
  ["Fixed volume",                                 'X', '-', '-', '-', '-', '-'],
  ["Matching delivery schedule",                   'X', '-', '-', '-', '-', '-'],
  ["Multiple buyer possibility check",             'X', '-', '-', '-', '-', '-'],
  ["Product matching checked",                     'X', '-', '-', '-', '-', '-'],
  
  ["Freight legs fixed",                           '-', 'X', '-', '-', '-', '-'],
  ["Freight terms checked",                        '-', 'X', '-', '-', '-', '-'],
  ["Leg details sent to operator",                 '-', 'X', '-', '-', '-', '-'],
  ["Product deal fixed",                           '-', 'X', '-', '-', '-', '-'],
  ["Product deal terms checked",                   '-', 'X', '-', '-', '-', '-'],

  ["Certificates checked",                         '-', 'X', '-', '-', '-', '-'],
  ["Tautiluokitus ok",                             '-', 'X', '-', '-', '-', '-'],
  ["Hyönteisluokitus ok",                          '-', 'X', '-', '-', '-', '-'],
  ["Sampling done",                                '-', '-', 'X', '-', '-', '-'],
  ["Samples delivered to lab",                     '-', '-', 'X', '-', '-', '-'],
  
  ["Final quantities added",                       '-', '-', 'X', '-', '-', '-'],
  ["Invoicing ready",                              '-', '-', 'X', '-', '-', '-'],
  ["Energy content verified",                      '-', '-', 'X', '-', '-', '-'],
  ["All receipts / annexes added",                 '-', '-', '-', 'X', '-', '-'],
  ["No open invoices",                             '-', '-', '-', 'X', 'X', '-'],

  ["No open claims",                               '-', '-', '-', 'X', 'X', '-'],
  ["Cancellation announced to seller",             '-', '-', '-', '-', 'X', '-'],
  ["Cancellation announced to buyer",              '-', '-', '-', '-', 'X', '-'],
  ["Cancellation announced to logistics operator", '-', '-', '-', '-', 'X', '-'],
  ["Failure announced to seller",                  '-', '-', '-', '-', '-', 'X'],

  ["Failure announced to buyer",                   '-', '-', '-', '-', '-', 'X'],
  ["Failure announced to logistics operators",     '-', '-', '-', '-', '-', 'X']
];