/* global apiBaseUrl */

'use strict';

require('../stylesheets/app.scss');
require('../stylesheets/components/react-datetime.scss');
require('../stylesheets/components/detailPage.scss');

import React from 'react';
import { Button, Checkbox, Col, ControlLabel, Form, FormControl, FormGroup, InputGroup, Radio, Row } from 'react-bootstrap';
import DateTime from 'react-datetime';
//import { Link } from 'react-router';
import ActionBar from '../components/ActionBar';
import ListComponent from '../components/ListComponent';
import FormAddToList from '../components/FormAddToList';
//import FilterBar from '../components/FilterBar';
//import ListComponent from '../components/ListComponent';
import { addFormItem, deleteFormItem, fetchList, handleInputChange, parseFetchError } from '../actions/utility';
import { ENUM_productTypes } from '../actions/enums';
import { calculateLeadEnergyContentsAndPrices } from '../actions/calculations';

export default class Reports extends React.Component {
  constructor (props) {
    super(props);
    
    this.addFormItem = addFormItem.bind(this);
    this.deleteFormItem = deleteFormItem.bind(this);
    this.handleInputChange = handleInputChange.bind(this);
    this.handleReportTypeInputChange = this.handleReportTypeInputChange.bind(this);
    
    this.fetchList = fetchList.bind(this);
    
    this.fetchLeadsList = this.fetchLeadsList.bind(this);
    this.getReportLink = this.getReportLink.bind(this);
    this.updateDate = this.updateDate.bind(this);
    
    this.state = {
      form: {
        dateFrom: '',
        dateFromChanged: false,
        dateTo: '',
        dateToChanged: false,
        seller: '',
        buyer: '',
        site: '',
        hub: '',
        product: '',
        product_type: '',
        reportType: 'fullDetails'
      },
      buttonVisibility: [false],
      dateInputFormat: 'DD.MM.YYYY',
      dateMode: 'days',
      loading: false,
      generatingReport: false,
      downloadLink: '',
      leads: [],
      selectedRows: [],
      productTypes: ENUM_productTypes,
      counterparties: [],
      products: [],
      hubs: [],
      sites: []
    };
    
    // //fetch sellers list
    // this.fetchList('sellers','sellers',false,'');
    
    // //fetch buyers list
    // this.fetchList('buyers','buyers', false,'');
    
    this.buttons = [
      {
        formId: 'addNewReport',
        icon: 'ion-plus',
        text: 'add new report'
      }
    ];
  }

  componentDidMount() {
    //fetch data
    this.fetchList('counterparties', 'counterparties', false, '');
    this.fetchList('products','products',false,'');
    this.fetchList('hubs','hubs',false,'');
    this.fetchList('sites', 'sites', false, '');
  }

  updateFormInState (form, callback) {
    // console.log("updateFormInState");
    // console.log("updateFormInState -> callback = " + callback);

    if (!form) {
      console.warn("form is undefined. Will not update state.");
      return;
    }

    // Use setState updater callback explicitly if a callback is provided.
    // This ensures that the state is updated before executing the callback.
    if (callback) {
      // console.log("callback = " + callback);
      this.setState((prevState, props) => {
        return {
          form: form
        };
      }, callback);
    } else {
      // console.log("no callback");
      this.setState({
        form: form
      });
    }
  }

  handleReportTypeInputChange(event, callback) {
    this.setState({ form: { ...this.state.form, reportType: event.target.value } })
  }
  
  fetchLeadsList() {
    // First, reset leads list and download link
    this.setState({
      leads: [],
      downloadLink: '',
      selectedRows: []
    });
    
    var errorFound = false;
    var informError = function (id) {
      //alert(id + ' is missing a value.');
      //add error class to all of the inputs that are missing information
      if (document.getElementById(id).className.indexOf('error') < 0) {
        document.getElementById(id).className += ' error';
      }
      errorFound = true;
    };
    for (let i in this.state.form) {
      //filter out the information that is not concerning step 1
      if (i !== 'seller' &&
        i !== 'buyer' &&
        i !== 'site' &&
        i !== 'hub' &&
        i !== 'product' &&
        i !== 'product_type') {
        if (i === 'dateFromChanged') {
          if (!this.state.form[i]) {
            informError('dateFrom');
          }
        } else if (i === 'dateToChanged') {
          if (!this.state.form[i]) {
            informError('dateTo');
          }
        } else {
          if (!this.state.form[i]) {
            informError(i);
          }
        }
      }
    }
    //user is not allowed to go forward if something is missing
    if (!errorFound) {
      var token = localStorage.getItem('woodtracker_id_token');
      var that = this;
      if (token) {
        var header = new Headers();
        header.append('Accept', 'application/json');
        header.append('Content-Type', 'application/json');
        header.append('Authorization', 'Bearer ' + token);

        let listId = "leads"

        var findId = function (name, array) {
          for (let i = 0; array.length > i; i++) {
            if (name === array[i].name) {
              return array[i].id;
            }
          }
        };
        
        // var queryParams = [
        //   {key: 'date_from', value: this.state.form.dateFrom },
        //   {key: 'date_to', value: this.state.form.dateTo },
        //   {key: 'seller_id', value: this.state.form.seller },
        //   {key: 'buyer_id', value: this.state.form.buyer },
        //   {key: 'site_id', value: this.state.form.site },
        //   {key: 'hub_id', value: this.state.form.hub },
        //   {key: 'product_id', value: this.state.form.product },
        //   {key: 'product_type', value: this.state.form.product_type }
        // ];

        // // Construct queryParameters string
        // let queryString = '';
        // if (queryParams && queryParams.length > 0) {
        //   queryString += '?';

        //   for (var i = 0; i < queryParams.length; i++) {
        //     if (queryParams[i].value) {
        //       if (queryParams[i].key === 'date_from') {
        //         queryString += 'from=' + queryParams[i].value.getTime();
        //       } else if (queryParams[i].key === 'date_to') {
        //         queryString += 'to=' + queryParams[i].value.getTime();
        //       }else {
        //         queryString += queryParams[i].key + '=' + queryParams[i].value;
        //       }  
        //       if (i < (queryParams.length - 1)) {
        //         queryString += '&';
        //       }
        //     }  
        //   }
        // }

        var queryParams = {
          leads: {
            date_from: this.state.form.dateFrom.getTime(),
            date_to: this.state.form.dateTo.getTime(),
            seller_id: findId(this.state.form.seller, this.state.counterparties),
            buyer_id: findId(this.state.form.buyer, this.state.counterparties),
            site_id: findId(this.state.form.site, this.state.sites),
            hub_id: findId(this.state.form.hub, this.state.hubs),
            product_id: findId(this.state.form.product, this.state.products),
            product_type: this.state.form.product_type
          }
        };
        
        var request = new Request(apiBaseUrl + '/reports', { 
          method: 'POST', 
          headers: header,
          body: JSON.stringify(queryParams)
        });
        
        this.setState({ 'loading': true });
        fetch(request).then(function (response) {
          that.setState({ ...that.state, loading: false });

          if (!response.ok) {
            return response.json().then(err => { throw err; });
          }
          return response.json();
        }).then(function(json) {
          //// DEBUG
          // console.log("fetchListPage -> response.json = " + JSON.stringify(json));
          // console.log(that);
          //fetch specific inner list data if it is found with the listId. Otherwise throw an error.
          if (json.hasOwnProperty(listId)) {
            //// DEBUG
            // console.log("Found in response.json, listId = \'" + listId + "\', length = " + json[listId].length);
            that.setState({ [listId]: json[listId] });
          } else {
            throw new Error("No list with the id = \'" + listId + "\' found in the fetched data.");
          }
          //set list page meta if meta is found
          if (json.hasOwnProperty('meta')) {
            //// DEBUG
            // console.log("Found in response.json, meta = " + JSON.stringify(json.meta));
            that.setState({ meta: json.meta });
          }
        }).catch(function(err) {
          that.setState({ ...that.state, loading: false, generatingReport: false });
          parseFetchError(err);
        });
      }
      this.setState({ 'loading': true });
    } else {
      alert('Data is missing. Please fill in all of the required data.');
    }
  }

  getReportLink() {
    // First, reset leads list and download link
    this.setState({
      downloadLink: '',
    });

    // var errorFound = false;
    // var informError = function (id) {
    //   //alert(id + ' is missing a value.');
    //   //add error class to all of the inputs that are missing information
    //   if (document.getElementById(id).className.indexOf('error') < 0) {
    //     document.getElementById(id).className += ' error';
    //   }
    //   errorFound = true;
    // };
    for (let i in this.state.form) {
      //filter out the information that is not concerning step 1
      // if (i !== 'seller' &&
      //   i !== 'buyer' &&
      //   i !== 'site' &&
      //   i !== 'hub' &&
      //   i !== 'product' &&
      //   i !== 'product_type') {
      //   if (i === 'dateFromChanged') {
      //     if (!this.state.form[i]) {
      //       informError('dateFrom');
      //     }
      //   } else if (i === 'dateToChanged') {
      //     if (!this.state.form[i]) {
      //       informError('dateTo');
      //     }
      //   } else {
      //     if (!this.state.form[i]) {
      //       informError(i);
      //     }
      //   }
      // }
    }
    //user is not allowed to go forward if something is missing
    // if (!errorFound) {
      var token = localStorage.getItem('woodtracker_id_token');
      var that = this;
      if (token) {
        var header = new Headers();
        header.append('Accept', 'application/json');
        header.append('Content-Type', 'application/json');
        header.append('Authorization', 'Bearer ' + token);
        
        var queryParams = {
          reports: {
            lead_ids: this.state.selectedRows
          }
        };
        
        var request = new Request(apiBaseUrl + '/reports/generate.xlsx', { 
          method: 'POST', 
          headers: header,
          body: JSON.stringify(queryParams)
        });
        
        this.setState({ generatingReport: true });
        fetch(request).then(function(response) {
          // if (response.status === 204) {
          //   that.setState({ generatingReport : false });
          //   that.setState({ 'downloadLink' : 'no_content' });
          //   //scroll user to the downloadlink
          //   window.scrollTo(0, document.querySelector('.download-link-holder').offsetTop);
          //   return false;
          // } else
          if (!response.ok) {
            that.setState({ generatingReport : false });
            that.setState({ 'downloadLink' : 'no_content' });
            //scroll user to the downloadlink
            window.scrollTo(0, document.querySelector('.download-link-holder').offsetTop);
            return false;

            // throw Error(response.statusText);
            // return response.json().then(err => { throw err; });
          } else {
            return response.blob();
          }
        }).then(function(response) {
          //console.log(URL.createObjectURL(response));
          if (response) {
            that.setState({ generatingReport : false });
            that.setState({ 'downloadLink' : URL.createObjectURL(response) });
            //scroll user to the downloadlink
            window.scrollTo(0, document.querySelector('.download-link-holder').offsetTop);
          }
        }).catch(function(err) {
          that.setState({ ...that.state, loading: false, generatingReport: false });
          parseFetchError(err);
        });
      }
      this.setState({ generatingReport: true });
    // } else {
    //   alert('Data is missing. Please fill in all of the required data.');
    // }
  }

  // Date BEGIN

  handleDate(id, date, callback) {
    // console.log("date = " + date);
    if (typeof date !== 'Date') {
      date = new Date(date);
    }
    // console.log("date = " + date);

    // Don't allow "to" to be earlier than "from"
    if (id === 'to') {
      if (this.state.dateFrom > date) {
        date = new Date(this.state.dateFrom);
      }
    }

    var form = this.state.form;
    form[id] = date;
    form[id + 'Changed'] = true;

    this.updateFormInState(form, callback);
    
    var elem = document.getElementById(id);
    if (elem.className.indexOf('error') > -1) {
      elem.className = elem.className.replace('error','');
    }
  }

  //clear date params
  clearDate (id) {
    let formIndex = this.getDefaultIndex(formIndex);
    //reset state
    var date = new Date();

    this.setState({
      forms: form
    });
    
    var elem = document.getElementById(id);
    if (elem.className.indexOf('error') > -1) {
      elem.className = elem.className.replace('error','');
    } 

    //reset the input state also
    this.refs.datePickerETA.setState({ inputValue: this.state.dateInputFormat });
  }

  // Date END
  
  updateDate (id, date) {
    var form = this.state.form;
    form[id] = date;
    form[id + 'Changed'] = true;
    this.setState({ 'form': form });
    
    var elem = document.getElementById(id);
    if (elem.className.indexOf('error') > -1) {
      elem.className = elem.className.replace('error','');
    } 
  }

  handleRowToggle (e, id) {
    // console.log(e.target);
    // console.log("id = " + id);

    let newSelectedRows = [];

    if (id) {
      if (this.state.selectedRows.find(x => x == id)) {
        // Will remove all found occurrences
        newSelectedRows = this.state.selectedRows.filter(x => x !== id);
      } else {
        newSelectedRows = this.state.selectedRows.slice();
        newSelectedRows.push(id);
      }

      this.setState({ ...this.state,
        selectedRows: newSelectedRows
      });

      // // calculate sums of checked rows as a callback
      // this.setState((prevState, props) => {
      //   return {selectedRows: newSelectedRows};
      // }, this.calculateSumsForSelectedRows(newSelectedRows));
    }
  }

  handleRowToggleAll(e) {
    let anySelected = (this.state.selectedRows.length ? true : false);

    // if any rows were selected, unselect all. Otherwise select all
    if (anySelected) {
      this.setState({ ...this.state,
        selectedRows: []
      });
    } else {
      let newSelectedRows = [];

      for (var i = 0; i < this.state.leads.length; i++) {
        newSelectedRows.push(this.state.leads[i].id);
      }

      this.setState({ ...this.state,
        selectedRows: newSelectedRows
      });
    }

  }
  
  render () {
    const {format, mode, inputFormat} = this.state;
    const {dateFrom, dateTo} = this.state.form;
    
    return (
      <div className="detail-page reports">
        <ActionBar elements={this.buttons} visibility={this.state.buttonVisibility} />
        
        <div className="detail-page-form">
          <Form>
            <FormGroup className="reportsQueryForm">
              {/* report query form */}
              <h1 className="detail-page-header">
                Create a report
              </h1>

              <Row>
                <Col componentClass={ControlLabel} sm={4}>
                  Report query details
                </Col>
                <Col sm={8}>
                  <Col className="date-input-holder element-padding horizontal" sm={6}>
                    <ControlLabel>From</ControlLabel>
                    <InputGroup>
                      <DateTime inputProps={{ id: 'dateFrom', placeholder: this.state.dateInputFormat }}
                        className="date-input"
                        value={this.state.form.dateFrom}
                        dateFormat={this.state.dateInputFormat}
                        timeFormat={false}
                        viewMode={this.state.dateMode}
                        closeOnSelect={true}
                        utc={true}
                        locale={"en"}
                        onChange={(date) => this.handleDate('dateFrom', date)} />
                      <InputGroup.Addon><span className={'icon ion-calendar'}></span></InputGroup.Addon>
                    </InputGroup>
                  </Col>
                  <Col className="date-input-holder element-padding horizontal" sm={6}>
                    <ControlLabel>To</ControlLabel>
                    <InputGroup>
                      <DateTime inputProps={{ id: 'dateTo', placeholder: this.state.dateInputFormat }}
                        className="date-input"
                        value={this.state.form.date_of_delivery}
                        dateFormat={this.state.dateInputFormat}
                        timeFormat={false}
                        viewMode={this.state.dateMode}
                        closeOnSelect={true}
                        utc={true}
                        locale={"en"}
                        onChange={(date) => this.handleDate('dateTo', date)} />
                      <InputGroup.Addon><span className={'icon ion-calendar'}></span></InputGroup.Addon>
                    </InputGroup>
                  </Col>
                  <Col className='element-padding horizontal' sm={6}>
                    <ControlLabel>Seller</ControlLabel>
                    <FormControl id="seller" type="text" value={this.state.form.seller || ''} onChange={this.handleInputChange} list="reportSellersDataList" />
                      {this.state.counterparties && 
                        <datalist id="reportSellersDataList">
                        {this.state.counterparties.map(function(item, i){
                          return <option key={i} value={item.name} disabled={((!item.active) ? true : false)} />;
                        },this)}
                        </datalist>
                    }
                  </Col>
                  <Col className='element-padding horizontal' sm={6}>
                    <ControlLabel>Buyer</ControlLabel>
                    <FormControl id="buyer" type="text" value={this.state.form.buyer || ''} onChange={this.handleInputChange} list="reportBuyersDataList" />
                      {this.state.counterparties && 
                        <datalist id="reportBuyersDataList">
                        {this.state.counterparties.map(function(item, i){
                          return <option key={i} value={item.name} disabled={((!item.active) ? true : false)} />;
                        },this)}
                        </datalist>
                    }
                  </Col>
                  <Col className='element-padding horizontal' sm={6}>
                    <ControlLabel>Site</ControlLabel>
                    <FormControl id="site" type="text" value={this.state.form.site || ''} onChange={this.handleInputChange} list="reportSitesDataList" />
                      {this.state.counterparties && 
                        <datalist id="reportSitesDataList">
                        {this.state.sites.map(function(item, i){
                          return <option key={i} value={item.name} disabled={((!item.active) ? true : false)} />;
                        },this)}
                        </datalist>
                    }
                  </Col>
                  <Col className='element-padding horizontal' sm={6}>
                    <ControlLabel>Hub</ControlLabel>
                    <FormControl id="hub" type="text" value={this.state.form.hub || ''} onChange={this.handleInputChange} list="reportHubsDataList" />
                      {this.state.counterparties && 
                        <datalist id="reportHubsDataList">
                        {this.state.hubs.map(function(item, i){
                          return <option key={i} value={item.name} disabled={((!item.active) ? true : false)} />;
                        },this)}
                        </datalist>
                    }
                  </Col>
                  <Col className='element-padding horizontal' sm={6}>
                    <ControlLabel>Product</ControlLabel>
                    <FormControl id="product" type="text" value={this.state.form.product || ''} onChange={this.handleInputChange} list="reportProductsDataList" />
                      {this.state.counterparties && 
                        <datalist id="reportProductsDataList">
                        {this.state.products.map(function(item, i){
                          return <option key={i} value={item.name} disabled={((!item.active) ? true : false)} />;
                        },this)}
                        </datalist>
                    }
                  </Col>
                  <Col className='element-padding horizontal' sm={6}>
                    <ControlLabel>Product type</ControlLabel>
                    <FormControl id="product_type" type="text" value={this.state.form.product_type || ''} onChange={this.handleInputChange} list="reportProductTypesDataList" />
                      {this.state.counterparties && 
                        <datalist id="reportProductTypesDataList">
                        {this.state.productTypes.map(function(item, i){
                          return <option key={i} value={item.name} disabled={((!item.active) ? true : false)} />;
                        },this)}
                        </datalist>
                    }
                  </Col>
                </Col>
                <Col className='element-padding horizontal' sm={12}>
                  <Button id="fetchLeadsList" className="pull-right" onClick={this.fetchLeadsList}>Get Leads</Button>
                </Col>  
              </Row>
              {/* report results */}
              <Row>
                {(this.state.leads && this.state.leads.length > 0) &&
                  <div>
                    <h2 className="detail-page-header">
                     Select leads to include in the report
                    </h2>
                  
                  <div>
                    
                    <ListComponent
                      changePage={() => this.changeLeadsPage('leads')}
                      elements={['componentList']}
                      location={this.props.location}
                      loading={this.state.loading}
                      meta={this.state.meta}>
                      <div>
                        <div>
                          <div className="list-header">
                            <span className="list-column listCheckbox" />
                            <span className="list-column id">ID</span>
                            <span className="list-column status">ST</span>
                            <span className="list-column report-lead-seller">Seller</span>
                            <span className="list-column report-lead-buyer">Buyer</span>
                            <span className="list-column report-lead-from">From</span>
                            <span className="list-column report-lead-to">To</span>
                            <span className="list-column report-lead-product">Product</span>
                            <span className="list-column report-lead-mwh">MWh</span>
                            <span className="list-column report-lead-valueEuros">Value €</span>
                            <span className="list-column report-lead-eurosPerMwh">€/MWh</span>
                            {/* <span className="list-column deadline">Deadline</span> */}
                            {/* <span className="list-column logistics">Logistics</span> */}
                            {/* <span className="list-column additionalCosts">Additional costs</span> */}
                          </div>
                          <ul>
                            {(this.state.leads.length === 0) &&
                              <li className="no-results">No results found.</li>
                            }
                            {this.state.leads.map(function (listItem, i) {
                              // Do product_details calculations
                              listItem = calculateLeadEnergyContentsAndPrices(listItem);

                              return <li id={listItem.id} key={i}>
                                <span className="list-column listCheckbox">
                                  <Checkbox inline id={listItem.id} key={listItem.id}
                                    checked={(this.state.selectedRows.find(x => x == listItem.id) ? true : false)}
                                    onChange={(event) => this.handleRowToggle(event, listItem.id)} />
                                </span>
                                
                                {/* Id */}
                                <span className="list-column id">{listItem.id}</span>
                                
                                {/* Status */}
                                <span className={'list-column status status-' + listItem.status} />
                                
                                {/* Seller */}
                                <span className="list-column report-lead-seller">{(listItem.seller && listItem.seller.id) ? listItem.seller.name :
                                  false}
                                </span>
                                
                                {/* Buyer */}
                                <span className="list-column report-lead-buyer">{(listItem.buyer && listItem.buyer.id) ? listItem.buyer.name :
                                  false}
                                </span>

                                {/* From */}
                                <span className="list-column report-lead-from">{(listItem.source && listItem.source.id) ? listItem.source.name :
                                  false}
                                </span>

                                {/* To */}
                                <span className="list-column report-lead-to">{(listItem.destination && listItem.destination.id) ? listItem.destination.name :
                                  false}
                                </span>

                                {/* Product */}
                                <span className="list-column report-lead-product">{(listItem.product_details && listItem.product_details.product_id) ? this.state.products.find(x => x.id == listItem.product_details.product_id).name + ', ' + listItem.product_details.product_type :
                                  false}
                                </span>

                                {/* Show verified value */}
                                <span className="list-column report-lead-mwh" id={listItem.id + 'mwh'}>{((listItem.product_details && listItem.product_details.sumTotalEnergyContentVerified) ?
                                  listItem.product_details.sumTotalEnergyContentVerified.toFixed(2) :
                                  false)}
                                </span>

                                {/* Value Euros */}
                                {/* Show verified value */}
                                <span className="list-column report-lead-valueEuros" id={listItem.id + 'valueEuros'}>{((listItem.product_details && listItem.product_details.sumTotalEnergyCostVerified) ?
                                  listItem.product_details.sumTotalEnergyCostVerified.toFixed(2) :
                                  false)}
                                </span>

                                {/* eurosPerMwh */}
                                {/* Show verified value */}
                                <span className="list-column report-lead-eurosPerMwh" id={listItem.id + 'eurosPerMwh'}>{((listItem.product_details && listItem.product_details.sumPriceTotalPerMWhVerified) ?
                                  listItem.product_details.sumPriceTotalPerMWhVerified.toFixed(2) :
                                  false)}
                                </span>

                                {/* Deadline */}
                                {/* <span className="list-column deadline">{(listItem.date_of_delivery) ?
                                  new Date(listItem.date_of_delivery).toLocaleDateString('en-GB', { year: 'numeric', month: 'numeric', day: 'numeric' }).replace(/\//g, '.')
                                  :
                                  false}
                                </span> */}

                                {/* Logistics */}
                                {/* <span className="list-column logistics">{(listItem.logistics && listItem.logistics.legs && listItem.logistics.legs.length > 0) ?
                                  <Button className="add-info-button icons-logistics negative" disabled={true}>
                                    <span className={'ion-social-euro'} /> <span className={'ion-android-boat'} /> <span className={'ion-android-train'} /></Button>
                                  :
                                  <Button className="add-info-button icons-logistics" >
                                    <span className={'ion-plus'} /> <span className={'ion-android-boat'} /> <span className={'ion-android-train'} /></Button>}
                                </span> */}

                                {/* Additional costs */}
                                {/* <span className="list-column additionalCosts">{(listItem.additional_costs && listItem.additional_costs.length > 0) ?
                                  <Button className="add-info-button icons-additional-costs negative" disabled={true}>
                                    <span className={'ion-social-euro'} /></Button>
                                  :
                                  <Button className="add-info-button icons-additional-costs">
                                    <span className={'ion-plus'} /> <span className={'ion-social-euro'} /></Button>}
                                </span> */}
                              </li>;
                            }, this)}
                          </ul>
                        </div>
                      </div>
                    </ListComponent>
                  </div>

                  <Col className='element-padding horizontal' sm={12}>
                    
                    <Button id="toggleAll" onClick={(event) => this.handleRowToggleAll(event)}>Select / deselect all</Button>
                    
                    <Button id="getReportLink" className="pull-right" onClick={this.getReportLink}>Create Report</Button>

                    {/* <span className="reportControls-report-type pull-right">
                      <Radio inline name="reportType" value='fullDetails'
                        onChange={this.handleReportTypeInputChange}
                        checked={(this.state.form.reportType === 'fullDetails' ? true : false)}>Full details</Radio>
                      <Radio inline name="reportType" value='fullDetailsWithAnnexes'
                        onChange={this.handleReportTypeInputChange}
                        checked={(this.state.form.reportType === 'fullDetailsWithAnnexes' ? true : false)}>Full details with annexes</Radio>
                      <Radio inline name="reportType" value='reference'
                        onChange={this.handleReportTypeInputChange}
                        checked={(this.state.form.reportType === 'reference' ? true : false)}>Reference</Radio>
                    </span> */}
                  </Col>
                </div>
                  
                }
              </Row>
              <Row>
                
                {(this.state.downloadLink && this.state.downloadLink !== 'no_content') &&
                  ((!this.state.generatingReport) ?  
                  <div className="download-link-holder">
                    <h2 className="detail-page-header">
                      View or download report
                    </h2>
                    <Button href={this.state.downloadLink}>
                      <span className={'icon ion-ios-download-outline'}></span>
                      Download
                      </Button>
                  </div>
                  :
                  <div className="download-link-holder loading" />)
                }

                {(this.state.downloadLink === 'no_content') &&
                  ((!this.state.generatingReport) ?  
                    <div className="download-link-holder">
                      <h2 className="detail-page-header">
                        A report could not be created with the given parameters.
                      </h2>
                      <h3 className="detail-page-header">
                        Please try again.
                      </h3>
                    </div>
                  :
                  <div className="download-link-holder loading" />)
                }
              </Row>
            </FormGroup>
          </Form>
        </div>
        
      </div>
    );
  }
}
