'use strict';

import React from 'react';
import { Button, Col, ControlLabel, FormControl, Row } from 'react-bootstrap';

require('../stylesheets/app.scss');
require('../stylesheets/components/formComponent.scss');

export default class FormAddToList extends React.Component {
  constructor (props) { 
    super(props);
  }
  
  render () {
    return (
      <div className={((this.props.loading) ? ' loading' : '')}>
        <Row className="add-item-holder">
        {this.props.label && 
          <ControlLabel>{this.props.label}</ControlLabel>
        }
        {(this.props.active !== false) &&
          <div>
            <Col sm={10}>
              {!this.props.dataList &&
                <FormControl id={this.props.inputId} type="text" value={this.props.inputValue} onChange={this.props.inputOnChange} placeholder="Click add to include the selected value to the form." />
              }
              {this.props.dataList &&
                <div>
                  <FormControl id={this.props.inputId} type="text" value={this.props.inputValue} onChange={this.props.inputOnChange} list={this.props.dataListId} placeholder="Click add to include the selected value to the form." />
                  <datalist id={this.props.dataListId} className="form-datalist">
                    {this.props.dataListArray.map(function(item, i) {
                      return (item.active !== false) ?
                      <option key={i} value={item.name} />: false;
                    },this)}
                  </datalist>
                </div>
              }
            </Col>
            <Col sm={2}>
              <Button className={"addNew-button" + ((this.props.dataListArray.length < 1) ? ' disabled' : '')} onClick={this.props.addToListFunc}>
                <span className={'icon ion-plus'}></span>
                Add
              </Button>
            </Col>
          </div>
        }
          
        {(this.props.listArray.length === 0 && this.props.inputId !== 'additional_supplier') &&
          <Col className="fuelList" sm={12}>
            <ul className="empty-list-ul">
              <li className="empty-list-li">
                The list {this.props.label ? 'for ' + this.props.label : ''} is empty.
              </li>
            </ul>
          </Col>
        }

        {(this.props.listArray.length > 0) &&
          <Col className="fuelList" sm={12}>
            <ul>
              {this.props.listArray.map(function(item, i){
                return <li id={i} key={i} className={item.active === false ? 'disabled' : ''}>
                  {(typeof item === 'string') ? item : item.name}
                  {(this.props.active !== false) &&
                    <span className="icon ion-android-delete" onClick={this.props.deleteFromListFunc}></span>
                  }
                </li>;
              },this)}
            </ul>
          </Col>
        }
        </Row>
      </div>
    );
  }
}

FormAddToList.propTypes = {
  inputId: React.PropTypes.string.isRequired, //element id used by data validation
  inputValue: React.PropTypes.string.isRequired, //the value within views state that is updated
  inputOnChange: React.PropTypes.func.isRequired, //the 2-way binding handler function
  
  dataList: React.PropTypes.bool.isRequired, //boolean to determine if autocomplete datalist should be used
  dataListId: React.PropTypes.string, //string id to connect the input to the datalist
  dataListArray: React.PropTypes.array, //array of the possible items to choose
  
  addToListFunc: React.PropTypes.func.isRequired, //function to handle adding elements to the form list
  deleteFromListFunc: React.PropTypes.func.isRequired, //function to handle removing elements to the form list
  listArray: React.PropTypes.array.isRequired, //form list array that is updated by adding and removal

  label: React.PropTypes.string.isRequired //the label for he input and also used on the empty list notification text
};

