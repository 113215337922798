import React from 'react';
import { Button, FormControl, FormGroup, ControlLabel, InputGroup } from 'react-bootstrap';
import DateTime from 'react-datetime';
import { fetchList, handleSessionStorage } from '../actions/utility';
import Dropdown from '../components/FilterBarDropDown';

require('../stylesheets/app.scss');
require('../stylesheets/components/react-datetime.scss');
require('../stylesheets/components/filterBar.scss');

// this.props.element contains an array of elements that are to be shown in the FilterBar
// supported elements: text-search, date-filter, dropdown

export default class FilterBar extends React.Component {
  constructor (props) {
    super(props);
    
    this.handleTextSearch = this.handleTextSearch.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.clearDate = this.clearDate.bind(this);
    this.handleSelection = this.handleSelection.bind(this);

    this.fetchList = fetchList.bind(this);
    this.handleSessionStorage = handleSessionStorage.bind(this);

    this.state = {
      dateFrom: (this.props.location.query.from) ? new Date(new Number(this.props.location.query.from)) : null,
      dateTo: (this.props.location.query.to) ? new Date(new Number(this.props.location.query.to)) : null,
      inputFormat: 'DD.MM.YYYY',
      mode: 'days',
      countries: [],
      hubs: []
    };
    
    //used for user role dropdown
    this.userRoleArray = [
      { active: true, id: 'trader', name: 'Trader' },
      { active: true, id: 'admin', name: 'Admin' }
    ];
  }
  
  //handle the free text search events and pass them to the url under q parameter
  handleTextSearch (e) {
    this.props.location.query.q = e.target.value.replace(/ /g,'+');
    //reset page when changing a filter
    this.props.location.query.page = 1;
    this.props.changePage();
  }

  handleKeyPress (e) {
    if (e.charCode === 13) {
      e.preventDefault();
    }
  }

  //handle the date input change events and pass them to the url under from and to parameters
  handleDate (id, date) {
    console.log("date = " + date);
    if (typeof date !== 'Date') {
      date = new Date(date);
    }
    console.log("date = " + date);

    // Don't allow "to" to be earlier than "from"
    if (id === 'to') {
      if (this.state.dateFrom > date) {
        date = new Date(this.state.dateFrom);
      }
    }

    this.props.location.query[id] = date.getTime();
    if (id === 'to' && this.props.location.query.from !== '') {
      //reset page when changing a filter
      this.props.location.query.page = 1;
    }
    this.props.changePage();

    if (id === 'from') {
      this.setState({ ...this.state, dateFrom: date });
    } else if (id === 'to') {
      this.setState({ ...this.state, dateTo: date });
    }
  }

  //clear date params from the url
  clearDate () {
    delete this.props.location.query.from;
    delete this.props.location.query.to;

    this.setState({
      dateFrom: null,
      dateTo: null
    });

    this.props.location.query.page = 1;
    this.props.changePage();
  }

  //handle the dropdown selections and pass them into the url under the passed parameter
  handleSelection (e, query) {
    (e.target.value === 'all') ?
      delete this.props.location.query[query] :
      this.props.location.query[query] = e.target.value;
    //reset page when changing a filter
    this.props.location.query.page = 1;
    this.props.changePage();
  }

  componentDidMount () {
    //// TODO - disabled fetching (because we don't yet have these that were imported from Wega Fuel Trader)
    //fetch data
    // this.handleSessionStorage('countries','countries',true,'');
    // this.handleSessionStorage('hubs','hubs',true,'');
  }

  render () {
    const { dateFrom, dateTo, format, mode, inputFormat } = this.state;

    return(
      <div className="filter-bar">
        <form id="listFilterForm">
        
          {/* text-search element */}
          {this.props.elements.indexOf('text-search') !== -1 && 
            <FormGroup className="filter-holder keyword" controlId="formKeywordFilter">
              <ControlLabel>Filter by free text</ControlLabel>
              <FormControl 
                type="text" 
                value={(this.props.location.query.q) ? this.props.location.query.q : ''} 
                placeholder="Start typing..." 
                onChange={this.handleTextSearch}
                onKeyPress={this.handleKeyPress} />
            </FormGroup>
          }
          
          {/* date-filter elements */}
          {this.props.elements.indexOf('date-filter') !== -1 &&
            <FormGroup className="filter-holder date-from" controlId="formDateFilter">
              <ControlLabel>Deadline</ControlLabel>
              <InputGroup>
                <DateTime inputProps={ { id: 'dateFromInput', placeholder: "From", ref: "datePickerFrom"} }
                  className="date-input"
                  value={dateFrom}
                  dateFormat={inputFormat}
                  timeFormat={false}
                  viewMode={mode}
                  closeOnSelect={true}
                  utc={true}
                  locale={"en"}
                  onChange={(date)=>this.handleDate('from', date)} />
                <InputGroup.Addon><span className={'icon ion-calendar'}></span></InputGroup.Addon>
              </InputGroup>
            </FormGroup>
          }

          {this.props.elements.indexOf('date-filter') !== -1 &&
            <FormGroup className="filter-holder date-to" controlId="formDateFilter">
              <ControlLabel></ControlLabel>
              <InputGroup>
                <DateTime inputProps={ { id: 'dateToInput', placeholder: "To", ref: "datePickerTo"} }
                  className="date-input"
                  value={dateTo}
                  dateFormat={inputFormat}
                  timeFormat={false}
                  viewMode={mode}
                  closeOnSelect={true}
                  utc={true}
                  locale={"en"}
                  onChange={(date)=>this.handleDate('to', date)} />
                <InputGroup.Addon><span className={'icon ion-calendar'}></span></InputGroup.Addon>
              </InputGroup>
            </FormGroup>
          }

          {this.props.elements.indexOf('date-filter') !== -1 &&
            <Button className="clear-date-filter" onClick={this.clearDate}>Clear</Button>
          }
          
          {/* dropdown-filter elements */}
          {this.props.elements.indexOf('dropdown-countryFilter') !== -1 &&
            <Dropdown 
              arr={this.state.countries}
              change={(event)=>this.handleSelection(event, 'country')}
              id="countries"
              label="Country"
              selected={this.props.location.query.country}/>
          }

          {this.props.elements.indexOf('dropdown-hubFilter') !== -1 &&
            <Dropdown 
              arr={this.state.hubs}
              change={(event)=>this.handleSelection(event, 'hub')}
              id="hubs"
              label="Hub"
              selected={this.props.location.query.hub}/>
          }
          
          {/* {this.props.elements.indexOf('dropdown-rightsFilter') !== -1 &&
            <Dropdown 
              arr={this.userRightsArray}
              change={(event)=>this.handleSelection(event, 'type')}
              id="rights"
              label="Rights"
              selected={this.props.location.query.type}/>
          } */}

          {this.props.elements.indexOf('dropdown-roleFilter') !== -1 &&
            <Dropdown 
              arr={this.userRoleArray}
              change={(event)=>this.handleSelection(event, 'role')}
              id="role"
              label="Role"
              selected={this.props.location.query.role}/>
          }
        </form>
      </div>
    );
  }
}

FilterBar.propTypes = {
  //children: React.PropTypes.element.isRequired
  changePage: React.PropTypes.func,
  elements: React.PropTypes.array.isRequired,
  location: React.PropTypes.object
};