/* global apiBaseUrl */

'use strict';

import React from 'react';
import { Button, Checkbox, Col, ControlLabel, Form, FormControl, FormGroup, Modal, Row } from 'react-bootstrap';
import FormAddToList from './FormAddToList';
import FormAddContactToList from './FormAddContactToList';
import Header from './FormHeader';
import Footer from './FormFooter';
import { handleInputChange, fetchList, fetchDataAsForm, fetchDataIntoForm, handleSessionStorage, parseFetchError } from '../actions/utility';
import { initializeLead } from '../actions/entities';

require('../stylesheets/app.scss');
require('../stylesheets/components/formComponent.scss');

export default class FormLeadReport extends React.Component {
  constructor (props) {
    super(props);
    
    this.initializeLead = initializeLead.bind(this);

    this.handleInputChange = handleInputChange.bind(this);
    this.fetchList = fetchList.bind(this);
    this.fetchDataAsForm = fetchDataAsForm.bind(this);
    this.fetchDataIntoForm = fetchDataIntoForm.bind(this);
    this.handleSessionStorage = handleSessionStorage.bind(this);

    this.handleCheckboxInputChange = this.handleCheckboxInputChange.bind(this);
    this.handleImplementedAsBuyerCheckboxInputChange = this.handleImplementedAsBuyerCheckboxInputChange.bind(this);
    this.handleObjectCheckboxInputChange = this.handleObjectCheckboxInputChange.bind(this);
    this.toggleAllFields = this.toggleAllFields.bind(this);
    this.toggleAllAttachments = this.toggleAllAttachments.bind(this);
    this.updateFormInState = this.updateFormInState.bind(this);

    this.getReportLink = this.getReportLink.bind(this);
    
    this.state = {
      form: this.initializeForm(),
      leadFormName: "lead",
      leads: [],
      hubs: [],
      sites: [],
      generatingReport: false,
      downloadLink: '',
      requestPending: false,
      sendResponse: false,
      step: 1
    };
  }

  initializeForm() {
    return {
      lead_id: '',
      include_all_fields: '',
      include_implementer_details: '',
      include_buyer_details: '',
      include_seller_details: '',
      implementer_as_buyer: '',
      include_product_details: '',
      include_product_price: '',
      include_product_quantity_estimated: '',
      include_product_quantity_verified: '',
      include_additional_details: '',
      include_additional_costs: '',
      fields_logistics: [],
      document_ids: []
    };
  }

  handleSelectLead(e, callback) {
    this.handleInputChange(e, () => this.fetchDataAsForm(this.state.form.lead_id, "leads", this.state.leadFormName, () => this.handleShowLeadContents()));
    this.setAllFields(false);
    this.setAllAttachments(false);
  }

  handleShowLeadContents() {
    // Select from preloaded list (problematic, if the lead changes while this view is open)
    // let lead = this.state.leads.find(x => x.id == lead_id);
    
    // Get lead from the state (must exist in the state before this)
    let lead = this.state[this.state.leadFormName];

    if (!lead) {
      this.setState({
        form: this.initializeForm()
      })
      return;
    } else {
      // Get counterparty files
      if (lead.buyer && lead.buyer.id) {
        this.fetchDataIntoForm(lead.buyer.id, 'counterparties', this.state.leadFormName, 'counterparty_buyer');
      } else {

      }
      if (lead.seller && lead.seller.id) {
        this.fetchDataIntoForm(lead.seller.id, 'counterparties', this.state.leadFormName, 'counterparty_seller');
      }
    }
  }

  handleCheckboxInputChange(event, callback) {
    // console.log("event.target.id = " + event.target.id + " / value = " + event.target.value + " / checked = " + event.target.checked);
    this.setState({ form: { ...this.state.form, [event.target.id]: event.target.checked } })
  }

  handleImplementedAsBuyerCheckboxInputChange(event, callback) {
    // console.log("event.target.id = " + event.target.id + " / value = " + event.target.value + " / checked = " + event.target.checked);
    this.setState({
      form: {
        ...this.state.form,
        [event.target.id]: event.target.checked,
        include_implementer_details: false,
        include_buyer_details: false
      }
    })
  }

  handleObjectCheckboxInputChange(event, obj, callback) {
    obj.selected = event.target.checked;
    this.setState({ [this.state.leadFormName]: { ...this.state[this.state.leadFormName] } })
  }

  toggleAllFields(event, callback) {
    let form = this.state.form;

    // toggle all
    this.setAllFields(!form.include_all_fields, callback);
  }

  setAllFields(value, callback) {
    let form = this.state.form;

    form.include_all_fields = value;
    form.include_implementer_details = form.include_all_fields;
    form.include_buyer_details = form.include_all_fields;
    form.include_seller_details = form.include_all_fields;
    form.include_product_details = form.include_all_fields;
    form.include_product_price = form.include_all_fields;
    form.include_product_quantity_estimated = form.include_all_fields;
    form.include_product_quantity_verified = form.include_all_fields;
    form.include_additional_details = form.include_all_fields;
    form.include_additional_costs = form.include_all_fields;

    // Don't set to true - not the same logic as the other checkboxes
    // if (form.include_all_fields) {
      form.implementer_as_buyer = false;
    // }
    // form.implementer_as_buyer = form.include_all_fields;

    if (this.state[this.state.leadFormName]) {
      // Select logistics / legs fields
      if (this.state[this.state.leadFormName].logistics && this.state[this.state.leadFormName].logistics.legs && this.state[this.state.leadFormName].logistics.legs.length > 0) {
        for (let i = 0; i < this.state[this.state.leadFormName].logistics.legs.length; i++) {
          this.state[this.state.leadFormName].logistics.legs[i].selected = form.include_all_fields;
        }
      }
    }

    this.updateFormInState(form, callback);
  }

  toggleAllAttachments(event, callback) {
    let form = this.state.form;

    // toggle all
    this.setAllAttachments(!form.include_all_attachments, callback)
  }

  setAllAttachments(value, callback) {
    let form = this.state.form;

    form.include_all_attachments = value;

    if (this.state[this.state.leadFormName]) {
      // Select counterparty_buyer attachments
      if (this.state[this.state.leadFormName].counterparty_buyer) {
        for (let i = 0; i < this.state[this.state.leadFormName].counterparty_buyer.documents.length; i++) {
          this.state[this.state.leadFormName].counterparty_buyer.documents[i].selected = form.include_all_attachments;
        }
      }
      // Select counterparty_seller attachments
      if (this.state[this.state.leadFormName].counterparty_seller) {
        for (let i = 0; i < this.state[this.state.leadFormName].counterparty_seller.documents.length; i++) {
          this.state[this.state.leadFormName].counterparty_seller.documents[i].selected = form.include_all_attachments;
        }
      }
      // Select product attachments
      if (this.state[this.state.leadFormName].product_details) {
        for (let i = 0; i < this.state[this.state.leadFormName].product_details.documents.length; i++) {
          this.state[this.state.leadFormName].product_details.documents[i].selected = form.include_all_attachments;
        }
      }
      // Select certificates attachments
      if (this.state[this.state.leadFormName].certificates && this.state[this.state.leadFormName].certificates.length > 0) {
        for (let i = 0; i < this.state[this.state.leadFormName].certificates.length; i++) {
          for (let j = 0; j < this.state[this.state.leadFormName].certificates[i].documents.length; j++) {
            this.state[this.state.leadFormName].certificates[i].documents[j].selected = form.include_all_attachments;
          }
        }
      }
      // Select lead_certificates attachments
      if (this.state[this.state.leadFormName].lead_certificates && this.state[this.state.leadFormName].lead_certificates.length > 0) {
        for (let i = 0; i < this.state[this.state.leadFormName].lead_certificates.length; i++) {
          this.state[this.state.leadFormName].lead_certificates[i].selected = form.include_all_attachments;
        }
      }
      // Select logistics / legs attachments
      if (this.state[this.state.leadFormName].logistics && this.state[this.state.leadFormName].logistics.legs && this.state[this.state.leadFormName].logistics.legs.length > 0) {
        for (let i = 0; i < this.state[this.state.leadFormName].logistics.legs.length; i++) {
          for (let j = 0; j < this.state[this.state.leadFormName].logistics.legs[i].documents.length; j++) {
            this.state[this.state.leadFormName].logistics.legs[i].documents[j].selected = form.include_all_attachments;
          }
        }
      }
      // Select additional_costs attachments
      if (this.state[this.state.leadFormName].additional_costs && this.state[this.state.leadFormName].additional_costs.length > 0) {
        for (let i = 0; i < this.state[this.state.leadFormName].additional_costs.length; i++) {
          for (let j = 0; j < this.state[this.state.leadFormName].additional_costs[i].documents.length; j++) {
            this.state[this.state.leadFormName].additional_costs[i].documents[j].selected = form.include_all_attachments;
          }
        }
      }
    }

    this.updateFormInState(form, callback);
  }

  updateFormInState(form, callback) {
    if (!form) {
      console.warn("form is undefined. Will not update state.");
      return;
    }

    // Use setState updater callback explicitly if a callback is provided.
    // This ensures that the state is updated before executing the callback.
    if (callback) {
      // console.log("callback = " + callback);
      this.setState((prevState, props) => {
        return {
          form: form
        };
      }, callback);
    } else {
      // console.log("no callback");
      this.setState({
        form: form
      });
    }
  }

  getReportLink() {
    // First, reset leads list and download link
    this.setState({
      downloadLink: '',
    });

    var token = localStorage.getItem('woodtracker_id_token');
    var that = this;
    if (token) {
      var header = new Headers();
      header.append('Accept', 'application/json');
      header.append('Content-Type', 'application/json');
      header.append('Authorization', 'Bearer ' + token);
      
      var queryParams = {
        reports: {
          lead_ids: [this.state.form.lead_id],
          include_implementer_details: this.state.form.include_implementer_details,
          include_buyer_details: this.state.form.include_buyer_details,
          include_seller_details: this.state.form.include_seller_details,
          implementer_as_buyer: this.state.form.implementer_as_buyer,
          include_product_details: this.state.form.include_product_details,
          include_product_price: this.state.form.include_product_price,
          include_product_quantity_estimated: this.state.form.include_product_quantity_estimated,
          include_product_quantity_verified: this.state.form.include_product_quantity_verified,
          include_additional_details: this.state.form.include_additional_details,
          include_additional_costs: this.state.form.include_additional_costs,
        }
      };

      // Add selected legs
      if (this.state[this.state.leadFormName] && this.state[this.state.leadFormName].logistics && this.state[this.state.leadFormName].logistics.legs && this.state[this.state.leadFormName].logistics.legs.length > 0) {
        let leg_ids = [];
        for (let i = 0; i < this.state[this.state.leadFormName].logistics.legs.length; i++) {
          if (this.state[this.state.leadFormName].logistics.legs[i].selected) {
            leg_ids.push(this.state[this.state.leadFormName].logistics.legs[i].id);
          }  
        }

        queryParams.reports.leg_ids = leg_ids;
      }  

      // Add selected documents
      let document_ids = [];

      // Add selected counterparty_buyer attachments
      if (this.state[this.state.leadFormName].counterparty_buyer) {
        for (let i = 0; i < this.state[this.state.leadFormName].counterparty_buyer.documents.length; i++) {
          if (this.state[this.state.leadFormName].counterparty_buyer.documents[i].selected) {
            document_ids.push(this.state[this.state.leadFormName].counterparty_buyer.documents[i].id);
          }
        }
      }
      // Add selected counterparty_seller attachments
      if (this.state[this.state.leadFormName].counterparty_seller) {
        for (let i = 0; i < this.state[this.state.leadFormName].counterparty_seller.documents.length; i++) {
          if (this.state[this.state.leadFormName].counterparty_seller.documents[i].selected) {
            document_ids.push(this.state[this.state.leadFormName].counterparty_seller.documents[i].id);
          }
        }
      }
      // Add selected product attachments
      if (this.state[this.state.leadFormName].product_details) {
        for (let i = 0; i < this.state[this.state.leadFormName].product_details.documents.length; i++) {
          if (this.state[this.state.leadFormName].product_details.documents[i].selected) {
            document_ids.push(this.state[this.state.leadFormName].product_details.documents[i].id);
          }
        }
      }
      // Add selected certificates attachments
      if (this.state[this.state.leadFormName].certificates && this.state[this.state.leadFormName].certificates.length > 0) {
        for (let i = 0; i < this.state[this.state.leadFormName].certificates.length; i++) {
          for (let j = 0; j < this.state[this.state.leadFormName].certificates[i].documents.length; j++) {
            if (this.state[this.state.leadFormName].certificates[i].documents[j].selected) {
              document_ids.push(this.state[this.state.leadFormName].certificates[i].documents[j].id);
            }
          }
        }
      }
      // Add selected lead_certificates attachments
      if (this.state[this.state.leadFormName].lead_certificates && this.state[this.state.leadFormName].lead_certificates.length > 0) {
        for (let i = 0; i < this.state[this.state.leadFormName].lead_certificates.length; i++) {
          if (this.state[this.state.leadFormName].lead_certificates[i].selected) {
            document_ids.push(this.state[this.state.leadFormName].lead_certificates[i].id);
          }
        }
      }
      // Add selected logistics / legs attachments
      if (this.state[this.state.leadFormName].logistics && this.state[this.state.leadFormName].logistics.legs && this.state[this.state.leadFormName].logistics.legs.length > 0) {
        for (let i = 0; i < this.state[this.state.leadFormName].logistics.legs.length; i++) {
          for (let j = 0; j < this.state[this.state.leadFormName].logistics.legs[i].documents.length; j++) {
            if (this.state[this.state.leadFormName].logistics.legs[i].documents[j].selected) {
              document_ids.push(this.state[this.state.leadFormName].logistics.legs[i].documents[j].id);
            }
          }
        }
      }
      // Add selected additional_costs attachments
      if (this.state[this.state.leadFormName].additional_costs && this.state[this.state.leadFormName].additional_costs.length > 0) {
        for (let i = 0; i < this.state[this.state.leadFormName].additional_costs.length; i++) {
          for (let j = 0; j < this.state[this.state.leadFormName].additional_costs[i].documents.length; j++) {
            if (this.state[this.state.leadFormName].additional_costs[i].documents[j].selected) {
              document_ids.push(this.state[this.state.leadFormName].additional_costs[i].documents[j].id);
            }
          }
        }
      }

      // Insert all selected documents into the queryParams
      if (document_ids && document_ids.length > 0) {
        queryParams.reports.document_ids = document_ids;
      }  
      
      var request = new Request(apiBaseUrl + '/reports/generate.pdf', { 
        method: 'POST', 
        headers: header,
        body: JSON.stringify(queryParams)
      });
      
      this.setState({ requestPending: true });
      fetch(request).then(function(response) {
        // if (response.status === 204) {
        //   that.setState({ 'generatingReport' : false });
        //   that.setState({ 'downloadLink' : 'no_content' });
        //   //scroll user to the downloadlink
        //   window.scrollTo(0, document.querySelector('.download-link-holder').offsetTop);
        //   return false;
        // } else
        if (!response.ok) {
          that.setState({ requestPending : false });
          that.setState({ 'downloadLink' : 'error' });
          //scroll user to the downloadlink
          window.scrollTo(0, document.querySelector('.download-link-holder').offsetTop);
          return false;

          // throw Error(response.statusText);
          // return response.json().then(err => { throw err; });
        } else {
          return response.blob();
        }
      }).then(function(response) {
        //console.log(URL.createObjectURL(response));
        if (response) {
          that.setState({ requestPending : false });
          that.setState({ 'downloadLink' : URL.createObjectURL(response) });
          //scroll user to the downloadlink
          window.scrollTo(0, document.querySelector('.download-link-holder').offsetTop);
        }
      }).catch(function(err) {
        that.setState({ ...that.state, requestPending: false, generatingReport: false });
        parseFetchError(err);
      });
    }
    this.setState({ requestPending: true });
  }
  
  componentDidMount () {
    //fetch data
    if (this.props.visible) {
      this.handleSessionStorage('leads', 'leads', false, '');
      this.handleSessionStorage('hubs', 'hubs', false, '');
      this.handleSessionStorage('sites', 'sites', false, '');
    }
  }

  render () {
    return(
      <Modal bsSize="large" id={this.props.formId} className={"form-component" + ((this.state.requestPending) ? " busy-blocking" : '')} backdrop={"static"} keyboard={false} show={this.props.visible} onHide={() => this.props.close(this.props.formId)}>
        <Modal.Header closeButton={false}>
          <Modal.Title>Create a report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <FormGroup id="leadReportInformation">
              <Row>
                <Col componentClass={ControlLabel} sm={4}>
                  Select lead
                </Col>
                <Col sm={8}>
                  <FormControl id="lead_id" componentClass="select" placeholder="select lead" value={this.state.form.lead_id || ''} onChange={(event) => this.handleSelectLead(event)}>
                    <option value=''></option>
                    {this.state.leads.map((item, i) => (
                      <option value={item.id} key={'lead' + item.id}>{'Lead ' + item.id}</option>
                    ))}
                  </FormControl>
                </Col>
              </Row>

              <Row>
                <Col componentClass={ControlLabel} sm={4}>
                  Select fields to include in the report
                </Col>
                <Col sm={8}>
                  <Col sm={12}>
                    <Checkbox inline id="include_all_fields"
                      checked={(this.state.form.include_all_fields ? true : false)}
                      onChange={this.toggleAllFields}>Select all fields</Checkbox>
                  </Col>

                  {/* Counterparties BEGIN */}
                  <Col sm={12}>
                    <ControlLabel>Counterparties</ControlLabel>
                  </Col>
                  <Col sm={6}>
                    <Checkbox inline id="include_seller_details"
                      checked={(this.state.form.include_seller_details ? true : false)}
                      onChange={this.handleCheckboxInputChange}>Seller counterparty details</Checkbox>
                  </Col>
                  <Col sm={6}>
                    <Checkbox inline id="include_buyer_details"
                      checked={(this.state.form.include_buyer_details ? true : false)}
                      onChange={this.handleCheckboxInputChange} disabled={this.state.form.implementer_as_buyer}>Buyer counterparty details</Checkbox>
                  </Col>
                  <Col sm={6}>
                    <Checkbox inline id="include_implementer_details"
                      checked={(this.state.form.include_implementer_details ? true : false)}
                      onChange={this.handleCheckboxInputChange} disabled={this.state.form.implementer_as_buyer}>Implementer details</Checkbox>
                  </Col>
                  <Col sm={6}>
                    <Checkbox inline id="implementer_as_buyer"
                      checked={(this.state.form.implementer_as_buyer ? true : false)}
                      onChange={this.handleImplementedAsBuyerCheckboxInputChange}>Implementer as buyer</Checkbox>
                  </Col>
                  {/* Counterparties END */}

                  {/* Product details BEGIN */}
                  <Col sm={12}>
                    <ControlLabel>Product details</ControlLabel>
                  </Col>
                  <Col sm={6}>
                    <Checkbox inline id="include_product_details"
                      checked={(this.state.form.include_product_details ? true : false)}
                      onChange={this.handleCheckboxInputChange}>Product details</Checkbox>
                  </Col>
                  <Col sm={6}>
                    <Checkbox inline id="include_product_quantity_estimated"
                      checked={(this.state.form.include_product_quantity_estimated ? true : false)}
                      onChange={this.handleCheckboxInputChange}>Product quantity</Checkbox>
                  </Col>
                  <Col sm={6}>
                    <Checkbox inline id="include_product_price"
                      checked={(this.state.form.include_product_price ? true : false)}
                      onChange={this.handleCheckboxInputChange}>Product price</Checkbox>
                  </Col>
                  <Col sm={6}>
                    <Checkbox inline id="include_product_quantity_verified"
                      checked={(this.state.form.include_product_quantity_verified ? true : false)}
                      onChange={this.handleCheckboxInputChange}>Verified quantity</Checkbox>
                  </Col>
                  {/* Product details END */}

                  {/* Additional details BEGIN */}
                  <Col sm={12}>
                    <ControlLabel>Additional details</ControlLabel>
                  </Col>
                  <Col sm={6}>
                    <Checkbox inline id="include_additional_details"
                      checked={(this.state.form.include_additional_details ? true : false)}
                      onChange={this.handleCheckboxInputChange}>ETA + additional info</Checkbox>
                  </Col>
                  {/* Additional details END */}

                  {/* Logistics BEGIN */}
                  {this.state[this.state.leadFormName] && this.state[this.state.leadFormName].logistics && this.state[this.state.leadFormName].logistics.legs && this.state[this.state.leadFormName].logistics.legs.length > 0 &&
                    <Col sm={12}>
                      <ControlLabel>Logistics</ControlLabel>
                    </Col>
                  }
                  {(this.state[this.state.leadFormName] && this.state[this.state.leadFormName].logistics && this.state[this.state.leadFormName].logistics.legs) ? this.state[this.state.leadFormName].logistics.legs.map((leg, legIndex) => (
                    <Col key={'legCol' + leg.id} sm={12}>
                      <Checkbox id={'leg' + leg.id} inline
                        checked={(leg.selected ? true : false)}
                        onChange={(event) => this.handleObjectCheckboxInputChange(event, leg)}> {legIndex + 1}. FROM: {leg.start_hub_id ? this.state.hubs.find(x => x.id == leg.start_hub_id).name : leg.start_site_id ? this.state.sites.find(x => x.id == leg.start_site_id).name : ''} - TO: {leg.end_hub_id ? this.state.hubs.find(x => x.id == leg.end_hub_id).name : leg.end_site_id ? this.state.sites.find(x => x.id == leg.end_site_id).name : ''}</Checkbox>
                    </Col>
                  )) : false}
                  {/* Logistics END */}

                  {/* Additional costs BEGIN */}
                  {this.state[this.state.leadFormName] && this.state[this.state.leadFormName].additional_costs && this.state[this.state.leadFormName].additional_costs.length > 0 &&
                    <Col sm={12}>
                      <ControlLabel>Additional costs</ControlLabel>
                    </Col>
                  }
                  {/* Selection for each intem in an array */}
                  {/* {(this.state[this.state.leadFormName] && this.state[this.state.leadFormName].additional_costs) ? this.state[this.state.leadFormName].additional_costs.map((additional_cost, additional_costIndex) => (
                    <Col key={'additional_costCol' + additional_cost.id} sm={12}>
                      <Checkbox id={'additional_cost' + additional_cost.id} inline
                        checked={(additional_cost.selected ? true : false)}
                        onChange={this.handleCheckboxInputChange}> {additional_cost.id}: {additional_cost.cost_type}</Checkbox>
                    </Col>
                  )) : false} */}
                  {/* One selection for all */}
                  {this.state[this.state.leadFormName] && this.state[this.state.leadFormName].additional_costs && this.state[this.state.leadFormName].additional_costs.length > 0 &&
                    <Col sm={6}>
                      <Checkbox inline id="include_additional_costs"
                        checked={(this.state.form.include_additional_costs ? true : false)}
                        onChange={this.handleCheckboxInputChange}>Include additional costs</Checkbox>
                    </Col>
                  }
                  {/* Additional costs END */}
                </Col>
              </Row>

              <Row>
                <Col componentClass={ControlLabel} sm={4}>
                  Select attachments
                </Col>
                <Col sm={8}>
                  <Col sm={12}>
                    <ControlLabel>Attachments</ControlLabel>
                  </Col>
                  <Col sm={12}>
                    <Checkbox inline id="include_all_attachments"
                      checked={(this.state.form.include_all_attachments ? true : false)}
                      onChange={this.toggleAllAttachments}>Include all attachments</Checkbox>
                  </Col>

                  {/* Counterparty attachments BEGIN */}
                  {((this.state[this.state.leadFormName] && this.state[this.state.leadFormName].counterparty_buyer && this.state[this.state.leadFormName].counterparty_buyer.documents && this.state[this.state.leadFormName].counterparty_buyer.documents.length > 0) || this.state.form.seller && this.state.form.seller.documents && this.state.form.seller.documents.length > 0) &&
                    <Col sm={12}>
                      <ControlLabel>Counterparty specific certificates and attachments</ControlLabel>
                    </Col>
                  }
                  {(this.state[this.state.leadFormName] && this.state[this.state.leadFormName].counterparty_seller && this.state[this.state.leadFormName].counterparty_seller.documents && this.state[this.state.leadFormName].counterparty_seller.documents.length > 0) ? this.state[this.state.leadFormName].counterparty_seller.documents.map((attachmentDocument, attachmentDocumentIndex) => (
                    <Col key={'attachmentDocumentCol' + attachmentDocument.id} sm={12}>
                      <Checkbox id={'attachmentDocument' + attachmentDocument.id} inline
                        checked={(attachmentDocument.selected ? true : false)}
                        onChange={(event) => this.handleObjectCheckboxInputChange(event, attachmentDocument)}> {/* {attachmentDocumentIndex + 1}. */} {attachmentDocument.file_name}</Checkbox>
                    </Col>
                  )) : false}
                  {(this.state[this.state.leadFormName] && this.state[this.state.leadFormName].counterparty_buyer && this.state[this.state.leadFormName].counterparty_buyer.documents && this.state[this.state.leadFormName].counterparty_buyer.documents.length > 0) ? this.state[this.state.leadFormName].counterparty_buyer.documents.map((attachmentDocument, attachmentDocumentIndex) => (
                    <Col key={'attachmentDocumentCol' + attachmentDocument.id} sm={12}>
                      <Checkbox id={'attachmentDocument' + attachmentDocument.id} inline
                        checked={(attachmentDocument.selected ? true : false)}
                        onChange={(event) => this.handleObjectCheckboxInputChange(event, attachmentDocument)}> {/* {attachmentDocumentIndex + 1}. */} {attachmentDocument.file_name}</Checkbox>
                    </Col>
                  )) : false}
                  {/* Counterparty attachments END */}

                  {/* Product attachments BEGIN */}
                  {(this.state[this.state.leadFormName] && this.state[this.state.leadFormName].product_details && this.state[this.state.leadFormName].product_details.documents && this.state[this.state.leadFormName].product_details.documents.length > 0) &&
                    <Col sm={12}>
                      <ControlLabel>Product specific attachments</ControlLabel>
                    </Col>
                  }
                  {(this.state[this.state.leadFormName] && this.state[this.state.leadFormName].product_details && this.state[this.state.leadFormName].product_details.documents && this.state[this.state.leadFormName].product_details.documents.length > 0) ? this.state[this.state.leadFormName].product_details.documents.map((attachmentDocument, attachmentDocumentIndex) => (
                    <Col key={'attachmentDocumentCol' + attachmentDocument.id} sm={12}>
                      <Checkbox id={'attachmentDocument' + attachmentDocument.id} inline
                        checked={(attachmentDocument.selected ? true : false)}
                        onChange={(event) => this.handleObjectCheckboxInputChange(event, attachmentDocument)}> {/* {attachmentDocumentIndex + 1}. */} {attachmentDocument.file_name}</Checkbox>
                    </Col>
                  )) : false}
                  {/* Product attachments END */}

                  {/* Certificates attachments BEGIN */}
                  {(this.state[this.state.leadFormName] && this.state[this.state.leadFormName].certificates && this.state[this.state.leadFormName].certificates.length > 0) &&
                    <Col sm={12}>
                      <ControlLabel>Certificates registry specific attachments</ControlLabel>
                    </Col>
                  }
                  {(this.state[this.state.leadFormName] && this.state[this.state.leadFormName].certificates && this.state[this.state.leadFormName].certificates.length > 0) ? this.state[this.state.leadFormName].certificates.map((certificate, certificateIndex) => (
                    (certificate && certificate.documents && certificate.documents.length > 0) ? certificate.documents.map((attachmentDocument, attachmentDocumentIndex) => (
                      <Col key={'attachmentDocumentCol' + attachmentDocument.id} sm={12}>
                        <Checkbox id={'attachmentDocument' + attachmentDocument.id} inline
                          checked={(attachmentDocument.selected ? true : false)}
                          onChange={(event) => this.handleObjectCheckboxInputChange(event, attachmentDocument)}> {/* {attachmentDocumentIndex + 1}. */} {attachmentDocument.file_name}</Checkbox>
                      </Col>
                    )) : false
                  )) : false}

                  {(this.state[this.state.leadFormName] && this.state[this.state.leadFormName].lead_certificates && this.state[this.state.leadFormName].lead_certificates.length > 0) &&
                    <Col sm={12}>
                      <ControlLabel>Lead specific certificates attachments</ControlLabel>
                    </Col>
                  }
                  {(this.state[this.state.leadFormName] && this.state[this.state.leadFormName].lead_certificates && this.state[this.state.leadFormName].lead_certificates.length > 0) ? this.state[this.state.leadFormName].lead_certificates.map((attachmentDocument, attachmentDocumentIndex) => (
                    <Col key={'attachmentDocumentCol' + attachmentDocument.id} sm={12}>
                      <Checkbox id={'attachmentDocument' + attachmentDocument.id} inline
                        checked={(attachmentDocument.selected ? true : false)}
                        onChange={(event) => this.handleObjectCheckboxInputChange(event, attachmentDocument)}> {/* {attachmentDocumentIndex + 1}. */} {attachmentDocument.file_name}</Checkbox>
                    </Col>
                  )) : false}
                  {/* Certificates attachments END */}

                  {/* Logistics attachments BEGIN */}
                  {(this.state[this.state.leadFormName] && this.state[this.state.leadFormName].logistics && this.state[this.state.leadFormName].logistics.legs && this.state[this.state.leadFormName].logistics.legs.length > 0) &&
                    <Col sm={12}>
                      <ControlLabel>Logistics attachments</ControlLabel>
                    </Col>
                  }
                  {(this.state[this.state.leadFormName] && this.state[this.state.leadFormName].logistics && this.state[this.state.leadFormName].logistics.legs && this.state[this.state.leadFormName].logistics.legs.length > 0) ? this.state[this.state.leadFormName].logistics.legs.map((leg, legIndex) => (
                    (leg && leg.documents && leg.documents.length > 0) ? leg.documents.map((attachmentDocument, attachmentDocumentIndex) => (
                      <Col key={'attachmentDocumentCol' + attachmentDocument.id} sm={12}>
                        <Checkbox id={'attachmentDocument' + attachmentDocument.id} inline
                          checked={(attachmentDocument.selected ? true : false)}
                          onChange={(event) => this.handleObjectCheckboxInputChange(event, attachmentDocument)}> {/* {attachmentDocumentIndex + 1}. */} {attachmentDocument.file_name}</Checkbox>
                      </Col>
                    )) : false
                  )) : false}
                  {/* Logistics attachments END */}

                  {/* Additional cost attachments BEGIN */}
                  {(this.state[this.state.leadFormName] && this.state[this.state.leadFormName].additional_costs && this.state[this.state.leadFormName].additional_costs.length > 0) &&
                    <Col sm={12}>
                      <ControlLabel>Additional cost attachments</ControlLabel>
                    </Col>
                  }
                  {(this.state[this.state.leadFormName] && this.state[this.state.leadFormName].additional_costs && this.state[this.state.leadFormName].additional_costs.length > 0) ? this.state[this.state.leadFormName].additional_costs.map((leg, legIndex) => (
                    (leg && leg.documents && leg.documents.length > 0) ? leg.documents.map((attachmentDocument, attachmentDocumentIndex) => (
                      <Col key={'attachmentDocumentCol' + attachmentDocument.id} sm={12}>
                        <Checkbox id={'attachmentDocument' + attachmentDocument.id} inline
                          checked={(attachmentDocument.selected ? true : false)}
                          onChange={(event) => this.handleObjectCheckboxInputChange(event, attachmentDocument)}> {/* {attachmentDocumentIndex + 1}. */} {attachmentDocument.file_name}</Checkbox>
                      </Col>
                    )) : false
                  )) : false}
                  {/* Additional cost attachments END */}

                </Col>
              </Row>

              <Row>
                
                {(this.state.downloadLink && this.state.downloadLink !== 'error') &&
                  ((!this.state.requestPending) ?
                    <div className="download-link-holder">
                      <Button href={this.state.downloadLink}>
                        <span className={'icon ion-ios-download-outline'}></span>
                        Download report
                      </Button>
                    </div>
                    :
                    <div className="download-link-holder loading" />)
                }

                {(this.state.downloadLink === 'error') &&
                  ((!this.state.requestPending) ?
                    <div className="download-link-holder">
                      <h2 className="detail-page-header">
                        An error occurrect. Possibly cause by incomplete or corrupt data.
                      </h2>
                      <h3 className="detail-page-header">
                      Please make a bug report. Include the current Lead id {(this.state[this.state.leadFormName] ? '(' + this.state[this.state.leadFormName].id + ') ' : '')}and a short description of what you were doing when the error occurred.
                      </h3>
                    </div>
                    :
                    <div className="download-link-holder loading" />)
                }
              </Row>
            </FormGroup>
          </Form>
        </Modal.Body>
        <Modal.Footer className="centered">
          {(this.state[this.state.leadFormName]) ?
            <Button id="getReportLink" onClick={this.getReportLink}>Create report</Button>
            :
            <Button disabled={true}>Select lead to create Report</Button>
          }
          {/* <Button onClick={(num)=>this.handleStepChange(num)}>Ok</Button> */}
          <Button className="cancel" onClick={this.props.toggle}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

FormLeadReport.propTypes = {
  close: React.PropTypes.func.isRequired,
  formId: React.PropTypes.string.isRequired,
  toggle: React.PropTypes.func.isRequired,
  visible: React.PropTypes.bool.isRequired
};