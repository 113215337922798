/* global apiBaseUrl */

'use strict';

import React from 'react';
import { Col, ControlLabel, Form, FormControl, FormGroup, Modal, Row } from 'react-bootstrap';
import Header from './FormHeader';
import Footer from './FormFooter';
import { handleInputChange, fetchList, handleSessionStorage, parseFetchError } from '../actions/utility';

require('../stylesheets/app.scss');
require('../stylesheets/components/formComponent.scss');

export default class FormNewProduct extends React.Component {
  constructor (props) {
    super(props);
    
    this.handleStepChange = this.handleStepChange.bind(this);
    this.handleInputChange = handleInputChange.bind(this);
    this.fetchList = fetchList.bind(this);
    this.handleSessionStorage = handleSessionStorage.bind(this);
    
    this.state = {
      form: {
        productName: '',
        additionalInformation: ''
      },
      requestPending: false,
      sendResponse: false,
      step: 1
    };
  }
  
  handleStepChange (num) {
    //console.log(e.target);
    //console.log(num);
    var errorFound = false;
    var informError = function (id) {
      //console.log(id);
      //alert(id + ' is missing a value.');
      //add error class to all of the inputs that are missing information
      if (document.getElementById(id).className.indexOf('error') < 0) {
        document.getElementById(id).className += ' error';
      }  
      errorFound = true;
    };
    switch (num) {
    case 2:
      //check for the first views data
      for (let i in this.state.form) {
         //filter out the information that is not concerning step 1
        if (i !== 'additionalInformation') {
          if (!this.state.form[i]) {
            informError(i);
          }
        }
      }
      break;
    }
    //user is not allowed to go forward if something is missing
    if (!errorFound && num === 2) {
      //send new product form
      var token = localStorage.getItem('woodtracker_id_token');
      var that = this;
      if (token) {
        var header = new Headers();
        header.append('Accept', 'application/json');
        header.append('Content-Type', 'application/json');
        header.append('Authorization', 'Bearer ' + token);
        
        var findIds = function (arr1, arr2, arr3) {
          for (let i = 0, j = arr2.length; i < j; i++) {
            for (let k = 0, l = arr3.length; k < l; k++) {
              if (arr3[k] === arr2[i].name) {
                arr1.push(arr2[i].id);
              }
            }
          }
        };
        
        var product = {
          product: {
            name: this.state.form.productName,
            additional_info: this.state.form.additionalInformation
          }
        };
        
        var request = new Request(apiBaseUrl + '/products', { 
          method: 'POST', 
          headers: header,
          body: JSON.stringify(product)
        });
        
        //prevent calling fetch if request is pending
        if (!that.state.requestPending) {
          that.setState({ requestPending: true });
          fetch(request).then(function(response) {
            if (!response.ok) {
              //throw Error(response.statusText);
              return response.json().then(err => { throw err; });
            }
            //console.log(response);
            return response.text();
          }).then(function(/*text*/) {
            //console.log(text);
            that.setState({ step: num });
            that.setState({ sendResponse: true });
            that.setState({ requestPending: false });
            sessionStorage.productsListUpdated = '';
          }).catch(function(err) {
            that.setState({ step: num });
            that.setState({ sendResponse: false });
            that.setState({ requestPending: false });
            parseFetchError(err);
          });
        }
      }
    } else if (!errorFound && num === 1) {
      this.setState({ step: num });
      this.setState({ sendResponse: false });
      this.setState({ requestPending: false });
    } else {
      alert('Data is missing. Please fill in all of the required data.');
    }
  }
  
  componentDidMount () {
    //fetch data
    if (this.props.visible) {
      //// TODO - disabled fetching (because we don't yet have these that were imported from Wega Fuel Trader)
      // this.handleSessionStorage('countries','countries',false,'');
    }
  }

  render () {
    return(
      <Modal bsSize="large" id={this.props.formId} className={"form-component" + ((this.state.requestPending) ? " busy-blocking" : '')} backdrop={((this.state.requestPending) ? "static" : true)} keyboard={((this.state.requestPending) ? false : true)} show={this.props.visible} onHide={() => (this.state.step !== 2) ? this.props.close(this.props.formId) : this.props.toggle(this.props.formId)}>
        <Header 
          buttons={['Product details','Done!']}
          currentStep={this.state.step}
          title="New product" />
        <Modal.Body>
          {/* step 1 - product information */}
          {this.state.step === 1 &&
            <Form>
              <FormGroup conrolId="newProductInformation">
                <Row>
                  <Col componentClass={ControlLabel} sm={4}>
                    Product details
                  </Col>
                  <Col sm={8}>
                    <ControlLabel>Name *</ControlLabel>
                    <FormControl id="productName" type="text" value={this.state.form.productName} onChange={this.handleInputChange} />
                  </Col>
                </Row>
                <Row>
                  <Col componentClass={ControlLabel} sm={4}>
                    Other
                  </Col>
                  <Col sm={8}>
                    <ControlLabel>Additional information</ControlLabel>
                    <FormControl id="additionalInformation" componentClass="textarea" value={this.state.form.additionalInformation} placeholder="" onChange={this.handleInputChange}/>
                  </Col>
                </Row>
              </FormGroup>
            </Form>
          }
          {/* step 2 - done */}
          {this.state.step === 2 &&
            <div>
              {/* success case */}
              {this.state.sendResponse &&
                <div>
                  <h2 className="inquiry-done success">
                    The product information was sent and stored succesfully.
                  </h2>
                  {/*<p className="inquiry-done-info">This window will close automatically in five seconds.</p>*/}
                </div>
              }
              {/* error case */}
              {!this.state.sendResponse &&
                <div>
                  <h2 className="inquiry-done error">
                    {/* TODO should show actual reasons for error */}
                    Something went wrong. Please try again in a few moments.
                  </h2>
                </div>
              }
            </div>
          }
        </Modal.Body>
        <Footer 
          currentStep={this.state.step}
          handleStepChange={(num)=>this.handleStepChange(num)}
          sendResponse={this.state.sendResponse}
          toggle={()=>this.props.toggle(this.props.formId)}
          totalSteps={2} />
      </Modal>
    );
  }
}

FormNewProduct.propTypes = {
  close: React.PropTypes.func.isRequired,
  formId: React.PropTypes.string.isRequired,
  toggle: React.PropTypes.func.isRequired,
  visible: React.PropTypes.bool.isRequired
};