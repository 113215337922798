'use strict';

require('../stylesheets/app.scss');

import React from 'react';

export default class NotFoundPage extends React.Component {
  render () {
    return (
      <div className="App">
        <h1>Not Found!</h1>
      </div>
    );
  }
}