'use strict';

import React from 'react';
import { Button, Col, ControlLabel, FormControl, Row } from 'react-bootstrap';

require('../stylesheets/app.scss');
require('../stylesheets/components/formComponent.scss');

export default class FormAddContactToList extends React.Component {
  constructor (props) { 
    super(props);
  }
  
  render () {
    return (
      <div className={((this.props.loading) ? ' loading' : '')}>
        <Row className="add-item-holder">
        {this.props.label && 
          <ControlLabel>{this.props.label}</ControlLabel>
        }
        {(this.props.active !== false) &&
          <div>
            <Row-fluid>
              <Col sm={10}>
                <Col className="input-field-padding left" sm={4}>
                  <ControlLabel>Contact name</ControlLabel>
                </Col>
                <Col className="input-field-padding middle" sm={4}>
                  <ControlLabel>Phone</ControlLabel>
                </Col>
                <Col className="input-field-padding right" sm={4}>
                  <ControlLabel>Email</ControlLabel>
                </Col>
              </Col>
            </Row-fluid>
            <Col sm={10}>
              <Col className="input-field-padding left" sm={4}>
                <FormControl id={this.props.inputName} type="text" value={this.props.inputValueName} onChange={this.props.inputOnChange} placeholder="Firstname Lastname" />
              </Col>
              <Col className="input-field-padding middle" sm={4}>
                <FormControl id={this.props.inputPhone} type="text" value={this.props.inputValuePhone} onChange={this.props.inputOnChange} placeholder="+XXX XX XXXX XXX" />
              </Col>
              <Col className="input-field-padding right" sm={4}>
                <FormControl id={this.props.inputEmail} type="text" value={this.props.inputValueEmail} onChange={this.props.inputOnChange} 
                placeholder="name@company.com" />
              </Col>
            </Col>

            <Col sm={2}>
              <Button className="addNew-button" onClick={this.props.addToListFunc}>
                <span className={'icon ion-plus'}></span>
                Add
              </Button>
            </Col>
          </div>
        }
          
          {(this.props.listArray.length === 0) &&
            <Col className="fuelList" sm={12}>
              <ul className="empty-list-ul">
                <li className="empty-list-li">
                  The list {this.props.label ? 'for ' + this.props.label : 'for Contacts'} is empty.
                </li>
              </ul>
            </Col>
          }

          {(this.props.listArray.length > 0) &&
            <Col className="fuelList" sm={12}>
              <ul>
                {this.props.listArray.map(function(item, i){
                  return <li id={i} key={i}>
                  <Col sm="10">
                    <Col className="input-field-padding left" sm={4}>{item.name}</Col>
                    <Col className="input-field-padding middle" sm={4}>{item.phone}</Col>
                    <Col className="input-field-padding middle" sm={4}>{item.email}</Col>
                  </Col>
                  <Col sm="0">
                    <Col className="input-field-padding right" sm={2}>
                      {(this.props.active !== false) &&
                        <span className="icon ion-android-delete" onClick={this.props.deleteFromListFunc}></span>
                      }
                    </Col>
                  </Col>
                  </li>;
                },this)}
              </ul>
            </Col>
          }
          </Row>
      </div>
    );
  }
}

FormAddContactToList.propTypes = {
  inputName: React.PropTypes.string.isRequired, //element id used by data validation
  inputEmail: React.PropTypes.string.isRequired, //element id used by data validation
  inputPhone: React.PropTypes.string.isRequired, //element id used by data validation
  inputValueName: React.PropTypes.string.isRequired, //the value within views state that is updated
  inputValueEmail: React.PropTypes.string.isRequired, //the value within views state that is updated
  inputValuePhone: React.PropTypes.string.isRequired, //the value within views state that is updated
  inputOnChange: React.PropTypes.func.isRequired, //the 2-way binding handler function
  
  dataList: React.PropTypes.bool.isRequired, //boolean to determine if autocomplete datalist should be used
  dataListId: React.PropTypes.string, //string id to connect the input to the datalist
  dataListArray: React.PropTypes.array, //array of the possible items to choose
  
  addToListFunc: React.PropTypes.func.isRequired, //function to handle adding elements to the form list
  deleteFromListFunc: React.PropTypes.func.isRequired, //function to handle removing elements to the form list
  listArray: React.PropTypes.array.isRequired, //form list array that is updated by adding and removal

  label: React.PropTypes.string.isRequired //the label for he input and also used on the empty list notification text
};

