'use strict';

require('../stylesheets/app.scss');

import React from 'react';
import { Link } from 'react-router';
import ActionBar from '../components/ActionBar';
import FilterBar from '../components/FilterBar';
import ListComponent from '../components/ListComponent';
import { changePage, fetchList, fetchListPage, handleSessionStorage, updateList } from '../actions/utility';

export default class Hubs extends React.Component {
  constructor (props) {
    super(props);
    
    this.fetchList = fetchList.bind(this); //requires the list id string
    this.fetchListPage = fetchListPage.bind(this);
    this.handleSessionStorage = handleSessionStorage.bind(this); //requires the list id string
    
    this.changePage = changePage.bind(this);
    this.updateList = updateList.bind(this);

    this.state = { 
      list: [],
      buttonVisibility: [true],
      meta: {}
    };
    
    this.buttons = [
      {
        formId: 'addNewHub',
        icon: 'ion-plus',
        refreshFunc: ()=>this.updateList(undefined, 'hubs'),
        text: 'add new hub'
      }
    ];

    // //call list update on component init to make sure that the list is up to date
    // this.updateList(undefined, 'hubs');
  }

  componentDidMount() {
    //call list update on component init to make sure that the list is up to date
    this.updateList(undefined, 'hubs');
  }

  /*componentDidMount() {
    var query = '';
    for (let i in this.props.location.query) {
      query += '&' + i + '=' + this.props.location.query[i];
    }
    this.fetchListPage('hubs','list',query.replace('&','?'));
  }*/
  
  componentWillReceiveProps (nextProps) {
    if (nextProps.location.action === 'POP') {
      this.changePage('hubs',nextProps.location.search);
    }
  }

  render () {
    return (
      <div className="hubs">
        <ActionBar 
          elements={this.buttons} 
          visibility={this.state.buttonVisibility} />
        
        {/* //// TODO - disabled 'dropdown-countryFilter' from FilterBar elements=. Add it later is we choose to support countries more robustly) */}
        <FilterBar 
          changePage={()=>this.changePage('hubs')}
          elements={['text-search']}//,'dropdown-countryFilter']} 
          location={this.props.location} />
        
        <ListComponent 
          changePage={()=>this.changePage('hubs')}
          elements={['componentList','componentShow','componentPagination']} 
          location={this.props.location}
          loading={this.state.loading}
          meta={this.state.meta}>
          <div>
            <div className="list-header">
              <span className="list-column hubHub">Hub</span>
              <span className="list-column hubAddress">Address</span>
              <span className="list-column hubCountry">Country</span>
              <span className="list-column hubContacts">Contacts</span>
              {/*<span className="list-column hubMasterHub">Master hub</span>*/}
            </div>
            <ul>
              {(this.state.list.length === 0) &&
                <li className="no-results">No results found.</li>
              }
              {this.state.list.map(function(listItem, i) {
                return <li id={listItem.id} key={i} className={!listItem.active ? 'disabled' : ''}>
                  <Link to={'/hub?id=' + listItem.id}>
                    <span className="list-column hubHub">{ listItem.name }</span>
                    <span className="list-column hubAddress">{ listItem.address + ((listItem.zip_code || listItem.city) ? ', ' + (listItem.zip_code ? listItem.zip_code : '') + ' ' + (listItem.city ? listItem.city : '') : '') }</span>
                    <span className="list-column hubCountry">{ listItem.country }</span>
                    <span className="list-column hubContacts">{ listItem.contacts.map(function(contactsItem, j) {return <span key={j}><b>• {contactsItem.name}</b>, {contactsItem.phone}, {contactsItem.email}{(j+1 < listItem.contacts.length) ? ' ' : ''}  </span>}) }</span>
                    {/*<span className="list-column hubMasterHub">{ listItem.masterHub }</span>*/}
                  </Link>
                </li>;
              })}
            </ul>
          </div>
        </ListComponent>
        
        {/*<h1>Hello world! hubs</h1>*/}
      </div>
    );
  }
}

Hubs.propTypes = {
  location: React.PropTypes.object.isRequired
};