import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export default class FormFooter extends React.Component {
  constructor (props) {
    super(props);
  }

  render () {
    const currentStep = this.props.currentStep,
      sendResponse = this.props.sendResponse,
      totalSteps = this.props.totalSteps;
    
    if (totalSteps === 2) {
      return(
        <Modal.Footer>
          {currentStep === 1 &&
            <Button className="pull-right" onClick={()=>this.props.handleStepChange(2)}>Send</Button>
          }
          {currentStep === 2 &&
            <div>
              {/* success case */}
              {sendResponse &&
                <div>
                  <Button className="pull-right" onClick={this.props.toggle}>Close</Button>
                </div>
              }
              {/* error case */}
              {!sendResponse &&
                <div>
                  <Button className="pull-right"onClick={()=>this.props.handleStepChange(1)}>Try again</Button>
                </div>
              }
            </div>
          }
        </Modal.Footer>
      );
    } else if (totalSteps === 4) {
      return(
        <Modal.Footer>
          {currentStep === 1 &&
            <Button className="pull-right" onClick={()=>this.props.handleStepChange(2)}>Select suppliers</Button>
          }
          {currentStep === 2 &&
            <div>
              <Button className="pull-left" onClick={()=>this.props.handleStepChange(1)}>Inquiry details</Button>
              <Button className="pull-right" onClick={()=>this.props.handleStepChange(3)}>Email preview</Button>
            </div>
          }
          {currentStep === 3 &&
            <div>
              <Button className="pull-left" onClick={()=>this.props.handleStepChange(2)}>Select suppliers</Button>
              <Button className="pull-right" onClick={()=>this.props.handleStepChange(4)}>Send</Button>
            </div>
          }
          {currentStep === 4 &&
            <div>
              {/* success case */}
              {sendResponse &&
                <div>
                  <Button className="pull-right" onClick={this.props.toggle}>Close</Button>
                </div>
              }
              {/* error case */}
              {!sendResponse &&
                <div>
                  <Button className="pull-right" onClick={()=>this.props.handleStepChange(3)}>Try again</Button>
                </div>
              }
            </div>
          }
        </Modal.Footer>
      );
    }
  }
}

FormFooter.propTypes = {
  currentStep: React.PropTypes.number.isRequired,
  handleStepChange: React.PropTypes.func.isRequired,
  sendResponse: React.PropTypes.bool,
  toggle: React.PropTypes.func.isRequired, 
  totalSteps: React.PropTypes.number.isRequired
};